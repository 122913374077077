import React, {lazy} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';

import {DEVICE_NOTIFICATION_ROUTES} from './config/route';

const DeviceNotificationHomePage = lazy(() => import('./pages/home/index'));
const DeviceNotificationsCreatePage = lazy(() => import('./pages/create/index'));
const DeviceNotificationsEditPage = lazy(() => import('./pages/edit/index'));

export const DevicesNotificationsRoutes = () => {
  return (
    <Switch>
      <Route exact path={DEVICE_NOTIFICATION_ROUTES.BASE} component={DeviceNotificationHomePage} />
      <Route path={DEVICE_NOTIFICATION_ROUTES.CREATE} component={DeviceNotificationsCreatePage} />
      <Route path={DEVICE_NOTIFICATION_ROUTES.EDIT(':id')} component={DeviceNotificationsEditPage} />
    </Switch>
  );
};

export const useDevicesNotificationRoutes = () => {
  const history = useHistory();

  const goToHomePage = () => history.push(DEVICE_NOTIFICATION_ROUTES.BASE);

  return {
    goToHomePage
  };
};
