import React from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import {NoRecordsFoundMessage, PleaseWaitMessage} from 'src/_metronic/_helpers';
import { Loading } from '../Loading';

interface TableNoDataProps {
  columnsToTable:any,
  paginationTableProps:any
}

export function TableNoData({columnsToTable,paginationTableProps}:TableNoDataProps) {
  const update  = columnsToTable.map((item:any) => {
    return {...item,classes: 'TABLE_NO_DATA_STYLE'}
  })

  return (
    <div style={{position: 'relative', padding: '20px 5px', minHeight: '5vh'}}>
      <BootstrapTable
        onTableChange={(type, props) => {
        }}
        // defaultSorted={[defaultSortedColumn]}
        wrapperClasses='table-responsive'
        bordered={false}
        classes='table table-head-custom table-vertical-center overflow-hidden'
        remote
        bootstrap4
        keyField='id'
        data={[]}
        columns={columnsToTable}
        // columns={tableColumns(lang, intl)}
        {...paginationTableProps}>
        <PleaseWaitMessage entities={[]} />
        <NoRecordsFoundMessage entities={[[]]} />
      </BootstrapTable>
      <Loading />
    </div>
  );
}
