import axios from 'axios';
import {dateToUNIX} from 'src/app/modules/Reports/utils/utils';

const API = process.env.REACT_APP_IDSECURE_API_URL;

export type GetProps = {
  pageSize: number;
  sortField: string;
  pageNumber: number;
  sortOrder: 'asc' | 'desc';
  status: number;
  value?: string;
};

export function getAllParks(props: GetProps) {
  return axios.get(`${API}/parkingLot`, {
    params: {
      ...props
    }
  });
}

export function createNewPark(payload:{description:string, capacity: number, unlimitedCapacity:boolean, parkingSpaces:any[]}){
  return axios.post(`${API}/parkingLot`, payload)
}

export function editPark(payload:{description:string, capacity?: number, id:number, unlimitedCapacity:boolean, parkingSpaces:any[]}){
  return axios.put(`${API}/parkingLot`, payload)
}


export function getParkById(id:number){
  return axios.get(`${API}/parkingLot/${id}`)
}

export function deleteParkById(id:number, erase: boolean){
  return axios.delete(`${API}/parkingLot/${id}`,  {
    params: {
      erase
    }
  })
}


export function getSpacesById(data:any){
  return axios.get(`${API}/parkingSpace`,{
    params:
      data
    
  })
}

export function newParkingSpace(data:any){
  return axios.post(`${API}/parkingSpace`, data)
}

export function editParkingSpace(data:any){
  return axios.put(`${API}/parkingSpace`, data)
}


export function getParkingSpaceById(id:number){
  return axios.get(`${API}/parkingSpace/${id}`)
}

export function deleteParkingById(id:number){
  return axios.delete(`${API}/parkingSpace/${id}`)
}

export function reativeParkingById(id:number){
  return axios.put(`${API}/parkingLot/${id}`)
}