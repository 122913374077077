import {ActionTypes} from './types';

export function ALTER_LANGUAGUE_CONFIG(language: string) {
  return {
    type: ActionTypes.SET_CONFIG_SYSTEM_LANGUAGUE,
    payload: language
  };
}

export function ALTER_CARD_PERSON_CONFIG(CARD: number) {
  return {
    type: ActionTypes.SET_CONFIG_SYSTEM_PERSON_CARD,
    payload: CARD
  };
}

export function ALTER_PACK_CONFIG(pack: {language: string; personCardType: number; personDocumentType: string; timeZone: number; timeZoneCode: string}) {
  return {
    type: ActionTypes.SET_CONFIG_SYSTEM_PACK,
    payload: pack
  };
}
