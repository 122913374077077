import { getVisitorToken, tokenStorageKey } from '../../domain/token-storage-key';
import { VisitorResponse } from '../../domain/visitor';
import {visitorRepo} from '../../repo';
import {GetVisitorErrors} from './errors';

class GetVisitor {
  async exec(): Promise<VisitorResponse> {
    const token = getVisitorToken()
    if(!token) throw new GetVisitorErrors.TokenNotFound()
    
    try {
      return await visitorRepo.getVisitor(token);
    } catch (e) {
      window.sessionStorage.removeItem(tokenStorageKey)
      throw new GetVisitorErrors.InvalidToken();
    }
  }
}

export const getVisitor = new GetVisitor();
