import styled from "styled-components"

export const Container = styled.div`
  article{
    display: flex;
    position: relative;

    span{
      padding: 10px 5px;
      display: block;
      width: 10%;
      text-align: center;

      
    }

    div{
      border-left:1px solid #ddd;
      padding: 10px 5px;
      width: 80%;
      /* display: flex; */
      /* flex-direction: column; */


       &::before{
        content: '';
        width: 13px;
        height: 13px;
        border: 2px solid red;
        border-radius: 50%;
        display: block;
        left: -12px; 
        background: #fff;
        position: relative;
        top: 3px;
      } 

      /* p{
        margin-top: -13px;
        margin-left: 5px;
      } */

      section{
        margin-top: -13px;
         margin-left: 24px;
      }

      
    }
  }
` 