import React from 'react';
import {useState} from 'react';
import {Button} from 'react-bootstrap';
import {useIntl} from 'react-intl';
import {ExceptionModeModal} from './ExceptionModeModal';

export function DevicesExceptionMode() {
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();

  return (
    <>
      <Button
        style={{
          display: 'flex',
          gap: '.5rem',
          alignItems: 'flex-end',
          marginRight: '1.5rem',
          fontSize: 14
        }}
        className='btn btn-danger'
        onClick={() => setIsOpen(true)}>
        {intl.formatMessage({id: 'DEVICE.DEVICE_EXCEPTION_MODE_BUTTON'})}
      </Button>

      <ExceptionModeModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
}
