import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { IntlShape } from 'react-intl';
import { sortCaret, headerSortingClasses, toAbsoluteUrl } from 'src/_metronic/_helpers';
import { Pagination } from 'src/_metronic/_partials/controls';
import { TableNoData } from 'src/_metronic/layout/components/TableNoData';
import BootstrapTable from 'react-bootstrap-table-next';
import { deleteParkingById, getSpacesById } from 'src/services/parkingService';

import { Button, Col, Modal, Row } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { usePermisssion } from 'src/redux/userPermissions/usePermission';

interface PROPS {
  idLot: number;
  name: string;
  fn(value: string): void;
  data: any[];
  fnSetData(id: any): void;
  reload(id: number): void;
  deleteCard(id: number): void;
}
export function Table({ idLot, name, fn, reload, fnSetData, data, deleteCard }: PROPS) {
  const [loadingDataToTable, setLoadingDataToTable] = useState(false);
  const [dataTable, setDataTable] = useState<any[]>([]);

  const [itemSelected, setItemSelected] = useState({
    name: '',
    id: 0
  });
  const [showModalDelete, setShowModalDelete] = useState(false);

  const [isRead, setIsRead] = useState(false);

  const { redirectView, actionsIsDesatived } = usePermisssion();

  useEffect(() => {
    redirectView(20);
    const value = actionsIsDesatived(20);
    setIsRead(value);
  }, []);

  const intl = useIntl();

  const columns = [
    // {
    //   dataField: 'parkingname',
    //   text: intl.formatMessage({id: 'PARKING_TABLE__ONE'}),
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses
    // },

    {
      dataField: 'decription',
      text: intl.formatMessage({ id: 'SCHEDULE.SCHEDULE.DESCRIPTION' })
    },
    {
      dataField: 'capacity',
      text: intl.formatMessage({ id: 'AREA.AREA_CAPACITY' })
    },
    {
      dataField: 'type',
      text: intl.formatMessage({ id: 'REPORTS.BLACK_LIST.TYPE' })
    },
    {
      dataField: 'pcds',
      text: intl.formatMessage({ id: 'PARKING.PCD.TABLE' })
    },
    {
      dataField: 'actions',
      text: intl.formatMessage({ id: 'OPERATOR_ROLE_COLLUM_ACTIONS' }),
      classes: 'text-right pr-0',
      headerClasses: 'text-right pr-3',
      style: {
        minWidth: '100px'
      }
    }
  ];


  const optionPagination = {
    custom: true,
    totalSize: dataTable.length,
    sizePerPageList: window.sizePerPageList,
    sizePerPage: 5,
    page: 1,
    pageStartIndex: 1,
    paginationSize: 5,
    showTotal: true
  };




  async function load() {
    const queryParans = {
      ParkingLotId: idLot,
      pageNumber: 1,
      status: 1,
      pageSize: 10,
      sortField: 'Description',
      sortOrder: 'asc'
    };

    // ParkingLotId=2&pageSize=10&pageNumber=1&status=1&sortOrder=asc&sortField=Id
    // const response = await getSpacesById(queryParans)
    // const data = response.data.data.data



    const daaTransform = data.map((item: any, index: number) => {
      let typeName = '';

      if (item.type === '2' || item.type === 'Enterprise') {
        typeName = intl.formatMessage({ id: 'CREATE_VEHICLE_COMPANY' });
      }

      if (item.type === '1' || item.type === 'Personal') {
        typeName = intl.formatMessage({ id: 'PERSON.BUILDER.PERSONAL' });
      }

      if (item.type === '3' || item.type === 'Common') {
        typeName = intl.formatMessage({ id: 'PARKING_COMMON' });
      }

      return {
        // parkingname:name,
        decription: item.description,
        capacity: item.capacity,
        type: typeName,
        pcds: item.pcdCapacity ? 'Sim' : 'Não',
        id: item.id || 0,

        actions: RenderAction({ ...item, isEnabled: true, index })
      };
    });


    setDataTable(daaTransform);
  }

  function RenderAction(item: any) {
    if (item.isEnabled) {
      return (
        <>
          <a
            className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3'
            onClick={() => {
              sessionStorage.setItem('@CONTROLID@/parking_SPACE/edit', item.id);
              fnSetData(item);
              fn('create');
            }}>
            <span className='svg-icon svg-icon-md svg-icon-primary'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
            </span>
          </a>

          <a
            className='btn btn-icon btn-light btn-hover-danger btn-sm mx-3'
            onClick={() => {
              // handleDelete(item.id, item.personName);

              // parkingSelectedToModal(item.id, item["0"])
              // modalToggle('delete')

              setItemSelected({
                name: item.description,
                id: item.index
              });

              setShowModalDelete(true);
            }}>
            <span className='svg-icon svg-icon-md svg-icon-danger'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
            </span>
          </a>
        </>
      );
    }

    return (
      <>
        <a
          className='btn btn-icon btn-icon btn-light btn-hover-success btn-sm mx-3'
          onClick={() => {
            // parkingSelectedToModal(item.id, item.name)
            // modalToggle('reactive')
          }}>
          <span className='svg-icon svg-icon-md svg-icon-success'>
            <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Check.svg')} />
          </span>
        </a>
      </>
    );
  }

  useEffect(() => {
    load();
  }, [data]);

  return (
    <>
      <PaginationProvider pagination={paginationFactory(optionPagination)}>
        {({ paginationProps, paginationTableProps }: any) => {

          return (
            // <Pagination isLoading={false} paginationProps={paginationProps}>

            // </Pagination>
            <>
              {loadingDataToTable ? (
                <TableNoData columnsToTable={columns} paginationTableProps={paginationTableProps} />
              ) : (
                <BootstrapTable
                  onTableChange={(type, props) => {
                    //alterProps(type, props)
                    // if(type === 'pagination'){
                    //   handlerAlterLimitItens(props.sizePerPage)
                    //   if (props.page !== 0) {
                    //      handlerAlterPage(props.page);
                    //   }
                    // }
                    // setSizePerPage(props.sizePerPage);
                  }}
                  // defaultSorted={[defaultSortedColumn]}
                  wrapperClasses='table-responsive'
                  bordered={false}
                  classes='table table-head-custom table-vertical-center overflow-hidden'
                  remote
                  bootstrap4
                  keyField='id'
                  data={dataTable}
                  columns={columns}
                  {...paginationTableProps}>
                  {/* <PleaseWaitMessage entities={dataToRender} />
                    <NoRecordsFoundMessage entities={[dataToRender]} /> */}
                </BootstrapTable>
              )}
            </>
          );
        }}
      </PaginationProvider>

      <Modal show={showModalDelete} onHide={() => setShowModalDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {intl.formatMessage({ id: 'TOAST.DELETE' })} : {itemSelected.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{intl.formatMessage({ id: 'PARKING.DO_YOU_WANT_TO_INACTIVATE_THIS_VACANCY' })}</Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              setShowModalDelete(false);
            }}>
            {intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' })}
          </Button>

          <Button
            variant='danger'
            disabled={isRead}
            onClick={async () => {
              try {
                // await deleteParkingById(itemSelected.id)
                // reload(idLot)
                deleteCard(itemSelected.id);
                setShowModalDelete(false);
                execToast('success', intl.formatMessage({ id: 'PARKING.VACANCY_SUCCESSFULLY_REMOVED' }), 3000);
              } catch {
                execToast('error', intl.formatMessage({ id: 'Não foi possivel remover a vaga' }), 3000);
              }
            }}>
            {intl.formatMessage({ id: 'TOAST.DELETE' })}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
