import { createSlice } from '@reduxjs/toolkit';

export interface StateDashboard {
  dashboard: DashboardModel;
}

export interface DashboardModel {
  indicators?: Indicators | null;
  accessLastDay?: AccessLastDay[] | null;
  accessLastWeek: AccessLastWeek[] | null;
  realTimeAccess: any[] | null;
  isLoadingRealTimeAccess: boolean,
}

export interface Indicators {
  totalPersonsActive: number;
  totalVisitantsActive: number;
  totalDevicesActive: number;
  totalAccessAuthorized: number;
  totalAccessUnauthorized: number;
  totalPersonsLocation: number,


}

export interface AccessLastDay {
  hour: number;
  qtAccess: number;
}

export interface AccessLastWeek {
  day: string;
  totalPersons: number;
  totalVisitants: number;
}

export interface RealTimeAccess {
  photo: string;
  name: string;
  dataHora: Date;
  destinationArea: string;
  status: number;
}

const initialDashState: DashboardModel = {
  indicators: null,
  accessLastDay: null,
  accessLastWeek: [
    // {
    //   day: 'Mon',
    //   totalPersons: 5,
    //   totalVisitants: 12
    // }
  ],
  realTimeAccess: [],
  isLoadingRealTimeAccess: false,
};
export const callTypes = {
  list: 'list',
  action: 'action'
};

export const dashboardSlices = createSlice({
  name: 'dashboard',
  initialState: initialDashState,
  reducers: {
    catchError: (state: any, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state: any, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    dashIndicatorsFetched: (state: any, action) => {
      state.indicators = action.payload;
    },
    dashAccessLastDayFetched: (state: any, action) => {
      // debugger
      state.accessLastDay = action.payload;
      // .map((f: any, i: number) => {
      //  let data = new Date()
      //  date.setH
      //   return {time: , ...f};
      // });
    },
    dashAccessLastWeekFetched: (state: any, action) => {
      state.accessLastWeek = action.payload;
    },
    dashRealTimeAccessFetched: (state: any, action) => {
      // let {realTimeAccess} = state.value;
      state.realTimeAccess = action.payload.map((f: any, i: number) => {
        return { id: `${i + new Date(f.time).getTime()}`, ...f, new: false };
      });
    },
    dashRealTimeAccessFetchedAdd: (state: any, action) => {
      // let {realTimeAccess} = state.value;
      state.realTimeAccess = [...state.value, action.payload]
    },

    setLoadingRealTimeAccess: (state, action) => {
      state.isLoadingRealTimeAccess = action.payload
    }

  }
});
