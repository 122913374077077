import {createContext, useContext} from 'react';

export type TView = 'form' | 'success' | 'invalidToken';

type VisitorRegistrationContext = {
  view: TView;
  changeView(view: TView): void;
};

export const VisitorRegistrationContext = createContext<VisitorRegistrationContext | null>(null);

export const useVisitorRegistrationContext = () => {
  const ctx = useContext(VisitorRegistrationContext);
  if (!ctx) throw new Error('You must use this hook inside VisitorRegistrationContext');

  return ctx;
};
