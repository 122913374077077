import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;

  display: flex;
  flex-wrap: wrap;
  & > * {
    flex: 1 1 380px;
    max-width: 380px;

    @media (max-width: 1300px) {
      max-width: 100%;
    }
  }

  gap: 20px;
`;
