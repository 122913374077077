import React from 'react';

function valueBoolean(value: any) {
  if (value) return 'True';

  return 'False';
}

export function textToElement(origin: string) {
  if (typeof origin !== 'string' || origin === '') {
    return origin;
  }

  try {
    const obj = JSON.parse(origin);

    const allKeys = Object.keys(obj);
    const allValues: any[] = Object.values(obj);

    const elementRencerKeysAndValues = allKeys.map((key, index) => {
      return (
        <>
          <span key={key}>
            <strong>{key}: </strong>
            {typeof allValues[index] === 'boolean' ? valueBoolean(allValues[index]) : allValues[index]}
          </span>
          <br />
        </>
      );
    });

    return elementRencerKeysAndValues;
  } catch {
    return origin;
  }
}
