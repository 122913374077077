import styled from 'styled-components';

export const NavContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 2px 10px;
`;

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 10px;
  margin-top: 10px;
`;

export const ArticleContainer = styled.form`
  .tab_header {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    button {
      margin-right: 10px;
    }
  }

  .function-panel {
    border: 1px solid #dedede;
    border-radius: 5px;
    padding: 2px 10px;

    & + .function-panel {
      margin-top: 10px;
    }
  }

  .container-radios {
    display: flex;

    gap: 6px;
    width: 600px;
    flex-wrap: wrap;

    .container-radio {
      display: flex;
      /* justify-content: center;
      align-items: center; */
      /* gap:4px; */
      width: 290px;

      label {
        display: inline-block;
        margin-left: 6px;
        margin-top: 0px;
      }
    }
  }
`;

export const RowSelect = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
