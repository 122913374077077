import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, CardBody, CardHeader, Input } from '../../../../_metronic/_partials/controls';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { Field, Formik } from 'formik';
import { Row } from 'react-bootstrap';
import { createCreditType } from 'src/services/creditsCrud';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { usePermisssion } from 'src/redux/userPermissions/usePermission';
import { useLang } from 'src/_metronic/i18n';

export function CreditTypeCreate() {
  const history = useHistory();
  const intl = useIntl();
  const lang = useLang()
  const description = intl.formatMessage({ id: 'CREDITS_TYPE.DESCRIPTION' });
  const maxDaily = intl.formatMessage({ id: 'CREDITS_TYPE.MAXDAILY' });
  const [isRead, setIsRead] = useState(false);

  const { redirectView, actionsIsDesatived } = usePermisssion();

  useEffect(() => {
    redirectView(16);
    const value = actionsIsDesatived(16);

    setIsRead(value);
  }, []);

  function INTL(value: string) {
    return intl.formatMessage({ id: value });
  }

  const validationSchema = Yup.object().shape({
    description: Yup.string()
      .min(3)
      .required('Name is required'),
    maxDailyConsumption: Yup.number().min(0),
    unitValue: Yup.string(),
  });

  async function handleSave(values: any) {
    // console.log({ values })
    try {
      await createCreditType(values);
      execToast('success', INTL('TOAST.CREDITS_TYPE.SUCCESSFULL_CREATED'), 3000);
      history.push('/credittype')

    } catch (erro: any) {

      if (erro.response.data.message == '410') {
        execToast('error', INTL('TOAST.CREDITS_TYPE.ALREADY_EXISTIS'), 3000);
        return
      }

      if (erro.response.data.message == '456') {
        execToast('error', INTL('TOAST.CREDITS_TYPE.MAX_IS_ZERO'), 3000);
        return
      }
    }
  }


  //   {
  //     '1.000.00'.split('').forEach((v, i) => {
  //     if(v === '.'){
  //         a = i
  //     }
  // })
  //   }

  return (
    <Card fluidHeight={null} className={null}>
      <CardHeader icon={null} toolbar={null} className={null} labelRef={null} sticky={false} linkAddTitle={undefined} title={INTL('CREDITS_TYPE.ADD.TILTE')} />
      <CardBody fit={null} fluid={null} className={null}>
        <Formik
          initialValues={{
            description: '',
            maxDailyConsumption: 0,
            // unitValue: ''
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values, { setFieldError }) => {
            values.description = values.description.trim();
            //@ts-ignore
            let unit = document.querySelector('[name="unitValue"]').value


            const payload = {
              ...values,
              unitValue: unit.replace(',', '.')
            }
            handleSave(payload);
          }}>
          {({ handleSubmit, resetForm }) => (
            <>
              <Row className='justify-content-between mb-5 p-5'>
                <div className='col-6'>
                  <label htmlFor="">{description}</label>
                  <Field name='description' component={Input} placeholder={''} />
                </div>
                <div className='col-6'>
                  <label htmlFor="">{maxDaily}</label>
                  <Field name='maxDailyConsumption' component={Input} placeholder={maxDaily} />
                </div>
              </Row>

              <Row className='justify-content-between mb-2 p-5'>
                <div className='col-6'>
                  <label htmlFor="">{intl.formatMessage({ id: "VALUE.UNIT" })}</label>
                  <Field name='unitValue'
                    component={Input}
                    placeholder={''}
                    onChange={(e: any) => {

                      const removeLetters = e.target.value.replaceAll(/[a-z]/gi, '')
                      let value = removeLetters
                      let separator = lang === 'pt' ? ',' : '.'

                      e.target.value = value
                    }}
                  // mask={
                  //   lang === 'pt' ? '9999,99' : '9999.99'
                  // }
                  />
                </div>
              </Row>

              <div className='align-right m-5'>
                <button id='novo-tipo-de-credito-limpar' className='btn btn-light btn-elevate mr-5' type='reset' onClick={() => resetForm()}>
                  <i className='fa fa-redo-alt' />
                  <FormattedMessage id='PERSON.NEWPERSON_CLEAR_BUTTON' />
                </button>
                <Link id='novo-tipo-de-credito-cancelar' type='button' className='btn btn-danger mr-5' to='/credittype'>
                  <FormattedMessage id='PERSON.NEWPERSON_CANCEL_BUTTON' />
                </Link>
                <button
                  disabled={isRead}
                  id='novo-tipo-de-credito-salvar'
                  type='submit'
                  onClick={() => {
                    if (isRead) return;
                    handleSubmit();
                  }}
                  className='btn btn-primary btn-elevate'>
                  <FormattedMessage id='PERSON.NEWPERSON_SAVE_BUTTON' />
                </button>
              </div>
            </>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};
