import axios from 'axios';
import {ILicenseRepo, LicenseModel} from 'src/app/modules/Config/components/LicensesTab/useCases/GetLicense';

const idsecureAPI = process.env.REACT_APP_IDSECURE_API_URL;

export type createConfig = {
  personId?: number;
  defaultLanguage: number;
  defaultDocumentType: string;
  defaultCompanyIDType: string;
  defaultCardType: number;
  defaultTimeZone: number;
  defaultTimeZoneCode: string;
};

interface UpdateProps extends createConfig {
  id: number;
}

export function createConfigService(props: createConfig) {
  const a = {...props};
  if (a.personId) {
    delete a.personId;
  }
  return axios.post(`${idsecureAPI}/config/global`, props);
}

export function updateConfigService(props: UpdateProps) {
  return axios.put(`${idsecureAPI}/config/global`, props);
}

// export function createConfigService(props: createConfig) {
//   return axios.post(`${idsecureAPI}/config`, props);
// }

// export function updateConfigService(props: UpdateProps) {
//   return axios.put(`${idsecureAPI}/config`, props);
// }

export function getConfigService(id: number, token = '') {
  if (token) {
    //axios.headers.Authorization = `Bearer ${token}`
    return axios.get(`${idsecureAPI}/config/global`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
  return axios.get(`${idsecureAPI}/config/global`);
}

export function getConfigServiceDashboard(id: number, token = '') {
  if (token) {
    //axios.headers.Authorization = `Bearer ${token}`
    return axios.get(`${idsecureAPI}/dashboard/config/global`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
  return axios.get(`${idsecureAPI}/config/global`);
}

// CHANDED POLICY
export function getConfigServiceChangedPolicy(id: number, token = '') {
  if (token) {
    //axios.headers.Authorization = `Bearer ${token}`
    return axios.get(`${idsecureAPI}/persons/config/global`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
  return axios.get(`${idsecureAPI}/persons/config/global`);
}

// CHANDED POLICY
export function getConfigServiceChangedPolicyVisitors(id: number, token = '') {
  if (token) {
    //axios.headers.Authorization = `Bearer ${token}`
    return axios.get(`${idsecureAPI}/visitors/config/global`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
  return axios.get(`${idsecureAPI}/visitors/config/global`);
}

// export function getConfigService(id: number, token = '') {
//   if (token) {
//     //axios.headers.Authorization = `Bearer ${token}`
//     return axios.get(`${idsecureAPI}/config/${id}`, {
//       headers: {
//         Authorization: `Bearer ${token}`
//       }
//     });
//   }
//   return axios.get(`${idsecureAPI}/config/${id}`);
// }

type ADD_TYPE_DOCUMENT_PROPS = {
  description: string;
  expirationBlockAccess: boolean;
  hasExpirationDate: boolean;
  hasImageAttached: boolean;
};

export function ADD_TYPE_DOCUMENT(data: ADD_TYPE_DOCUMENT_PROPS) {
  return axios.post(`${idsecureAPI}/config/documentTypes`, data);
}

export function GET_ALL_TYPE_DOCUMENT() {
  return axios.get(`${idsecureAPI}/config/documentTypes`);
}

export function GET_ALL_TYPE_DOCUMENT_PERSON() {
  return axios.get(`${idsecureAPI}/persons/config/documentTypes`);
}

export function GET_ALL_TYPE_DOCUMENT_VISITOR() {
  return axios.get(`${idsecureAPI}/visitors/config/documentTypes`);
}

export function GET_ALL_TYPE_DOCUMENT_REPORTS() {
  return axios.get(`${idsecureAPI}/reports/documentTypes`);
}



export function DELETE_ONE_TYPE_DOCUMENT(name: string) {
  return axios.delete(`${idsecureAPI}/config/documentTypes`, {
    params: {
      name
    }
  });
}

export function UPDATE_ONE_TYPE_DOCUMENT(data: ADD_TYPE_DOCUMENT_PROPS) {
  return axios.put(`${idsecureAPI}/config/documentTypes`, data);
}

type DATA_CREATE_GLOBAL_CONFIG = {
  'defaultDocumentTypeId': number;
  'defaultCardType': string;
  'enableRandomSecurityCheck': boolean;
  'enableCreditControl': boolean;
  'enableAccessMethodsConfig': boolean;
};

export function CREATE_GLOBAL_CONFIG(data: DATA_CREATE_GLOBAL_CONFIG) {
  return axios.post(`${idsecureAPI}/config/global`, data);
}

export function GET_GLOBAL_CONFIG() {
  return axios.get(`${idsecureAPI}/config/global`);
}

export function GET_GLOBAL_CONFIG_ACCESS_RULES() {
  return axios.get(`${idsecureAPI}/accessrules/global`);
}

export function GET_GLOBAL_CONFIG_ACCESS_RULES_CHANGED_POLICY() {
  return axios.get(`${idsecureAPI}/vehicleaccessrules/config/global`);
}

export class LicenseRepo implements ILicenseRepo {
  private readonly url = `${idsecureAPI}/config/licenseStatus`;

  async getLicense(): Promise<LicenseModel> {
    const {data} = await axios.get(this.url);

    return {
      user: {
        total: data.data.maxUsers,
        used: data.data.currentUsers,
        percentage: (data.data.currentUsers / data.data.maxUsers) * 100
      },
      device: {
        total: data.data.maxDevices,
        used: data.data.currentDevices,
        percentage: (data.data.currentDevices / data.data.maxDevices) * 100
      }
    };
  }
}
