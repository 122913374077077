import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import {Card, CardBody, CardHeader} from '../../../../_metronic/_partials/controls';
import {useTableHooks} from '../hooks/table';
//@ts-ignore
import {NotificationContent} from './Content';

import '../createStyles.css';
export function CreateNotification() {
  const {INTL} = useTableHooks();
  const {pathname} = useLocation();
  const isEditing = !!pathname.includes('/notifications/edit/');

  return (
    <Card fluidHeight={null} className={null}>
      <CardHeader icon={null} toolbar={null} className={null} labelRef={null} sticky={false} linkAddTitle={undefined} title={INTL(isEditing ? 'NOTIFICATION.EDIT_TITLE' : 'NOTIFICATION.CREATE_TITLE')} />
      <CardBody fit={null} fluid={null} className={null}>
        <NotificationContent />
      </CardBody>
    </Card>
  );
}
