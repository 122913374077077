import axios from 'axios';

const idsecureAPI = process.env.REACT_APP_IDSECURE_API_URL;

export function createArea(area: any) {
  return axios.post(`${idsecureAPI}/areas`, {...area});
}

export function getAllAreas(params: any) {
  return axios.get(`${idsecureAPI}/areas`, {params});
}

export function getAreaById(areaId: number) {
  return axios.get(`${idsecureAPI}/areas/${areaId}`);
}

export function findAreas(params: any) {
  return axios.get(`${idsecureAPI}/areas`, {params});
}

export function updateArea(area: any) {
  return axios.put(`${idsecureAPI}/areas`, {...area});
}

export function deleteArea(areaId: number, erase?: boolean) {
  return axios.delete(`${idsecureAPI}/areas/${areaId}`, {
    params: {
      erase
    }
  });
}

export function enableArea(areaId: number) {
  return axios.put(`${idsecureAPI}/areas/${areaId}`);
}
