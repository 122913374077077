import {createSlice} from '@reduxjs/toolkit';

const initialTimesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  timesForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: 'list',
  action: 'action'
};

export const timesSlice = createSlice({
  name: 'times',
  initialState: initialTimesState,
  reducers: {
    catchError: (state: any, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state: any, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    timeFetched: (state: any, action) => {
      state.actionsLoading = false;
      state.timeForEdit = action.payload.timeForEdit;
      state.error = null;
    },
    timesFetched: (state: any, action) => {
      const {totalCount, entities} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    timeCreated: (state: any, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.time);
    },
    timeUpdated: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity: any) => {
        if (entity.id === action.payload.time.id) {
          return action.payload.time;
        }
        return entity;
      });
    },
    timeDeleted: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el: any) => el.id !== action.payload.id);
    },
    linkedTimes: (state: any, action) => {
      const {times} = action.payload;
      state.linkedTimesIds = times;
    },
    linkedTimesDeleted: (state: any, action) => {
      state.linkedTimesIds = state.linkedTimesIds.filter((el: any) => el.id !== action.payload.id);
    }
  }
});
