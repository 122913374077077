// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useCallback, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { getHandlerTableChange, NoRecordsFoundMessage, PleaseWaitMessage, sortCaret, headerSortingClasses } from '../../../../../_metronic/_helpers';
import { Pagination } from 'src/_metronic/_partials/controls';
import { useIntl } from 'react-intl';
import { findPersons } from '../../../../../services/personsCrud';

// import * as uiHelpers from '../../../Person/pages/PersonsUIHelpers';
import { isEqual, isFunction } from 'lodash';

import './style.css';
import { findGroups } from 'src/services/accessLogsCrud';

const initialFilter = {
  value: '',
  status: 1,
  sortOrder: 'asc', // asc||desc
  sortField: 'Name',
  pageNumber: 1,
  pageSize: 10,
  personType: 'Person'
};

interface PROPS {
  users: any[];
  fnUserSeelected: (value: any) => void;
}

export const PersonGroups = ({ users, fnUserSeelected }: PROPS) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [entities, setEntities] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);

  const usersListNoselected = entities
    // .filter((item) => {
    //   const userSelected = users.find((user) => user.id === item.id);

    //   if (!userSelected) return item;
    // })
    .map((item) => {
      return {
        ...item,
        name: item.description === 'GROUPS.DEFAULT.PERSON' || item.description === 'GROUPS.DEFAULT.PERSON' ? intl.formatMessage({ id: "GROUPS.DEFAULT.PERSON" }) : item.description,
        action: <input type='checkbox' checked={users.includes(item.id)} onChange={() => fnUserSeelected(item)} />
      };
    });

  const diffUsers = entities.length - usersListNoselected.length;

  const NameText = intl.formatMessage({ id: 'PERSON.PERSONS_TABLE_NAME' });
  const registrationText = intl.formatMessage({ id: 'PERSON.PERSONS_TABLE_REGISTRATION' });
  const phoneNumberText = intl.formatMessage({ id: 'PERSON.PERSONS_TABLE_PHONE_NUMBER' });

  const handleFetchPeople = async () => {
    setLoading(true);

    const payload: any = {
      groupType: 1,
      status: 1,
      sortOrder: 'asc',
      sortField: 'Description',
      pageNumber: queryParams.pageNumber,
      pageSize: queryParams.pageSize,
      value: queryParams.value
    }

    if (!payload.value) delete payload.value

    const {
      data: {
        data: { data: people, total: totalCount }
      }
    } = await findGroups(payload);

    setEntities(people as any);
    setTotalCount(totalCount);
    setLoading(false);
  };

  const columns = [
    {
      dataField: 'action',
      text: ''
    },
    {
      dataField: 'name',
      text: NameText,
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    }
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    // totalSize: totalCount - diffUsers,
    sizePerPageList: window.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber
  };



  useEffect(() => {
    (async () => {
      await handleFetchPeople();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await handleFetchPeople();
    })();
  }, [queryParams]);

  const setQueryParams = useCallback(
    (nextQueryParams: any) => {
      setQueryParamsBase((prevQueryParams) => {
        if (isFunction(nextQueryParams)) {
          nextQueryParams = nextQueryParams(prevQueryParams);
        }

        if (isEqual(prevQueryParams, nextQueryParams)) {
          return prevQueryParams;
        }

        return nextQueryParams;
      });
    },
    [queryParams, initialFilter]
  );


  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }: { paginationProps: any; paginationTableProps: any }) => {
        return (
          <Pagination isLoading={loading} paginationProps={paginationProps}>
            <form className='form form-label-right' onSubmit={(event) => {
              event.preventDefault()
            }}>
              <div className='form-group row justify-left'>
                <div className='col-12'>
                  <input
                    onChange={(e) => {
                      setQueryParamsBase({ ...queryParams, value: e.target.value, pageNumber: 1 });
                    }}
                    type='text'
                    className='form-control'
                    name='searchText'
                    placeholder={intl.formatMessage({ id: 'PERSON.PERSONS_FILTER_SEARCH_PH' })}
                  />
                </div>
              </div>
            </form>
            <BootstrapTable
              wrapperClasses='table-responsive'
              bordered={false}
              classes='table table-head-custom table-vertical-center overflow-hidden NOTRCHCKBOX'
              bootstrap4
              remote
              keyField='id'
              data={usersListNoselected}
              columns={columns}
              // selectRow={selectRow}
              // defaultSorted={uiHelpers.defaultSorted}
              onTableChange={(type, value) => {


                if (type === "pagination") {
                  setQueryParamsBase(current => {
                    return {
                      ...current, pageSize: value.sizePerPage,
                      pageNumber: value.page,
                    }
                  })
                }
              }}
              {...paginationTableProps}>
              <PleaseWaitMessage entities={usersListNoselected} />
              <NoRecordsFoundMessage entities={usersListNoselected} />
            </BootstrapTable>
          </Pagination>
        );
      }}
    </PaginationProvider>
  );
};

// export default injectIntl(ListPersonsTable);
