import {IntlShape} from 'react-intl';
import {sortCaret, headerSortingClasses} from 'src/_metronic/_helpers';

export function GridListColumns(intl: IntlShape) {
  const columns = [
    {
      dataField: 'description',
      text: intl.formatMessage({id: 'MANAGEMENT_TYPES_CREDITS_GRID_COLUMN_ONE'}),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },

    {
      dataField: 'numberUser',
      text: intl.formatMessage({id: 'MANAGEMENT_TYPES_CREDITS_GRID_COLUMN_TWO'})
    },
    {
      dataField: 'UserPrice',
      text: intl.formatMessage({id: 'MANAGEMENT_TYPES_CREDITS_GRID_COLUMN_TREE'})
    },
    {
      dataField: 'actions',
      text: intl.formatMessage({id: 'OPERATOR_ROLE_COLLUM_ACTIONS'}),
      classes: 'text-right pr-0',
      headerClasses: 'text-right pr-3',
      style: {
        minWidth: '100px'
      }
    }
  ];

  return columns;
}
