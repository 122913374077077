import { IntlShape } from 'react-intl';
import { setExceptionMode } from 'src/services/devicesCrud';

export async function DeviceNormal(id: number, intl: IntlShape) {
  try {
    setExceptionMode({
      deviceId: id,
      mode: 1,
    })
  } catch (e) {
    console.log({ e })
  }
}

export async function DeviceEmergency(id: number, intl: IntlShape) {
  try {
    setExceptionMode({
      deviceId: id,
      mode: 2,
    })
  } catch (e) {
    console.log({ e })
  }
}

export async function DeviceLockDown(id: number, intl: IntlShape) {
  try {
    await setExceptionMode({
      deviceId: id,
      mode: 3,
    })
  } catch (e) {
    console.log({ e })
  }
}