import axios from 'axios';

const idsecureAPI = process.env.REACT_APP_IDSECURE_API_URL;

export function getAllModules() {
  return axios.get(`${idsecureAPI}/operatorRole/modules`);
}

export function getAllFunctions() {
  return axios.get(`${idsecureAPI}/operatorRole/modulesFunctionality`);
}

export function saveOperation(data: any) {
  return axios.post(`${idsecureAPI}/operatorRole`, data);
}

export function editOperation(data: any) {
  return axios.put(`${idsecureAPI}/operatorRole`, data);
}

export function deleteOperation(id: number) {
  return axios.delete(`${idsecureAPI}/operatorRole/${id}`);
}

export function activeOperation(id: number) {
  return axios.put(`${idsecureAPI}/operatorRole/${id}`);
}

export function getOperation(id: number) {
  return axios.get(`${idsecureAPI}/operatorRole/${id}`);
}

export type GetProps = {
  pageSize: number;
  sortField: string;
  pageNumber: number;
  sortOrder: string;
  status: number;
  value?: string;
  removeAdminOperatorRole?: boolean;
};

export function getOperations(props: GetProps) {
  return axios.get(`${idsecureAPI}/operatorRole`, {
    params: {
      ...props
    }
  });
}

export function findPersons(params: any) {
  return axios.get(`${idsecureAPI}/operatorRole/person`, {params});
}
