import { IntlShape } from 'react-intl'
import React from 'react'

interface Props {
  intl: IntlShape,
  value: number,
  setValue: React.Dispatch<React.SetStateAction<number>>
}

export function Select({ intl, setValue, value }: Props) {

  const labelPeople = intl.formatMessage({ id: 'ASIDE.MENU.ITEM.PERSON' })
  const labelVisitor = intl.formatMessage({ id: 'ASIDE.MENU.ITEM.VISITORS' })
  const labelOther = intl.formatMessage({ id: 'Ambos' })

  function changed(e: React.ChangeEvent<HTMLSelectElement>) {
    setValue(+e.target.value)
  }

  return (
    <select className='form-control' onChange={changed} value={value ? value.toString() : '0'}>
      <option value="1">{labelPeople}</option>
      <option value="2">{labelVisitor}</option>
      <option value="0">{labelOther}</option>
    </select>
  )
}