import React, {forwardRef, useEffect, useState} from 'react';
import clsx from 'clsx';
import {isFragment} from 'react-is';
import SVG from 'react-inlinesvg';
import {toAbsoluteUrl} from 'src/_metronic/_helpers';
import {Link} from 'react-router-dom';
import {Button} from 'react-bootstrap';

export const CardHeaderIcon = forwardRef(({className}: {className: string | null}, ref: React.ForwardedRef<any>) => (
  <span ref={ref} className={clsx('card-head-icon', className)} />
));

export const CardHeaderTitle = forwardRef(
  ({className, ...props}: {className: string | null; children?: React.ReactNode}, ref: React.ForwardedRef<any>) => (
    // eslint-disable-next-line jsx-a11y/heading-has-content
    <h3 {...props} ref={ref} className={clsx('card-label', className)} />
  )
);

export const CardHeaderToolbar = forwardRef(
  (
    {children, className, ...props}: {children: React.ReactNode | null; className: string | null},
    ref: React.ForwardedRef<any>
  ) => (
    <div {...props} ref={ref} className={clsx('card-toolbar', className)}>
      {children}
    </div>
  )
);

export const CardHeader = forwardRef(
  (
    {
      children,
      icon,
      title,
      toolbar,
      className,
      classNameHeight,
      sticky = false,
      labelRef,
      linkAddTitle,
      linkAddTo,
      minHeight,
      ...props
    }: {
      children?: React.ReactNode;
      icon: any;
      title: string;
      toolbar: any;
      className: string | null;
      classNameHeight?: string | null;
      sticky: Boolean;
      linkAddTitle?: string;
      linkAddTo?: string;
      minHeight?: number;
      labelRef: any;
    },
    ref: React.ForwardedRef<any>
  ) => {
    const [top, setTop] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);

    useEffect(() => {
      handleResize();

      function handleResize() {
        setWindowHeight(window.innerWidth);
      }

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    });

    useEffect(() => {
      // Skip if sticky is disabled or on initial render when we don't know about window height.
      if (!sticky || windowHeight === 0) {
        return;
      }

      const headerElement = document.querySelector('.header') as HTMLElement;
      const subheaderElement = document.querySelector('.subheader') as HTMLElement;
      const headerMobileElement = document.querySelector('.header-mobile') as HTMLElement;

      let nextMarginTop = 0;

      // mobile header
      if (
        headerElement != null &&
        headerMobileElement != null &&
        window.getComputedStyle(headerElement).height === '0px'
      ) {
        nextMarginTop = headerMobileElement.offsetHeight;
      } else {
        // desktop header
        if (document.body.classList.contains('header-minimize-topbar')) {
          // hardcoded minimized header height
          nextMarginTop = 60;
        } else {
          // normal fixed header
          if (document.body.classList.contains('header-fixed')) {
            nextMarginTop += headerElement.offsetHeight;
          }

          if (document.body.classList.contains('subheader-fixed')) {
            nextMarginTop += subheaderElement.offsetHeight;
          }
        }
      }

      setTop(nextMarginTop);
    }, [sticky, windowHeight]);

    return (
      <div
        ref={ref}
        className={clsx('card-header', classNameHeight)}
        style={!sticky ? undefined : {top, position: 'sticky', backgroundColor: '#fff'}}>
        {title != null && (
          <div ref={labelRef} className={clsx('card-title titulo-card', className)}>
            {icon}

            {/* Wrap string and fragments in CardHeaderTitle */ typeof title === 'string' || isFragment(title) ? (
              <CardHeaderTitle className={null}>{title}</CardHeaderTitle>
            ) : (
              title
            )}
            {linkAddTo && (
              <Link title={linkAddTitle} type='button' to={linkAddTo}>
                <span className='svg-icon svg-icon-xl svg-icon-primary'>
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Plus.svg')} />
                </span>
              </Link>
            )}
          </div>
        )}

        {toolbar}
        {children}
      </div>
    );
  }
);

export const CardBody = forwardRef(
  (
    {
      fit,
      fluid,
      className,
      ...props
    }: {fit: any | null; fluid: any | null; className: string | null; children?: React.ReactNode},
    ref: React.ForwardedRef<any>
  ) => (
    <div
      {...props}
      ref={ref}
      className={clsx(
        'card-body',
        {
          'card-body-fit': fit,
          'card-body-fluid': fluid
        },
        className
      )}
    />
  )
);

export const CardFooter = forwardRef(
  ({className, ...props}: {className: string | null; children?: React.ReactNode}, ref: React.ForwardedRef<any>) => (
    <div {...props} ref={ref} className={clsx('card-footer', className)} />
  )
);

export const Card = forwardRef(
  (
    {
      fluidHeight,
      className,
      ...props
    }: {fluidHeight: any; className: string | null; children?: React.ReactNode; style?: React.CSSProperties},
    ref: React.ForwardedRef<any>
  ) => (
    <div
      {...props}
      ref={ref}
      className={clsx('card card-custom gutter-b', {'card-height-fluid': fluidHeight}, className)}
    />
  )
);

// Set display names for debugging.
if (process.env.NODE_ENV !== 'production') {
  Card.displayName = 'Card';

  CardHeader.displayName = 'CardHeader';
  CardHeaderIcon.displayName = 'CardHeaderIcon';
  CardHeaderTitle.displayName = 'CardHeaderTitle';
  CardHeaderToolbar.displayName = 'CardHeaderToolbar';

  CardBody.displayName = 'CardBody';
  CardFooter.displayName = 'CardFooter';
}
