/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import {useSelector} from 'react-redux';
import objectPath from 'object-path';
import {useHtmlClassService} from '../../../_core/MetronicLayout';
import {toAbsoluteUrl} from '../../../../_helpers';
import {DropdownTopbarItemToggler} from '../../../../_partials/dropdowns';
import {FormattedMessage, useIntl} from 'react-intl';
import {Avatar} from '@material-ui/core';
import {logout} from 'src/services/authCrud';
import {useHistory} from 'react-router-dom';
import {BsQuestionLg} from 'react-icons/bs';
import {FaBookReader, FaFilePdf, FaListAlt, FaWifi} from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import './styles.css';
import { modulesConfigCulture } from 'src/utils/modulesControll';

export function UserManualDropdown() {
  const history = useHistory();
  const intl = useIntl()
  const locale = intl.locale;
  const {authToken: user} = useSelector((state) => state.auth);
  const {operator} = useSelector((state) => state.operator);
  const uiService = useHtmlClassService();
  const {remoteAccess} = modulesConfigCulture.getValues()
  const layoutProps = useMemo(() => {
    return {
      light: objectPath.get(uiService.config, 'extras.user.dropdown.style') === 'light'
    };
  }, [uiService]);

  async function handleSignOut() {
    try {
      await logout();

      //history.push('/logout');
    } catch {
    } finally {
      history.push('/logout');
    }
  }
  const currentUrl = window.location.origin;

  // let manualUrl = `${currentUrl}/site`;

  let manualUrl = `${currentUrl}/site/pt`;
    if (locale === 'en') {
    manualUrl = `${currentUrl}/site/en`;
  } else if (locale === 'es') {
    manualUrl = `${currentUrl}/site/es`;
  }

  let manualUrlPdf = `${currentUrl}/site/pt.pdf`;
    if (locale === 'en') {
    manualUrlPdf = `${currentUrl}/site/en/en.pdf`;
  } else if (locale === 'es') {
    manualUrlPdf = `${currentUrl}/site/Manual do iDSecure SaaS.pdf`;
  }

  return (
    <Dropdown drop='down' alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id='dropdown-toggle-user-profile'>
        <ReactTooltip className='shadow' id='manual-tooltip' type='light' effect='solid'>
          {intl.formatMessage({id:'CENTER.HELP'})}
        </ReactTooltip>
        <buttun
          className='helper-center-button'
          data-tip
          data-for='manual-tooltip'
          type='button'
          style={{paddingRight: '16px'}}>
          <BsQuestionLg />
        </buttun>
      </Dropdown.Toggle>

      <Dropdown.Menu className='custom-drop-down-menu' rootCloseEvent='mousedown'>
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className='d-flex align-items-center p-8 rounded-top'>
                <div className='text-dark m-0 flex-grow-1 mr-3 font-size-h5'>{intl.formatMessage({id:'CENTER.HELP'})}</div>
                <span className='label label-light-success label-lg font-weight-bold label-inline'>3 messages</span>
              </div>
              <div className='separator separator-solid'></div>
            </>
          )}

          {!layoutProps.light && (
            <div className='custom-speech-bubbles-help-center'>
              <div className='speech-bubbles-triangle-hc' />
              <div
                className='d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top'
                style={{
                  backgroundImage: `url(${toAbsoluteUrl('/media/misc/bg-1.jpg')})`,
                  zIndex: '99'
                }}>
                <div className='text-white m-0 flex-grow-1 mr-3 font-size-h5'>{intl.formatMessage({id:'CENTER.HELP'})}</div>
              </div>
            </div>
          )}
        </>

        <div className='help-center-navi-container'>
          <button
          style={{border: 'none', backgroundColor: 'transparent'}}
            onClick={() => {
              window.open(manualUrl, '_blank', 'noopener, noreferrer');
            }}
            className='navi-item px-8 cursor-pointer'>
            <div className='helper-center-item'>
              <div className='helper-center-item-icon'>
                <FaBookReader />
              </div>
              <div className='helper-center-item-text'>
                <FormattedMessage id='MANUAL.SYSTEM' />
              </div>
            </div>
          </button>

          <button
          style={{border: 'none', backgroundColor: 'transparent'}}
            onClick={() => {
              window.open(manualUrlPdf, '_blank', 'noopener, noreferrer');
            }}
            className='navi-item px-8 cursor-pointer'>
            <div className='helper-center-item'>
              <div className='helper-center-item-icon'>
                <FaFilePdf />
              </div>
              <div className='helper-center-item-text'>
                <FormattedMessage id='MANUAL.SYSTEM.PDF' />
              </div>
            </div>
          </button>

          {/* <div className='navi-item px-8 cursor-pointer'>
            <a
              href='https://rhid.com.br/v2/Acordo%20de%20Licenca%20Para%20Usu%C3%A1rio%20Final%20(EULA).pdf'
              target='_blank'
              rel='noopener noreferrer'>
              <div className='helper-center-item'>
                <div className='helper-center-item-icon'>
                  <FaFilePdf />
                </div>
                <div className='helper-center-item-text'>
                  <FormattedMessage id='Manual em PDF' />
                </div>
              </div>
            </a>
          </div> */}

          <Link to='/releases' className='navi-item px-8 cursor-pointer'>
            <div className='helper-center-item'>
              <div className='helper-center-item-icon'>
                <FaListAlt />
              </div>
              <div className='helper-center-item-text'>
                <FormattedMessage id='Release Notes' />
              </div>
            </div>
          </Link>

          {
            remoteAccess && (
              <button 
            style={{border: 'none', backgroundColor: 'transparent'}} 
            className='navi-item px-8 cursor-pointer'
            onClick={() => {
              
              window.open(remoteAccess, '_blank', 'noopener, noreferrer');
            }}
            >
            <div className='helper-center-item'>
              <div className='helper-center-item-icon'>
                <FaWifi />
              </div>
              <div className='helper-center-item-text'>
                <FormattedMessage id='TEAMVIWER' />
              </div>
            </div>
          </button>
            )
          }
          

          {/* <div className='navi-footer  px-8 py-5'>
            <button onClick={handleSignOut} to='/logout' className='btn btn-light-primary font-weight-bold'>
              <FormattedMessage id='AUTH.GENERAL.LOGOUT_BUTTON' />
            </button>
          </div> */}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
