import axios from 'axios';
import { defaultDateTime } from 'src/utils/defaultDateTime';

const idsecureAPI = process.env.REACT_APP_IDSECURE_API_URL;
const idsecureAPI_REPORTS = process.env.REACT_APP_IDSECURE_API_URL_REPORTS

export function getAllPersons(params: any) {
  return axios.get(`${idsecureAPI}/permissionReports/persons`, { params });
}

export function getAllAreas(params: any) {
  return axios.get(`${idsecureAPI}/permissionReports/areas`, { params });
}

export function getAllDevices(params: any) {
  return axios.get(`${idsecureAPI_REPORTS}/accesslog/devices`, { params });
}

export function findGroups(queryParams: any) {
  return axios.get(`${idsecureAPI}/permissionReports/groups`, {
    params: queryParams
  });
}

export function getAllAccessRules(params: any) {
  return axios.get(`${idsecureAPI}/permissionReports/accessrule`, { params });
}

export type GetProps = {
  pageSize: number;
  sortField: string;
  pageNumber: number;
  sortOrder: string;
  AreaIds?: string;
  Ids?: string;
};

export function getLogsToPerson(props: GetProps) {
  return axios.get(`${idsecureAPI}/permissionReports/person`, {
    params: {
      ...props,
    },
    transformResponse: [function (data) {

      const response = JSON.parse(data)

      try {
        const transformValues = response.value.data.map((v: any) => {
          return {
            ...v,
            saturday: defaultDateTime.labelStringToTimePermission(v.saturday),
            wednesday: defaultDateTime.labelStringToTimePermission(v.wednesday),
            tuesday: defaultDateTime.labelStringToTimePermission(v.tuesday),
            thursday: defaultDateTime.labelStringToTimePermission(v.thursday),
            sunday: defaultDateTime.labelStringToTimePermission(v.sunday),
            monday: defaultDateTime.labelStringToTimePermission(v.monday),
            friday: defaultDateTime.labelStringToTimePermission(v.friday),
          }
        })

        response.value.data = transformValues


        return {
          hasValue: response.hasValue,
          value: {
            ...response.value,
            data: transformValues
          }
        }



      } catch {

        return response
      }


    }],
  });
}

export function getLogsToGroupsPerson(props: GetProps) {
  return axios.get(`${idsecureAPI}/permissionReports/group`, {
    params: {
      ...props,
    },
    transformResponse: [function (data) {

      const response = JSON.parse(data)

      try {
        const transformValues = response.value.data.map((v: any) => {
          return {
            ...v,
            saturday: defaultDateTime.labelStringToTimePermission(v.saturday),
            wednesday: defaultDateTime.labelStringToTimePermission(v.wednesday),
            tuesday: defaultDateTime.labelStringToTimePermission(v.tuesday),
            thursday: defaultDateTime.labelStringToTimePermission(v.thursday),
            sunday: defaultDateTime.labelStringToTimePermission(v.sunday),
            monday: defaultDateTime.labelStringToTimePermission(v.monday),
            friday: defaultDateTime.labelStringToTimePermission(v.friday),
          }
        })

        response.value.data = transformValues


        return {
          hasValue: response.hasValue,
          value: {
            ...response.value,
            data: transformValues
          }
        }



      } catch {

        return response
      }


    }],
  });
}

export function getLogsToCompanies(props: GetProps) {
  return axios.get(`${idsecureAPI}/permissionReports/company`, {
    params: {
      ...props
    },
    transformResponse: [function (data) {

      const response = JSON.parse(data)

      try {
        const transformValues = response.value.data.map((v: any) => {
          return {
            ...v,
            saturday: defaultDateTime.labelStringToTimePermission(v.saturday),
            wednesday: defaultDateTime.labelStringToTimePermission(v.wednesday),
            tuesday: defaultDateTime.labelStringToTimePermission(v.tuesday),
            thursday: defaultDateTime.labelStringToTimePermission(v.thursday),
            sunday: defaultDateTime.labelStringToTimePermission(v.sunday),
            monday: defaultDateTime.labelStringToTimePermission(v.monday),
            friday: defaultDateTime.labelStringToTimePermission(v.friday),
          }
        })

        response.value.data = transformValues


        return {
          hasValue: response.hasValue,
          value: {
            ...response.value,
            data: transformValues
          }
        }



      } catch {

        return response
      }


    }],
  });
}

export function getLogsToGroupVisitors(props: GetProps) {
  return axios.get(`${idsecureAPI}/permissionReports/visitor`, {
    params: {
      ...props
    }
  });
}

export function getLogsToGroupPersonsArea(props: GetProps) {
  return axios.get(`${idsecureAPI}/permissionReports/personArea`, {
    params: {
      ...props
    },
    transformResponse: [function (data) {

      const response = JSON.parse(data)

      try {
        const transformValues = response.value.data.map((v: any) => {
          return {
            ...v,
            saturday: defaultDateTime.labelStringToTimePermission(v.saturday),
            wednesday: defaultDateTime.labelStringToTimePermission(v.wednesday),
            tuesday: defaultDateTime.labelStringToTimePermission(v.tuesday),
            thursday: defaultDateTime.labelStringToTimePermission(v.thursday),
            sunday: defaultDateTime.labelStringToTimePermission(v.sunday),
            monday: defaultDateTime.labelStringToTimePermission(v.monday),
            friday: defaultDateTime.labelStringToTimePermission(v.friday),
          }
        })

        response.value.data = transformValues


        return {
          hasValue: response.hasValue,
          value: {
            ...response.value,
            data: transformValues
          }
        }



      } catch {

        return response
      }


    }],
  });
}

export function getLogsToGroupArea(props: GetProps) {
  return axios.get(`${idsecureAPI}/permissionReports/personArea`, {
    params: {
      ...props
    },
  });
}

export function getLogsToGroupAreas(props: GetProps) {
  return axios.get(`${idsecureAPI}/permissionReports/groupArea`, {
    params: {
      ...props
    }
  });
}
