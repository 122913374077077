import axios from 'axios';
import { I_getAccessLogParking, I_getAllParkinglot, I_getAllParkinglot_request, I_parking } from './model';

interface Props {
  id?: number;
  oldest: boolean;
  types: string;
  devices: string;
}
export class MonitorVehicleService {
  private API_URL = process.env.REACT_APP_IDSECURE_API_URL_REPORTS;

  getAllParkinglot(params: I_getAllParkinglot_request): Promise<I_getAllParkinglot> {
    if (params.value) delete params.value

    return axios.get(`${this.API_URL}/vehicleAccesslog/parkinglot`, {
      params
    })
  }

  transformParkingValuesToSelectComponent(values: I_parking[]) {
    return {
      options: values.map((park) => {
        return {
          value: park.id,
          label: park.description
        };
      }),
      hasMore: false
    }
  }

  getVehicleAccesslog(params: any): Promise<I_getAccessLogParking> {

    if (params.id === 0) delete params.id

    return axios.get<any, I_getAccessLogParking>(`${this.API_URL}/vehicleAccesslog/last`, {
      params
    })
  }

  getAccessPendingEvent({ ids }: any) {
    const params: {
      logIds: any;
    } = {
      logIds: ids,
    };
    return axios.get<any>(`${this.API_URL}/vehicleAccesslog/last`, {
      params
    });
  }


  getRealTimeAccessNopag({ id, oldest, types, devices }: Props) {
    const params: {
      lastLogId?: number;
      oldest: boolean;
      EventTypes: string;
      DeviceIds: string;
      Quantity: string
    } = {
      lastLogId: id,
      oldest: oldest,
      EventTypes: types,
      DeviceIds: devices,
      Quantity: "20"
    };

    if (!id) {
      delete params.lastLogId;
    }
    return axios.get<any>(`${this.API_URL}/vehicleAccesslog/last`, {
      params
    });
  }
}