import {createSlice} from '@reduxjs/toolkit';

const initialAccessRulesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  AccessRuleForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: 'list',
  action: 'action'
};

export const accessRulesSlice = createSlice({
  name: 'AccessRules',
  initialState: initialAccessRulesState,
  reducers: {
    catchError: (state: any, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state: any, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getPersonById
    accessRuleFetched: (state: any, action) => {
      state.accessRuleForEdit = undefined;
      state.actionsLoading = false;
      state.accessRuleForEdit = action.payload.accessRuleForEdit;
      state.error = null;
    },
    // finddevices
    accessRulesFetched: (state: any, action) => {
      const {totalCount, entities} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.accessRuleForEdit = undefined;
    },
    // createdevice
    accessRuleCreated: (state: any, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.accessRules);
    },
    // updatedevice
    accessRuleUpdated: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity: any) => {
        if (entity.id === action.payload.device.id) {
          return action.payload.accessRules;
        }
        return entity;
      });
      state.accessRuleForEdit = undefined;
    },
    // deletedevice
    accessRuleDeleted: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el: any) => el.id !== action.payload.id);
      state.accessRuleForEdit = undefined;
    },
    // deletedevices
    accessRulesDeleted: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el: any) => !action.payload.ids.includes(el.id));
    },
    // devicesUpdateState
    accessRulesStatusUpdated: (state: any, action) => {
      state.actionsLoading = false;
      state.error = null;
      const {ids, status} = action.payload;
      state.entities = state.entities.map((entity: any) => {
        if (ids.findIndex((id: number) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    linkedList: (state: any, action) => {
      const {list} = action.payload;
      state.linkedListsIds = list;
    },
    linkedListDeleted: (state: any, action) => {
      state.linkedListsIds = state.linkedListsIds.filter((el: any) => el.id !== action.payload.id);
    }
  }
});
