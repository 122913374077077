export const handleParseYupError = (errorObject: Record<string, any>): Record<string, any> => {
  const object = errorObject.inner.reduce(
    (acc: Record<string, any>, error: Record<string, any>) => ({
      ...acc,
      [error.path]: error.message
    }),
    {}
  );

  const parsed = transformObject(object);

  return parsed;
};

function transformObject(input: Record<string, any>) {
  const result: Record<string, any> = {};

  for (let key in input) {
    const parts = key.split('.');
    let current = result;
    for (let i = 0; i < parts.length; i++) {
      if (!current[parts[i]]) {
        current[parts[i]] = {};
      }
      if (i === parts.length - 1) {
        current[parts[i]] = input[key];
      }
      current = current[parts[i]];
    }
    if (parts.length === 1) {
      result[key] = input[key];
    }
  }

  return result;
}
