import React, { useEffect, useState } from 'react'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { useLang } from 'src/_metronic/i18n';
import { defaultDateTime } from 'src/utils/defaultDateTime';
import MaskedTextInput from 'react-text-mask';
import pt from 'date-fns/locale/pt-BR';
import { addDays } from 'date-fns';


import spani from 'date-fns/locale/es';
import ptBr from 'date-fns/locale/pt-BR';
import enUs from 'date-fns/locale/en-US';

// registerLocale('pt-BR', pt);

interface Props {
  label: string,
  valueStart: Date | null,
  valueEnd: Date | null,
  updateValueStart(value: any): void,
  updateValueEnd(value: any): void,
  onlyDate?: boolean,
  id?: string,
  isClearable?: boolean
}

export function DateTimeRangeInput({
  label,
  updateValueEnd,
  updateValueStart,
  valueEnd, valueStart,
  onlyDate = false,
  id = '',
  isClearable = false }: Props) {
  const defaultDateTimeObject = defaultDateTime.getDefault()
  const lang = useLang();

  if (lang === 'es') {
    spani.formatLong = {
      date: () => `${defaultDateTimeObject.date}`,
      dateTime: () => `${defaultDateTimeObject.date} ${defaultDateTimeObject.time}`,
      time: () => `${defaultDateTimeObject.time}`
    }
    registerLocale('_input_', spani);
  }


  if (lang === 'pt') {
    ptBr.formatLong = {
      date: () => `${defaultDateTimeObject.date}`,
      dateTime: () => `${defaultDateTimeObject.date} ${defaultDateTimeObject.time}`,
      time: () => `${defaultDateTimeObject.time}`
    }
    registerLocale('_input_', ptBr);
  }

  if (lang === 'en') {
    enUs.formatLong = {
      date: () => `${defaultDateTimeObject.date}`,
      dateTime: () => `${defaultDateTimeObject.date} ${defaultDateTimeObject.time}`,
      time: () => `${defaultDateTimeObject.time}`
    }
    registerLocale('_input_', enUs);
  }


  const maskModels = defaultDateTime.prepare_mask_component_date()

  // console.log({ maskModels })
  let mask = [
    ...maskModels.date,
    ' ',
    ...maskModels.time
  ]

  if (defaultDateTimeObject.time.includes('a')) {
    mask.push(' ')
    mask.push(/[A|P]/,)
    mask.push('M')
  }


  // useEffect(() => {
  //   console.log('OLA',
  //     `${defaultDateTimeObject.date} ${defaultDateTimeObject.time}`
  //   )
  // })

  return (
    <div style={{ width: '100%' }} className='TRANSFORM' id={id}>
      <div className='rdt col-5 p-0'>
        <p className='col-12 mb-0 pl-0' >
          {label}
        </p>
      </div>

      <div className='row d-flex mt-2'>
        <div className="rdt col-6 dp-full">
          <DatePicker
            locale='_input_'
            showTimeSelect
            dateFormat={`${defaultDateTimeObject.date} ${defaultDateTimeObject.time}`}
            className='form-control'
            disabledKeyboardNavigation
            selected={valueStart}
            onChange={(e) => {
              updateValueStart(e)
            }}
          // customInput={
          //   <MaskedTextInput
          //     type='text'
          //     mask={
          //       mask
          //     }
          //   />
          // }
          />
        </div>

        <div className="rdt col-6 dp-full">
          <DatePicker
            locale='_input_'
            showTimeSelect
            dateFormat={`${defaultDateTimeObject.date} ${defaultDateTimeObject.time}`}
            className='form-control'
            disabledKeyboardNavigation
            selected={valueEnd}
            onChange={(e) => {
              updateValueEnd(e)
            }}
          // customInput={
          //   <MaskedTextInput
          //     type='text'
          //     mask={
          //       mask
          //     }
          //   />
          // }
          // minDate={valueStart ? addDays(valueStart, 1) : null}

          // customInput={
          //   <MaskedTextInput
          //     type='text'
          //     mask={[
          //       /[0-9]/,
          //       /[0-9]/,
          //       '/',
          //       /[0-9]/,
          //       /[0-9]/,
          //       '/',
          //       /[0-9]/,
          //       /[0-9]/,
          //       /[0-9]/,
          //       /[0-9]/,
          //       ',',
          //       ' ',
          //       /[0-9]/,
          //       /[0-9]/,
          //       ':',
          //       /[0-9]/,
          //       /[0-9]/,
          //       ' ',
          //       /[A|P]/,
          //       'M'
          //     ]}
          //   />
          // }
          />
        </div>
      </div>

    </div>
  )


  // rdt col-12 mb-6 mb-md-0 col-md
}