import React, { ReactNode, useState } from 'react'
import { useIntl } from 'react-intl';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';


import { NoRecordsFoundMessage, PleaseWaitMessage, sortCaret, headerSortingClasses } from 'src/_metronic/_helpers';
import { Pagination } from 'src/_metronic/_partials/controls';
import { Card, Col, Row } from 'react-bootstrap';
import BootstrapTable, { SortOrder } from 'react-bootstrap-table-next';

type DocumentProps = {
  id: number,
  name: string,
  block: boolean,
  isExpirationDate: boolean,
  isImage: boolean
  allowDuplicity: boolean
}

interface Props {
  pack: {
    documentsList: DocumentProps[],
    buttons: (id: number, name: string) => ReactNode
  },
  buttonHeader: () => void
}
export function ListDocuments({ pack, buttonHeader }: Props) {
  const [pagination, setPagination] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(100)
  const intl = useIntl()
  const data = pack.documentsList.map(({ block, id, name, isExpirationDate, isImage, allowDuplicity }) => {
    return {
      name,
      id,
      block: isExpirationDate ? intl.formatMessage({ id: 'LOGREGISTER.TABLE.VALUE.ACTIVED' }) : intl.formatMessage({ id: 'LOGREGISTER.TABLE.VALUE.NOT_ACTIVED' }),
      isExpirationDate: block ? intl.formatMessage({ id: 'LOGREGISTER.TABLE.VALUE.ACTIVED' }) : intl.formatMessage({ id: 'LOGREGISTER.TABLE.VALUE.NOT_ACTIVED' }),
      isImage: isImage ? intl.formatMessage({ id: 'LOGREGISTER.TABLE.VALUE.ACTIVED' }) : intl.formatMessage({ id: 'LOGREGISTER.TABLE.VALUE.NOT_ACTIVED' }),
      allowDuplicity: allowDuplicity ? intl.formatMessage({ id: 'LOGREGISTER.TABLE.VALUE.ACTIVED' }) : intl.formatMessage({ id: 'LOGREGISTER.TABLE.VALUE.NOT_ACTIVED' }),
      action: pack.buttons(id, name)
    }
  })

  const columns = [
    {
      dataField: 'name',
      text: intl.formatMessage({ id: 'CONFIG_TAB_FILES_TABLE_ONE_COLUMN' }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,

      headerAlign: 'left',
      align: 'left',
      style: {
        minWidth: '150px',
        textAlign: 'left'
      }
    },
    {
      dataField: 'block',
      text: intl.formatMessage({ id: 'CONFIG_TAB_FILES_TOGGLE_ONE' }),
      sort: true,

      headerAlign: 'left',
      align: 'left',
      style: {
        minWidth: '150px',
        textAlign: 'left'
      }
    },

    {
      dataField: 'isExpirationDate',
      text: intl.formatMessage({ id: 'CONFIG_TAB_FILES_TOGGLE_TWO' }),
      sort: true,

      headerAlign: 'left',
      align: 'left',
      style: {
        minWidth: '150px',
        textAlign: 'left'
      }
    },

    {
      dataField: 'isImage',
      text: intl.formatMessage({ id: 'CONFIG_TAB_FILES_TOGGLE_TREE' }),
      sort: true,

      headerAlign: 'left',
      align: 'left',
      style: {
        minWidth: '150px',
        textAlign: 'left'
      }
    },
    {
      dataField: 'allowDuplicity',
      text: intl.formatMessage({ id: 'CONFIG_TAB_FILES_TOGGLE_THREE' }),
      sort: true,

      headerAlign: 'left',
      align: 'left',
      style: {
        minWidth: '150px',
        textAlign: 'left'
      }
    },
    {
      dataField: 'action',
      text: intl.formatMessage({ id: 'MENU.ACTIONS' }),
      sort: true,

      headerAlign: 'right',
      align: 'right',
      style: {
        minWidth: '150px',
        textAlign: 'right'
      }
    }
  ];

  const paginationOptions = {
    custom: true,
    totalSize: data.length,
    sizePerPageList: window.sizePerPageList,
    sizePerPage: sizePerPage,
    page: pagination
  };

  return (
    <div >
      <Card className={'card card-custom gutter-b mt-md-0'}>
        <Card.Header style={{
          overflow: "hidden"
        }}>
          <Card.Title className={'m-0'}>{intl.formatMessage({ id: 'CONFIG_TAB_FILES_TITLE_TABLE' })}</Card.Title>
          <button
            onClick={buttonHeader}
            style={{ marginLeft: '12px' }}
            className='btn btn-primary'>
            {intl.formatMessage({ id: 'CONFIG_TAB_FILES_ADD' })}
          </button>
        </Card.Header>

        <Card.Body>
          <PaginationProvider
            pagination={paginationFactory(
              //@ts-ignore
              paginationOptions
            )}>
            {({ paginationProps, paginationTableProps }: any) => {

              return (
                <Pagination isLoading={false} paginationProps={paginationProps}>
                  <BootstrapTable
                    onTableChange={(type, props) => {
                      // if (type == 'sort') {
                      //   setOrder(props.sortOrder);
                      // }

                      if (props.page !== 0) {
                        setPagination(props.page);
                      }
                      setSizePerPage(props.sizePerPage);
                    }}
                    wrapperClasses='table-responsive'
                    bordered={false}
                    classes='table table-head-custom table-vertical-center overflow-hidden'
                    remote
                    bootstrap4
                    keyField='id'
                    data={data}
                    columns={columns}
                    {...paginationTableProps}>
                    <PleaseWaitMessage entities={data} />
                    <NoRecordsFoundMessage entities={[data]} />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        </Card.Body>
      </Card>
    </div>
  )
}