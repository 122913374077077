import React from 'react'

import { AiOutlineLoading } from 'react-icons/ai'
import { LoaderContainer } from './styles'
import { useIntl } from 'react-intl'

export function Loading() {

  const intl = useIntl()
  return (
    <LoaderContainer>
      <AiOutlineLoading size={48} color="#3699FF" />
      <span>{intl.formatMessage({ id: 'PERSON.PERSONS_TABLE_LOADING' })}</span>
    </LoaderContainer>
  )
}