import styled, {css} from 'styled-components';

interface StepsContainer {
  isShow: boolean;
}
export const StepOneContainer = styled.div<StepsContainer>`
  ${(props) =>
    props.isShow &&
    css`
      display: block;
    `};

  align-items: center;
  justify-content: center;
`;

export const ConfirmationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
  gap: 15px;

  > div {
    flex: 1;
    min-width: 200px;

    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  > div {
    overflow-y: auto;

    height: 250px;
    opacity: 1;

    border-radius: 3px;
    border: 1px solid #ddd;

    > h6 {
      padding: 10px;
      margin-bottom: 0px;
      font-size: 1rem;
      background: #efefef;
    }

    > span {
      display: block;
      padding: 1px 10px;
    }
  }
`;
