import React, {lazy} from 'react';
import {Route, Switch} from 'react-router-dom';

import {SCHEDULE_INACTIVATION_ROUTES} from './config/route';

const ScheduleHomePage = lazy(() => import('./pages/home/index'));
const CreateSchedulePage = lazy(() => import('./pages/create/index'));
const EditSchedulePage = lazy(() => import('./pages/edit/index'));

export const ScheduleInactivationsRoutes = () => {
  return (
    <Switch>
      <Route exact path={SCHEDULE_INACTIVATION_ROUTES.BASE} component={ScheduleHomePage} />
      <Route path={SCHEDULE_INACTIVATION_ROUTES.CREATE} component={CreateSchedulePage} />
      <Route path={SCHEDULE_INACTIVATION_ROUTES.EDIT(':id')} component={EditSchedulePage} />
    </Switch>
  );
};
