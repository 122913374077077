import axios from 'axios';
import { defaultDateTime } from 'src/utils/defaultDateTime';

const idsecureAPI = process.env.REACT_APP_IDSECURE_API_URL_REPORTS;

interface queryParams {
  pageSize: number;
  pageNumber: number;
  // status: number;
  value: string;
  // sortOrder: string;
  // sortField: string;
  OperatorIds: string;
  TableId: string;
  AuditLogType: string;
  dtEnd: number;
  dtStart: number;
  sortOrder: string;
  sortField: string;
}

export function GetValues(params: queryParams) {

  return axios.get(`${idsecureAPI}/systemAuditTrail`, {
    params,
    transformResponse: [function (data) {

      const response = JSON.parse(data)

      try {
        const transformValues = response.data.data.map((v: any) => {
          return {
            ...v,
            auditLogDate: defaultDateTime.labelDateTime(v.auditLogDate)
          }
        })

        response.data.data = transformValues


        return {
          data: {
            ...response.data,
            data: transformValues
          }
        }



      } catch {
        return response
      }


    }],
  });
}

export function ExportCSV(Hearder: string[], params: queryParams) {
  let allHeaders = Hearder.reduce((acc, el) => {
    if (acc === '') {
      acc += `?headers=${el}`;
    } else {
      acc += `&headers=${el}`;
    }

    return acc;
  }, '');

  let url = `/systemAuditTrail/export${allHeaders}`;

  if (params.OperatorIds) {
    url = `${url}&OperatorIds=${params.OperatorIds}`;
  }

  if (params.AuditLogType) {
    url = `${url}&AuditLogType=${params.AuditLogType}`;
  }

  if (params.TableId) {
    url = `${url}&TableName=${params.TableId}`;
  }

  if (params.dtEnd) {
    url = `${url}&dtEnd=${params.dtEnd}&dtStart=${params.dtStart}`;
  }

  if (params.pageNumber) {
    url = `${url}&pageNumber=${params.pageNumber}`;
  }

  if (params.pageSize) {
    url = `${url}&pageSize=${params.pageSize}`;
  }

  if (params.sortField) {
    url = `${url}&sortField=${params.sortField}`;
  }

  if (params.sortOrder) {
    url = `${url}&sortOrder=${params.sortOrder}`;
  }


  return url;
}
