import React, { useMemo, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import objectPath from 'object-path';
import ApexCharts from 'apexcharts';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { useHtmlClassService } from '../../../../_metronic/layout';
import { useIntl } from 'react-intl';
import { Indicators } from '../_redux/dashboardSlices';
import { Col } from 'react-bootstrap';
export function GeneralIndicators(props: any) {
  const indicators: Indicators = props.indicators;
  const uiService = useHtmlClassService();
  const intl = useIntl();

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(uiService.config, 'js.colors.gray.gray500'),
      colorsGrayGray200: objectPath.get(uiService.config, 'js.colors.gray.gray200'),
      colorsGrayGray300: objectPath.get(uiService.config, 'js.colors.gray.gray300'),
      colorsThemeBaseDanger: objectPath.get(uiService.config, 'js.colors.theme.base.danger'),
      fontFamily: objectPath.get(uiService.config, 'js.fontFamily')
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById('kt_mixed_widget_1_chart');
    if (!element) {
      return;
    }

    const options = getChartOptions(layoutProps);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps]);
  return (
    <>
      <div className='card card-stretch gutter-b p-5 mtt'>
        {/* Header */}
        <div className=''>
          <h3 className='card-title font-weight-bolder text-black'>{intl.formatMessage({ id: 'DASH.GENERAL_STATISTICS' })}</h3>
        </div>
        {/* Body */}
        <div className='card-body p-0 position-relative overflow-hidden'>
          {/* Chart */}

          {/* Stat */}
          <div className='card-spacer'>
            <div className='row'>
              <Col xl={3} lg={6} sm={12} md={12} className='mb-2 bg-warning-card p-5 rounded-xl' style={{ border: '4px solid white' }}>
                <span className='svg-icon svg-icon-3x svg-icon-warning d-block my-2 d-flex w-100 justify-content-between text-warning font-weight-bold'>
                  <SVG src={toAbsoluteUrl('/media/svg/icons/General/User.svg')}></SVG>
                  <p className='text-warning font-size-h2'>{indicators?.totalPersonsActive || 0}</p>
                </span>
                <p className='text-warning font-weight-bold font-size-h7'>{intl.formatMessage({ id: 'DASH.ACTIVE_PEOPLE' })}</p>
              </Col>

              <Col xl={3} lg={6} sm={12} md={12} className='mb-2 bg-success-card p-5 rounded-xl' style={{ border: '4px solid white' }}>
                <span className='svg-icon svg-icon-3x svg-icon-success d-block my-2 d-flex w-100 justify-content-between font-weight-bold'>
                  <SVG src={toAbsoluteUrl('/media/svg/icons/General/User.svg')}></SVG>
                  <p className='font-size-h2'>{indicators?.totalVisitantsActive || 0}</p>
                </span>
                <p className='font-weight-bold font-size-h7 mt-2'>{intl.formatMessage({ id: 'DASH.ACTIVE_VISITORS' })}</p>
              </Col>
              {/* <Col xl={3} lg={6} sm={12} md={12} className='mb-2 bg-danger-card p-5 rounded-xl' style={{ border: '4px solid white' }}>
                <span className='svg-icon svg-icon-3x svg-icon-danger d-block my-2 d-flex w-100 justify-content-between text-danger font-weight-bold'>
                  <SVG src={toAbsoluteUrl('/media/svg/icons/General/Unlock.svg')}></SVG>
                  <p className=' font-size-h2'>{indicators?.totalPersonsLocation || 0}</p>
                </span>
                <p className=' font-weight-bold font-size-h7 mt-2'>{intl.formatMessage({ id: 'Total de Pessoas no Local' })}</p>
              </Col> */}
              <Col xl={3} lg={6} sm={12} md={12} className='mb-2 bg-danger-card p-5 rounded-xl' style={{ border: '4px solid white' }}>
                <span className='svg-icon svg-icon-3x svg-icon-danger d-block my-2 d-flex w-100 justify-content-between text-danger font-weight-bold'>
                  <SVG src={toAbsoluteUrl('/media/svg/icons/General/User.svg')}></SVG>
                  <p className=' font-size-h2'>{indicators?.totalPersonsLocation || 0}</p>
                </span>
                <p className=' font-weight-bold font-size-h7 mt-2'>{intl.formatMessage({ id: 'TOTAL.PEOPLE.ON.SITE' })}</p>
              </Col>
              {/* <Col xl={3} lg={6} sm={12} md={12} className='mb-2 bg-primary-card p-5 rounded-xl' style={{ border: '4px solid white' }}>
                <span className='svg-icon svg-icon-3x svg-icon-primary d-block my-2 d-flex w-100 justify-content-between text-primary font-weight-bold'>
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Key.svg')}></SVG>

                  <p className='text-primary font-size-h2'>{indicators?.totalDevicesActive || 0}</p>
                </span>
                <p className='text-primary font-weight-bold font-size-h7 mt-2'>{intl.formatMessage({ id: 'Total de Equipamentos' })}</p>
              </Col> */}
              <Col xl={3} lg={6} sm={12} md={12} className='mb-2 bg-primary-card p-5 rounded-xl' style={{ border: '4px solid white' }}>
                <span className='svg-icon svg-icon-3x svg-icon-primary d-block my-2 d-flex w-100 justify-content-between text-primary font-weight-bold'>
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Devices/Diagnostics.svg')}></SVG>

                  <p className='text-primary font-size-h2'>{indicators?.totalDevicesActive || 0}</p>
                </span>
                <p className='text-primary font-weight-bold font-size-h7 mt-2'>{intl.formatMessage({ id: 'TOTAL.EQUIPMENTS' })}</p>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function getChartOptions(layoutProps: any) {
  const strokeColor = '#D13647';

  const options = {
    series: [
      {
        name: 'Net Profit',
        data: [30, 45, 32, 70, 40, 40, 40]
      }
    ],
    chart: {
      type: 'area',
      height: 200,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      sparkline: {
        enabled: true
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5
      }
    },
    plotOptions: {},
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'solid',
      opacity: 0
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [strokeColor]
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: '12px',
          fontFamily: layoutProps.fontFamily
        }
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3
        }
      }
    },
    yaxis: {
      min: 0,
      max: 80,
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: '12px',
          fontFamily: layoutProps.fontFamily
        }
      }
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: '12px',
        fontFamily: layoutProps.fontFamily
      },
      y: {
        formatter: function (val: any) {
          return '$' + val + ' thousands';
        }
      },
      marker: {
        show: false
      }
    },
    colors: ['transparent'],
    markers: {
      colors: layoutProps.colorsThemeBaseDanger,
      strokeColor: [strokeColor],
      strokeWidth: 3
    }
  };
  return options;
}
