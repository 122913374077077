import React from 'react';
import {ProgressBar} from 'react-bootstrap';

export enum VariantTypes {
  success = 'success',
  danger = 'danger',
  warning = 'warning',
  info = 'info'
}

export function ModalProgressBar({variant = VariantTypes.success}: {variant?: VariantTypes}) {
  return <ProgressBar variant={variant} animated now={100} style={{height: '3px', width: '100%'}} />;
}
