/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useEffect} from 'react';
import objectPath from 'object-path';
import ApexCharts from 'apexcharts';
import {useHtmlClassService} from '../../../../_metronic/layout';
import {useIntl} from 'react-intl';
import {AccessLastDay} from '../_redux/dashboardSlices';

export function ChartArea({className, symbolShape, baseColor, numberFormat, accessLastDay, heigth = 768}: any) {

  const accessLast: AccessLastDay[] = accessLastDay;

  const uiService = useHtmlClassService();
  const intl = useIntl();
  const accessLabel = intl.formatMessage({id: 'DASH.ACCESSES'})
  const getQtdAccess = () => {
   try{
    return accessLast?.map((item) => item.qtAccess).reduce((a, b) => a + b);
   }catch{
    return 0
   }
  };

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(uiService.config, 'js.colors.gray.gray500'),
      colorsGrayGray200: objectPath.get(uiService.config, 'js.colors.gray.gray200'),
      colorsGrayGray300: objectPath.get(uiService.config, 'js.colors.gray.gray300'),
      colorsThemeBaseSuccess: objectPath.get(uiService.config, `js.colors.theme.base.${baseColor}`),
      colorsThemeLightSuccess: objectPath.get(uiService.config, `js.colors.theme.light.${baseColor}`),
      fontFamily: objectPath.get(uiService.config, 'js.fontFamily')
    };
  }, [uiService, baseColor]);

  useEffect(() => {
    const element = document.getElementById('kt_stats_widget_11_chart');

    if (!element) {
      return;
    }

    const options = getChartOption(layoutProps, accessLast, heigth, accessLabel);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps, accessLast, accessLabel]);

  return (
    <div className={`card ${className}`}>
      <div className='card-body  d-flex flex-column p-0'>
        <div className='d-flex align-items-center justify-content-between card-spacer flex-grow-1'>
          <div className='d-flex  flex-stack flex-wrap flex-grow-1 justify-content-between'>
            <div className='me-2'>
              <span className='text-dark-75 font-weight-bolder font-size-h5'>{intl.formatMessage({id: 'DASH.ACCESS_LAST_DAY'})}</span>
              <br />
              <span className='text-muted font-weight-bold mt-2'>
                {getQtdAccess()} {accessLabel}
              </span>
            </div>
          </div>
        </div>
        <div id='kt_stats_widget_11_chart' className='card-rounded-bottom'></div>
      </div>
    </div>
  );
}

function getChartOption(layoutProps: any, accessLast: AccessLastDay[], heigth: number, accessLabel: string) {
  const labelColor = '#B5B5C3';
  const borderColor = '#b5b5c338';
  const secondaryColor = '#B5B5C3';
  const baseColor = '#b5b5c338';

  return {
    series: [
      {
        name: accessLabel,
        data: accessLast?.map((item) => item.qtAccess)
      }
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: parseInt(`${heigth - 565}`) > 300 ? parseInt(`${heigth - 565}`) : 300,
      toolbar: {
        show: false
      }
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: accessLast
        // .sort(function compare(a, b) {
        //   var dateNow = new Date();
        //   var dateA = new Date();

        //   dateA.setHours(a.hour);
        //   var dateB = new Date();
        //   dateB.setHours(b.hour);
        //   if (dateNow.getHours() > a.hour) {
        //     dateA.setDate(dateNow.getDate() - 1);
        //   }
        //   if (dateNow.getHours() > b.hour) {
        //     dateB.setDate(dateNow.getDate() - 1);
        //   }

        //   return dateA.getTime() - dateB.getTime();
        // })
        ?.map((item) => `${item.hour}`),
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          // colors: labelColor,
          fontSize: '12px'
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          // colors: labelColor,
          fontSize: '12px'
        }
      }
    },
    fill: {
      type: 'solid',
      opacity: 1
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [layoutProps.colorsThemeBaseSuccess]
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: '12px'
      },
      y: {
        formatter: function(val: any) {
          return val;
        }
      }
    },
    colors: [baseColor, secondaryColor],
    grid: {
      padding: {
        top: 10
      },
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true
        }
      }
    }
  };
}
