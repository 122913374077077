import React from 'react';
import {useIntl} from 'react-intl';
import {TABLE_ENUMERATORS} from '../../../../../src/enumerators/tablesWithDataBase';

export function OptionTable() {
  const intl = useIntl();

  function INTL(label: string) {
    return label;
    // return intl.formatMessage({id: label});
  }
  return (
    <>
      {TABLE_ENUMERATORS.map((item) => (
        <option key={item.id} value={item.id} style={{textTransform: "capitalize"}}>
          {intl.formatMessage({
            id: item.label
          }).toLowerCase()}
        </option>
      ))}
    </>
  );
}
