import axios from 'axios';
import {dateToUNIX} from 'src/app/modules/Reports/utils/utils';

const idsecureAPI = process.env.REACT_APP_IDSECURE_API_URL;

export type GetProps = {
  pageSize: number;
  sortField: string;
  pageNumber: number;
  sortOrder: 'asc' | 'desc';
  status: number;
  value?: string;
};

export function geAllBluePrints(props: GetProps) {
  return axios.get(`${idsecureAPI}/blueprint`, {
    params: {
      ...props
    }
  });
}

export function createBluePrints(props: any) {
  return axios.post(`${idsecureAPI}/blueprint`, props);
}

export function editBluePrints(props: any) {
  return axios.put(`${idsecureAPI}/blueprint`, props);
}

export function geAllBluePrintsDevicesActiveds(props: GetProps) {
  return axios.get(`${idsecureAPI}/blueprint/devices-output-avaliable`, {
    params: {
      ...props,
      pageSize: 100
    }
  });
}

export function getBluePrintById(id: number) {
  return axios.get(`${idsecureAPI}/blueprint/${id}`);
}

export function removeBluePrintById(id: number) {
  return axios.delete(`${idsecureAPI}/blueprint/${id}`);
}

export function reativeBluePrintById(id: number) {
  return axios.put(`${idsecureAPI}/blueprint/${id}`);
}

export function BluePrintOpenDoor(id: number) {
  return axios.post(`${idsecureAPI}/devices/${id}/set/6`);
}

export function getRealTimeAccess() {
  return axios.get<any>(`${process.env.REACT_APP_IDSECURE_API_URL}/blueprint/accesslog`, {
    params: {
      pageSize: 10,
      pageNumber: 1,
      // personsIds: [],
      // areasIds: [],
      // devicesIds: [],
      // events: [],
      // dtStart: dateToUNIX(new Date(newdate)),
      // dtEnd: dateToUNIX(new Date()),
      sortOrder: 'desc',
      sortField: 'Time'
    }
  });
}

export function getRealTimeAccess2() {
  return axios.get<any>(`${process.env.REACT_APP_IDSECURE_API_URL}/blueprint/accesslog`, {
    params: {
      pageSize: 10,
      pageNumber: 1,
      // personsIds: [],
      // areasIds: [],
      // devicesIds: [],
      // events: [],
      dtStart: dateToUNIX(new Date()),
      dtEnd: dateToUNIX(new Date()),
      sortOrder: 'desc',
      sortField: 'Time'
    }
  });
}
