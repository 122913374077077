import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { set, format } from 'date-fns';
//@ts-ignore
import { CSSObjectWithLabel } from 'react-select';
import { Card, Col, Row } from 'react-bootstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { CardHeaderToolbar, Pagination } from 'src/_metronic/_partials/controls';
import BootstrapTable from 'react-bootstrap-table-next';

import { AsyncPaginate } from 'react-select-async-paginate';
import { getAllPersons } from 'src/services/accessLogsCrud';
import { useLang } from 'src/_metronic/i18n';
import { NoRecordsFoundMessage, PleaseWaitMessage, sortCaret, headerSortingClasses } from 'src/_metronic/_helpers';

import { OptionTable } from './renders/OptionTable';


import { ExportCSV, GetValues } from 'src/services/audit';
import { dateToUNIX } from '../Reports/utils/utils';
import { textToElement } from './renders/textToElement';
import { useIntl } from 'react-intl';
import LongMenu from 'src/_metronic/_partials/controls/MenuToolbarCard';
import { formatFullDateStyle } from 'src/utils/formatFullDateStyle';
import { DateTimeRangeInput } from 'src/app/components/inputs/DateTimeRangeInput';
import { handleCulture } from 'src/utils/culture';

interface PROPDATA {
  affectedColumns: string;
  auditLogDate: string;
  auditLogType: number;
  newValues: string;
  oldValues: string;
  personId: number;
  personName: string;
  // primaryKey: null;
  tableName: string;
}
interface headersCSVLogs {
  pt: string[];
  en: string[];
  es: string[];
  fr: string[];
}
const headersCSVLogs: headersCSVLogs = {
  'pt': ['DATA/HORA', 'TIPO DE REGISTRO', 'OPERADOR', 'TABELA', 'ANTES', 'DEPOIS'],
  'en': ['DATA/HORA', 'TIPO DE REGISTRO', 'OPERADOR', 'TABELA', 'ANTES', 'DEPOIS'],
  'es': ['DATA/HORA', 'TIPO DE REGISTRO', 'OPERADOR', 'TABELA', 'ANTES', 'DEPOIS'],
  'fr': ['DATE/HEURE', "TYPE D'ENREGISTREMENT", "OPÉRATEUR", 'TABLE', 'AVANT', 'APRÈS'],
};


let debounce: NodeJS.Timeout

export function Auditlogs() {
  const searchRef = useRef<HTMLInputElement>(null)
  const [searchText, setSearchText] = useState<string>('')

  const [endDate, setEndDate] = useState(() => {
    const dateTimeNow = new Date();
    const result = set(dateTimeNow, { hours: 23, minutes: 59, seconds: 59 });

    return result;
  });

  const [startDate, setStartDate] = useState(() => {
    const dateTimeNow = new Date();
    const result = set(dateTimeNow, { date: dateTimeNow.getDate() - 8, hours: 24, minutes: 0, seconds: 0 });

    return result;
  });

  const [dateToApi, setDateToApi] = useState({ dtStart: dateToUNIX(startDate), dtEnd: dateToUNIX(endDate) });

  const [operatorsIds, setOperatorsIds] = useState<number[]>([]);

  const [inputTableSelected, setInputTableSelected] = useState('');
  const [typeLogSelected, setTypeLogSelected] = useState('');

  const [pagination, setPagination] = useState<number>(1);
  const [sizePerPage, setSizePerPage] = useState<number>(10);
  const [totalItens, setTotalItens] = useState<number>(0);
  const [valuesDataTables, setValuesDataTables] = useState([]);
  const [sortOrder, setSortOrder] = useState<string>('desc');
  const [sortField, setSortField] = useState<string>('AuditLogDate');

  const languages: 'pt' | 'en' | 'es' = useLang();
  const intl = useIntl();

  function INTL(label: string) {
    return intl.formatMessage({ id: label });
  }

  function updateFilterColumnName(value: string) {
    switch (value) {
      case 'table':
        return setSortField('TableName');
      case 'after':
        return setSortField('NewValues');
      case 'before':
        return setSortField('OldValues');
      case 'datetime':
        return setSortField('AuditLogDate');

      default:
        return '';
    }
  }

  function handleAlterValueInputTableSelected(event: ChangeEvent<HTMLSelectElement>) {
    const value = event.target.value;
    setInputTableSelected(value);
  }

  function handleTypeLogInputTableSelected(event: ChangeEvent<HTMLSelectElement>) {
    const value = event.target.value;
    setTypeLogSelected(value);
  }

  function typeRegister(type: number) {
    switch (type) {
      case 1:
        return INTL('TYPE.REGISTER.LoggedIn');
      case 2:
        return INTL('TYPE.REGISTER.LoggedOut');
      case 3:
        return INTL('TYPE.REGISTER.Create');
      case 4:
        return INTL('TYPE.REGISTER.Update');
      case 5:
        return INTL('TYPE.REGISTER.Delete');
      case 6:
        return INTL('TYPE.REGISTER.Reactivate');
      default:
        return type;
    }
  }

  const getPeople = async (value: string) => {
    const {
      data: {
        data: { data: people, pages }
      }
    } = await getAllPersons({
      pageSize: 500,
      pageNumber: 1,
      status: 1,
      ...(value && { value }),
      sortOrder: 'asc',
      sortField: 'Name',
      personType: 'Person'
    });

    return {
      options: people.map((el: any) => ({ value: el.id, label: el.name })),
      hasMore: false
    };
  };

  const getRegisters = useCallback(async () => {
    // alert(inputTableSelected);
  }, [inputTableSelected]);

  const paginationOptions = {
    custom: true,
    totalSize: totalItens,
    sizePerPageList: window.sizePerPageList,
    sizePerPage: sizePerPage,
    page: pagination
  };

  const columns = [
    {
      dataField: 'datetime',
      text: INTL('REPORTS.AUDITLOG.TABLE.DATETIME'),

      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses

      // headerAlign: 'center',
      // align: 'center',
      // style: {
      //   minWidth: '70px',
      //   maxWidth: '70px'
      // }
    },
    {
      dataField: 'typeRegister',
      text: INTL('REPORTS.AUDITLOG.TABLE.TYPE.REGISTER'),
      sort: true,

      sortCaret: sortCaret,
      headerSortingClasses,

      // headerAlign: 'center',
      // align: 'center',
      style: {
        minWidth: '180px'
      }
    },
    {
      dataField: 'operator',
      text: INTL('REPORTS.AUDITLOG.TABLE.OPERATOR'),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses

      // headerAlign: 'center',
      // align: 'center',
      // style: {
      //   minWidth: '150px',
      //   textAlign: 'center'
      // }
    },
    {
      dataField: 'table',
      text: INTL('REPORTS.AUDITLOG.TABLE.TABLE'),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses

      // headerAlign: 'center',
      // align: 'center',
      // style: {
      //   minWidth: '150px',
      //   textAlign: 'center'
      // }
    },
    {
      dataField: 'before',
      text: INTL('REPORTS.AUDITLOG.TABLE.BEFORE')
      // sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses

      // headerAlign: 'center',
      // align: 'center',
      // style: {
      //   minWidth: '150px',
      //   textAlign: 'center'
      // }
    },
    {
      dataField: 'after',
      text: INTL('REPORTS.AUDITLOG.TABLE.AFTER')
      // sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses

      // headerAlign: 'center',
      // align: 'center',
      // style: {
      //   minWidth: '150px',
      //   textAlign: 'center'
      // }
    }
  ];

  const selectStyle = {
    control: (styles: CSSObjectWithLabel) => ({
      ...styles,
      border: '1px solid #E4E6EF',
      '&:hover': {
        cursor: 'pointer'
      }
    })
  };

  function TablesNames(value: any) {
    return INTL(value);
  }


  function updateSearchValue() {
    if (!searchRef || !searchRef.current) return

    // console.log(searchRef.current.value)
    setSearchText(searchRef.current.value)
  }

  // useEffect(() => {
  //   getRegisters();
  // }, [getRegisters]);

  useEffect(() => {
    GetValues({
      OperatorIds: operatorsIds.toString(),
      TableId: inputTableSelected,
      AuditLogType: typeLogSelected,
      dtEnd: dateToApi.dtEnd,
      dtStart: dateToApi.dtStart,
      pageNumber: 1,
      pageSize: sizePerPage,
      sortOrder: sortOrder,
      sortField: sortField,
      value: searchText
    }).then((resp) => {
      const data = resp.data.data;

      const formatValues = data.data.map((item: PROPDATA) => {
        return {
          after: textToElement(item.newValues),
          before: textToElement(item.oldValues),
          table: item.tableName ? intl.formatMessage({ id: item.tableName }).toUpperCase() : '',
          // datetime: format(new Date(item.auditLogDate), `dd/MM/yyyy HH:mm:ss`),
          datetime: item.auditLogDate,
          typeRegister: typeRegister(item.auditLogType),
          operator: item.personName
        };
      });

      setValuesDataTables(formatValues);
      setTotalItens(data.total);
    });
  }, [operatorsIds, inputTableSelected, typeLogSelected, dateToApi, searchText]);

  useEffect(() => {
    GetValues({
      OperatorIds: operatorsIds.toString(),
      TableId: inputTableSelected,
      AuditLogType: typeLogSelected,
      dtEnd: dateToApi.dtEnd,
      dtStart: dateToApi.dtStart,
      pageNumber: pagination,
      pageSize: sizePerPage,
      sortOrder: sortOrder,
      sortField: sortField,
      value: searchText
    }).then((resp) => {
      const data = resp.data.data;

      const formatValues = data.data.map((item: PROPDATA) => {
        return {
          after: textToElement(item.newValues),
          before: textToElement(item.oldValues),
          table: item.tableName ? intl.formatMessage({ id: item.tableName }) : '',
          datetime: formatFullDateStyle(item.auditLogDate),
          typeRegister: typeRegister(item.auditLogType),
          operator: item.personName
        };
      });

      setValuesDataTables(formatValues);
      setTotalItens(data.total);
    });
  }, [sizePerPage, sortOrder, sortField, pagination]);


  const exportParams = () => {
    return {
      OperatorIds: operatorsIds.toString(),
      TableId: inputTableSelected,
      AuditLogType: typeLogSelected,
      dtEnd: dateToApi.dtEnd,
      dtStart: dateToApi.dtStart,
      pageNumber: pagination,
      pageSize: sizePerPage,
      sortOrder: 'desc',
      sortField: 'AuditLogDate',
      value: searchText,
      culture: handleCulture(languages)
    };
  };

  const headerCSV: string[] = headersCSVLogs[languages];

  return (
    <Card style={{ marginTop: 80 }} className={'card card-custom gutter-b mt-md-0'}>
      <Card.Header>
        <Card.Title className={'m-0'}>{INTL('REPORTS.AUDITLOG')}</Card.Title>

        <CardHeaderToolbar className={null}>
          <Row className='mr-5' style={{ paddingRight: 5 }}>
            <Col>
              <LongMenu
                filtros={exportParams()}
                totalCount={totalItens}
                linkApi={ExportCSV([''], exportParams())}
                headersHTML={ExportCSV([''], exportParams())}
                exportList={true}
                linkApiViewHTML={'/systemAuditTrail/viewhtml'}
                headers={headerCSV}
                nomeArquivo={intl.formatMessage({
                  id: 'REPORTS.AUDITLOG'
                })}
              />
            </Col>
          </Row>
        </CardHeaderToolbar>
      </Card.Header>

      <Card.Body>
        <PaginationProvider
          pagination={paginationFactory(
            //@ts-ignore
            paginationOptions
          )}>
          {({ paginationProps, paginationTableProps }: any) => {

            return (
              <Pagination isLoading={false} paginationProps={paginationProps}>
                <form
                  className={'form mb-3'}
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}>
                  <div className={`row col-12 mb-5`} style={{ paddingRight: '0px' }}>
                    <div className={`col-3 mb-5 mb-md-0`}>
                      <label className={'form-label'}>{INTL('REPORTS.AUDITLOG.FILTER.OPERATOR')}</label>

                      <AsyncPaginate
                        loadingMessage={() => 'Loading'}
                        noOptionsMessage={() => 'Sem dados'}
                        debounceTimeout={500}
                        styles={selectStyle}
                        closeMenuOnSelect={false}
                        placeholder={INTL('REPORTS.AUDITLOG.FILTER.OPERATOR')}
                        loadOptions={getPeople}
                        loadOptionsOnMenuOpen
                        isMulti
                        onChange={(values: any) => {
                          const ids = values ? [...values.map(({ value }: any) => Number(value))] : [];

                          setOperatorsIds(ids);
                        }}
                      />
                    </div>

                    <div className={`col-3 mb-5 mb-md-0`}>
                      <label className={'form-label'}>{INTL('REPORTS.AUDITLOG.FILTER.TYPE.OPERATOR')}</label>

                      <select name='' id='' value={typeLogSelected} onChange={handleTypeLogInputTableSelected} className='form-control'>
                        <option value=''>{INTL('TYPE.REGISTER.ALL')}</option>
                        <option value='1'>{INTL('TYPE.REGISTER.LoggedIn')}</option>
                        <option value='2'>{INTL('TYPE.REGISTER.LoggedOut')}</option>
                        <option value='3'>{INTL('TYPE.REGISTER.Create')}</option>
                        <option value='4'>{INTL('TYPE.REGISTER.Update')}</option>
                        <option value='5'>{INTL('TYPE.REGISTER.Delete')}</option>
                        <option value='6'>{INTL('TYPE.REGISTER.Reactivate')}</option>
                      </select>
                    </div>

                    <div className={`col-6 mb-5 mb-md-0`}>



                      <DateTimeRangeInput
                        label={intl.formatMessage({ id: 'ACCESS_LOGS.INPUTS.DATETIME_RANGE' })}
                        valueEnd={endDate}
                        valueStart={startDate}
                        updateValueEnd={(e) => {
                          setPagination(1);
                          setEndDate(e);

                          setDateToApi({
                            dtEnd: dateToUNIX(e),
                            dtStart: dateToUNIX(startDate)
                          });
                        }}
                        updateValueStart={(e) => {
                          setPagination(1);
                          setStartDate(e);

                          setDateToApi({
                            dtEnd: dateToUNIX(endDate),
                            dtStart: dateToUNIX(e)
                          });

                        }}
                      />
                    </div>

                    <div className={`col-3 mb-5 mb-md-0 `} style={{ marginTop: '10px' }}>
                      <label className={'form-label'}>{INTL('REPORTS.AUDITLOG.FILTER.TYPE.TABLES')}</label>

                      <select style={{
                        textTransform: "capitalize"
                      }} name='' id='' value={inputTableSelected} className='form-control' onChange={handleAlterValueInputTableSelected}>
                        <option value=''>{INTL('TYPE.REGISTER.ALL')}</option>
                        <OptionTable />
                      </select>
                    </div>


                    <div className={`col-3 mb-5 mb-md-0 `} style={{ marginTop: '10px' }}>
                      <label className={'form-label'}>{INTL('GENERIC.SEARCH')}</label>

                      <input type="text"
                        ref={searchRef}
                        className='form-control'
                        onChange={(e: any) => {
                          if (debounce) {
                            clearTimeout(debounce)
                          }

                          debounce = setTimeout(() => {
                            updateSearchValue()
                          }, 500)
                        }}
                      />
                    </div>

                    {/* <div className={`col-3 mb-5 mb-md-0`} style={{marginTop: '10px'}}>
                      <label className={'form-label'}>Registros</label>

                      <select name='' id='' className='form-control' disabled></select>
                    </div> */}
                  </div>
                </form>
                <BootstrapTable
                  onTableChange={(type, props) => {
                    if (props.page !== 0) {
                      setPagination(props.page);
                    }

                    setSizePerPage(props.sizePerPage);

                    if (type == 'sort') {
                      setSortOrder(props.sortOrder);
                      updateFilterColumnName(props.sortField);
                    }
                  }}
                  // defaultSorted={[defaultSortedColumn]}
                  wrapperClasses='table-responsive'
                  bordered={false}
                  classes='table table-head-custom table-vertical-center overflow-hidden'
                  remote
                  bootstrap4
                  keyField='id'
                  data={valuesDataTables}
                  columns={columns}
                  // columns={tableColumns(lang, intl)}
                  {...paginationTableProps}>
                  <PleaseWaitMessage entities={valuesDataTables} />
                  <NoRecordsFoundMessage entities={[valuesDataTables]} />
                </BootstrapTable>
              </Pagination>
            );
          }}
        </PaginationProvider>
      </Card.Body>
    </Card>
  );
}
