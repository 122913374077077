import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;

  .content-container {
    text-align: center;
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-color: #dcfce7;
`;
