/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {useEffect} from 'react';
import {Redirect, Switch, Route} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Layout} from '../_metronic/layout';
import BasePage from './BasePage';
import {Logout, AuthPage} from './modules/Auth';
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage';
// import {indexedDB} from 'src/utils/DataBaseIndexed';
// import {getConfigService} from 'src/services/configs';
// import {setLanguage} from 'src/_metronic/i18n';
import {ALTER_PERMISSIONS_SYSTEM} from 'src/redux/userPermissions/action';
import {convertTypePermissions} from './modules/Auth/utils/convertPermissionsType';
import {ALTER_MODE_OPERATION} from 'src/redux/isMode/actions';
import {RedirectToVisitorRegistration, VisitorRegistration} from './modules/Visitors/pages/remote-registration';

export function Routes() {
  const GLOBAL = useSelector((stage: any) => stage.auth);
  // const userActived = GLOBAL.authToken ? GLOBAL.authToken.userId : 0;

  const {isAuthorized} = useSelector<any, any>(
    ({auth}) => ({
      isAuthorized: auth.user != null
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const data = localStorage.getItem('@P');

    if (data) {
      const permissionsData = JSON.parse(data);
      const convert = convertTypePermissions(permissionsData);

      dispatch(ALTER_PERMISSIONS_SYSTEM(convert));
    }

    const tenalt = localStorage.getItem('controlid-demo');
    const isModeDemo = tenalt
      ? tenalt === 'Control iD' ||
        tenalt === 'controlid' ||
        tenalt === 'fabcidnovo' ||
        tenalt === 'Fábrica Control iD Novo'
      : false;
    dispatch(ALTER_MODE_OPERATION(isModeDemo));
  });

  return (
    <Switch>
      <Route path='/remote-registration/token' component={RedirectToVisitorRegistration} />
      <Route exact path='/remote-registration' component={VisitorRegistration} />

      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from='/auth' to='/' />
      )}

      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to='/auth/login' />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
