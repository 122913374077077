import React from 'react'
import {Card, CardBody, CardHeader, CardHeaderToolbar} from '../../../_metronic/_partials/controls';
import { List } from './list';


export function Releases(){
  return (
    <Card fluidHeight={null} className={null}>
      <CardHeader 
      icon={null} 
      toolbar={null} 
      className={null} 
      labelRef={null} sticky={false} 
      linkAddTitle={''} linkAddTo='' title={'Releases'} />

      <CardBody fit={null} fluid={null} className={null}>
        <List />
      </CardBody>
    
    </Card>
  )
}