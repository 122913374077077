import styled from 'styled-components';

export const Main = styled.main`
  margin-top: 15px;
  min-height: 59vh;

  h5 {
    margin-top: 30px;
  }

  .row {
    & + .row {
      margin-top: 25px;
    }
  }
`;

export const Footer = styled.main`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const TabDocuments = styled.div`
    margin-top: 15px;
  min-height: 59vh;
`

export const TabAccess = styled.div`
    margin-top: 15px;
    min-height: 59vh;

    & > div {
      display: flex;
      gap:10px;
    }

    .one-config{
      display: flex;
      flex-direction: column;
      width: 400px;

      div{
        display: flex;
        gap: 5px;
        align-items: start;
      }

      p{
        font-size: 11px;
      }
    }
    .one-config-2{
      display: flex;
      flex-direction: column;
      width: 800px;

      div{
        display: flex;
        gap: 5px;
        align-items: start;
      }

      p{
        font-size: 11px;
      }
    }
    .text-area-default-message {
      border: solid 1px #e2e2e2;
      border-radius: 4px;
      outline: none;
      width: 800px;
      padding: 10px;
    }
    .text-area-qr-code-message {
      border: solid 1px #e2e2e2;
      border-radius: 4px;
      outline: none;
      width: 100%;
      padding: 10px;
    }
`