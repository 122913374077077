import { IntlShape } from 'react-intl';
import { useLang, } from 'src/_metronic/i18n';

export function Culture(culture: 'pt' | 'es' | 'en') {
  switch (culture) {
    case 'pt':
      return 'pt-br'
    case 'es':
      return 'es-es'
    case 'en':
      return 'en-us'
  }
}

export function handleCulture(culture: string) {
  switch (culture) {
    case 'pt':
      return 'pt-br';
    case 'es':
      return 'es-es';
    case 'en':
      return 'en-us';
    case 'fr':
      return 'fr-FR';
    default: {
      return culture
    }
  }
}

export function TranslatorLabelsApis(value: string, intl: IntlShape) {
  switch (value) {
    case 'OPROLE.DEFAULT': {
      return intl.formatMessage({ id: value })
    }

    default: {
      return value
    }
  }
}