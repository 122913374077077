/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Row, Spinner } from 'react-bootstrap';
import moment from 'moment';
import { Animated } from 'react-animated-css';
import { makeStyles, Avatar, Tooltip } from '@material-ui/core';
// import {Avatar} from '@material-ui/core';
// import {makeStyles} from '@material-ui/core/styles';
import { eventsLiteral } from '../../Reports/utils/utils';
import { Show } from 'src/app/components/Show';
import { useSelector } from 'react-redux';
import { DashboardModel, StateDashboard } from '../_redux/dashboardSlices';
import { defaultDateTime } from 'src/utils/defaultDateTime';
const useStyles = makeStyles({
  // avatar: {
  //   margin: 10
  // },
  bigAvatar: {
    marginLeft: 0,
    marginRigth: 10,
    width: 60,
    height: 60
  }
});
export function RealTimeAccessComponent({ className, realTimeAccess }: any) {
  const isLoading = useSelector<StateDashboard>(state => state.dashboard.isLoadingRealTimeAccess) as boolean
  const classes = useStyles();
  // const realTime: any[] = realTimeAccess;
  const [realTime, setRealTime] = useState<any[]>([]);
  const [imagemErro, setImagemErro] = useState<any[]>([]);
  const intl = useIntl();

  const duration = 700;

  useEffect(() => {
    // return () => {

    if (realTimeAccess && realTimeAccess.length) {
      setRealTime(realTimeAccess);
      let temp = false;
      let realTimeAux: any[] = [];
      for (const r in realTimeAccess) {
        let realTimeAuxs: any = { ...realTimeAccess[r] };
        realTimeAuxs.new = !realTime.some((f) => f.id == realTimeAccess[r].id);
        realTimeAux.push(realTimeAuxs);
        if (!temp && realTimeAuxs.new) {
          temp = true;
        }
      }
      // if (temp) {
      setRealTime(realTimeAux);
      // }
      // setRealTime(
      //   realTimeAccess.map((item: any) => {
      //     if (realTime.some((f) => f.id == item.id)) {
      //       return {...item, new: false};
      //     }
      //     return {...item, new: true};
      //   })
      // );
    }
    // };
  }, [realTimeAccess]);



  const List = React.memo(({ realT }: { realT: any[] }) => {
    const getHour = (date: any) => {
      const d = new Date(date);
      return `${moment(d).format('HH:mm')}`;
    };

    let CardPersonTime = React.memo(({ person }: any) => (
      <>
        <div className={`timeline-item align-items-start card-t-top`} style={{ display: 'flex' }}>
          <div className='timeline-label font-weight-bolder text-dark-75 font-size-lg mt-4 TIME_ACCESS_RIGHT' style={{
            width: '70px'
          }}>
            {/* {getHour(person.dataHora)} */}
            {
              defaultDateTime.onlyTime(person.dataHora)
            }

          </div>

          <div className='timeline-badge' style={{ marginTop: '15px' }}>
            <i className={`fa fa-genderless icon-xl ${![7, 11, 12, 15].includes(person.status) ? 'text-danger' : 'text-success'}`}></i>
          </div>

          <div className='font-weight-mormal font-size-lg timeline-content text-muted pl-3'>
            <Row>
              <div className='symbol symbol-60px symbol-circle ml-1 mr-1'>
                <Avatar
                  alt='Pic'
                  className={classes.bigAvatar}
                  style={{ objectFit: 'cover' }}
                  src={person.photo}
                // onError={({currentTarget}) => {
                //   currentTarget.onerror = null; // prevents looping
                //   setImagemErro((erImg) => [...erImg, currentTarget.src]);
                //   currentTarget.src = toAbsoluteUrl('/media/svg/icons/General/User.svg');
                //   // person.personAvatar = null;
                // }}
                />
              </div>
              <div className='col'>
                <Tooltip disableFocusListener title={(person.name != '' && person.name !== 'Not Identified' && person.name) || (eventsLiteral(intl) as any)[person.event] || intl.formatMessage({ id: 'UNKNOWN' })}>
                  <div className='row'>
                    <span style={{ maxWidth: '100%' }} className='fs-6 text-truncate fw-bolder text-dark-50 text-start  text-hover-primary font-weight-bolder d-inline-block'>
                      {(person.name != '' && person.name !== 'Not Identified' && person.name) || (eventsLiteral(intl) as any)[person.event] || intl.formatMessage({ id: 'UNKNOWN' })}
                    </span>
                  </div>
                </Tooltip>
                <div className='row fw-bold fs-7 text-muted'>
                  {/* {intl.formatDate(person.dataHora)} */}
                  {
                    defaultDateTime.onlyDate(person.dataHora)
                  }
                </div>
                <div className='row fw-bold fs-7 text-muted'>{person.destinationArea}</div>
              </div>
            </Row>
          </div>
        </div>
      </>
    ));

    let CardListTimeLine = React.memo(({ person }: any) => {
      // if (!realTime.some((f) => f.id == person.id)) {
      //   person.new = true;
      // }

      return (
        <Animated className='timeline-item align-items-start' animationOutDuration={0} animationIn='bounceInRight' animationOut='pulse' isVisible={person.new} key={person.id}>
          <CardPersonTime person={person} />
        </Animated>
      );
    });

    return (
      // <div className='card-body pt-4'>
      <div className='timeline timeline-6 mt-3'>
        {realT.map((person, i) => {
          // return person.new ? <CardListTimeLine key={i} person={person} /> : <CardPersonTime key={i} person={person} />;
          // cardListTImeLine({person: person});
          return <CardListTimeLine key={i} person={person} />;
        })}
      </div>
      // </div>
    );
  });

  return (
    <div className='container-logs'>
      <div className={`card card-custom ${className}`}>
        {/* Head */}
        <h3 className='pl-5 pr-5 pt-5 card-title font-weight-bolder text-black'>{intl.formatMessage({ id: 'DASH.LAST_HITS' })}</h3>
        {/* Body */}
        <Show show={isLoading}>
          <div style={{ flex: 1, display: 'flex' }}>
            <Spinner animation='border' style={{ margin: 'auto' }} />
          </div>
        </Show>

        <Show show={!isLoading && realTime.length > 0}>
          <>
            <div className='card-body real-time pt-2' style={{ overflowX: 'hidden' }}>
              <List realT={realTime} />
            </div>
          </>
        </Show>

        <Show show={!isLoading && realTime.length <= 0}>
          <span style={{ textAlign: 'center' }}>{intl.formatMessage({ id: 'DASHBOARD.NO_DATA_TO_PRESENT' })}</span>
        </Show>
      </div>
    </div>
  );
}
