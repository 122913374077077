import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { Pagination } from 'src/_metronic/_partials/controls';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { useHistory } from 'react-router';
import { NoRecordsFoundMessage, PleaseWaitMessage, toAbsoluteUrl } from 'src/_metronic/_helpers';

import { useTableHooks } from './hooks/table';
import { useFilters } from './hooks/filter';
import SVG from 'react-inlinesvg';
import { activeOperation, deleteOperation, getOperations } from 'src/services/permissions';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { usePermisssion } from 'src/redux/userPermissions/usePermission';
import { activeNotification, deleteNotification, getAllNotification } from 'src/services/notificationsCrud';
import { useIntl } from 'react-intl';

let debounce: NodeJS.Timeout | null;

export function NotificationList() {
  const history = useHistory();
  const [isRead, setIsRead] = useState(false);
  const { redirectView, actionsIsDesatived } = usePermisssion();

  const { INTL, columns } = useTableHooks();
  const intl = useIntl();
  const { permission, page, order } = useFilters();
  const [LIST, SETLIST] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');
  const [modalDeleteIsShow, setModalDeleteIsShow] = useState(false);
  const [modalActivedIsShow, setModalActivedIsShow] = useState(false);
  const [showModalErase, setShowModalErase] = useState(false);
  const [dataModal, setDataModel] = useState({
    id: 0,
    name: ''
  });

  const paginationOptions = {
    custom: true,
    totalSize: total,
    sizePerPageList: window.sizePerPageList,
    sizePerPage: page.sizePerPage,
    page: page.page
  };

  useEffect(() => {
    redirectView(12);
    const value = actionsIsDesatived(12);
    setIsRead(value);
  }, []);

  function RenderActionOld(item: any) {
    return (
      <>
        <Link className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3' to={`/notifications/edit/${item.id}`}>
          <span className='svg-icon svg-icon-md svg-icon-primary'>
            <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
          </span>
        </Link>

        <a
          className='btn btn-icon btn-light btn-hover-danger btn-sm mx-3'
          onClick={() => {
            handleDelete(item.id, item.description);
          }}>
          <span className='svg-icon svg-icon-md svg-icon-danger'>
            <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
          </span>
        </a>

        <a
          className='btn btn-icon btn-icon btn-light btn-hover-success btn-sm mx-3'
          onClick={() => {
            handleReactive(item.id, item.description);
          }}>
          <span className='svg-icon svg-icon-md svg-icon-success'>
            <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Check.svg')} />
          </span>
        </a>
      </>
    );
  }

  function RenderAction(item: any) {
    if (item.isEnabled) {
      return (
        <>
          <Link className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3' to={`/notifications/edit/${item.id}`}>
            <span className='svg-icon svg-icon-md svg-icon-primary'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
            </span>
          </Link>

          <a
            className='btn btn-icon btn-light btn-hover-danger btn-sm mx-3'
            onClick={() => {
              handleDelete(item.id, item.description);
            }}>
            <span className='svg-icon svg-icon-md svg-icon-danger'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
            </span>
          </a>
        </>
      );
    }

    return (
      <>
        <a
          className='btn btn-icon btn-icon btn-light btn-hover-success btn-sm mx-3'
          onClick={() => {
            handleReactive(item.id, item.description);
          }}>
          <span className='svg-icon svg-icon-md svg-icon-success'>
            <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Check.svg')} />
          </span>
        </a>
      </>
    );
  }

  function handleDelete(id: number, name: string) {
    setDataModel({ id, name });
    setModalDeleteIsShow(true);
  }

  function handleReactive(id: number, name: string) {
    setDataModel({ id, name });
    setModalActivedIsShow(true);
  }

  async function load() {
    const filters = {
      pageNumber: page.page,
      pageSize: page.sizePerPage,
      status: permission.permisionStatus,
      sortOrder: order.sortOrder,
      sortField: 'Description',
      value: search
    };

    const { data } = await getAllNotification(filters);

    SETLIST(
      data.data.data.map((operation: any) => {
        return {
          id: operation.id,
          description: INTL(operation.description),
          actions: RenderAction(operation)
        };
      })
    );

    setTotal(data.data.total);
  }

  useEffect(() => {
    load();
  }, [permission.permisionStatus, page.sizePerPage, page.page, order.sortOrder, search]);

  return (
    <Card fluidHeight={null} className={null}>
      <CardHeader
        icon={null}
        toolbar={null}
        className={null}
        labelRef={null}
        sticky={false}
        linkAddTitle={INTL('NOTIFICATION.NEW_NOTIFICATION')}
        linkAddTo='/notifications/create'
        title={INTL('NOTIFICATION.TITLE')}>
        <CardHeaderToolbar className={null}>
          <div style={{ width: '8vw' }}>
            <select
              className='form-control'
              value={permission.permisionStatus}
              onChange={({ target }) => permission.setPermisionStatus(Number(target.value))}>
              <option value='2'>{INTL('PAGE.BLUEPRINT.LIST_STATUS.ALL')}</option>
              <option value='1'>{INTL('PAGE.BLUEPRINT.LIST_STATUS.ACTIVE')}</option>
              <option value='0'>{INTL('PAGE.BLUEPRINT.LIST_STATUS.DESACTIVE')}</option>
            </select>
          </div>

          <div style={{ width: '8vw', marginLeft: '10px' }}>
            <input
              onChange={({ target }) => {
                if (debounce) clearTimeout(debounce);

                debounce = setTimeout(() => {
                  setSearch(target.value);
                  page.setPage(1);
                }, 500);
              }}
              className='form-control'
              type='text'
              placeholder={INTL('PAGE.BLUEPRINT.LIST_INPUT.SEARCH')}
            />
          </div>

          <div>
            <Link
              title={INTL('NOTIFICATION.NEW_NOTIFICATION')}
              type='button'
              className='btn btn-primary ml-5 mr-2'
              onClick={() => { }}
              to='/notifications/create'>
              {INTL('NOTIFICATION.NEW_NOTIFICATION')}
            </Link>
          </div>
        </CardHeaderToolbar>
      </CardHeader>

      <CardBody fit={null} fluid={null} className={null}>
        <Modal show={modalDeleteIsShow} onHide={() => setModalDeleteIsShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {INTL('TOAST.DELETE_BUTTON')} : {dataModal.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{INTL('DEVICE_NOTIFICATIONS.TOAST.DELETE_CONFIRM')}</Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => {
                setModalDeleteIsShow(false);
              }}>
              {INTL('PERSON.NEWPERSON_CANCEL_BUTTON')}
            </Button>
            <div style={{ width: '88px' }} />
            <Button
              variant='outline-danger'
              onClick={() => {
                setModalDeleteIsShow(false);
                setShowModalErase(true);
              }}>
              {INTL('PERMANENTLY_DELETE')}
            </Button>
            <Button
              variant='danger'
              disabled={isRead}
              onClick={async () => {
                if (isRead) return;

                setModalDeleteIsShow(false);
                await deleteNotification(dataModal.id);
                load();
                execToast('success', INTL('OPERATOR_ROLE_.TOAST_INATIVE'), 3000);
              }}>
              {INTL('TOAST.DELETE_BUTTON')}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showModalErase} onHide={() => setShowModalErase(false)}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: '#F64E60' }}>{`${dataModal.name}: ${INTL(
              'ARE_YOU_SURE_YOU_WANT_TO_PERMANENTLY_DELETE_IT'
            )}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{intl.formatMessage({ id: 'THIS_ACTION_CANNOT_BE_UNDONE' })} </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => {
                setShowModalErase(false);
              }}>
              {INTL('PERSON.NEWPERSON_CANCEL_BUTTON')}
            </Button>

            <div style={{ width: '34px' }} />
            <Button
              disabled={isRead}
              variant='outline-danger'
              onClick={async () => {
                setModalDeleteIsShow(false);
                setShowModalErase(false);
                try {
                  await deleteNotification(dataModal.id);
                  load();
                  execToast('success', INTL('OPERATOR_ROLE_.TOAST_INATIVE'), 3000);
                } catch {
                  execToast('error', INTL('Erro ao inativar'), 3000);
                }

              }}>
              {intl.formatMessage({ id: 'INACTIVATE_ONLY' })}
            </Button>

            <Button
              variant='danger'
              disabled={isRead}
              onClick={async () => {
                setModalDeleteIsShow(false);
                setShowModalErase(false);
                try {
                  await deleteNotification(dataModal.id, true);
                  load();
                  execToast('success', INTL('TOAST.PERMANENTLY_DELETED.SUCCESS'), 3000);
                } catch {
                  execToast('error', INTL('TOAST.PERMANENTLY_DELETED.ERROR'), 3000);
                }
              }}>
              {INTL('PERMANENTLY_DELETE')}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={modalActivedIsShow} onHide={() => setModalActivedIsShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {INTL('TOAST.ENABLE')} : {dataModal.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{INTL('DEVICE_NOTIFICATIONS.TOAST.ACTIVE_CONFIRM')}</Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => {
                setModalActivedIsShow(false);
              }}>
              {INTL('PERSON.NEWPERSON_CANCEL_BUTTON')}
            </Button>
            <Button
              variant='primary'
              disabled={isRead}
              onClick={async () => {
                if (isRead) return;
                setModalActivedIsShow(false);
                await activeNotification(dataModal.id);
                load();
                execToast('success', INTL('OPERATOR_ROLE_.TOAST_ATIVE'), 3000);
              }}>
              {INTL('TOAST.ENABLE')}
            </Button>
          </Modal.Footer>
        </Modal>

        <PaginationProvider
          pagination={paginationFactory(
            //@ts-ignore
            paginationOptions
          )}>
          {({ paginationProps, paginationTableProps }: any) => {

            return (
              <Pagination isLoading={false} paginationProps={paginationProps}>
                <BootstrapTable
                  onTableChange={(type, props) => {
                    if (type == 'sort') {
                      order.setSortOrder(props.sortOrder);
                    }



                    if (!props.page) return;
                    page.setPage(props.page);
                    page.setSizePerPage(props.sizePerPage);
                  }}
                  // defaultSorted={[defaultSortedColumn]}
                  wrapperClasses='table-responsive'
                  bordered={false}
                  classes='table table-head-custom table-vertical-center overflow-hidden'
                  remote
                  bootstrap4
                  keyField='id'
                  data={LIST}
                  columns={columns}
                  // columns={tableColumns(lang, intl)}
                  {...paginationTableProps}>
                  <PleaseWaitMessage entities={LIST} />
                  <NoRecordsFoundMessage entities={[LIST]} />
                </BootstrapTable>
              </Pagination>
            );
          }}
        </PaginationProvider>
      </CardBody>
    </Card>
  );
}
