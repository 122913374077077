import {Reducer} from 'redux';

import produce from 'immer'; //immer
import {ActionTypes, MODE_TYPE} from './types';



const INITIAL_STATE = {
  isDemo:false
};

const userMode: Reducer<MODE_TYPE> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.SET_MODE_OPERATION: {
        
        draft.isDemo = action.payload

        return draft;
      }
      default: {
        return draft;
      }
    }
  });
};

export default userMode;
