import React from 'react';
import {PaginationLinks} from './PaginationLinks';
import {PaginationToolbarList} from './PaginationTableList';
// import {PaginationToolbar} from './PaginationToolbar';

export function Pagination(props) {
  const {children, isLoading, paginationProps, showLabel = true} = props;

  return (
    <>
      {children}
      <div className='d-flex justify-content-between align-items-center flex-wrap'>
        <PaginationLinks paginationProps={paginationProps} />
        <span></span>
        {!paginationProps.hideSizePerPage && (
          <PaginationToolbarList isLoading={isLoading} paginationProps={paginationProps} showLabel={showLabel} />
        )}
      </div>
    </>
  );
}
