import {NoRecordsFoundMessage, PleaseWaitMessage, sortCaret, headerSortingClasses, toAbsoluteUrl} from 'src/_metronic/_helpers';
import {Link} from 'react-router-dom';
import {useIntl} from 'react-intl';
import React from 'react';
import SVG from 'react-inlinesvg';

export function useTableHooks() {
  const intl = useIntl();
  const columns = [
    {
      dataField: 'description',
      text: INTL('OPERATOR_ROLE_COLLUM_NAME'),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'actions',
      text: INTL('OPERATOR_ROLE_COLLUM_ACTIONS'),
      classes: 'text-right pr-0',
      headerClasses: 'text-right pr-3',
      style: {
        minWidth: '100px'
      }
    }
  ];

  function RenderAction(item: any) {
    if (item.isEnabled) {
      return (
        <>
          <Link className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3' to={`/blueprint/edit/${item.id}`}>
            <span className='svg-icon svg-icon-md svg-icon-primary'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
            </span>
          </Link>

          <a
            className='btn btn-icon btn-light btn-hover-danger btn-sm mx-3'
            onClick={() => {
              // handleDelete(item.id, item.description);
            }}>
            <span className='svg-icon svg-icon-md svg-icon-danger'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
            </span>
          </a>
        </>
      );
    }

    return (
      <>
        <a
          className='btn btn-icon btn-icon btn-light btn-hover-success btn-sm mx-3'
          onClick={() => {
            // handleReactive(item.id, item.description)
          }}>
          <span className='svg-icon svg-icon-md svg-icon-success'>
            <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Check.svg')} />
          </span>
        </a>
      </>
    );
  }

  function INTL(label: string) {
    return intl.formatMessage({id: label});
  }

  return {
    columns,
    INTL
  };
}
