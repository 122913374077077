import React, { useEffect, useState } from 'react';
import { BiInfoCircle } from 'react-icons/bi';

import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import * as S from './styles';
import { useIntl } from 'react-intl';
import { eachDayOfInterval } from 'date-fns';
import { defaultDateTime } from 'src/utils/defaultDateTime';

interface ILicenseCard {
  type: 'user' | 'device' | 'expiration';

  title: string;
  subTitle: string;
  value: number;
  total: number;
  percentage: number;
}

export function ExpirationCard(props: ILicenseCard) {
  const intl = useIntl();
  const [daysToExpiration, setDaysToExpiration] = useState(0);
  const [porcent, setPorcent] = useState(0);
  const [dateLimit, setSateLimit] = useState('');
  const [isShow, setIsShow] = useState(false)

  function licenceInDays() {
    try {
      const licenseExpiration = localStorage.getItem('licenseExpiration')
      // const licenseExpiration = '2024/09-27'
      const resellerId = localStorage.getItem('resellerId')

      if (resellerId !== '2071') {
        return
      }

      setIsShow(true)

      if (licenseExpiration) {
        const value = eachDayOfInterval({
          start: new Date(),
          end: new Date(licenseExpiration)
        })


        setDaysToExpiration(value.length)
        setPorcent(100 - ((100 / 60) * value.length))
        const defaultDateTimeValue = defaultDateTime.labelDateTime(licenseExpiration)
        const defaultDate = defaultDateTime.onlyDate(defaultDateTimeValue.toString())


        setSateLimit(defaultDate)
      }
    } catch { }
  }

  useEffect(() => {
    licenceInDays()
  }, [])


  if (!isShow) {
    return (
      <></>
    )
  }

  return (
    <S.Wrapper type={props.type}>
      <header>
        <h1>{props.title}</h1>
        <span className='sub-title'>
          <BiInfoCircle />
          {props.subTitle}
        </span>
      </header>

      <S.Body>
        <CircularProgressbarWithChildren value={porcent}>
          <h1>{daysToExpiration}</h1>
          <span>{intl.formatMessage({ id: 'NOTIFICATION.DAYS' })}</span>
        </CircularProgressbarWithChildren>
      </S.Body>

      <S.Footer>
        {intl.formatMessage({ id: 'LICENSE.DAYS.DATE' })}{' '}
        <h1>{dateLimit}</h1>
      </S.Footer>
    </S.Wrapper>
  );
}
