import axios from 'axios';

const idsecureAPI = process.env.REACT_APP_IDSECURE_API_URL;

type ADD_MANAGE_CREDIT_PROPS = {
  'creditTypeId': string;
  'personId': string;
  'currentBalance': string;
};

export function ADD_MANAGE_CREDIT(data: ADD_MANAGE_CREDIT_PROPS) {
  return axios.post(`${idsecureAPI}/credit/typeperson`, data);
}

type GET_LIST_ALL_CREDIT_TYPES_ACTIVED_PROPS = {
  pageSize:number,
  pageNumber:number,
  status:'1',
  sortOrder:'asc',
  sortField:"Description"
}

export function GET_LIST_ALL_CREDIT_TYPES_ACTIVED(params:GET_LIST_ALL_CREDIT_TYPES_ACTIVED_PROPS){
  return axios.get(`${idsecureAPI}/credit/type`, {
    params
  });
}

export function GET_LIST_ALL_CREDIT_TYPES_ACTIVED_CHANGED_POLICY(params:GET_LIST_ALL_CREDIT_TYPES_ACTIVED_PROPS){
  return axios.get(`${idsecureAPI}/credit/typeperson/type`, {
    params
  });
}

type GET_LIST_ALL_CREDIT_MANAGEMENT_PROPS = {
  pageNumber:number,
  pageSize:number,
  status: number,
  sortOrder:'asc' | 'desc',
  sortField: string,
  value?:string,
  StartDate?:string,
  FinishDate?:string,
  UsageType?:string,
  GroupId?:number,
  CreditTypeId?:string,
  PersonId?:string,
}

export function GET_LIST_ALL_CREDIT_MANAGEMENT(params:GET_LIST_ALL_CREDIT_MANAGEMENT_PROPS){
  return axios.get(`${idsecureAPI}/credit/typeperson`, {
    params
  })
}

export function GET_CREDIT_MANAGEMENT_BY_ID(id:string){
  return axios.get(`${idsecureAPI}/credit/typeperson/${id}`)
}


type EDIT_CREDIT_MANAGEMENT_PROPD= 
  {
    "creditTypeId": number,
    "personId": number,
    "currentBalance": string,
    "id": string,
    "managementType": string//"Add" | "Adjust" //| "SubTract"
  }

export function EDIT_CREDIT_MANAGEMENT(data:EDIT_CREDIT_MANAGEMENT_PROPD){
  return axios.put(`${idsecureAPI}/credit/typeperson`,
    data
  )
}

type ADD_CREDIT_MANAGEMENT_GROUP_PROPS = {
  "creditTypeId": string,
  "currentBalance": string,
  "groupIds": string[],
  "managementType":  string
}

export function ADD_CREDIT_MANAGEMENT_GROUP(data:ADD_CREDIT_MANAGEMENT_GROUP_PROPS){
  return axios.post(`${idsecureAPI}/credit/typeperson/group`,
    data
  )
}

export function DELETE_CREDIT_MANAGEMENT(id:number, erase?: boolean){
  return axios.delete(`${idsecureAPI}/credit/typeperson/${id}`, {
    params: {
      erase
    }
  })
}

export function ACTIVE_CREDIT_MANAGEMENT(id:number){
  return axios.put(`${idsecureAPI}/credit/typeperson/${id}`)
}