import { AxiosError, AxiosInstance } from 'axios';
import { Store } from 'redux';
import { isErrorPermission } from 'src/utils/errorPermissionsApi';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { actions } from '../app/modules/Auth/_redux/authRedux';
import { getCookieRefreshToken, isRefreshToken, isRequestNewTokenProgress, setCookieRefreshToken, setRequestNewTokenProgress } from 'src/utils/refreshToken';
import Cookies from 'js-cookie';

export default function setupAxios(axios: AxiosInstance, store: Store) {
  let lang = localStorage.getItem('i18nConfig');
  // Get user token
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken }
      } = store.getState();

      const token = Cookies.get('auth-token');

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      // if (authToken) {
      //   config.headers.Authorization = `Bearer ${authToken.token}`;
      // }

      // const refreshTonken = isRefreshToken()


      // if(refreshTonken ){
      //   const payload = getCookieRefreshToken()

      //   if(!payload) return config;

      //   axios.post('/api/v1/operators/refresh-token', {
      //     accessToken: authToken.token,
      //     refreshToken: payload.refreshToken
      //   }).then(response => {


      //   })

      // }

      return config;
    },
    (err) => Promise.reject(err)
  );

  // Waiting 401 error
  axios.interceptors.response.use(
    (response) => {
      const refreshTonken = isRefreshToken()

      if (refreshTonken && !isRequestNewTokenProgress()) {

        setRequestNewTokenProgress(true)

        const payload = getCookieRefreshToken()

        if (payload) {
          const {
            auth: { authToken }
          } = store.getState()
          const idsecureAPI = process.env.REACT_APP_IDSECURE_API_URL;
          axios.post(`${idsecureAPI}/operators/refresh-token`, {
            accessToken: authToken.token,
            refreshToken: payload.refreshToken
          }).then(response => {

            setRequestNewTokenProgress(false)
            const values: { accessToken: string, refreshToken: string, expiration: string } = response.data.data

            Cookies.set('auth-token', values.accessToken);
            setCookieRefreshToken({
              expiration: values.expiration,
              refreshToken: values.refreshToken
            })
          }).catch(err => {
            console.error(err)
            store.dispatch(actions.logout());
            // setRequestNewTokenProgress(false)
          })
        }


      }
      return response;
    },
    (error: AxiosError) => {
      if (error.response?.status == 401) {
        store.dispatch(actions.logout());
        const newLang = lang ? JSON.parse(lang) : '';
        if (newLang.selectedLang == 'en') {
          execToast('warning', 'Session expired', false);
        } else if (newLang.selectedLang == 'pt') {
          execToast('warning', 'Sessão expirada', false);
        } else if (newLang.selectedLang == 'es') {
          execToast('warning', 'Sesión expirada', false);
        }
      }

      if (error.response?.status == 403) {
        isErrorPermission(403);
      }
      return Promise.reject(error);
    }
  );
}
