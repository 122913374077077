import React, {lazy} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';

import {TEMPORARY_CARDS} from './config/route';

const TemporaryCardsHomePage = lazy(() => import('./pages/home/index'));
const TemporaryCardsCreatePage = lazy(() => import('./pages/create/index'));
const TemporaryCardsEditPage = lazy(() => import('./pages/edit/index'));

const TemporaryCardsRoutes = () => {
  return (
    <>
      <Switch>
        <Route exact path={TEMPORARY_CARDS.BASE} component={TemporaryCardsHomePage} />
        <Route path={TEMPORARY_CARDS.CREATE} component={TemporaryCardsCreatePage} />
        <Route path={TEMPORARY_CARDS.EDIT(':id')} component={TemporaryCardsEditPage} />
      </Switch>
    </>
  );
};

const useTemporaryCardsRouter = () => {
  const history = useHistory();

  const goToHome = () => history.push(TEMPORARY_CARDS.BASE);

  const goToEdit = (id: number) => history.push(TEMPORARY_CARDS.EDIT(id));

  const goToCreate = () => history.push(TEMPORARY_CARDS.CREATE);

  return {
    goToHome,
    goToEdit,
    goToCreate
  };
};

export {TemporaryCardsRoutes, useTemporaryCardsRouter};
