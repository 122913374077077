import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  gap: 64px;
  padding: 30px 0px;

  label {
    display: block;
  }

  span {
    cursor: pointer;
  }

  .path {
    margin-top: 18px;
  }

  .hide {
    visibility: hidden;
  }

  .mt {
    margin-top: 15px;

    span {
      display: flex;
      align-items: flex-start;
    }

    input {
      margin-top: 10px;
    }
  }
`;

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 10px;
  margin-top: 10px;
`;
