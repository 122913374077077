import React, {useEffect, useMemo} from 'react';
import objectPath from 'object-path';
import {useHtmlClassService} from '../../_core/MetronicLayout';
import {LanguageSelectorDropdown} from '../extras/dropdowns/LanguageSelectorDropdown';
import {QuickUserToggler} from '../extras/QuiclUserToggler';
import {QuickManualToggler} from '../extras/QuickManualToggler';
import {getOperatorData} from 'src/app/modules/UserProfile/_redux/operatorActions';
import {useDispatch} from 'react-redux';

export function Topbar() {
  const dispatch = useDispatch();
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(uiService.config, 'extras.search.display'),
      viewNotificationsDisplay: objectPath.get(uiService.config, 'extras.notifications.display'),
      viewQuickActionsDisplay: objectPath.get(uiService.config, 'extras.quick-actions.display'),
      viewCartDisplay: objectPath.get(uiService.config, 'extras.cart.display'),
      viewQuickPanelDisplay: objectPath.get(uiService.config, 'extras.quick-panel.display'),
      viewLanguagesDisplay: objectPath.get(uiService.config, 'extras.languages.display'),
      viewUserDisplay: objectPath.get(uiService.config, 'extras.user.display')
    };
  }, [uiService]);

  useEffect(() => {
    dispatch(getOperatorData());
  }, []);

  return (
    <div className='topbar'>
      <QuickManualToggler />
      {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />}
      {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
  );
}
