import React, {useEffect, useMemo, useState} from 'react';
import {Input} from 'src/app/modules/vehicles/pages/CreateVehiclePage/components/Input';
import PhoneInput from 'react-phone-input-2';
import {Button, Spinner} from 'react-bootstrap';
import {AvatarWebcamComponent} from 'src/_metronic/_partials/controls/AvatarWebCam';
import * as yup from 'yup';

import * as S from './styles';
import {useVisitorRegistrationContext} from '../../context/visitor-registration-context';
import {handleParseYupError} from 'src/app/modules/Devices/utils/handle-parse-yup-error';
import {MdOutlineDateRange, MdOutlineUpdate} from 'react-icons/md';
import {getVisitor} from '../../use-cases/get-visitor';
import {GetVisitorErrors} from '../../use-cases/get-visitor/errors';
import {createPortal} from 'react-dom';
import {Show} from 'src/app/components/Show';
import {editVisitor} from '../../use-cases/edit-visitor';
import {execToast} from 'src/_metronic/_partials/controls/Toast';
import {useIntl} from 'react-intl';
import {differenceInDays} from 'date-fns';
import {useHistory} from 'react-router-dom';
import {AsyncPaginate} from 'react-select-async-paginate';
import {useFormState} from './hooks/use-form-state';
import {formSchema} from './validation';
import {formatFullDateStyle} from 'src/utils/formatFullDateStyle';

export function Form() {
  const {state, actions} = useFormState();
  const [status, setStatus] = useState<'loading' | 'idle'>('loading');

  const visitingTime = useMemo(
    () =>
      state.data.exitDate.getTime() === state.data.entryDate.getTime()
        ? 1
        : differenceInDays(state.data.exitDate, state.data.entryDate),
    [state.data.exitDate, state.data.entryDate]
  );

  const {changeView} = useVisitorRegistrationContext();
  const intl = useIntl();
  const heading = intl.formatMessage({id: 'VISITOR.REMOTE_REGISTRATION.TITLE'}).split(',');
  const history = useHistory();

  const intlDocOptions = state.formConfig.documentTypes.map(document => ({...document, label: document.label === 'NONE' ? intl.formatMessage({id: document.label}): document.label}))
  const onSubmit = async () => {
    actions.setDirty(true);
    try {
      formSchema.validateSync({...state.data, requiredFields: state.formConfig.requiredFields}, {abortEarly: false});
      setStatus('loading');
      await editVisitor.exec(state.data);
      changeView('success');
    } catch (e) {
      if (e.constructor === yup.ValidationError) return actions.setErrors(handleParseYupError(e));

      execToast('error', intl.formatMessage({id: e.message}), 3000);
    }
    setStatus('idle');
  };

  useEffect(() => {
    const handleFetch = async () => {
      try {
        const data = await getVisitor.exec();
        actions.setFormState({data: data.visitor, formConfig: data.formConfig});
      } catch (e) {
        switch (e.constructor) {
          case GetVisitorErrors.InvalidToken:
            return changeView('invalidToken');
          case GetVisitorErrors.TokenNotFound:
            return history.push('/');
        }
      }
      setStatus('idle');
    };

    handleFetch();
  }, []);

  return (
    <S.Wrapper className='show-in'>
      <header>
        <h1 className='title'>{intl.formatMessage({id: 'VISITOR.REMOTE_REGISTRATION'})}</h1>
        <span className='sub-title'>
          {heading[0]} <span style={{color: '#3F4254', fontWeight: 'bold'}}>{state.data.name.split(' ')[0]}</span>,{' '}
          {heading[1]}
        </span>
      </header>

      <form className='form-container'>
        <div style={{textAlign: 'center'}}>
          <AvatarWebcamComponent setImagem={(e: any) => actions.setData({avatar: e})} image={state.data.avatar} />
          {state.errors?.avatar && <span style={{color: 'red'}}>{intl.formatMessage({id: state.errors.avatar})}</span>}
        </div>

        <Input
          label={intl.formatMessage({id: 'REPORTS.PHOTOS.NAME'})}
          placeholder={intl.formatMessage({id: 'REPORTS.PHOTOS.NAME'})}
          value={state.data.name}
          disabled
        />

        <Input
          label='E-mail'
          placeholder='E-mail'
          type='email'
          value={state.data.email}
          onChange={(e) => actions.setData({email: e.target.value})}
          error={state.errors?.email && intl.formatMessage({id: state.errors.email})}
        />

        <div>
          <PhoneInput
            masks={{fr: '(...) ..-..-..', at: '(....) ...-....', br: '(..).....-....'}}
            preferredCountries={['br', 'us', 'ar', 'bo', 'gb', 'uy', 'py', 'cl', 'pe']}
            country={'br'}
            enableLongNumbers={true}
            inputStyle={{maxWidth: '100%', width: '100%', color: '#3F4254', fontSize: '1rem', borderColor: '#E4E6EF'}}
            buttonStyle={{backgroundColor: '#eeeeee'}}
            value={`${state.data.phoneDDI || '+55'}${state.data.phoneNumber}`}
            onChange={(value, data: any, env, formattedValue: string) =>
              actions.setData({
                phoneNumber: value
                  ? formattedValue.slice(data.dialCode.length + 1).replace(' ', '')
                  : value.slice(data.dialCode.length),
                phoneDDI: data.dialCode
              })
            }
          />

          {state.errors?.phoneNumber && (
            <span style={{color: 'red'}}>{intl.formatMessage({id: state.errors?.phoneNumber})}</span>
          )}
        </div>

        <Show show={!!state.formConfig.documentTypes.length}>
          <div style={{display: 'flex', flexWrap: 'wrap', gap: 24}}>
            <Input
              disabled={state.data.personDocuments?.documentTypeId === undefined}
              value={state?.data.personDocuments?.value || ''}
              onChange={(e) => actions.setPersonDocuments({value: e.target.value})}
              label={intl.formatMessage({id: 'VISITOR.DOC_LABEL'})}
              placeholder={intl.formatMessage({id: 'VISITOR.DOC_LABEL'})}
              style={{flex: 1}}
              error={
                state.errors?.personDocuments?.value && intl.formatMessage({id: state.errors?.personDocuments?.value})
              }
            />

            <div style={{flex: 1}}>
              <label>{intl.formatMessage({id: 'VISITOR.TYPEDOC_LABEL'})}</label>

              <AsyncPaginate
                id='select'
                value={
                  intlDocOptions.find(
                    (type) => type.value === state.data.personDocuments?.documentTypeId
                  ) || ''
                }
                onChange={(props: any) => actions.setPersonDocuments({documentTypeId: props.value})}
                options={intlDocOptions}
                placeholder={intl.formatMessage({id: 'VISITOR.TYPEDOC_LABEL'})}
                noOptionsMessage={() => intl.formatMessage({id: 'EXPIRED_DOCUMENTS.INPUTS.NO_OPTIONS'})}
                isSearchable={false}
              />
              {state.errors?.personDocuments?.documentTypeId && (
                <span style={{color: 'red'}}>
                  {intl.formatMessage({id: state.errors?.personDocuments?.documentTypeId})}
                </span>
              )}
            </div>
          </div>
        </Show>

        <S.DateContainer>
          <div>
            <h1>{intl.formatMessage({id: 'VISITOR.REMOTE_REGISTRATION.START_DATE'})}</h1>
            <S.Date>
              <MdOutlineDateRange />
              <span>{formatFullDateStyle(state.data.entryDate.toString()).split(' ')[0]}</span>
            </S.Date>
          </div>

          <div>
            <h1>{intl.formatMessage({id: 'VISITOR.REMOTE_REGISTRATION.END_DATE'})}</h1>
            <S.Date>
              <MdOutlineDateRange />
              <span>{formatFullDateStyle(state.data.exitDate.toString()).split(' ')[0]}</span>
            </S.Date>
          </div>

          <div>
            <h1>{intl.formatMessage({id: 'VISITOR.REMOTE_REGISTRATION.STAY_TIME'})}</h1>
            <S.Date>
              <MdOutlineUpdate />
              <span>
                {visitingTime} {intl.formatMessage({id: 'COMMON.DAY'})}
                {visitingTime > 1 && 's'}
              </span>
            </S.Date>
          </div>
        </S.DateContainer>
      </form>

      <footer className='footer'>
        <Button className='btn btn-primary' onClick={onSubmit}>
          {intl.formatMessage({id: 'PAGE.BLUEPRINT.BTN.SAVE'})}
        </Button>
      </footer>

      <Show show={status === 'loading'}>
        {createPortal(
          <S.LoadingScreen>
            <Spinner animation='border' role='status' />
          </S.LoadingScreen>,
          document.body
        )}
      </Show>
    </S.Wrapper>
  );
}
