import axios from 'axios';

import {idsecureAPI} from 'src/services/devicesCrud';
import {Visitor, VisitorResponse} from '../domain/visitor';
import {EditVisitorParamsDTO, VisitorResponseDTO} from './response';
import {VisitorMap} from '../mappers/visitor-map';

interface IVisitorRepo {
  getVisitor(token: string): Promise<VisitorResponse>;
  editVisitor(input: Visitor, token: string): Promise<void>;
  sendLinkWithEmail(id: number): Promise<void>;
}

export class VisitorRepo implements IVisitorRepo {
  private url = `${idsecureAPI?.replace('/v1', '')}/RemoteOnBoarding`;

  async getVisitor(token: string): Promise<VisitorResponse> {
    try {
      const {data} = await axios.get<VisitorResponseDTO>(this.url, {params: {token}, headers: {Authorization: token}});

      return VisitorMap.toDomain(data.data);
    } catch (e) {
      throw new Error('WENT_WRONG');
    }
  }

  async editVisitor(input: Visitor, token: string): Promise<void> {
    const hasPhoto = input.avatar.length > 0;

    const paramsDTO: EditVisitorParamsDTO = {
      token,
      email: input.email || null,
      phoneDDI: input.phoneDDI || null,
      phoneNumber: input.phoneNumber || null,
      personPhoto: hasPhoto
        ? {
            id: 0,
            status: 0,
            type: 1,
            ...(input.rawAvatar || {}),
            photo: input.avatar
          }
        : null,
      personDocuments:
        input.personDocuments?.documentTypeId && input.personDocuments.documentTypeId !== undefined
          ? [input.personDocuments]
          : null
    };

    await axios.put(this.url, paramsDTO, {headers: {Authorization: token}});
  }

  async removeLink(id: number) {
    await axios.delete(`${idsecureAPI}/visitors/${id}/removeURL`);
  }

  async sendLinkWithEmail(id: number) {
    await axios.get(`${idsecureAPI}/visitors/remoteonboarding/send-email/${id}`);
  }
}
