import styled from "styled-components";


interface CardContainerProps{
  isActived:boolean
}
 
export const CardContainer = styled.div<CardContainerProps>`
  width: 370px;
  /* height: auto; */
  height: 450px;
  background: ${({isActived}) => isActived ? "#D2F6F4" : "#F6D6D2"};
  padding: 0px 40px;
  border-radius:10px;
  position: relative;

  & > .buttonClose{
    position: absolute;
    right: -15px;
    top: -15px
  }


  header{
    width: 100%;
    text-align: center;
    border-bottom: 1px solid  ${({isActived}) => isActived ? "#B8E2DE" : "#E2B9B8"}; 

    h1{
      color: ${({isActived}) => isActived ? "#64BEB5" : "#BE6664"};
      font-size: 18px;
      margin: 0;
      padding: 12px 0px;
    }
  }

  .imageContainer{
    margin-top: 20px;
    display: flex;
    justify-content: center;

    div{
      overflow: hidden;
      width: 119px;
      height: 119px;
      border-radius: 50%;
      border: 2px solid  ${({isActived}) => isActived ? "#64BEB5" : "#BE6664"};
      padding: 2px;
      display: flex;
      justify-content: center;
      align-items: center;

      img{
        aspect-ratio: 1;
        object-fit: cover;
        width: 109px;
        border-radius: 50%;
      }
    }
  }

  .details{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid ${({isActived}) => isActived ? "#B8E2DE" : "#E2B9B8"}; 
    padding-bottom: 20px;

    & > strong{
      color:${({isActived}) => isActived ? "#64BEB5" : "#BE6664"};
      font-size: 18px;
      text-align: center;
    }

    span{
      margin-top: 3px;
      color: ${({isActived}) => isActived ? "#72B9B1" : "#B97472"};
      font-size: 15px;

      strong{
        color: ${({isActived}) => isActived ? "#64BEB5" : "#BE6664"}; 
      }
    }
  }

  footer{
    padding-top: 10px;
    
    p{
      width: 100%;
      color:${({isActived}) => isActived ? "#72B9B1" : "#B97472"};
      text-align: center;
      font-size: 15px;
    }

    .contianer-button{
      display: flex;
      align-items: center;
      justify-content: center;

      & > div{
        width: 72px;
        height: 72px;
        border-radius: 50%;
        padding: 4px;
        background-color: #fff;
        z-index: 2;

        img{
          width: 64px;
          height: 64px;
          border: 2px solid ${({isActived}) => isActived ? "#D2F6F4" : "#F6D6D2"} ;
          border-radius: 50%;
          object-fit: contain;
        }
      }

      button{
        width: 120px;
        padding: 12px 8px;
        border: none;
        background: ${({isActived}) => isActived ? "#64BEB5" : "#BE6664"};
        border-radius: 4px;
        margin-left: -17px;
        color:#fff
      }
    }
  }
`