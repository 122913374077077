import axios from 'axios';

class BULK_SERVICE {
  private API = process.env.REACT_APP_IDSECURE_API_URL;

  removePersons(ids: number[], erase = false) {
    return axios.delete(`${this.API}/persons/bulk?erase=${erase}`, {
      data: ids
    },)
  }

  activedPersons(ids: number[]) {
    return axios.put(`${this.API}/persons/bulk`,
      ids
      ,)
  }

  removeVisitors(ids: number[], erase = false) {
    return axios.delete(`${this.API}/visitors/bulk?erase=${erase}`, {
      data: ids
    },)
  }

  activedVisitors(ids: number[]) {
    return axios.put(`${this.API}/visitors/bulk`,
      ids
      ,)
  }

  removeGroups(ids: number[], erase = false) {
    return axios.delete(`${this.API}/groups/bulk?erase=${erase}`, {
      data: ids
    },)
  }

  activedGroups(ids: number[]) {
    return axios.put(`${this.API}/groups/bulk`,
      ids
      ,)
  }

  removeCompanies(ids: number[], erase = false) {
    return axios.delete(`${this.API}/companys/bulk?erase=${erase}`, {
      data: ids
    },)
  }

  activedCompanies(ids: number[]) {

    return axios.put(`${this.API}/companys/bulk`,
      ids
      ,)
  }

  removeTypeCredit(ids: number[], erase = false) {
    return axios.delete(`${this.API}/credit/type/bulk?erase=${erase}`, {
      data: ids
    },)
  }

  activedTypeCredit(ids: number[]) {
    return axios.put(`${this.API}/credit/type/bulk`,
      ids
      ,)
  }

  removeCreditManagement(ids: number[], erase = false) {
    return axios.delete(`${this.API}/credit/typeperson/bulk?erase=${erase}`, {
      data: ids
    },)
  }

  activedCreditManagement(ids: number[]) {
    return axios.put(`${this.API}/credit/typeperson/bulk`,
      ids
      ,)
  }

  removeProvisionalCards(ids: number[], erase = false) {
    return axios.delete(`${this.API}/groups/bulk?erase=${erase}`, {
      data: ids
    },)
  }

  activedProvisionalCards(ids: number[]) {
    return axios.put(`${this.API}/groups/bulk`,
      ids
      ,)
  }

  removeVehicles(ids: number[], erase = false) {
    return axios.delete(`${this.API}/vehicles/bulk?erase=${erase}`, {
      data: ids
    },)
  }

  activedVehicles(ids: number[]) {
    return axios.put(`${this.API}/vehicles/bulk`,
      ids
      ,)
  }

  removeParking(ids: number[], erase = false) {
    return axios.delete(`${this.API}/parkingLot/bulk?erase=${erase}`, {
      data: ids
    },)
  }

  activedParking(ids: number[]) {
    return axios.put(`${this.API}/parkingLot/bulk`,
      ids
      ,)
  }
}

export const bulkService = new BULK_SERVICE()