import styled from 'styled-components';

export const Main = styled.div`
  padding: 10px;
  border:1px solid #ccc;
  margin-bottom: 10px;
`

export const Item = styled.div`
  
  padding: 5px;

  .header-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .list-inputs{
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;


    div{
      min-width: 33.33%;
      margin-bottom: 5px;
    }

    span{
      display: block;
    }
  }
`