import { isErrorPermission } from 'src/utils/errorPermissionsApi';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import * as dashboardService from '../../../../services/dashboard.service';
import { AccessLastDay, callTypes, dashboardSlices } from './dashboardSlices';

const { actions } = dashboardSlices;
let index = 0;
export const fetchDashIndicators = () => (dispatch: Function) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return dashboardService
    .getIndicators()
    .then((response) => {
      dispatch(actions.dashIndicatorsFetched(response.data.data));
    })
    .catch((error) => {
      if (error.response?.status) {
        isErrorPermission(error.response?.status);
      }
      error.clientMessage = "Can't find indicators";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchAccessLastDayFetched = () => (dispatch: Function) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return dashboardService
    .getAccessLastDay()
    .then((response) => {
      const array = Object.entries(response.data.data);
      const res: AccessLastDay[] = [];

      array.forEach((item: any) => {
        res.push({ hour: item[0], qtAccess: item[1] });
      });

      const currentTimeIndex = res.findIndex((el) => el.hour == new Date().getHours());
      const currentTimeSliced = res.slice(0, res.length < currentTimeIndex ? currentTimeIndex : currentTimeIndex + 1);
      const restSliced = res.slice(currentTimeIndex, res.length);
      restSliced.shift();

      const merged = [...restSliced, ...currentTimeSliced];

      dispatch(actions.dashAccessLastDayFetched(merged));
    })
    .catch((error) => {
      // console.dir(error.response.status);

      // alert(error.response.status);

      if (error.response?.status) {
        isErrorPermission(error.response?.status);
      }
      // alert('error);
      dispatch(actions.dashAccessLastDayFetched(null))
      error.clientMessage = "Can't find";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchAccessLastWeekFetched = () => (dispatch: Function) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return dashboardService
    .getAccessLastWeek()
    .then((response) => {
      dispatch(actions.dashAccessLastWeekFetched(response.data.data));
    })
    .catch((error) => {
      if (error.response?.status) {
        isErrorPermission(error.response?.status);
      }
      error.clientMessage = "Can't find";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchRealTimeAccessFetched = (ids?: any[]) => (dispatch: Function) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return dashboardService
    .getRealTimeAccess(true)
    .then((response) => {
      // if (!gambiarraFeia.length || JSON.stringify(response?.data?.data?.data) != JSON.stringify(gambiarraFeia)) {
      let gambiarraFeia = response?.data?.data?.data;
      // // if (index % 5 == 0) {
      //   dispatch(actions.dashRealTimeAccessFetched(array_move(gambiarraFeia, 0, 9).map((item: any, i) => ({...item, id: i != 0 ? item.id : i}))));
      // } else

      if (response?.data?.data?.data.length <= 0) {
        dispatch(actions.dashRealTimeAccessFetched([]));
      } else if (gambiarraFeia.filter((item: any) => !ids?.includes(item.id)).length) {
        dispatch(actions.dashRealTimeAccessFetched(response?.data?.data?.data || []));
      }
      // index++;
    })
    .catch((error) => {
      if (error.response?.status) {
        isErrorPermission(error.response?.status);
      }
      error.clientMessage = "Can't find";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const setLoadingRealTimeAccess = actions.setLoadingRealTimeAccess