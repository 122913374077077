import React, { useEffect, useState } from 'react';
import { useTableHooks } from '../hooks/table';
//@ts-ignore
import { TableSelectionGroupsAndCompanies } from './Components/tableGroupsAndCompanies';
//@ts-ignore
import { TableSelectionPersons } from './Components/tablePerson';
//@ts-ignore
import { TableSelectionVisitant } from './Components/tableVisitant';
//@ts-ignore
import { TableSelectionTypes } from './Components/tableTypes';
//@ts-ignore
import { TableSelectionWhere } from './Components/tableWhere';
import { ToggleSwitch } from 'src/_metronic/_partials/controls/forms/ToggleSwitch';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { CREATE_NOTIFICATION_RULE, EDIT_NOTIFICATION_RULE, getNotificationById } from 'src/services/notificationsCrud';
import { useLocation, useHistory } from 'react-router-dom';
import { isErrorApi } from 'src/utils/errorType';
import { Input } from '../../../../_metronic/_partials/controls';
// @ts-ignore
import Select, { CSSObjectWithLabel } from 'react-select';
// @ts-ignore
import { AsyncPaginate } from 'react-select-async-paginate';
import { useIntl } from 'react-intl';
import { daysOfWeek } from '../utils/utils';
import { useEmailsInput } from '../hooks/useEmailsInput';
import { CardsContainer, ConfirmationContainer } from './styles';
import { Field, Formik } from 'formik';

type Person = {
  id: number;
  name: string;
};

interface PersonLoadData {
  person: {
    personType: number;
    id: number;
    name: string;
  };
}

interface AreaLoadData {
  area: {
    id: number;
    name: string;
  };
}
interface CompaniesLoadData {
  group: {
    id: number;
    description: string;
  };
}

interface PersonTypeData {
  personType: number;
}

let VisitantHelper: Person[] = [];
let PersonHelper: Person[] = [];
let CompaniesHelper: Person[] = [];
let TypesHelper: Person[] = [];
let WhereHelper: Person[] = [];

export function NotificationContent() {
  const { INTL } = useTableHooks();
  const intl = useIntl();
  const { pathname } = useLocation();
  const history = useHistory();
  const isEditing = !!pathname.includes('/notifications/edit/');

  const [id, setId] = useState(0);

  const [step, setStep] = useState(0);
  const [stepProgress, setStepProgress] = useState([0]);
  const [who, setWho] = useState<string>('1');

  const [description, setDescription] = useState('');
  const [visitantListSelecteds, setVisitantListSelecteds] = useState<Person[]>([]);
  const [personListSelecteds, setPersonListSelecteds] = useState<Person[]>([]);
  const [CompaniesListSelecteds, SetCompaniesListSelecteds] = useState<Person[]>([]);
  const [typesListSelecteds, setTypesListSelecteds] = useState<Person[]>([]);
  const [whereSelecteds, setWhereSelecteds] = useState<Person[]>([]);
  const [notificationToAlert, setNotificationToAlert] = useState(false);
  const [notificationToDined, setNotificationToDined] = useState(false);
  const [notificationToAllowed, setNotificationToAllowed] = useState(false);
  const [notificationToUndefined, setNotificationToUndefined] = useState(false);
  const [sendMonday, setSendMonday] = useState(false);
  const [sendTuesday, setSendTuesday] = useState(false);
  const [sendWednesday, setSendWednesday] = useState(false);
  const [sendThursday, setSendThursday] = useState(false);
  const [sendFriday, setSendFriday] = useState(false);
  const [sendSaturday, setSendSaturday] = useState(false);
  const [sendSunday, setSendSunday] = useState(false);
  const [ALLPERSONS, SETALLPERSONS] = useState(false);
  const [ALLAREAS, SETALLAREAS] = useState(false);
  const [time, setTime] = useState('19:00');
  const [sendNotification, setSendNotification] = useState(1);
  const [daysToSendMail, setdaysToSendMail] = useState<any[]>([]);

  // const [emails, setEmails] = useState('');
  const {
    emails,
    addNewEmailEntry,
    changeEmailEntry,
    setEmailValue,
    setEmailInitialValue,
    getEmailsPayload
  } = useEmailsInput();
  const [emailTitle, setEmailTitle] = useState('');
  const [subject, setSubject] = useState(INTL('NOTIFICATION'));
  const [message, setMessage] = useState('-');
  const [signature, setSignature] = useState('-');

  const allTexts = {
    description: INTL('NOTIFICATION.IDENTIFICATION'),
    who: INTL('NOTIFICATION.WHO'),
    when: INTL('NOTIFICATION.WHEN'),
    how: INTL('NOTIFICATION.HOW'),
    resume: INTL('ACRULE.CONFIRM_TEXT'),
    step2Title: INTL('ACRULE.PEOPLE_TITLE'),
    selectPersonText: INTL('ASIDE.MENU.ITEM.PERSON'),
    selectGroupText: INTL('PERSON.BUILDER.ITEM.COMPANIES'),
    selectVisitorText: INTL('ASIDE.MENU.ITEM.VISITORS'),
    backTextselectTypesText: INTL('ACRULE.SELECT_TYPES')
  };

  const selectStyle = {
    control: (styles: CSSObjectWithLabel) => ({
      ...styles,
      width: '100%',
      marginTop: '15px',
      border: '1px solid #E4E6EF',
      '&:hover': {
        cursor: 'pointer'
      }
    })
  };

  function handleRemoveSelectVisitant(id: number) {
    const persons = VisitantHelper.filter((person) => person.id !== id);
    setVisitantListSelecteds(persons);
    VisitantHelper = persons;
  }

  function handleAddSelectVisitant(person: Person) {
    setVisitantListSelecteds((visitors) => {
      const update = [...visitors, person];
      VisitantHelper.push(person);
      return update;
    });
  }

  function handleRemoveSelectPerson(id: number) {
    const persons = PersonHelper.filter((person) => person.id !== id);
    setPersonListSelecteds(persons);
    PersonHelper = persons;
  }

  function handleAddSelectPerson(person: Person) {
    setPersonListSelecteds((visitors) => {
      const update = [...visitors, person];
      PersonHelper.push(person);
      return update;
    });
  }

  function handleRemoveSelectCompanie(id: number) {
    const persons = CompaniesHelper.filter((person) => person.id !== id);
    SetCompaniesListSelecteds(persons);
    CompaniesHelper = persons;
  }

  function handleAddSelectCompanie(person: Person) {
    SetCompaniesListSelecteds((visitors) => {
      const update = [...visitors, person];
      CompaniesHelper.push(person);
      return update;
    });
  }

  function handleRemoveSelectTypes(id: number) {
    const persons = TypesHelper.filter((person) => person.id !== id);
    setTypesListSelecteds(persons);
    TypesHelper = persons;
  }

  function handleAddSelectTypes(person: Person) {
    setTypesListSelecteds((visitors) => {
      const update = [...visitors, person];
      TypesHelper.push(person);
      return update;
    });
  }

  function handleRemoveSelectWhere(id: number) {
    const persons = WhereHelper.filter((person) => person.id !== id);
    setWhereSelecteds(persons);
    WhereHelper = persons;
  }

  function handleAddSelectWhere(person: Person) {
    setWhereSelecteds((visitors) => {
      const update = [...visitors, person];
      WhereHelper.push(person);
      return update;
    });
  }

  function handleNextStep() {
    if (step === 0) {
      if (!description) {
        execToast('error', INTL('NOTIFICATION_TOAST_ERROR_DESC_EMPTY'), 3000);
        return;
      }
    }
    if (step === 0) {
      if (description.length < 4) {
        execToast('error', INTL('THE_VEHICLE_RULE_NAME_MUST_CONTAIN_AT_LEAST4_CHARACTERS'), 3000);
        return;
      }
    }

    if (step === 1) {
      const visitantListSelectedsQTD = visitantListSelecteds.length;
      const personListSelectedsQTD = personListSelecteds.length;
      const CompaniesListSelectedsQTD = CompaniesListSelecteds.length;
      const typesListSelectedsQTD = typesListSelecteds.length;

      if (
        !visitantListSelectedsQTD &&
        !personListSelectedsQTD &&
        !CompaniesListSelectedsQTD &&
        !typesListSelectedsQTD &&
        !ALLPERSONS
      ) {
        execToast('error', INTL('NOTIFICATION_TOAST_ERROR_PEOPLE_OR_VISITS_EMPTY'), 3000);
        return;
      }
    }

    if (step === 2) {
      if (!notificationToAlert && !notificationToAllowed && !notificationToUndefined && !notificationToDined) {
        execToast('error', INTL('NOTIFICATION_TOAST_ERROR_MONITORY_TYPE'), 3000);
        return;
      }
    }

    if (step === 3) {
      const whereSelectedsQTD = whereSelecteds.length;

      if (!whereSelectedsQTD && !ALLAREAS) {
        execToast('error', INTL('NOTIFICATION_TOAST_ERROR_AREA_EMPTY'), 3000);
        return;
      }
    }

    if (step === 4) {
      if (getEmailsPayload().length === 0) {
        execToast('error', INTL('NOTIFICATION_TOAST_ERROR_EMAIL_EMPTY'), 3000);
        return;
      }

      if (!subject) {
        execToast('error', INTL('NOTIFICATION_TOAST_ERROR_SUBJECT'), 3000);
        return;
      }

      if (!emailTitle.length) {
        execToast('error', INTL('NOTIFICATION_TOAST_ERROR_EMAIL_BODY_TITLE_EMPTY'), 3000);
        return false;
      }
    }

    click();
  }

  function handlePrevStep() {
    if (!step) {
      history.push('/notifications');
      return;
    }

    const isProgress = wizardNavigation();
    if (!isProgress) return;

    setStep((step) => step - 1);
  }

  async function handleSave() {
    try {
      if (isEditing) {
        handleEdit();
        return;
      }
      const areasIds = whereSelecteds.map((where) => where.id);
      const visitantIds = visitantListSelecteds.map((visitant) => visitant.id);
      const groupsIds = CompaniesListSelecteds.map((companies) => companies.id);
      const personsIds = personListSelecteds.map((person) => person.id);
      const typesIdToNameType = typesListSelecteds.map((type) => {
        return type.id === 1 ? 'Person' : 'Visitant';
      });

      const allPersons = [...visitantIds, ...personsIds];

      const allidsDasyActived = daysToSendMail.map((item) => item.value);
      await CREATE_NOTIFICATION_RULE({
        areasIds,
        groupsIds,
        personsIds: allPersons,
        description,
        personTypes: typesIdToNameType,
        notifyAlerts: notificationToAlert,
        notifyDeniedAccess: notificationToDined,
        notifyGarantAccess: notificationToAllowed,
        // notifyNotIdentified: notificationToUndefined,
        emailTitle: emailTitle,
        emailBody: message,
        emailSendTime: time,
        emailSendType: String(sendNotification),
        emailSignature: signature,
        emailRecipients: getEmailsPayload(),
        emailSubject: subject,
        sendMonday: allidsDasyActived.includes(1),
        sendTuesday: allidsDasyActived.includes(2),
        sendWednesday: allidsDasyActived.includes(3),
        sendThursday: allidsDasyActived.includes(4),
        sendFriday: allidsDasyActived.includes(5),
        sendSaturday: allidsDasyActived.includes(6),
        sendSunday: allidsDasyActived.includes(7),
        isAllArea: ALLAREAS,
        isAllPerson: ALLPERSONS
      });

      execToast('success', INTL('NOTIFICATION.SUCCESS.CREATE'), 3000);

      history.push('/notifications');
    } catch (err) {
      const errorByName = isErrorApi(err, '410');

      if (errorByName) {
        setStep(0);
        execToast('error', INTL('NOTIFICATION_TOAST_ERROR_DESCRIPION'), 3000);
        return;
      }
      // alert('Deu erro');
    }
  }

  async function handleEdit() {
    try {
      const areasIds = whereSelecteds.map((where) => where.id);
      const visitantIds = visitantListSelecteds.map((visitant) => visitant.id);
      const groupsIds = CompaniesListSelecteds.map((companies) => companies.id);
      const personsIds = personListSelecteds.map((person) => person.id);
      const typesIdToNameType = typesListSelecteds.map((type) => {
        return type.id === 1 ? 'Person' : 'Visitant';
      });

      const allPersons = [...visitantIds, ...personsIds];
      const allidsDasyActived = daysToSendMail.map((item) => item.value);
      await EDIT_NOTIFICATION_RULE({
        id,
        areasIds,
        groupsIds,
        personsIds: allPersons,
        description,
        personTypes: typesIdToNameType,
        notifyAlerts: notificationToAlert,
        notifyDeniedAccess: notificationToDined,
        notifyGarantAccess: notificationToAllowed,
        // notifyNotIdentified: notificationToAllowed,
        emailTitle: emailTitle,
        emailBody: message,
        emailSendTime: time,
        emailSendType: String(sendNotification),
        emailSignature: signature,
        emailRecipients: getEmailsPayload(),
        emailSubject: subject,
        sendMonday: allidsDasyActived.includes(1),
        sendTuesday: allidsDasyActived.includes(2),
        sendWednesday: allidsDasyActived.includes(3),
        sendThursday: allidsDasyActived.includes(4),
        sendFriday: allidsDasyActived.includes(5),
        sendSaturday: allidsDasyActived.includes(6),
        sendSunday: allidsDasyActived.includes(7),
        isAllArea: ALLAREAS,
        isAllPerson: ALLPERSONS
      });

      execToast('success', INTL('NOTIFICATION.SUCCESS.EDIT'), 3000);

      history.push('/notifications');
    } catch (err) {
      // alert('Deu erro');
    }
  }

  async function loadNotification(id: number) {
    try {
      const {
        data: { data }
      } = await getNotificationById(id);

      SETALLPERSONS(data.isAllPerson);
      SETALLAREAS(data.isAllArea);

      const personsData: Person[] = [];
      const visitorsData: Person[] = [];
      const personTypeData: Person[] = [];
      const companiesTypeData: Person[] = [];

      const areaLoad: Person[] = [];

      data.notificationRulePerson.forEach(({ person }: PersonLoadData) => {
        const personData = {
          id: person.id,
          name: person.name
        };

        if (person.personType === 1) {
          personsData.push(personData);
        } else {
          visitorsData.push(personData);
        }
      });

      data.notificationRuleGroup.forEach(({ group }: CompaniesLoadData) => {
        const data = {
          id: group.id,
          name: group.description
        };
        companiesTypeData.push(data);
      });

      data.notificationRuleArea.forEach(({ area }: AreaLoadData) => {
        const data = {
          id: area.id,
          name: area.name === 'AREA.DEFAULT' ? intl.formatMessage({ id: 'AREA.DEFAULT' }) : area.name
        };
        areaLoad.push(data);
      });

      data.notificationRulePersonType.forEach(({ personType }: PersonTypeData) => {
        const data = {
          id: personType,
          name: personType === 1 ? 'Pessoa' : 'Visitante'
        };
        personTypeData.push(data);
      });

      setDescription(data.description);
      setPersonListSelecteds(personsData);
      setVisitantListSelecteds(visitorsData);
      setWhereSelecteds(areaLoad);
      SetCompaniesListSelecteds(companiesTypeData);
      setTypesListSelecteds(personTypeData);

      setNotificationToAlert(data.notifyAlerts);
      setNotificationToDined(data.notifyDeniedAccess);
      setNotificationToUndefined(data.notifyNotIdentified);
      setNotificationToAllowed(data.notifyGarantAccess);
      setSendNotification(data.emailSendType);
      if (data.emailSendType === 3) {
        // setSendMonday(data.sendMonday);
        // setSendTuesday(data.sendTuesday);
        // setSendWednesday(data.sendWednesday);
        // setSendThursday(data.sendThursday);
        // setSendFriday(data.sendFriday);
        // setSendSaturday(data.sendSaturday);
        // setSendSunday(data.sendSunday);

        const pathDays: any = [];

        if (data.sendMonday) {
          pathDays.push({
            value: 1,
            label: intl.formatMessage({ id: 'MONDAY_LABEL' })
          });
        }

        if (data.sendTuesday) {
          pathDays.push({
            value: 2,
            label: intl.formatMessage({ id: 'TUESDAY_LABEL' })
          });
        }

        if (data.sendWednesday) {
          pathDays.push({
            value: 3,
            label: intl.formatMessage({ id: 'WEDNESDAY_LABEL' })
          });
        }

        if (data.sendThursday) {
          pathDays.push({
            value: 4,
            label: intl.formatMessage({ id: 'THURSDAY_LABEL' })
          });
        }

        if (data.sendFriday) {
          pathDays.push({
            value: 5,
            label: intl.formatMessage({ id: 'FRIDAY_LABEL' })
          });
        }

        if (data.sendSaturday) {
          pathDays.push({
            value: 6,
            label: intl.formatMessage({ id: 'SATURDAY_LABEL' })
          });
        }

        if (data.sendSunday) {
          pathDays.push({
            value: 7,
            label: intl.formatMessage({ id: 'SUNDAY_LABEL' })
          });
        }
        setdaysToSendMail(pathDays);
      }

      setTime(data.emailSendTime === '' ? '19:00' : data.emailSendTime);

      setMessage(data.emailBody);
      setSignature(data.emailSignature);
      setSubject(data.emailSubject);
      setEmailInitialValue(data.emailRecipients);
      setEmailTitle(data?.emailTitle || '');

      setStepProgress([0, 1, 2, 3, 4, 5]);

      PersonHelper = personsData;
      WhereHelper = areaLoad;
      VisitantHelper = visitorsData;
      TypesHelper = personTypeData;
      CompaniesHelper = companiesTypeData;
    } catch { }
  }

  const getTypeCredits = async () => {
    return {
      options: [
        {
          value: 1,
          label: intl.formatMessage({ id: 'MONDAY_LABEL' })
        },
        {
          value: 2,
          label: intl.formatMessage({ id: 'TUESDAY_LABEL' })
        },
        {
          value: 3,
          label: intl.formatMessage({ id: 'WEDNESDAY_LABEL' })
        },
        {
          value: 4,
          label: intl.formatMessage({ id: 'THURSDAY_LABEL' })
        },
        {
          value: 5,
          label: intl.formatMessage({ id: 'FRIDAY_LABEL' })
        },
        {
          value: 6,
          label: intl.formatMessage({ id: 'SATURDAY_LABEL' })
        },
        {
          value: 7,
          label: intl.formatMessage({ id: 'SUNDAY_LABEL' })
        }
      ],
      hasMore: false
    };
  };

  const click = () => {
    if (step === 0) {
      setStep(1);
      if (!stepProgress.includes(1)) {
        setStepProgress((e) => [...e, 1]);
      }
    } else if (step === 1) {
      setStep(2);
      if (!stepProgress.includes(2)) {
        setStepProgress((e) => [...e, 2]);
      }
    } else if (step === 2) {
      setStep(3);
      if (!stepProgress.includes(3)) {
        setStepProgress((e) => [...e, 3]);
      }
    } else if (step === 3) {
      setStep(4);
      if (!stepProgress.includes(4)) {
        setStepProgress((e) => [...e, 4]);
      }
    } else if (step === 4) {
      setStep(5);
      if (!stepProgress.includes(5)) {
        setStepProgress((e) => [...e, 5]);
      }
    }
  };

  function wizardNavigation() {
    if (step === 0) {
      if (!description) {
        execToast('error', INTL('NOTIFICATION_TOAST_ERROR_DESC_EMPTY'), 3000);
        return false;
      }
    }

    if (step === 1) {
      const visitantListSelectedsQTD = visitantListSelecteds.length;
      const personListSelectedsQTD = personListSelecteds.length;
      const CompaniesListSelectedsQTD = CompaniesListSelecteds.length;
      const typesListSelectedsQTD = typesListSelecteds.length;

      if (
        !visitantListSelectedsQTD &&
        !personListSelectedsQTD &&
        !CompaniesListSelectedsQTD &&
        !typesListSelectedsQTD &&
        !ALLPERSONS
      ) {
        execToast('error', INTL('NOTIFICATION_TOAST_ERROR_PEOPLE_OR_VISITS_EMPTY'), 3000);
        return false;
      }
    }

    if (step === 2) {
      if (!notificationToAlert && !notificationToAllowed && !notificationToUndefined && !notificationToDined) {
        execToast('error', INTL('NOTIFICATION_TOAST_ERROR_MONITORY_TYPE'), 3000);
        return false;
      }
    }

    if (step === 3) {
      const whereSelectedsQTD = whereSelecteds.length;

      if (!whereSelectedsQTD && !ALLAREAS) {
        execToast('error', INTL('NOTIFICATION_TOAST_ERROR_AREA_EMPTY'), 3000);
        return false;
      }
    }

    if (step === 4) {
      if (!emails) {
        execToast('error', INTL('NOTIFICATION_TOAST_ERROR_EMAIL_EMPTY'), 3000);
        return false;
      }

      if (!emailTitle.length) {
        execToast('error', INTL('NOTIFICATION_TOAST_ERROR_EMAIL_BODY_TITLE_EMPTY'), 3000);
        return false;
      }
    }

    return true;
  }

  useEffect(() => {
    VisitantHelper = [];
    PersonHelper = [];
    CompaniesHelper = [];
    TypesHelper = [];
    WhereHelper = [];

    if (pathname.includes('/notifications/edit/')) {
      const id = pathname.replace('/notifications/edit/', '');
      loadNotification(+id);
      setId(+id);
    }

    return () => {
      VisitantHelper = [];
      PersonHelper = [];
      CompaniesHelper = [];
      TypesHelper = [];
      WhereHelper = [];
    };
  }, []);

  return (
    <>
      <ul className='step-wizard-list'>
        <li
          className={`step-wizard-item ${step === 0 && 'current-item'}`}
          id='item-name'
          onClick={() => {
            const isProgress = wizardNavigation();
            if (!isProgress) return;
            if (stepProgress.includes(0)) {
              setStep(0);
            }
          }}
          style={stepProgress.includes(0) ? { cursor: 'pointer' } : {}}>
          <span className={`progress-count ${stepProgress.includes(1) && 'ball-sync'}`}>1</span>
          <span className='progress-label'>{intl.formatMessage({ id: 'NOTIFICATION.IDENTIFICATION_NAME' })}</span>
        </li>
        <li
          className={`step-wizard-item ${step === 1 && 'current-item'}`}
          id='item-who'
          onClick={() => {
            const isProgress = wizardNavigation();
            if (!isProgress) return;
            if (stepProgress.includes(1)) {
              setStep(1);
            }
          }}
          style={stepProgress.includes(1) ? { cursor: 'pointer' } : {}}>
          <span className={`progress-count ${stepProgress.includes(1) && 'ball-sync'}`}>2</span>
          <span className='progress-label'>{allTexts.who}</span>
        </li>
        <li
          className={`step-wizard-item ${step === 2 && 'current-item'}`}
          id='item-when'
          onClick={() => {
            const isProgress = wizardNavigation();
            if (!isProgress) return;
            if (stepProgress.includes(2)) {
              setStep(2);
            }
          }}
          style={stepProgress.includes(2) ? { cursor: 'pointer' } : {}}>
          <span className={`progress-count ${stepProgress.includes(2) && 'ball-sync'}`}>3</span>
          <span className='progress-label'>{allTexts.when}</span>
        </li>
        <li
          className={`step-wizard-item ${step === 3 && 'current-item'}`}
          id='item-when'
          onClick={() => {
            const isProgress = wizardNavigation();
            if (!isProgress) return;
            if (stepProgress.includes(3)) {
              setStep(3);
            }
          }}
          style={stepProgress.includes(3) ? { cursor: 'pointer' } : {}}>
          <span className={`progress-count ${stepProgress.includes(3) && 'ball-sync'}`}>4</span>
          <span className='progress-label'>{INTL('NOTIFICATION.WHERE')}</span>
        </li>
        <li
          className={`step-wizard-item ${step === 4 && 'current-item'}`}
          id='item-where'
          onClick={() => {
            const isProgress = wizardNavigation();
            if (!isProgress) return;
            if (stepProgress.includes(4)) {
              setStep(4);
            }
          }}
          style={stepProgress.includes(3) ? { cursor: 'pointer' } : {}}>
          <span className={`progress-count ${stepProgress.includes(4) && 'ball-sync'}`}>5</span>
          <span className='progress-label'>{allTexts.how}</span>
        </li>
        <li
          className={`step-wizard-item ${step === 5 && 'current-item'}`}
          id='item-confirm'
          onClick={() => {
            const isProgress = wizardNavigation();
            if (!isProgress) return;
            if (stepProgress.includes(5)) {
              setStep(5);
            }
          }}
          style={stepProgress.includes(5) ? { cursor: 'pointer' } : {}}>
          <span className={`progress-count ${stepProgress.includes(5) && 'ball-sync'}`}>6</span>
          <span className='progress-label'>{allTexts.resume}</span>
        </li>
      </ul>

      {!!step && <hr className='MuiDivider-root mt-10 MuiDivider-middle' />}

      {step === 0 && (
        <div style={{ display: 'flex', alignItems: 'center', 'justifyContent': 'center' }}>
          <div style={{ width: '400px' }}>
            <label style={{ fontSize: '1.25rem' }} className='font-weight-bolder' htmlFor='name-notification'>
              {INTL('NOTIFICATION.IDENTIFICATION')}
            </label>

            {/* <input
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              autoComplete='off'
              id='name-notification'
              type='text'
              className='form-control'
            /> */}
            <Formik
              initialValues={{
                notification: ''
              }}
              onSubmit={(v, { setErrors, setTouched, setFieldTouched }) => {

              }}>
              {({ }) => (
                <Field
                  data-id='input-description'
                  value={description}
                  // name='description'
                  maxLength={50}
                  component={Input}
                  onChange={(e: any) => {
                    setDescription(e.target.value);
                  }}
                  onBlur={(e: InputEvent) => {

                    //@ts-ignore
                    if (e.target.value.length <= 3) {
                      document.querySelector("[data-id='input-description']")?.classList.remove('is-valid');
                      document.querySelector("[data-id='input-description']")?.classList.add('is-invalid');
                    } else {
                      document.querySelector("[data-id='input-description']")?.classList.remove('is-invalid');
                      document.querySelector("[data-id='input-description']")?.classList.add('is-valid');
                    }
                  }}
                  onMouseLeave={(e: InputEvent) => {

                    //@ts-ignore
                    if (e.target.value.length <= 3) {
                      document.querySelector("[data-id='input-description']")?.classList.remove('is-valid');
                      document.querySelector("[data-id='input-description']")?.classList.add('is-invalid');
                    } else {
                      document.querySelector("[data-id='input-description']")?.classList.remove('is-invalid');
                      document.querySelector("[data-id='input-description']")?.classList.add('is-valid');
                    }
                  }}
                />
              )}
            </Formik>
          </div>
        </div>
      )}

      {step === 1 && (
        <div
          style={{
            padding: '0px 16px'
          }}>
          <div
            className='col-lg-12'
            style={{ paddingLeft: '0px', marginLeft: '2px', fontWeight: 500, fontSize: '1.25rem' }}>
            <span>{allTexts.step2Title}</span>
          </div>

          <div
            className='col-lg-12'
            style={{ marginBottom: '15px', display: 'flex', paddingLeft: '0px', marginLeft: '-12px' }}>
            <div
              className='col-lg-6 pr-0 ANIMATION'
              style={{ opacity: ALLPERSONS ? 0.3 : 1, pointerEvents: ALLPERSONS ? 'none' : 'all' }}>
              <select value={who} className='form-control' onChange={(e) => setWho(e.target.value)}>
                <option value='1'>{allTexts.selectPersonText}</option>
                <option value='2'>{allTexts.selectGroupText}</option>
                <option value='3'>{allTexts.selectVisitorText}</option>
                <option value='4'>{allTexts.backTextselectTypesText}</option>
              </select>
            </div>

            <div className='col-lg-6 ml-5 ANIMATION' style={{ display: who === '1' ? 'block' : 'none' }}>
              {INTL('NOTIFICATION_INPUT_SELECT_ALL_PEOPLES')}{' '}
              <ToggleSwitch
                style={{ marginLeft: '7px' }}
                checked={ALLPERSONS}
                onChange={(e) => SETALLPERSONS(e.target.checked)}
              />
            </div>
          </div>

          <div className='col-lg-12 modal-vinc p-0' id='tablePerson'>
            {who === '1' && (
              <TableSelectionPersons
                data={{
                  removeSelect: handleRemoveSelectPerson,
                  addSelect: handleAddSelectPerson,
                  itensSelected: personListSelecteds,
                  block: ALLPERSONS
                }}
              />
            )}
            {who === '2' && (
              <TableSelectionGroupsAndCompanies
                data={{
                  removeSelect: handleRemoveSelectCompanie,
                  addSelect: handleAddSelectCompanie,
                  itensSelected: CompaniesListSelecteds
                }}
              />
            )}
            {who === '3' && (
              <TableSelectionVisitant
                data={{
                  removeSelect: handleRemoveSelectVisitant,
                  addSelect: handleAddSelectVisitant,
                  itensSelected: visitantListSelecteds
                }}
              />
            )}
            {who === '4' && (
              <TableSelectionTypes
                data={{
                  removeSelect: handleRemoveSelectTypes,
                  addSelect: handleAddSelectTypes,
                  itensSelected: typesListSelecteds
                }}
              />
            )}
          </div>
        </div>
      )}

      {step === 2 && (
        <div
          style={{
            padding: '0px 16px'
          }}>
          <div
            style={{
              marginLeft: '11px'
            }}>
            <span
              style={{
                fontWeight: 600,
                display: 'block',
                fontSize: '1.25rem'
              }}>
              {INTL('NOTIFICATION.EMAIL_TYPES_SEND')}
            </span>
            <div style={{ display: 'flex', gap: '20px', marginTop: '12px' }}>
              <div
                style={{
                  display: 'none',
                  alignItems: 'flex-start'
                }}>
                <ToggleSwitch
                  checked={notificationToAlert}
                  onChange={(e) => {
                    setNotificationToAlert(e.target.checked);
                  }}
                />{' '}
                {INTL('NOTIFICATION.EMAIL_TYPE_ALERT')}
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start'
                }}>
                <ToggleSwitch
                  checked={notificationToDined}
                  onChange={(e) => {
                    setNotificationToDined(e.target.checked);
                  }}
                />{' '}
                {INTL('NOTIFICATION.EMAIL_TYPE_DECLINE')}
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start'
                }}>
                <ToggleSwitch
                  checked={notificationToAllowed}
                  onChange={(e) => {
                    setNotificationToAllowed(e.target.checked);
                  }}
                />{' '}
                {INTL('NOTIFICATION.EMAIL_TYPE_ALLOWED')}
              </div>

              <div
                style={{
                  // display: 'flex',
                  alignItems: 'flex-start',
                  display: 'none'
                }}>
                <ToggleSwitch
                  checked={notificationToUndefined}
                  onChange={(e) => {
                    setNotificationToUndefined(e.target.checked);
                  }}
                />{' '}
                {INTL('NOTIFICATION.EMAIL_TYPE_NON_ID')}
              </div>
            </div>
          </div>

          <div className='col-lg-12' style={{ marginTop: '15px' }}>
            <span
              style={{
                fontWeight: 600,
                display: 'block'
              }}>
              {INTL('NOTIFICATION.EMAIL_WHEN_SEND')}
            </span>
          </div>

          <div className='row'>
            <div className='col-lg-5' style={{ marginTop: '7px', marginLeft: '12px' }}>
              <select
                value={sendNotification}
                className='form-control'
                onChange={(e) => setSendNotification(+e.target.value)}>
                <option value='1'>{INTL('NOTIFICATION.EMAIL_SEND_IMMEDIATELY')}</option>
                <option value='2'>{INTL('NOTIFICATION.EMAIL_SEND_ONE_TIME')}</option>
                <option value='3'>{INTL('NOTIFICATION.EMAIL_SEND_ON_SELECTED_DAYS')}</option>
              </select>
            </div>

            <div className='col-lg-5' style={{ marginTop: '-19px', display: sendNotification === 1 ? 'none' : 'block' }}>
              <span style={{ display: 'block', fontWeight: 600, marginBottom: '5px' }}>
                {INTL('NOTIFICATION.EMAIL_SEND_TIME')}
              </span>
              <input
                className='form-control'
                disabled={sendNotification === 1}
                type='time'
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
            </div>
          </div>
          <div className='row'>
            <div
              className='col-10'
              style={{ marginTop: '7px', marginLeft: '12px', display: sendNotification === 3 ? 'block' : 'none' }}>
              {/* <AsyncPaginate
                styles={selectStyle}
                isMulti
                closeMenuOnSelect={false}
                noOptionsMessage={INTL('NOTHING_FOUND')}
                placeholder={INTL('ACCESS_LOGS.PLACEHOLDERS.SELECT')}
                options={
                
                    
                
                }
                loadOptionsOnMenuOpen
                onChange={(values: any) => {
                  
                  const ids = values ? [...values.map(({value}: any) => value)] : [];

                  setdaysToSendMail(ids)
                }}
                value={daysToSendMail}
              /> */}

              <AsyncPaginate
                loadingMessage={() => intl.formatMessage({ id: 'PERSON.PERSONS_TABLE_LOADING' })}
                noOptionsMessage={() => INTL('NOTHING_FOUND')}
                debounceTimeout={500}
                styles={selectStyle}
                closeMenuOnSelect={false}
                placeholder={intl.formatMessage({ id: 'ACCESS_LOGS.PLACEHOLDERS.SELECT' })}
                loadOptions={getTypeCredits}
                loadOptionsOnMenuOpen
                isMulti
                onChange={(values: any) => {
                  setdaysToSendMail(values);
                }}
                value={daysToSendMail}
              />
            </div>
          </div>
        </div>
      )}

      {step === 3 && (
        <div
          style={{
            padding: '0px 16px'
          }}>
          <span
            style={{
              marginBottom: '5px',
              fontWeight: 'bold',
              display: 'block',
              fontSize: '1.25rem'
            }}>
            {INTL('NOTIFICATION_TITLE_SECTION_TREE')}
          </span>
          <div className='col-lg-6' style={{ display: 'flex', gap: '6px', paddingLeft: '0' }}>
            {INTL('NOTIFICATION_INPUT_SELECT_ALL_AREAS')}
            <ToggleSwitch
              style={{ marginLeft: '7px' }}
              checked={ALLAREAS}
              onChange={(e) => SETALLAREAS(e.target.checked)}
            />
          </div>
          <div className='col-lg-12 modal-vinc pl-0' id='tablePerson'>
            <TableSelectionWhere
              data={{
                removeSelect: handleRemoveSelectWhere,
                addSelect: handleAddSelectWhere,
                itensSelected: whereSelecteds,
                block: ALLAREAS
              }}
            />
          </div>
        </div>
      )}

      {step === 4 && (
        <div
          style={{
            padding: '0px 16px'
          }}>
          <div>
            <span
              style={{
                fontWeight: 600,
                display: 'block',
                fontSize: '1.25rem'
              }}>
              {INTL('NOTIFICATION.EMAILS_LIST')}
            </span>
            {/* <input className='form-control' type='text' value={emails} onChange={(e) => setEmails(e.target.value)} /> */}

            <Select
              placeholder={INTL('NOTIFICATION.EMAIL_INPUT')}
              value={emails.entries}
              inputValue={emails.value}
              onChange={changeEmailEntry}
              onInputChange={setEmailValue}
              onKeyDown={addNewEmailEntry}
              components={{ DropdownIndicator: null }}
              isClearable
              isSearchable
              isMulti
              menuIsOpen={false}
              tabindex='-1'
            />
            {!!emails.error && <span className='text-danger'>{INTL(`NOTIFICATION.${emails.error}`)}</span>}
          </div>

          <div style={{ marginTop: '18px' }}>
            <span
              style={{
                fontWeight: 600,
                display: 'block'
              }}>
              {INTL('NOTIFICATION.EMAIL_SUBJECT')}
            </span>
            <input value={subject} onChange={(e) => setSubject(e.target.value)} className='form-control' type='text' />
          </div>

          <div style={{ marginTop: '18px' }}>
            <span
              style={{
                fontWeight: 600,
                display: 'block'
              }}>
              {INTL('NOTIFICATION.EMAIL_BODY_TITLE')}
            </span>
            <input
              placeholder={INTL('NOTIFICATION.EMAIL_TITLE_PLACEHOLDER')}
              value={emailTitle}
              onChange={(e) => setEmailTitle(e.target.value)}
              className='form-control'
              type='text'
            />
          </div>

          {/* 
          <div style={{marginTop: '18px'}}>
            <span
              style={{
                fontWeight: 600,
                display: 'block'
              }}>
              {INTL('NOTIFICATION.EMAIL_MESSAGE')}
            </span>
            <textarea value={message} onChange={(e) => setMessage(e.target.value)} className='form-control' name='' id='' cols={20} rows={7} />
          </div>

          <div style={{marginTop: '18px'}}>
            <span
              style={{
                fontWeight: 600,
                display: 'block'
              }}>
              {INTL('NOTIFICATION.EMAIL_SIGNATURE')}
            </span>
            <textarea value={signature} className='form-control' rows={3} onChange={(e) => setSignature(e.target.value)} />
          </div> */}
        </div>
      )}

      {step === 5 && (
        <ConfirmationContainer>
          <div>
            <h5>{INTL('NOTIFICATION.WHO')}</h5>

            <CardsContainer>
              <div
                style={{
                  opacity: ALLPERSONS || personListSelecteds.length !== 0 ? 1 : 0.4
                }}>
                <h6>{INTL('ACCESS_LOGS.INPUTS.NAME')}</h6>

                {!ALLPERSONS &&
                  personListSelecteds.map(({ id, name }) => (
                    <span className='' key={id}>
                      {name}
                    </span>
                  ))}

                {ALLPERSONS && <span className=''>{INTL('NOTIFICATION_ALL_PEOPLE')}</span>}
              </div>

              <div
                style={{
                  opacity: CompaniesListSelecteds.length !== 0 ? 1 : 0.4
                }}>
                <h6>{INTL('PERSON.BUILDER.ITEM.COMPANIES')}</h6>

                {CompaniesListSelecteds.map(({ id, name }) => (
                  <span className='' key={id}>
                    {name}
                  </span>
                ))}
              </div>

              <div
                style={{
                  opacity: ALLPERSONS || visitantListSelecteds.length !== 0 ? 1 : 0.4
                }}>
                <h6>{INTL('ASIDE.MENU.ITEM.VISITORS')}</h6>

                {!ALLPERSONS &&
                  visitantListSelecteds.map(({ id, name }) => (
                    <span className='' key={id}>
                      {name}
                    </span>
                  ))}

                {ALLPERSONS && <span>Todas as perssoas</span>}
              </div>

              <div
                style={{
                  opacity: typesListSelecteds.length !== 0 ? 1 : 0.4
                }}>
                <h6>{INTL('ACCESS_LOGS.COLUMNS.PERSON_TYPE')}</h6>

                {typesListSelecteds.map(({ id, name }) => (
                  <span className='' key={id}>
                    {name}
                  </span>
                ))}
              </div>
            </CardsContainer>
          </div>

          <div>
            <h5>{INTL('NOTIFICATION.WHEN')}</h5>

            <CardsContainer>
              <div>
                <h6>{INTL('NOTIFICATION.WHENNOTIFICATION')}</h6>

                {notificationToAlert && <span>{INTL('NOTIFICATION.EMAIL_TYPE_ALERT')}</span>}
                {notificationToDined && <span>{INTL('NOTIFICATION.EMAIL_TYPE_DECLINE')}</span>}
                {notificationToAllowed && <span>{INTL('NOTIFICATION.EMAIL_TYPE_ALLOWED')}</span>}
                {notificationToUndefined && <span>{INTL('NOTIFICATION.EMAIL_TYPE_NON_ID')}</span>}
              </div>
            </CardsContainer>
          </div>

          <div>
            <h5>{INTL('NOTIFICATION.WHERE')}</h5>

            <CardsContainer>
              <div>
                <h6
                  style={{
                    opacity: whereSelecteds.length !== 0 ? 1 : 0.4
                  }}>
                  {INTL('ACRULE.AREA')}
                </h6>

                {!ALLAREAS && whereSelecteds.map(({ id, name }) => <span key={id}>{name}</span>)}
                {ALLAREAS && <span className=''>{INTL('NOTIFICATION_ALL_AREAS')}</span>}
              </div>
            </CardsContainer>
          </div>

          <div>
            <h5>{INTL('NOTIFICATION.HOW')}</h5>

            <CardsContainer>
              <div>
                <h6>{INTL('NOTIFICATION.RESPONSIBLE')}</h6>

                {emails.entries.map((entry) => (
                  <span key={entry.value}>{entry.label}</span>
                ))}
              </div>
            </CardsContainer>
          </div>
        </ConfirmationContainer>
      )}

      <hr className='MuiDivider-root mt-10 MuiDivider-middle' />
      <div data-id='teste' className='row space-buttons'>
        <div className=''>
          <button
            onClick={handlePrevStep}
            id='button-next'
            className={step ? 'btn-elevate btn btn-primary' : 'btn btn-danger mr-5'}
            type='button'>
            {!step ? INTL('PERSON.NEWPERSON_CANCEL_BUTTON') : INTL('AUTH.GENERAL.BACK_BUTTON')}
          </button>
        </div>

        <div className=''>
          <button
            onClick={step === 5 ? handleSave : handleNextStep}
            id='button-next'
            className='btn-elevate btn btn-primary'
            color='primary'
            type='button'>
            {step === 5 ? INTL('PERSON.NEWPERSON_SAVE_BUTTON') : INTL('ACRULE.BUTTON_NEXT')}
          </button>
        </div>
      </div>
    </>
  );
}
