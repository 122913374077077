import { IntlShape } from 'react-intl'

const typesReports = (intl: IntlShape) => {
  // eslint-disable-next-line no-sparse-arrays
  const base = [
    {
      value: 'AccessLogs',
      label: intl.formatMessage({ id: 'ASIDE.MENU.ITEM.ACCESS_LOGS' }) // 01
    },
    {
      value: 'AccessLogsByDateTime',
      label: intl.formatMessage({ id: 'ASIDE.MENU.ITEM.DATETIME_ACCESS_LOGS' }) // 02
    },
    {
      value: 'AccessLogsByGroupAndCompanies',
      label: intl.formatMessage({ id: 'ASIDE.MENU.ITEM.ACCESS_LOGS_BY_GROUP_AND_COMPANY' }) // 03
    },
    {
      value: 'AccessLogsByUsers',
      label: intl.formatMessage({ id: 'ASIDE.MENU.ITEM.PEOPLE_ACCESS_LOGS' }) // 04
    },
    ,
    {
      value: 'AccessLogsByVisitors',
      label: intl.formatMessage({ id: 'ASIDE.MENU.ITEM.ACCESS_LOGS_GUEST_ACCESS' }) // 05
    },
    {
      value: 'AccessLogsByAreas',
      label: intl.formatMessage({ id: 'ASIDE.MENU.ITEM.AREAS_ACCESS_LOGS' }) // 06
    },
    {
      value: 'AccessLogsByDevices',
      label: intl.formatMessage({ id: 'ASIDE.MENU.ITEM.DEVICES_ACCESS_LOGS' }) // 07
    },
    {
      value: 'AccessLogsByAuthorization',
      label: intl.formatMessage({ id: 'ASIDE.MENU.ITEM.AUTH_ACCESS_LOGS' }) // 08
    },
    {
      value: 'AccessLogsByBlockedPeople',
      label: intl.formatMessage({ id: 'ACCESS_LOGS.BLACK_LIST' }) // 09
    },
    {
      value: 'VehicleAccess',
      label: intl.formatMessage({ id: 'ACCESS_VEHICLES_LOGS.GENERAL' }) // 10
    },
    {
      value: 'VehicleAccessByPeriod',
      label: intl.formatMessage({ id: 'ACCESS_VEHICLES_LOGS.BY_DATE' }) // 11
    },
    {
      value: 'VehicleAccessByGroupAndCompany',
      label: intl.formatMessage({ id: 'ACCESS_VEHICLES_LOGS.BY_GROUP' }) // 12
    },
    {
      value: 'VehicleAccessByPerson',
      label: intl.formatMessage({ id: 'ACCESS_VEHICLES_LOGS.BY_PEOPLE' }) // 13
    },
    {
      value: 'VehicleAccessByVehicles',
      label: intl.formatMessage({ id: 'ACCESS_VEHICLES_LOGS.BY_VEHICLE' }) // 14
    },
    {
      value: 'VehicleAccessByParking',
      label: intl.formatMessage({ id: 'ACCESS_VEHICLES_LOGS.BY_PARKING' }) // 15
    },
    {
      value: 'VehicleAccessByDevice',
      label: intl.formatMessage({ id: 'ACCESS_VEHICLES_LOGS.BY_DEVICE' }) // 16
    },
    {
      value: 'VehicleAccessByAuthorization',
      label: intl.formatMessage({ id: 'ACCESS_VEHICLES_LOGS.BY_AUTH' }) // 17
    },
    {
      value: 'AccessPermissionsByUsers',
      label: intl.formatMessage({ id: 'LOGSPERMISSIONS_VIEW_PEOPLE' }) // 18
    },
    {
      value: 'AccessPermissionsByCompanies',
      label: intl.formatMessage({ id: 'LOGSPERMISSIONS_VIEW_COMPANIES' }) // 19
    },
    {
      value: 'AccessPermissionsByGroups',
      label: intl.formatMessage({ id: 'LOGSPERMISSIONS_VIEW_GROUPPEOPLE' }) // 20
    },
    {
      value: 'UserPermissionsByArea',
      label: intl.formatMessage({ id: 'LOGSPERMISSIONS_VIEW_PEOPLEAREA' }) // 21
    },
    {
      value: 'GroupPermissionsByArea',
      label: intl.formatMessage({ id: 'LOGSPERMISSIONS_VIEW_GROUPAREA' }) // 22
    },
    {
      value: 'UsersRecords',
      label: intl.formatMessage({ id: 'ASIDE.MENU.ITEM.PEOPLE_RECORDS' }) // 23
    },
    {
      value: 'VisitorsRecords',
      label: intl.formatMessage({ id: 'ASIDE.MENU.ITEM.VISITORS_RECORDS' }) // 24
    },
    {
      value: 'UsersRecordsByGroups',
      label: intl.formatMessage({ id: 'ASIDE.MENU.ITEM.PEOPLE_RECORDS_BY_PEOPLE_GROUP' }) // 25
    },
    {
      value: 'UsersRecordsByCompanies',
      label: intl.formatMessage({ id: 'ASIDE.MENU.ITEM.PEOPLE_RECORDS_BY_COMPANIES' }) // 26
    },
    {
      value: 'VisitorsRecordsByGroups',
      label: intl.formatMessage({ id: 'ASIDE.MENU.ITEM.VISITORS_RECORDS_BY_VISITORS_GROUP' }) // 27
    },
    {
      value: 'VisitorsRecordsByCompanies',
      label: intl.formatMessage({ id: 'ASIDE.MENU.ITEM.VISITORS_RECORDS_BY_COMPANIES' }) // 28
    },
    {
      value: 'CreditRegistrationsByUser',
      label: intl.formatMessage({ id: 'REGISTER_CREDIT_TO_USER_TITLE' }) // 29
    },
    {
      value: 'RegisterOfExpiredDocuments',
      label: intl.formatMessage({ id: 'ASIDE.MENU.ITEM.REGISTER_EXPIRED_DOCUMENTS' }) // 30
    },
    {
      value: 'RecordsOfBadLoginAttempts',
      label: intl.formatMessage({ id: 'BADLOGIN_TITLE' })// 31
    },
    {
      value: 'UserPhotoRegistration',
      label: intl.formatMessage({ id: 'REPORTS.PHOTOS' }) // 32
    },
    {
      value: 'PeopleRecordsFromTheBlackList',
      label: intl.formatMessage({ id: 'REPORTS.BLACK_LIST' })  // 33
    },
    {
      value: 'ActualLocationByCompanyAndProfession',
      label: intl.formatMessage({ id: 'REPORTS.CURRENT_STATUS_PEOPLE_VISITORS_PRESENT_COMPANY' }) // 34
    },
    {
      value: 'PeopleLocation',
      label: intl.formatMessage({ id: 'REPORTS.CURRENT_STATUS_LOCATION_PEOPLE' })// 35
    },
    {
      value: 'VisitorsLocation',
      label: intl.formatMessage({ id: 'REPORTS.CURRENT_STATUS_LOCATION_VISITORS' })// 36
    },
    {
      value: 'ExpiredVisitors',
      label: intl.formatMessage({ id: 'REPORTS.CURRENT_STATUS_EXPIRED_VISITORS' })//37
    },
    {
      value: 'StayTimeInCurrentArea',
      label: intl.formatMessage({ id: 'REPORTS.CURRENT_STATUS_LENGTH_STAY_CURRENT_AREA' })//38
    },
    {
      value: 'StayTimeInCurrentParking',
      label: intl.formatMessage({ id: 'REPORTS.CURRENT_PARKING_LENGTH_OF_STAY' })//39
    },
    {
      value: 'VehicleLocation',
      label: intl.formatMessage({ id: 'LOGREGISTER.VEHICLE_LOCATION' })// 40
    },
    {
      value: 'AuditLogs',
      label: intl.formatMessage({ id: 'REPORTS.AUDITLOG' }) //41
    }
  ]

  return base


}


export {
  typesReports
}