import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { v4 } from 'uuid';
import { Container, TableContainerB } from './styles';
import { RiAlarmWarningLine } from 'react-icons/ri';
import { ConfirmModal } from 'src/_metronic/_partials/controls/DevicesExceptionMode/ConfirmModal';
import { stopAlarm } from 'src/services/devicesCrud';
import { execToast } from 'src/_metronic/_partials/controls/Toast';

interface LOGSPROPS {
  datetime: string;
  area: string;
  device: string;
  id: number;
  typeAlarme: boolean;
  deviceId: number;
}

interface PROPS {
  data: LOGSPROPS[];
  nextPage(): void;
  selected(id: number): void;
  idActived: number;
}
export function Table({ data, nextPage, selected, idActived }: PROPS) {
  const trigger = data.length - 2;
  const [currentAlarm, setCurrentAlarm] = useState<{ id: number; name: string } | undefined>(undefined);
  const intl = useIntl();

  const handleStopAlarm = async () => {
    if (!currentAlarm?.id) return;

    try {
      await stopAlarm(currentAlarm.id);
      execToast('success', intl.formatMessage({ id: 'MONITORING.ALARM.STOP_ALARM.SUCCESS' }), 3000);
    } catch (e) {
      execToast('error', intl.formatMessage({ id: e.message }), 3000);
    }
    setCurrentAlarm(undefined);
  };

  useEffect(() => {
    if (data.length < 8) return;

    const intersectionObserver = new IntersectionObserver((entries) => {
      const elementVisible = entries.some((entrie) => entrie.isIntersecting);

      if (elementVisible) {

        //setTimeout(() => {
        nextPage();
        // setData(current => {
        //   return [...current,20,30,40,50,60,70,80,90]
        // })
        //},1000)
      }
    });

    intersectionObserver.observe(document.querySelector('#next')!);

    return () => {
      intersectionObserver.disconnect();
    };
  }, [data]);

  return (
    <Container>
      <TableContainerB responsive>
        <thead>
          <tr>
            <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
              {intl.formatMessage({ id: 'DATE_TIME_LABEL' })}
            </th>
            <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
              {intl.formatMessage({ id: 'DEVICE_LABEL' })}
            </th>
            <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
              {intl.formatMessage({ id: 'AREA_LABEL' })}
            </th>
            <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
              {intl.formatMessage({ id: 'ALARM_HISTORY.TABLE.ALARM' })}
            </th>

            {/* <th style={{color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase'}}>
              {intl.formatMessage({id: 'PERSON.PERSONS_TABLE_ACTIONS'})}
            </th> */}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            const key = v4();
            return (
              // <tr style={idActived === item.id ? {backgroundColor: '#e6e6e6'} : {}} key={v4()} data-item={item.id} className='item-table' id={index === trigger ? 'next' : ''} onClick={() => selected(item.id)}>
              <tr
                style={idActived === item.id ? { backgroundColor: '#D2F6F4' } : {}}
                key={key}
                data-item={item.id}
                className='item-table'
                id={index === trigger ? 'next' : ''}
                onClick={() => selected(item.id)}>
                <td>{item.datetime}</td>
                <td>{item.device}</td>
                <td>{item.area}</td>
                <td>{item.typeAlarme}</td>
                {/* <td>
                  <button
                    onClick={() => setCurrentAlarm({id: item.deviceId, name: item.device})}
                    type='button'
                    className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3'>
                    <RiAlarmWarningLine />
                  </button>
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </TableContainerB>

      <ConfirmModal
        title={`${intl.formatMessage({ id: 'MONITORING.ALARM.STOP_ALARM.TITLE' })} ${currentAlarm?.name}`}
        description={intl.formatMessage({ id: 'MONITORING.ALARM.STOP_ALARM.DESCRIPTION' })}
        onConfirm={handleStopAlarm}
        onCancel={() => setCurrentAlarm(undefined)}
        isOpen={!!currentAlarm?.id}
        variant='confirm'
      />
    </Container>
  );
}
