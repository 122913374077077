import {createSlice} from '@reduxjs/toolkit';

const initialAreaState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  areaForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: 'list',
  action: 'action'
};

export const areaSlice = createSlice({
  name: 'area',
  initialState: initialAreaState,
  reducers: {
    catchError: (state: any, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state: any, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    areaFetched: (state: any, action) => {
      state.actionsLoading = false;
      state.areaForEdit = action.payload.areaForEdit;
      state.error = null;
    },
    areasFetched: (state: any, action) => {
      const {totalCount, entities} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    areaCreated: (state: any, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.area);
    },
    areaUpdated: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity: any) => {
        if (entity.id === action.payload.area.id) {
          return action.payload.area;
        }
        return entity;
      });
    },
    areaDeleted: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el: any) => el.id !== action.payload.id);
    },
    areaEnable: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el: any) => el.id !== action.payload.id);
    },
    linkedAreas: (state: any, action) => {
      const {areas} = action.payload;
      state.linkedAreasIds = areas;
    },
    linkedAreaDeleted: (state: any, action) => {
      state.linkedlinkedAreasIdsIds = state.linkedlinkedAreasIdsIds.filter((el: any) => el.id !== action.payload.id);
    }
  }
});
