import {createSlice} from '@reduxjs/toolkit';

const initialGroupsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  groupForEdit: undefined,
  lastError: null,
  linkedGoupsIds: []
};
export const callTypes = {
  list: 'list',
  action: 'action'
};

export const groupsSlice = createSlice({
  name: 'groups',
  initialState: initialGroupsState,
  reducers: {
    catchError: (state: any, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state: any, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    groupFetched: (state: any, action) => {
      state.actionsLoading = false;
      state.groupForEdit = action.payload.groupForEdit;
      state.error = null;
    },
    groupsFetched: (state: any, action) => {
      const {totalCount, entities} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    groupCreated: (state: any, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.group);
    },
    groupUpdated: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity: any) => {
        if (entity.id === action.payload.group.id) {
          return action.payload.group;
        }
        return entity;
      });
    },
    groupDeleted: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el: any) => el.id !== action.payload.id);
    },

    linkedGroups: (state: any, action) => {
      const {groups} = action.payload;
      state.linkedGoupsIds = groups;
    },
    linkedGroupDeleted: (state: any, action) => {
      state.linkedGoupsIds = state.linkedGoupsIds.filter((el: any) => el.id !== action.payload.id);
    }
  }
});
