import axios from 'axios';

const idsecureAPI = process.env.REACT_APP_IDSECURE_API_URL;

// tipos de dado

type newCreditType = {
  description: string,
  maxDailyConsumption: number
}

type editCreditType = {
  description: string,
  maxDailyConsumption: number,
  id: number,
}

type getAllCreditTypesFilterData = {
  pageSize:number,
  pageNumber:number,
  status:number,
  sortOrder:string,
  sortField:string
  value: string;
}

type exportCreditTypesFilterData = {
  hearder: Array<string>,
  pageSize:number,
  pageNumber:number,
  status:number,
  sortOrder:string,
  sortField:string
}

// Tipo de crédito

export function exportCreditType(queryParams: exportCreditTypesFilterData) {
  return axios.get(`${idsecureAPI}/credit/type/export`, {
    params: queryParams
  });
}

export function createCreditType(data: newCreditType) {
  return axios.post(`${idsecureAPI}/credit/type`, {...data});
}

export function editCreditType(data: editCreditType) {
  return axios.put(`${idsecureAPI}/credit/type`, {...data});
}

export function getAllCreditTypes(queryParams: getAllCreditTypesFilterData) {
  return axios.get(`${idsecureAPI}/credit/type`, {
    params: queryParams
  });
}

export function getAllCreditTypesManagement(queryParams: getAllCreditTypesFilterData) {
  return axios.get(`${idsecureAPI}/credit/typeperson/type`, {
    params: queryParams
  });
}

export function getAllCreditTypesChangedPolicy(queryParams: getAllCreditTypesFilterData) {
  return axios.get(`${idsecureAPI}/accessrules/credit/type`, {
    params: queryParams
  });
}

export function getByIdCreditTypes(id: number) {
  return axios.get(`${idsecureAPI}/credit/type/${id}`);
}

export function disableCreditTypes(id: number, erase?: boolean) {
  return axios.delete(`${idsecureAPI}/credit/type/${id}`, {
    params: {
      erase
    }
  });
}

export function enableCreditType(id: number) {
  return axios.put(`${idsecureAPI}/credit/type/${id}`);
}