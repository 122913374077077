import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, CardBody, CardHeader, Input } from '../../../../_metronic/_partials/controls';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { Field, Formik } from 'formik';
import { Row } from 'react-bootstrap';
import { editCreditType, getByIdCreditTypes } from 'src/services/creditsCrud';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { usePermisssion } from 'src/redux/userPermissions/usePermission';
import { useLang } from 'src/_metronic/i18n';

export function CreditTypeEdit() {
  const history = useHistory();
  const intl = useIntl();
  const description = intl.formatMessage({ id: 'CREDITS_TYPE.DESCRIPTION' });
  const maxDaily = intl.formatMessage({ id: 'CREDITS_TYPE.MAXDAILY' });
  const [isRead, setIsRead] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const lang = useLang()

  const { redirectView, actionsIsDesatived } = usePermisssion();

  useEffect(() => {
    redirectView(16);
    const value = actionsIsDesatived(16);
    setIsRead(value);
  }, []);

  function INTL(value: string) {
    return intl.formatMessage({ id: value });
  }

  const validationSchema = Yup.object().shape({
    description: Yup.string()
      .min(3)
      .required('Name is required'),
    maxDailyConsumption: Yup.number().min(0),
    unitValue: Yup.string(),
  });

  async function getData(id: number) {
    const { data } = await getByIdCreditTypes(id);

    const restoredValues = data.data

    let qtdFormated = ''

    if (restoredValues.unitValue.toString().includes('.')) {
      const [start, end] = restoredValues.unitValue.toString().split('.')

      qtdFormated = `${start.padStart(2, '0')}${lang === 'pt' ? ',' : '.'}${end.padStart(2, '0')}`
    } else {
      qtdFormated = restoredValues.unitValue
    }

    delete restoredValues.unitValue

    setInitialValues({
      ...restoredValues
    });


    setTimeout(() => {
      //@ts-ignore
      document.querySelector('[name="unitValue"]').value = qtdFormated
    }, 200)
  }

  useEffect(() => {
    const id = localStorage.getItem('@CONTROLID@/credittype/edit')!
    getData(+id);
  }, []);


  async function handleSave(values: any) {
    try {
      values.description = values.description.trim();
      //@ts-ignore
      let unit = document.querySelector('[name="unitValue"]').value


      const payload = {
        ...values,
        unitValue: unit.replace(',', '.')
      }

      await editCreditType(payload);
      execToast('success', INTL('TOAST.CREDITS_TYPE.SUCCESSFULL_EDITED'), 3000);
      history.push('/credittype')

    } catch (erro: any) {
      if (erro.response.data.message == '410') {
        execToast('error', INTL('TOAST.CREDITS_TYPE.ALREADY_EXISTIS'), 3000);
        return
      }

      if (erro.response.data.message == '456') {
        execToast('error', INTL('TOAST.CREDITS_TYPE.MAX_IS_ZERO'), 3000);
        return
      }
    }
  }


  return (
    <Card fluidHeight={null} className={null}>
      <CardHeader icon={null} toolbar={null} className={null} labelRef={null} sticky={false} linkAddTitle={undefined} title={INTL('CREDITS_TYPE.EDIT.TILTE')} />
      <CardBody fit={null} fluid={null} className={null}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values, { setFieldError }) => {
            //@ts-ignore
            values.description = values.description.trim();
            handleSave(values);
          }}>
          {({ handleSubmit }) => (
            <>
              <Row className='justify-content-between mb-5 p-5'>
                <div className='col-6'>
                  <Field id='nova-area-descricao' name='description' component={Input} label={description} placeholder={description} />
                </div>
                <div className='col-6'>
                  <Field id='nova-area-descricao' name='maxDailyConsumption' component={Input} label={maxDaily} placeholder={maxDaily} />
                </div>
              </Row>

              <Row className='justify-content-between mb-2 p-5'>
                <div className='col-6'>
                  <label htmlFor=""> {intl.formatMessage({ id: "VALUE.UNIT" })} </label>
                  <Field name='unitValue'
                    component={Input}
                    placeholder={''}
                    onChange={(e: any) => {

                      const removeLetters = e.target.value.replaceAll(/[a-z]/gi, '')
                      let value = removeLetters

                      e.target.value = value
                    }}
                  // mask={
                  //   lang === 'pt' ? '9999,99' : '9999.99'
                  // }
                  />
                </div>
              </Row>

              <div className='align-right m-5'>
                <Link id='tipo-de-credito-cancelar' type='button' className='btn btn-danger mr-5' to='/credittype'>
                  <FormattedMessage id='PERSON.NEWPERSON_CANCEL_BUTTON' />
                </Link>
                <button
                  disabled={isRead}
                  id='tipo-de-credito-salvar'
                  type='submit'
                  onClick={() => {
                    if (isRead) return;
                    handleSubmit();
                  }}
                  className='btn btn-primary btn-elevate'>
                  <FormattedMessage id='PERSON.NEWPERSON_SAVE_BUTTON' />
                </button>
              </div>
            </>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};
