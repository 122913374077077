import { createSlice } from '@reduxjs/toolkit';

const initialDeviceState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  visitorForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: 'list',
  action: 'action'
};

export const deviceSlice = createSlice({
  name: 'devices',
  initialState: initialDeviceState,
  reducers: {
    catchError: (state: any, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state: any, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getPersonById
    deviceFetched: (state: any, action) => {
      state.deviceForEdit = undefined;
      state.actionsLoading = false;
      state.deviceForEdit = action.payload.deviceForEdit;
      state.error = null;
    },
    deviceModelFetched: (state: any, action) => {
      state.actionsLoading = false;
      state.deviceModel = action.payload.device;
      state.error = null;

    },
    // finddevices
    devicesFetched: (state: any, action) => {
      const { totalCount, entities } = action.payload;


      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.deviceForEdit = undefined;
    },
    areasFetched: (state: any, action) => {
      const { totalCount, entities } = action.payload;
      state.areasLoading = false;
      state.error = null;
      state.areas = entities;
      state.totalCount = totalCount;
    },
    parkingFetched: (state: any, action) => {
      const { entities } = action.payload;
      state.parkingLoading = false;
      state.error = null;
      state.parkings = entities;
    },
    // createdevice
    deviceCreated: (state: any, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.device);
      state.deviceForEdit = undefined;
    },
    // updatedevice
    deviceUpdated: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity: any) => {
        if (entity.id === action.payload.device.id) {
          return action.payload.device;
        }
        return entity;
      });
      state.deviceForEdit = undefined;
    },
    // deletedevice
    deviceDeleted: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el: any) => el.id !== action.payload.id);
    },
    // deletedevices
    devicesDeleted: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el: any) => !action.payload.ids.includes(el.id));
    },
    // devicesUpdateState
    devicesStatusUpdated: (state: any, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity: any) => {
        if (ids.findIndex((id: number) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    deviceDoorOpened: (state: any, action) => {
      state.actionsLoading = false;
      state.entities = state.entities.map((entity: any) => {
        if (entity.id === action.payload.device.id) {
          return action.payload.device;
        }
        return entity;
      });
    }
  }
});
