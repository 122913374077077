import React, { FormEvent, useEffect, useState } from 'react';
import { FaBullseye } from 'react-icons/fa';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from 'src/_metronic/_partials/controls';
import { ToggleSwitch } from 'src/_metronic/_partials/controls/forms/ToggleSwitch';
import { FormElement } from './styles';

import { useHistory } from 'react-router-dom';
import { useParkingProvider } from '../Context/ParkingContext';
import { useIntl } from 'react-intl';
import { ParkingSpaces } from '../../parkingSpaces';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { cardIsValidPerson } from 'src/services/personsCrud';
import { editParkingSpace, getSpacesById, newParkingSpace } from 'src/services/parkingService';
import { usePermisssion } from 'src/redux/userPermissions/usePermission';

export function Form() {
  const history = useHistory();
  const { fn } = useParkingProvider();
  const [name, setName] = useState<string>('');
  const [spaceAvailable, setSpaceAvailable] = useState<string>('');
  const [constrolSpaceAvailable, setConstrolSpaceAvailable] = useState(false);
  const [blockDoubleEntry, setBlockDoubleEntry] = useState(false);
  const [blockDoubleExit, setBlockDoubleExit] = useState(false);
  const [id, setId] = useState(0);
  const intl = useIntl();

  const [totalSpaces, setTotalSpaces] = useState(0)



  const [newSpacing, setNewSpacing] = useState<any[]>([])

  const [tab, setTab] = useState(1);

  const [isRead, setIsRead] = useState(false);

  const { redirectView, actionsIsDesatived } = usePermisssion();

  useEffect(() => {
    redirectView(20);
    const value = actionsIsDesatived(20);
    setIsRead(value);
  }, []);

  function hanlderSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!name) {
      execToast('error', 'Nome não pode estar vazio', 3000);
      return;
    }

    if (id) {

      fn.edit({
        id,
        constrolSpaceAvailable: !constrolSpaceAvailable,
        capacity: !!spaceAvailable ? +spaceAvailable : (undefined as any),
        description: name,
        parkingSpaces: newSpacing
      });

      return

    }

    fn.create({
      name,
      blockDoubleEntry,
      blockDoubleExit,
      constrolSpaceAvailable: !constrolSpaceAvailable,
      spaceAvailable: !!spaceAvailable ? +spaceAvailable : (undefined as any),
      parkingSpaces: newSpacing
    });
  }


  function addSpacing(data: any) {

    if (data.id) {
      const updated = newSpacing.map((item, index) => {
        if (index === data.index) {
          return data
        }

        return item
      })

      setNewSpacing(updated)
    } else {

      if (data.index || data.index === 0) {
        const updated = newSpacing.map((item, index) => {
          if (index === data.index) {
            return data
          }

          return item
        })

        setNewSpacing(updated)
      } else {
        delete data.index


        setNewSpacing([...newSpacing, data])
      }
    }
  }

  function deleteCard(position: number) {


    const updated = newSpacing.filter((_, index) => index !== position)

    setNewSpacing(updated)
  }

  async function loadSpacesByIdParking(id: number) {
    const queryParans = {
      ParkingLotId: id,
      pageNumber: 1,
      status: 1,
      pageSize: 1000,
      sortField: 'Description',
      sortOrder: 'asc'

    }

    const { data } = await getSpacesById(queryParans)

    const totalItens = data.data.total



    setNewSpacing(data.data.data)
  }

  useEffect(() => {
    const id = sessionStorage.getItem('@CONTROLID@/parking/edit');

    if (id) {
      setId(+id);
      (async () => {
        const response = await fn.getPark(+id);

        setName(response.data.data.description);
        setSpaceAvailable(response.data.data.capacity);
        setConstrolSpaceAvailable(!response.data.data.unlimitedCapacity)
      })();

      loadSpacesByIdParking(+id)
    }
  }, []);


  useEffect(() => {

    let total = 0

    newSpacing.forEach(item => {
      total += item.capacity
    })

    setTotalSpaces(total)

  }, [newSpacing])



  return (
    <Card fluidHeight={null} className={null}>
      <CardHeader
        icon={null}
        toolbar={null}
        className={null}
        labelRef={null}
        sticky={false}
        linkAddTitle={undefined}
        title={
          id ? intl.formatMessage({ id: 'PARKING_EDIT_REGISTER' }) : intl.formatMessage({ id: 'PARKING_NEW_REGISTER' })
        }
      />
      <CardBody fit={null} fluid={null} className={null}>



        <FormElement action='' onSubmit={hanlderSubmit}>
          <article>
            <div>
              <label htmlFor='name-notification'>{intl.formatMessage({ id: 'NAME_LABEL' })}</label>
              <input
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                autoComplete='off'
                id='name-notification'
                type='text'
                className='form-control'
              />
            </div>

            <div>
              <label htmlFor='name-notification'>{intl.formatMessage({ id: 'PARKING_TABLE__TWO' })}</label>
              <input
                disabled
                value={totalSpaces}
                type='text'
                className='form-control'
              />
            </div>

            <div style={{ marginTop: '33px' }}>
              <ToggleSwitch
                checked={constrolSpaceAvailable}
                onChange={(e) => {
                  setConstrolSpaceAvailable(e.target.checked);
                }}
              />

              <label htmlFor='name-notification'>{intl.formatMessage({ id: 'TOGGLE.CONTROL.LOT' })}</label>

            </div>


          </article>


          <article style={{ marginTop: '35px' }}>
            {/* <div>
                  <label htmlFor='name-notification'>{intl.formatMessage({id: 'Número de Vagas no Estacionamento'})} </label>
                  <input
                  disabled={!constrolSpaceAvailable}
                    value={spaceAvailable}
                    onChange={(e) =>
                      setSpaceAvailable(/^(?:\d*\.?\d+|)$/.test(e.target.value) ? e.target.value : spaceAvailable)
                    }
                    autoComplete='off'
                    id='name-notification'
                    className='form-control'
                  />
                </div> */}
          </article>



          <div className='spacing'>
            <div style={{ marginBottom: '30px', opacity: id ? '1' : '0.5' }} >
              <ul className='nav nav-dark nav-bold nav-tabs nav-tabs-line' data-remember-tab='tab_id' role='tablist'>

                <li className='nav-item'>
                  <a
                    className={`nav-link active`}
                    data-toggle='tab'
                    onClick={() => {
                      setTab(1);
                    }}>
                    {intl.formatMessage({ id: 'PARKING.LOTE.TAB.V' })}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div>
            <ParkingSpaces
              idLot={id}
              name={name}
              addSpacing={addSpacing}
              dataListSpacings={newSpacing}
              loadSpaces={loadSpacesByIdParking}
              deleteCard={deleteCard}
              constrolSpaceAvailable={constrolSpaceAvailable}
              spaceAvailable={spaceAvailable}
            />
          </div>

          <section>
            <div className='container'>
              <div className=''>
                <button
                  id='button-next'
                  className={'btn btn-danger mr-5'}
                  type='button'
                  onClick={() => {
                    sessionStorage.removeItem('@CONTROLID@/parking/edit');
                    history.push('/parkingregister');
                  }}>
                  {intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' })}
                </button>
              </div>

              <div className=''>
                <button id='button-next' disabled={isRead} className='btn-elevate btn btn-primary' color='primary' type='submit'>
                  {intl.formatMessage({ id: 'PERSON.NEWPERSON_SAVE_BUTTON' })}
                </button>
              </div>
            </div>
          </section>
        </FormElement>


      </CardBody>
    </Card>
  );
}
