import React from 'react';
import {FormattedMessage} from 'react-intl';

export function PaginationCustomTotal(from, to, size) {
  // const intl = useIntl();
  return (
    <span className='react-bootstrap-table-pagination-total' style={{paddingLeft: 3}}>
      <FormattedMessage id='PAGINATION.SHOWING' /> {from} <FormattedMessage id='PAGINATION.TO' /> {to} <FormattedMessage id='PAGINATION.OF' /> {size}
    </span>
  );
}
