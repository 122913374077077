const BASE = '/device-notification';

export const DEVICE_NOTIFICATION_ROUTES = {
  BASE,
  CREATE: `${BASE}/create`,
  EDIT: (id: number | string) => `${BASE}/edit/${id}`
};

export const DEVICE_NOTIFICATION_ROUTES_LIST = [
  DEVICE_NOTIFICATION_ROUTES.BASE,
  DEVICE_NOTIFICATION_ROUTES.EDIT(':id'),
  DEVICE_NOTIFICATION_ROUTES.CREATE
];
