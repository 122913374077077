import React from 'react';
import {Modal} from 'react-bootstrap';

import {PersonsTable} from 'src/_metronic/layout/components/TableLists/Persons';

import {useTableHooks} from './hooks/table';

interface Props {
  isVisible: boolean;
  close: (value: boolean) => void;
  users: any[];
  fnUserSeelected: (value: any) => void;
  fnSave: () => void;
}

export function ModalSelectUsers({close, isVisible, users, fnUserSeelected, fnSave}: Props) {
  const {INTL} = useTableHooks();

  return (
    <Modal
      show={isVisible}
      centered
      size={'lg'}
      onHide={() => {
        close(false);
      }}>
      <Modal.Header>
        <Modal.Title>{INTL('GROUPS.ADD_PEOPLE_TO_GROUP')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PersonsTable users={users} fnUserSeelected={fnUserSeelected} />
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex justify-content-end'>
          <button
            onClick={() => {
              // setShow(false);
              close(false);
            }}
            className={'btn btn-danger'}>
            {INTL('PERSON.NEWPERSON_CANCEL_BUTTON')}
          </button>
        </div>
        <div className='d-flex justify-content-end'>
          <button
            onClick={() => {
              fnSave();
            }}
            className={'btn btn-primary'}>
            {INTL('SAVE')}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
