import format from 'date-fns/format'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'


interface Props {

  modelDay: string,
  modelMonth: string,
  modelYear: string,
  modelSeparator: string
  modelHour: string
  modelMinutes: string,
  order: number,
  setOrder(value: number): void
  setModelMinutes(value: string): void
  setModelDay(value: string): void
  setModelMonth(value: string): void
  setModelYear(value: string): void
  setModelSeparator(value: string): void
  setModelHour(value: string): void

}

export function ConfigDateTime(data: Props) {
  const intl = useIntl();

  const {
    setModelDay,
    setModelHour,
    setModelMinutes,
    setModelMonth,
    setModelSeparator,
    setModelYear,
    setOrder,

    modelDay,
    modelHour,
    modelMinutes,
    modelMonth,
    modelSeparator,
    modelYear, order, } = data

  const AM = modelHour.includes('h') ? 'a' : ''

  const templates = [
    `${modelDay}${modelSeparator}${modelMonth}${modelSeparator}${modelYear} ${modelHour}:${modelMinutes} ${AM}`,
    `${modelYear}${modelSeparator}${modelMonth}${modelSeparator}${modelDay} ${modelHour}:${modelMinutes} ${AM}`,
    `${modelMonth}${modelSeparator}${modelDay}${modelSeparator}${modelYear} ${modelHour}:${modelMinutes} ${AM}`,
    `${modelYear}${modelSeparator}${modelDay}${modelSeparator}${modelMonth} ${modelHour}:${modelMinutes} ${AM}`
  ]

  const exemple = format(new Date(), templates[order])




  return (
    <div>
      <h5 className='weight-400'>{intl.formatMessage({ id: "CONFIG.DATETIME.TITLE" })}</h5>


      <div className='row'>
        <div className='col-lg-3'>
          <label>{intl.formatMessage({ id: "CONFIG.DATETIME.DAY" })}</label>
          <select
            className='custom-select'
            value={modelDay}
            onChange={e => setModelDay(e.target.value)}
          >
            <option value="d">d</option>
            <option value="dd">dd</option>
          </select>
        </div>

        <div className='col-lg-3'>
          <label>{intl.formatMessage({ id: "CONFIG.DATETIME.MONTH" })}</label>
          <select
            className='custom-select'
            value={modelMonth}
            onChange={e => setModelMonth(e.target.value)}
          >
            <option value="M">M</option>
            <option value="MM">MM</option>
          </select>
        </div>

        <div className='col-lg-3'>
          <label>{intl.formatMessage({ id: "CONFIG.DATETIME.YEAR" })}</label>
          <select
            className='custom-select'
            value={modelYear}
            onChange={e => setModelYear(e.target.value)}
          >
            <option value="yy">YY</option>
            <option value="yyyy">YYYY</option>
          </select>
        </div>

        <div className='col-lg-3'>
          <label>Selecione o separador</label>
          <select
            className='custom-select'
            value={modelSeparator}
            onChange={e => setModelSeparator(e.target.value)}
          >
            <option value="-">-</option>
            <option value="/">/</option>
          </select>
        </div>

      </div>

      <div className='row'>
        <div className='col-lg-3'>
          <label>{intl.formatMessage({ id: "CONFIG.DATETIME.HOUR" })}</label>
          <select
            className='custom-select'
            value={modelHour}
            onChange={e => setModelHour(e.target.value)}
          >
            <option value="H">H</option>
            <option value="HH">HH</option>
            <option value="h">h</option>
            <option value="hh">hh</option>
          </select>
        </div>

        <div className='col-lg-3'>
          <label>{intl.formatMessage({ id: "CONFIG.DATETIME.MINUTES" })}</label>
          <select
            className='custom-select'
            value={modelMinutes}
            onChange={e => setModelMinutes(e.target.value)}
          >
            <option value="m">m</option>
            <option value="mm">mm</option>
          </select>
        </div>
      </div>

      <div className="row">
        <div className='col-lg-3'>
          <label>{intl.formatMessage({ id: "CONFIG.DATETIME.ORDER" })}</label>
          <select
            className='custom-select'
            onChange={e => setOrder(+e.target.value)}
            value={order}
            id="templates-order-format-date"
          >
            <option value="0">{templates[0]}</option>
            <option value="1">{templates[1]}</option>
            <option value="2">{templates[2]}</option>
            <option value="3">{templates[3]}</option>
          </select>
        </div>
      </div>


      <div className="row">
        <div className='col-lg-4'>
          <label>{intl.formatMessage({ id: "CONFIG.DATETIME.RESULT" })}</label>
          <input type="text" className="form-control" value={`${templates[order]} = ${exemple}`} disabled />
        </div>


      </div>
    </div>
  )
}