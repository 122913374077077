import axios from 'axios';
import qs from 'qs';
// import {dateToUNIX} from 'src/app/modules/AccessLog/utils/utils';
import { AccessLastDay, AccessLastWeek, Indicators, RealTimeAccess } from 'src/app/modules/Dashboard/_redux/dashboardSlices';
import { dateToUNIX } from 'src/app/modules/Reports/utils/utils';
import { defaultDateTime } from 'src/utils/defaultDateTime';

const idsecureAPI = `${process.env.REACT_APP_IDSECURE_API_URL_REPORTS}/dashboard`;

export function getIndicators() {
  return axios.get<any>(`${idsecureAPI}/indicators`);
}

export function getAccessLastDay() {
  return axios.get<any>(`${idsecureAPI}/lastdayaccess`);
}

export function getAccessLastWeek() {
  return axios.get<any>(`${idsecureAPI}/lastweekaccess`);
}

export function getRealTimeAccess(ignoreCount: boolean = false) {
  return axios.get<any>(`${process.env.REACT_APP_IDSECURE_API_URL_REPORTS}/dashboard/logs`, {
    params: {
      pageSize: 10,
      pageNumber: 1,
      // personsIds: [],
      // areasIds: [],
      // devicesIds: [],
      // events: [],
      // dtStart: dateToUNIX(new Date(newdate)),
      // dtEnd: dateToUNIX(new Date()),
      sortOrder: 'desc',
      sortField: 'Time',
      ignoreCount
    },
    transformResponse: [function (data) {

      const response = JSON.parse(data)

      try {
        const transformValues = response.data.data.map((v: any) => {
          return {
            ...v,
            time: defaultDateTime.labelDateTime(v.time)
          }
        })

        response.data.data = transformValues
        return {
          data: {
            ...response.data,
            data: transformValues
          }
        }



      } catch {
        return response
      }


    }],
  });
}

interface IDashboardLast {
  LastLogId?: number,
  Quantity?: number
}

export function getRealTimeAccessLast({ Quantity, LastLogId }: IDashboardLast) {
  return axios.get<any>(`${process.env.REACT_APP_IDSECURE_API_URL_REPORTS}/dashboard/last`, {
    params: {
      LastLogId,
      Quantity,
    }
  });
}


export function getRealTimeAccess2() {
  return axios.get<any>(`${process.env.REACT_APP_IDSECURE_API_URL_REPORTS}/dashboard/logs`, {
    params: {
      pageSize: 10,
      pageNumber: 1,
      // personsIds: [],
      // areasIds: [],
      // devicesIds: [],
      // events: [],
      dtStart: dateToUNIX(new Date()),
      dtEnd: dateToUNIX(new Date()),
      sortOrder: 'desc',
      sortField: 'Time'
    }
  });
}
