import React from 'react';

import * as S from './styles';
import { useIntl } from 'react-intl';

export function InvalidToken() {

  const intl = useIntl()

  return (
    <S.Wrapper>
      <S.Icon className='show-in'>
        <img src='/media/svg/avatars/time.svg' alt='Time icon' width={100} />

        <div className='background' />
      </S.Icon>

      <div className='content-container'>
        <h1 className='title show-in' style={{animationDelay: '.1s'}}>
          {intl.formatMessage({id: 'VISITOR.REMOTE_REGISTRATION.EXPIRED_LINK'})}
        </h1>
        <p className='sub-title show-in' style={{animationDelay: '.15s'}}>
          {intl.formatMessage({id: "VISITOR.REMOTE_REGISTRATION.EXPIRED_LINK_SUB_TEXT"})}
        </p>
      </div>
    </S.Wrapper>
  );
}
