import format from 'date-fns/format'

const nameDate = "@config@default@date"
const nameTime = "@config@default@time"


class DefaultDateTime {
  default_configs = {
    date: 'dd/MM/yyyy',
    time: 'HH:mm'
  }

  setDefault({ date, time }: {
    date: string,
    time: string
  }) {

    if (!date || !time) return

    localStorage.setItem(`${nameDate}`, date)
    localStorage.setItem(`${nameTime}`, time)

  }

  getDefault(iscomponent = false) {
    let date = localStorage.getItem(`${nameDate}`)!
    const time = localStorage.getItem(`${nameTime}`)!



    if (iscomponent) {
      date = date!.replaceAll("Y", "y")
    }

    return {
      time: time,
      date: date
    }
  }

  labelDateTime(value: Date | string) {

    try {
      if (!value) return ''
      const labels = format(new Date(value), `${this.getDefault().date} ${this.getDefault().time}`)
      return labels
    } catch (e) {
      console.log({ e })
      return value
    }
  }

  onlyTime(datetime: string) {
    const result = datetime.split(' ')

    if (result.length === 3) {
      return `${result[1]} ${result[2]}`
    }

    return `${result[1]}`
  }

  onlyDate(datetime: string) {
    const [date, _] = datetime.split(' ')

    return date
  }

  labelOnlyTime(value: any) {
    try {
      const result = this.labelDateTime(new Date(value * 1000))
      const time = this.onlyTime(result as string)

      return time
    } catch {
      return value
    }
  }

  labelStringToTimePermission(value: string) {
    try {

      const result = value.split(' - ').map(time => {
        const [hour, minute] = time.split(":")

        const timeHour = new Date()
          .setHours(+hour)

        const timeComplete = new Date(timeHour).setMinutes(+minute)
        const datetime = this.labelDateTime(new Date(timeComplete))

        return this.onlyTime(datetime as string)

      }).join(" - ")

      return result
    } catch {

      return value
    }
  }

  modificateTime(time: number) {
    switch (time) {

      case 13: {
        return '1'
      }

      case 14: {
        return '2'
      }

      case 15: {
        return '3'
      }

      case 16: {
        return '4'
      }

      case 17: {
        return '5'
      }

      case 18: {
        return '6'
      }

      case 19: {
        return '7'
      }

      case 20: {
        return '8'
      }
      case 21: {
        return '9'
      }
      case 22: {
        return '10'
      }
      case 23: {
        return '11'
      }
      case 24: {
        return '00'
      }

      default: {
        return time.toString()
      }
    }
  }

  modifyText(value: string) {
    const [timeDefault, minutesDefault] = this.getDefault().time.split(':')

    const [time, minutes] = value.split(':')

    let timeTranform = ''
    let MinutesTranform = minutes
    let info = ''

    if (timeDefault.includes('hh') || timeDefault.includes('h')) {
      if (+time > 12) {
        info = ' PM'
      } else {
        info = " AM"
      }

      timeTranform = this.modificateTime(+time)
    } else {
      timeTranform = time
    }

    if (timeDefault === ('hh')) {
      timeTranform = timeTranform.padStart(2, '0')
    }


    return `${timeTranform}:${MinutesTranform}${info}`
  }

  maskToComponentDate() {
    const valuesDefault = this.getDefault()


    const divisorDate = valuesDefault.date.includes('/') ? valuesDefault.date.split('/') : valuesDefault.date.split('-')

    const modelMaskArray = divisorDate.map(item => {
      const values = item.split('')
      const model = values.map(char => {
        return /[0-9]/
      })

      return model
    })



    let modelDate: any = []

    for (let maskList of modelMaskArray) {
      for (let mask of maskList) {
        modelDate.push(mask)
      }

      if (valuesDefault.date.includes('/')) {
        modelDate.push('/')
      } else {
        modelDate.push('-')
      }
    }


    return modelDate.filter((_: string, index: number) => index !== modelDate.length - 1)
  }

  maskToComponentDateNew() {
    const valuesDefault = this.getDefault()

    const divisorDate = valuesDefault.date.includes('/') ? valuesDefault.date.split('/') : valuesDefault.date.split('-')

    const modelMaskArray = divisorDate.map(item => {
      if (item.match(/[d|m]/gi)) {
        return [/[0-9]/, /[0-9]/]
      }

      return item.length === 4 ? [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/] : [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
    })

    let modelDate: any = []

    for (let maskList of modelMaskArray) {
      for (let mask of maskList) {
        modelDate.push(mask)
      }

      if (valuesDefault.date.includes('/')) {
        modelDate.push('/')
      } else {
        modelDate.push('-')
      }
    }

    return modelDate.filter((_: string, index: number) => index !== modelDate.length - 1)
  }


  maskToTimeDate() {
    const valuesDefault = this.getDefault()

    const divisorDate = valuesDefault.time.replace(' ', '').replace('a', '').split(':')



    const modelMaskArray = divisorDate.map(item => {
      const values = item.split('')
      const model = values.map(char => {
        return /[0-9]/
      })

      return model
    })


    let modelDate: any = []

    for (let maskList of modelMaskArray) {
      for (let mask of maskList) {
        modelDate.push(mask)
      }

      modelDate.push(':')

    }

    return modelDate.filter((_: string, index: number) => index !== modelDate.length - 1)



  }

  maskToTimeDateNew() {

    return [/[0-9]/, /[0-9]/, ':', /[0-9]/, /[0-9]/]

  }

  prepare_mask_component_date() {


    return {
      date: this.maskToComponentDateNew(),
      time: this.maskToTimeDateNew()
    }
  }

  __init__config__app() {
    const date = localStorage.getItem(`${nameDate}`)
    const time = localStorage.getItem(`${nameTime}`)


    if (!date || !time) {
      this.setDefault(this.default_configs)
    }


  }

}


export const defaultDateTime = new DefaultDateTime()