import styled from 'styled-components';

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* position: absolute; */
  padding: 10px;

  margin-bottom: 10px;
  margin-top: 10px;



  @keyframes Rotatew {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  svg {
    animation: Rotatew 1.2s linear infinite;
  }

  span{
    display: block;
    margin-top: 6px;
    margin-bottom: 10px;
    font-weight: 400;
    font-size:1.25rem;
  }
`;
