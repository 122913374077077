import axios from 'axios';
const API_URL = process.env.REACT_APP_IDSECURE_API_URL;


export interface I_validPhoto{
  paramterUrl: 'visitors' | 'persons',
  base64:string
}

export function validPhoto({paramterUrl,base64}:I_validPhoto){
  const url = `${API_URL}/${paramterUrl}/validatePhoto`

  return axios.post(url, {
    photo:base64
  })
}