import React, {useState} from 'react';

export function useFilters() {
  const [permisionStatus, setPermisionStatus] = useState<number>(1);
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const [sizePerPage, setSizePerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  return {
    permission: {
      permisionStatus,
      setPermisionStatus
    },
    order: {
      sortOrder,
      setSortOrder
    },
    page: {
      sizePerPage,
      setSizePerPage,
      page,
      setPage
    }
  };
}
