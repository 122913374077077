import axios from 'axios'

class WhiteLabel {
  idsecureAPI = process.env.REACT_APP_IDSECURE_API_URL

  async getImageClient() {
    return axios.get(`${this.idsecureAPI}/operators/whitelabel`)
  }
}

export const whiteLabelService = new WhiteLabel()