import React from 'react';

import {RadioInput} from 'src/app/modules/vehicles/pages/CreateVehiclePage/components/Input';
import {useIntl} from 'react-intl';
import { useTableHooks } from '../../hooks/table';

type ModelSegregationFormProps = {
  modelSegregation: number;
  onChange(limitation: number): void;
};

export function ModelSegregationForm({modelSegregation, onChange}: ModelSegregationFormProps) {
  const intl = useIntl();
  const {INTL, columns} = useTableHooks();
  const qwerStorage: any = localStorage.getItem('QWER');
  const hasLocalStorage = qwerStorage ? JSON.parse(qwerStorage) : null;
  const isAdmin = hasLocalStorage?.qwer;

  const rowValueStorage = localStorage.getItem('@CONTROLID@_OPERATION_RULE_EDIT');
  const hasRowValue = rowValueStorage ? JSON.parse(rowValueStorage) : null;
  const isAdminOperatorRole = hasRowValue?.isAdminOperatorRole;

  return (
    <div
      style={{
        display: 'flex',
        gap: '30px',
        marginTop: '10px'
      }}>
      <div>
        <label>{INTL("ACCESS_PROFILE.LIMITATIONS")}</label>
        <div style={{display: 'flex', flexDirection: 'column', gap: 5, paddingLeft: 20}}>
          <RadioInput
            name='webmaster'
            label={INTL("ACCESS_PROFILE.ACCESS_TO_ALL_DATA")}
            id='no-limit'
            value='1'
            disabled={isAdminOperatorRole}
            checked={[0, 1].includes(modelSegregation)}
            onChange={() => onChange(1)}
          />
          <RadioInput
            name='webmaster'
            id='limit-group-company'
            value='2'
            disabled={isAdminOperatorRole}
            checked={modelSegregation === 2}
            onChange={() => onChange(2)}
            label={INTL("ACCESS_PROFILE.LIMIT_SCOPE_TO_USER_GROUPS_AND_COMPANIES")}
          />
          <RadioInput
            name='webmaster'
            id='limit-company'
            checked={modelSegregation === 4}
            value='4'
            disabled={isAdminOperatorRole}
            onChange={() => onChange(4)}
            label={INTL("ACCESS_PROFILE.LIMIT_SCOPE_TO_USER_COMPANIES")}
          />
          <RadioInput
            name='webmaster'
            id='limit-group-company-detail'
            checked={modelSegregation === 3}
            value='3'
            disabled={isAdminOperatorRole}
            onChange={() => onChange(3)}
            label={INTL("ACCESS_PROFILE.LIMIT_SCOPE_TO_SPECIFIC_GROUPS_AND_COMPANIES")}
          />
        </div>
      </div>
    </div>
  );
}
