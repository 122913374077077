interface configProps {
  value: number, 
  label:string
}

const NAME_CONFIG = "@CONTROLID/MONITORAMENTO"
const NAME_CONFIG_TYPES = "@CONTROLID/MONITORAMENTO-types"
const NAME_CONFIG_DEVICES = "@CONTROLID/MONITORAMENTO-devices"

export function saveConfig(data:configProps[]){

  

  const dataToString = JSON.stringify(data)

  localStorage.setItem(NAME_CONFIG ,dataToString)
}


export function loadConfig(){
  const data = localStorage.getItem(NAME_CONFIG)

  if(data){
    return JSON.parse(data)
  }

  return []
}

export function saveConfigTypes(data:configProps[]){

  

  const dataToString = JSON.stringify(data)

  localStorage.setItem(NAME_CONFIG_TYPES ,dataToString)
}

export function loadConfigTypes(){
  const data = localStorage.getItem(NAME_CONFIG_TYPES)

  if(data){
    return JSON.parse(data)
  }

  return []
}

export function saveConfigDevices(data:configProps[]){

  

  const dataToString = JSON.stringify(data)

  localStorage.setItem(NAME_CONFIG_DEVICES ,dataToString)
}


export function loadConfigDevices(){
  const data = localStorage.getItem(NAME_CONFIG_DEVICES)

  if(data){
    return JSON.parse(data)
  }

  return []
}
