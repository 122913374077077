import React, {useRef, useState, useEffect} from 'react';
import {Avatar, makeStyles, Grid, Button} from '@material-ui/core';
import ImageUploader from 'react-images-upload';
import Webcam from 'react-webcam';
import {useIntl} from 'react-intl';
// import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import SwitchCameraIcon from '@material-ui/icons/SwitchCamera';
import Delete from '@material-ui/icons/Delete';
import Resizer from 'react-image-file-resizer';
import {FaCheck} from 'react-icons/fa';

import {execToast} from './Toast';
import {validPhoto, I_validPhoto} from 'src/services/validatePhoto';

const videoConstraints = {
  width: 180,
  height: 180,
  facingMode: 'user'
};

const ONE_MB_IN_BYTES = 1000000;

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1)
  },
  bigAvatar: {
    width: 180,
    height: 180
  }
}));

export const AvatarWebcamComponent = ({setImagem, image}) => {
  const classes = useStyles();
  const intl = useIntl();
  const uploadRef = useRef(null);
  const webcamRef = React.useRef(null);
  const webcamRefFake = React.useRef(null);
  const [webCamEnable, setWebcam] = useState(false);
  const [webCamModalEnable, setWebCamModalEnable] = useState(false);
  const [erroWebCam, setErroWebCam] = useState(false);
  const [devices, setDevices] = React.useState([]);
  const [noDevices, setNoDevices] = React.useState(false);
  const [currentDeviceId, setCurrentDeviceId] = React.useState('');

  const msgErrorImage = intl.formatMessage({id:'IMAGE.ERROR.COMPONENT'})

  const msgOkImage = intl.formatMessage({id:'IMAGE.OK.COMPONENT'})

  useEffect(() => {
    const localStorageDeviceId = localStorage.getItem('@webcam-current-device-id');
    if (localStorageDeviceId) {
      setCurrentDeviceId(localStorageDeviceId);
    }
  }, []);

  const handleDevices = React.useCallback(
    (mediaDevices) => {
      if (mediaDevices.filter(({kind}) => kind === 'videoinput').length <= 0) {
        setNoDevices(true);
      }
      setDevices(mediaDevices.filter(({kind}) => kind === 'videoinput'));
    },
    [setDevices]
  );

  const onlyAveilableDevices = (device) => {
    const devicesCopy = [...devices];
    const aveilableDevices = devicesCopy.filter((item) => item.deviceId !== device.deviceId);

    if (aveilableDevices.length === 1) {
      setCurrentDeviceId(aveilableDevices[0].deviceId);
      setWebCamModalEnable(false);
      setWebcam(true);
    }
    setDevices(aveilableDevices);
  };

  const handleSelectedDevice = (deviceId) => {
    setCurrentDeviceId(deviceId);
    setWebcam(true);
    setWebCamModalEnable(false);
    localStorage.setItem('@webcam-current-device-id', deviceId);
  };

  useEffect(() => {
    try {
      navigator.mediaDevices.enumerateDevices().then((data) => {
        handleDevices(data);
      });
    } catch(err) {
      setDevices([])
    }

  }, [handleDevices]);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRefFake.current.getScreenshot();

    setImagem(imageSrc);
  }, [webcamRef, webcamRefFake]);

  async function onDrop(files, urlFiles) {
    const file = files?.[0];
    let urlFile = urlFiles?.[0];
    if (!file || !urlFile) return;

    if (file.size > ONE_MB_IN_BYTES / 2) {
      urlFile = await resizeFile(file);
    }

    setImagem(urlFile);
  }

  //@ts-ignore
  async function execValidation({base64, paramterUrl}) {
    try {
      const {data} = await validPhoto({
        base64,
        paramterUrl
      });

     

      if (data.data.success) {
        execToast('success', `${msgOkImage}`, 3000);
      } else {
        const errors = data.data.errors.reduce((acc, el) => {
          acc += `${el.message} \n`;
          return acc;
        }, '');

        execToast('error', `${msgErrorImage} \n ${errors}`, 500000);
      }
    } catch (err) {
      console.dir(err);
    }
  }

  function isImageValid(image) {
    const location = window.location.href;

    if (location.includes('people')) {
      execValidation({
        base64: image,
        paramterUrl: 'persons'
      });

      return;
    }

    if (location.includes('visitors')) {
      execValidation({
        base64: image,
        paramterUrl: 'persons'
      });
      return;
    }
  }

  return (
    <Grid container justify='center' alignItems='center' style={{overflow: 'hidden', position: 'relative'}}>
      {webCamModalEnable ? (
        <div
          style={{
            width: '100%',
            border: 'solid 1px #cacaca40',
            borderRadius: '3px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '6px',
            paddingTop: '10px',
            paddingBottom: '10px',
            marginBottom: '20px'
          }}>
          <span>{intl.formatMessage({id: 'WEBCAM_SELECT'})}</span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '6px',
              padding: '6px'
            }}>
            {devices?.map((device, key) => (
              <div
                key={device.deviceId}
                onClick={(e) => {
                  e.preventDefault();
                  handleSelectedDevice(device.deviceId);
                }}
                style={{
                  width: 'min-content',
                  height: 'min-content',
                  padding: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                  borderRadius: '3px',
                  boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
                  cursor: 'pointer'
                }}>
                <Webcam
                  ref={webcamRef}
                  mirrored={true}
                  audio={false}
                  height={95}
                  onUserMediaError={() => onlyAveilableDevices(device)}
                  screenshotFormat='image/jpeg'
                  width={95}
                  videoConstraints={{deviceId: device.deviceId}}></Webcam>
                {device.label || `Device ${key + 1}`}
              </div>
            ))}
          </div>
        </div>
      ) : null}
      
      <div className='column' style={{overflow: 'hidden', position: 'relative'}}>
        {!webCamEnable && (
          <Avatar
            id='avatar-img'
            src={image}
            name='avatar'
            className={classes.bigAvatar}
            style={{marginLeft: '72px'}}
          />
        )}
        {webCamEnable && (
          <Webcam
            ref={webcamRef}
            mirrored={true}
            audio={false}
            height={280}
            onUserMediaError={() => {
              //alert('erro')
              setWebCamModalEnable(true);
            }}
            screenshotFormat='image/jpeg'
            width={220}
            videoConstraints={{deviceId: currentDeviceId}}></Webcam>
        )}

        {webCamEnable && (
          <div
            style={{
              position: 'absolute',
              visibility: 'hidden'
            }}>
            <Webcam
              ref={webcamRefFake}
              mirrored={true}
              audio={false}
              height={720}
              screenshotFormat='image/jpeg'
              width={1280}
              videoConstraints={{deviceId: currentDeviceId}}></Webcam>
          </div>
        )}

        <ImageUploader
          ref={uploadRef}
          accept='image/jpg, image/jpeg, image/jfif, image/pjpeg, image/pjp, image/png'
          label={intl.formatMessage({id: 'AREA.AREA_IMAGE_LABEL'})}
          singleImage={true}
          name={'inp-upload-area'}
          withPreview={false}
          withLabel={false}
          withIcon={false}
          buttonText={intl.formatMessage({id: 'AREA.AREA_IMAGE'})}
          onChange={onDrop}
          imgExtension={['.jpg', '.jpeg', '.jfif', '.pjpeg', '.pjp', '.png']}
          className='none d-none'
          buttonClassName='none d-none'
          // maxFileSize={2064880}
        />

        <div className={`mt-4 ${!webCamEnable ? 'd-flex justify-content-between' : 'col align-self-center'}`}>
          {!webCamEnable && (
            <Button
              id='escolherFoto'
              className={classes.button}
              variant='outlined'
              color='secondary'
              onClick={(e) => {
                e.preventDefault();
                uploadRef.current.triggerFileUpload();
              }}>
              <CloudUploadIcon />
            </Button>
          )}

          <div className={webCamEnable ? 'd-flex justify-content-center' : classes.button}>
            <Button
              disabled={noDevices}
              variant='outlined'
              color='primary'
              onClick={(e) => {
                e.preventDefault();
                if (webCamEnable) capture();
                setWebcam(!webCamEnable);
              }}>
              <PhotoCameraIcon />
            </Button>
            {webCamEnable ? (
              <Button
                style={{marginLeft: '8px'}}
                disabled={devices.length < 2}
                variant='outlined'
                color='secondary'
                onClick={(e) => {
                  e.preventDefault();
                  setWebCamModalEnable(true);
                  setWebcam(false);
                }}>
                <SwitchCameraIcon />
              </Button>
            ) : null}
          </div>

          {!webCamEnable && (
            <>
              <div className={webCamEnable ? 'd-flex justify-content-center' : classes.button}>
                <Button
                  id='ola'
                  disabled={image ? false : true}
                  variant='outlined'
                  style={{
                    // borderColor: '#f018a6'
                    borderColor: '#3783e7',
                    height: '30px',
                    opacity: image ? 1 : 0.2
                  }}
                  onClick={(e) => {
                    isImageValid(image);
                  }}>
                  <FaCheck color='#3783e7' />
                </Button>
              </div>

              <div className={webCamEnable ? 'd-flex justify-content-center' : classes.button}>
                <Button
                  disabled={image ? false : true}
                  variant='outlined'
                  style={{
                    // borderColor: '#f018a6'
                    borderColor: '#F64E60',
                    opacity: image ? 1 : 0.2
                  }}
                  onClick={(e) => {
                    setImagem('');
                    // e.preventDefault();
                    // if (webCamEnable) capture();
                    // setWebcam(!webCamEnable);
                  }}>
                  <Delete style={{color: '#F64E60'}} />
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </Grid>
  );
};

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      400,
      400,
      'png',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64'
    );
  });
