import styled from 'styled-components';

export const MonitoringContainer = styled.div`
  position: relative;

  .loader-control {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translate(-50%, -00%);
  }

  h6 {
    margin-left: 8px;
  }


  .containerTableAndBox{
    flex: 1;
    transition: all 0.5s linear;
  }
`;

interface isVisible {
  isVisible: boolean;
}

export const MonitorResume = styled.div<isVisible>`
  border: 1px solid #dedede;
  /* width: 530px; */
  /* width: 730px; */
  padding: 10px;
  border-radius: 4px;

  display: flex;
  gap: 10px;
  align-items: flex-start;
  opacity: ${({isVisible}) => (isVisible ? 1 : 0)};

  & > img {
    margin-left: 5px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    margin-top: 5px;
    &.green {
      border: 1px solid green;
    }

    &.red {
      border: 1px solid red;
    }
  }

  & > div {
    header {
      display: flex;
      flex-direction: column;

      strong {
        font-size: 18px;
      }
    }

    main {
      margin-top: 10px;
      display: flex;
      gap: 7px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #ccc;
      }

      div {
        display: flex;
        flex-direction: column;
      }
    }

    .status {
      margin-top: 4px;

      &.green {
        color: green;
      }

      &.red {
        color: red;
      }
    }
  }

  .infopeople{
    margin-top: 5px;
    width: 200px;
  }

  .actions {
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
  

    align-items: center;
    button {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .containerDevice{
    /* padding: 10px 20px; */
    /* margin-left: 92px; */
    display: flex;
    align-items: center;
    gap:5px;

    div{
      display: flex;
      display: flex;
      flex-direction: column;
      align-items: center;
      
    }

    img{
        width: 60px;
        height: 60px;
        border-radius: 5px;
      }

    button {
      display: flex;
      align-items: center;
      gap: 5px;
      height: 30px;
      white-space: nowrap;
    }
  }
`;

interface MonitorFilterProps {
  isVisibility: boolean;
}
export const MonitorFilter = styled.div<MonitorFilterProps>`
  display: ${({isVisibility}) => (isVisibility ? 'flex' : 'none')};
  margin-bottom: 10px;

  .filter {
    width: 30%;
    margin-left: 10px;
  }

  .hide {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    margin-left: 20px;
  }
`;
