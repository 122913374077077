interface IShow {
  show: boolean;
  children: JSX.Element;
}

export function Show(props: IShow) {
  if (!props.show) return null;

  return props.children;
}
