import React from 'react';

import * as S from './styles';

export function LicenseCardSkeleton() {
  return (
    <S.Wrapper type='skeleton'>
      <div>
        <div className='bar' style={{maxWidth: 170, marginBottom: 5}} />
        <div className='bar' style={{maxWidth: 100}} />
      </div>

      <div className='bar' style={{borderRadius: '50%', maxWidth: 150, height: 150}} />

      <div className='bar' style={{maxWidth: 100}} />
    </S.Wrapper>
  );
}
