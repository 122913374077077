
import React from 'react'

import {ParkingProvider} from '../Context/ParkingContext'
import {List} from './list'

export function ParkingList(){
  
  return (
    <ParkingProvider>
      <List />
    </ParkingProvider>
  )
}