import { title } from 'process';
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { ToggleSwitch } from 'src/_metronic/_partials/controls/forms/ToggleSwitch';
import { bulkService } from 'src/services/bulk';

interface Props {
  titles: {
    headerInative: string,
    headerDelete: string,
    bodyInative: string,
    bodyDelete: string,
    toggle: string,
  },
  buttons: {
    cancel: string,
    delete: string,
    inative: string
  },
  toastMessage: {
    success: string,
    error: string
  }
  isVisibleModal: boolean,
  closeModal: () => void,
  data: {
    idsToTemoved: number[],
    reload: () => void
    resetIds: () => void,
    save: (ids: number[], isDeleteActived: boolean) => Promise<void>
  }
}
export function BulkModal({ titles, isVisibleModal, closeModal, buttons, data, toastMessage, }: Props) {

  const [isDeleteActived, setIsDeleteActived] = useState(false)

  return (
    <Modal show={isVisibleModal} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>
          {isDeleteActived ? titles.headerDelete : titles.headerInative}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isDeleteActived ? titles.bodyDelete : titles.bodyInative}
        <br />
        <div style={{ marginTop: '15px', }}>
          <span style={{ display: 'block' }}>{titles.toggle}</span>
          <ToggleSwitch
            style={{ marginTop: '7px', }}
            checked={isDeleteActived}
            onChange={(e) => {
              setIsDeleteActived(e.target.checked);
            }}
          />

        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant='secondary'
          onClick={() => {
            closeModal();
          }}>
          {buttons.cancel}
        </Button>
        <Button
          variant='danger'
          // disabled={isRead}
          onClick={async () => {

            // setIsModalRemoveAllItensSelectedOpen(false);
            try {

              await data.save(data.idsToTemoved, isDeleteActived);

              execToast('success', toastMessage.success, 3000);

              data.resetIds()
              data.reload()
              closeModal()
            } catch (err) {
              execToast('error', toastMessage.error, 3000);
            }
          }}>

          {isDeleteActived ? buttons.delete : buttons.inative}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}