import Delete from '@material-ui/icons/Delete';
import React, { useEffect, useRef, useState } from 'react';

import Draggable from 'react-draggable';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { MdAddCircle } from 'react-icons/md';
import { Button } from '@material-ui/core';

import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls';
import { useIntl } from 'react-intl';
// import './styles.css';
import { PainelHooks } from './hooks';
import { Hidden, InputContainer, SelectImage, ContainerImg } from './styles';
import { usePermisssion } from 'src/redux/userPermissions/usePermission';

export function BluePrintCreate() {
  const intl = useIntl();
  //hooks
  const { history, setExt, devices, inputRef, TABLEDEVICES, plantImage, setPlantImage, points, handleUpdatePosition, removePoint, handleSave } = PainelHooks();

  const uploadImage = useRef<HTMLInputElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  function loadImageInput({ target }: React.ChangeEvent<HTMLInputElement>) {
    // console.dir(target);
    // alert('Chamou');
    if (target && target.files && target.files.length > 0) {
      const file = new FileReader();

      file.onload = function (e) {
        //@ts-ignore
        setExt(target.files[0].type);
        setPlantImage(String(e.target?.result));
      };

      file.readAsDataURL(target.files[0]);
    }
  }

  const { moduleOnly } = usePermisssion();
  const [isRead, setIsRead] = useState(false);

  useEffect(() => {
    const data = moduleOnly(2);

    if (data.isRedirect) {
      history.push('/dashboard');
    }

    setIsRead(data.isLevel);
  }, []);

  return (
    <Card fluidHeight={null} className={null}>
      <div style={{
        position: 'relative'
      }}>
        <CardHeader icon={null} toolbar={null} className={null} labelRef={null} sticky={false} title={intl.formatMessage({ id: 'PAGE.BLUEPRINT.TITLE.CREATE' })} />

        <button style={{
          position: 'absolute',
          right: '10px',
          marginTop: '20px',
        }}
          className='btn btn-light'
          onClick={() => {
            history.push('/blueprint');
          }}>
          {intl.formatMessage({ id: 'AUTH.GENERAL.BACK_BUTTON' })}</button>


      </div>
      <CardBody fit={null} fluid={null} className=''>
        <Hidden>
          <input
            ref={uploadImage}
            type='file'
            accept='image/jpg, image/jpeg, image/png'
            onChange={loadImageInput}
            onClick={(e) => {
              console.dir(e);
            }}
          />
        </Hidden>
        {plantImage === '' ? (
          <SelectImage>
            <div>
              <p>{intl.formatMessage({ id: 'PAGE.BLUEPRINT.NOIMAGE' })}</p>
              <Button
                style={{
                  padding: '6px'
                }}
                id='escolherFoto'
                variant='outlined'
                color='secondary'
                onClick={(e) => {
                  uploadImage.current?.click();
                }}>
                <CloudUploadIcon />
              </Button>
            </div>
          </SelectImage>
        ) : (
          <>
            <div id=''>
              <InputContainer>
                <input className='form-control' type='text' accept='image/png,image/jpeg' placeholder={intl.formatMessage({ id: 'PAGE.BLUEPRINT.LIST_COLUMN_DESCRIPTION' })} ref={inputRef} />

                <div style={{ display: 'flex', gap: '0.8rem', justifyContent: 'flex-end' }}>
                  <button
                    className='btn btn-light'
                    onClick={() => {
                      history.push('/blueprint');
                    }}>
                    {intl.formatMessage({ id: 'PAGE.BLUEPRINT.BTN.Cancel' })}
                  </button>
                  <button
                    disabled={isRead}
                    className='btn btn-primary btn-elevate'
                    onClick={() => {
                      if (isRead) return;
                      handleSave();
                    }}>
                    {intl.formatMessage({ id: 'PAGE.BLUEPRINT.BTN.SAVE' })}
                  </button>
                </div>
              </InputContainer>

              <ContainerImg>
                <section>
                  <img src={plantImage} alt='' />
                  <span />
                  {points.map((point) => (
                    <Draggable
                      key={point.id}
                      bounds='parent'
                      enableUserSelectHack
                      onStop={(e, data) => {
                        // e.target.stop

                        handleUpdatePosition(point.id, data.x, data.y);
                      }}
                      defaultPosition={{
                        x: point.x,
                        y: point.y
                      }}>
                      <div
                        onMouseEnter={() => {
                          //@ts-ignore
                          const btnClose = document.querySelector(`[data-id="P-delete-${point.id}"]`);
                          if (!btnClose) return;
                          //@ts-ignore
                          btnClose.style.display = 'block';
                        }}
                        onMouseLeave={() => {
                          //@ts-ignore
                          const btnClose = document.querySelector(`[data-id="P-delete-${point.id}"]`);
                          if (!btnClose) return;
                          //@ts-ignore
                          btnClose.style.display = 'none';
                        }}
                        title={point.name}
                        style={{
                          width: '42px',
                          height: '42px',
                          borderRadius: '50%',
                          position: 'relative',
                          border: '1px solid #000',
                          visibility: !point.actived ? 'hidden' : 'initial'
                        }}>
                        <img
                          src={point.img}
                          alt=''
                          style={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '50%',
                            objectFit: 'cover'
                          }}
                        />

                        <div
                          style={{
                            width: '40px',
                            height: '40px',
                            position: 'absolute',
                            top: 0,
                            cursor: 'grabbing'
                          }}
                        />

                        <button
                          data-id={`P-delete-${point.id}`}
                          onClick={() => {
                            removePoint(point.id);
                          }}
                          type='button'
                          style={{
                            display: 'none',
                            background: 'none',
                            border: 'none',
                            top: '-11px',
                            right: '-20px',
                            position: 'absolute',
                            padding: '2px'
                          }}>
                          <Delete style={{ color: '#F64E60' }} />
                        </button>
                      </div>
                    </Draggable>
                  ))}
                </section>

                <section>
                  <p>{intl.formatMessage({ id: 'PAGE.BLUEPRINT.DEVICE.LIST.TITLE' })}</p>

                  <ul>
                    <li className='fixedd'>
                      <div>
                        <img src='' alt='' />
                      </div>
                      <div className='name'>{intl.formatMessage({ id: 'PAGE.BLUEPRINT.TABLEDEVICES.COL.NAME' })}</div>
                      <div className='model'>{intl.formatMessage({ id: 'PAGE.BLUEPRINT.TABLEDEVICES.COL.MODEL' })}</div>
                      <div className='output'>{intl.formatMessage({ id: 'PAGE.BLUEPRINT.TABLEDEVICES.COL.MUMBER' })}</div>
                      <div className='area'>{intl.formatMessage({ id: 'PAGE.BLUEPRINT.TABLEDEVICES.COL.AREA' })}</div>
                      <div className='action'>
                        <button title={intl.formatMessage({ id: 'PAGE.BLUEPRINT.ADD_DEVICE_TO_PLANT' })} disabled={!plantImage} style={{ cursor: plantImage ? 'pointer' : 'initial' }} className='btn btn-primary'>
                          <MdAddCircle size={16} />
                        </button>
                      </div>
                    </li>

                    <div className='divisor'>{TABLEDEVICES(devices)}</div>
                  </ul>
                </section>
              </ContainerImg>

              {/* <div className='CONTAINER_PAGE'>
                <div className='IMG' style={{border: plantImage ? '1px solid #ccc' : ''}}>
                  {plantImage === '' && <span className='helper'> {intl.formatMessage({id: 'PAGE.BLUEPRINT.NOIMAGE'})}</span>}
                  {plantImage !== '' && <img src={plantImage} alt='' />}
                  {plantImage !== '' && <span />}
                  {plantImage !== '' &&
                    points.map((point) => (
                      <Draggable
                        key={point.id}
                        bounds='parent'
                        enableUserSelectHack
                        onStop={(e, data) => {
                          // e.target.stop
                        
                          handleUpdatePosition(point.id, data.x, data.y);
                        }}
                        defaultPosition={{
                          x: point.x,
                          y: point.y
                        }}>
                        <div
                          onMouseEnter={() => {
                            //@ts-ignore
                            const btnClose = document.querySelector(`[data-id="P-delete-${point.id}"]`);
                            if (!btnClose) return;
                            //@ts-ignore
                            btnClose.style.display = 'block';
                          }}
                          onMouseLeave={() => {
                            //@ts-ignore
                            const btnClose = document.querySelector(`[data-id="P-delete-${point.id}"]`);
                            if (!btnClose) return;
                            //@ts-ignore
                            btnClose.style.display = 'none';
                          }}
                          title={point.name}
                          style={{
                            width: '42px',
                            height: '42px',
                            borderRadius: '50%',
                            position: 'relative',
                            border: '1px solid #000',
                            visibility: !point.actived ? 'hidden' : 'initial'
                          }}>
                          <img
                            src={point.img}
                            alt=''
                            style={{
                              width: '40px',
                              height: '40px',
                              borderRadius: '50%',
                              objectFit: 'cover'
                            }}
                          />

                          <div
                            style={{
                              width: '40px',
                              height: '40px',
                              position: 'absolute',
                              top: 0,
                              cursor: 'grabbing'
                            }}
                          />

                          <button
                            data-id={`P-delete-${point.id}`}
                            onClick={() => {
                              removePoint(point.id);
                            }}
                            type='button'
                            style={{
                              display: 'none',
                              background: 'none',
                              border: 'none',
                              top: '-11px',
                              right: '-20px',
                              position: 'absolute',
                              padding: '2px'
                            }}>
                            <Delete style={{color: '#F64E60'}} />
                          </button>
                        </div>
                      </Draggable>
                    ))}
                </div>
                <div className='DEVICES_CONTAINER'>
                  {TABLEDEVICES(devices)}
                  <div className='NODATA'></div>
                </div>
              </div> */}
            </div>
            <canvas ref={canvasRef}></canvas>
          </>
        )}
      </CardBody>
    </Card>
  );
}
