import React, { CSSProperties } from 'react';
import InputMask from 'react-input-mask';
import { Show } from 'src/app/components/Show';
import styled from 'styled-components';

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  label?: string;
  mask?: string;
  labelStyle?: CSSProperties;
}

interface IRadioInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  error?: string;
  label?: string | React.ReactNode;
}

export function Input({ error, label, mask, labelStyle, ...rest }: IInputProps) {
  const shouldUseMask = !!mask && rest.type === 'text';



  return (
    <div className={rest?.className} style={rest?.style}>
      <Show show={!!label}>
        <label htmlFor={rest.id} style={labelStyle}>
          {label}
        </label>
      </Show>

      <Show show={shouldUseMask}>
        <InputMask
          {...rest}
          className={`form-control ${error ? 'is-invalid' : ''} ${rest.className}`}
          style={{ height: 38 }}
          mask={mask!}
        />
      </Show>

      <Show show={!shouldUseMask}>
        <input
          {...rest}
          className={`form-control ${error ? 'is-invalid' : ''} ${rest.className}`}
          style={{ height: 38 }}
        />
      </Show>

      <Show show={!!error}>
        <span style={{ color: 'red' }}>{error}</span>
      </Show>
    </div>
  );
}

export const RadioInput = ({ label, error, ...props }: IRadioInputProps) => {
  return (
    <Wrapper htmlFor={props.id} style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <input className='form-control' type='radio' style={{ width: 'fit-content' }} {...props} />
        {typeof label === 'string' ? <span>{label}</span> : label}
      </div>

      <Show show={!!error}>
        <span style={{ color: 'red' }}>{error}</span>
      </Show>
    </Wrapper>
  );
};

const Wrapper = styled.label`
  .form-control {
    height: fit-content !important;
  }
`;
