
import React, { useState } from 'react'

import {ParkingProvider} from '../Context/ParkingContext'
import {Form} from './form'

export function ParkingManager(){


  return (
    <ParkingProvider>
      <Form />
    </ParkingProvider>
  )
}