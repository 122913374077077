import React, { Suspense, lazy } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
import { BuilderPage } from './pages/BuilderPage';
import { MyPage } from './pages/MyPage';
import { DashboardPage } from './pages/DashboardPage';
import { Config } from './modules/Config';
import { SynopticPanel } from './modules/SynopticPanel';
import { BluePrintCreate } from './modules/SynopticPanel/bluePrintCreate';
import { BluePrintEdit } from './modules/SynopticPanel/bluePrintEdit/';
import { BluePrintView } from './modules/SynopticPanel/bluePrintView/';
import { PermissionAccess } from './modules/PermissionAccess';
import { CreatePermission } from './modules/PermissionAccess/create';
import { ListCreditTypesManagemnt } from './modules/CreditTypeManagement/Pages/List';
import { CreateAndEditCreditTypesManagemnt } from './modules/CreditTypeManagement/Pages/Create';
import { ManagementCreditTypesManagemnt } from './modules/CreditTypeManagement/Pages/Management';
import { Monitoring } from './modules/Monitoring';
import { MonitoringVehicle } from './modules/MonitoringVehicle';
import { MonitoringAlarms } from './modules/MonitoringAlarms';

import { Releases } from './modules/Releases';

import { Auditlogs } from './modules/Auditlogs';
import { NotificationList } from './modules/Notifications';
import { ParkingList } from './modules/Parking/List';
import { CreatedAndEditAccessRule } from './modules/AccessRule/CreatedAndEdit';

import { CreditType } from './modules/CreditType';
import { CreditTypeEdit } from './modules/CreditType/edit';
import { CreditTypeCreate } from './modules/CreditType/create';
//@ts-ignore
import { CreateNotification } from './modules/Notifications/Create';
import { ParkingManager } from './modules/Parking/CreateAndEdit';
import { ScheduleInactivationsRoutes } from './modules/scheduled-inactivation';
import { DEVICE_NOTIFICATION_ROUTES } from './modules/devices-notifications/config/route';
import { TemporaryCardsRoutes } from './modules/temporary-cards';
import { TEMPORARY_CARDS } from './modules/temporary-cards/config/route';
import { DevicesNotificationsRoutes } from './modules/devices-notifications';
import { RulesExceptionList } from './modules/RulesException/List';
import { ListPdf } from './modules/Reports/ListPdf';

// const GoogleMaterialPage = lazy(() => import('./modules/GoogleMaterialExamples/GoogleMaterialPage'));
// const ReactBootstrapPage = lazy(() => import('./modules/ReactBootstrapExamples/ReactBootstrapPage'));

//Persons
const PersonsMainPage = lazy(() => import('./modules/Person/pages/persons-page/PersonsMainPage'));
const PersonsNewMainPage = lazy(() => import('./modules/Person/pages/persons-new1/PersonsNewMainPage'));
const EditPersonMainPage = lazy(() => import('./modules/Person/pages/person-edit/EditPersonMainPage'));

//Groups
const GroupsMainPage = lazy(() => import('./modules/Groups/pages/groups-page/GroupsMainPage'));
const GroupsNewMainPage = lazy(() => import('./modules/Groups/pages/groups-new/GroupsNewMainPage'));

const GroupEditMainPage = lazy(() => import('./modules/Groups/pages/groups-edit/GroupEditMainPage'));

//Visitors
const VisitorMainPage = lazy(() => import('./modules/Visitors/pages/visitor-page/VisitorMainPage'));

const VisitorNewMainPage = lazy(() => import('./modules/Visitors/pages/visitor-new/VisitorNewMainPage'));

const VisitorEditMainPage = lazy(() => import('./modules/Visitors/pages/visitor-edit/EditVisitorMainPage'));

//Devices
const DeviceMainPage = lazy(() => import('./modules/Devices/pages/device-page/DeviceMainPage'));

const CreateDevicePage = lazy(() => import('./modules/Devices/page/create-device'));

const EditDevicePage = lazy(() => import('./modules/Devices/page/edit-device'));

//Times
const TimesMainPage = lazy(() => import('./modules/Times/pages/times-page/TimesMainPage'));

const TimesNewMainPage = lazy(() => import('./modules/Times/pages/times-new/TimesNewMainPage'));
const TimeEditMainPage = lazy(() => import('./modules/Times/pages/times-edit/TimeEditMainPage'));

//Area
const AreaMainPage = lazy(() => import('./modules/Area/pages/area-page/AreaMainPage'));

const AreaNewMainPage = lazy(() => import('./modules/Area/pages/area-new/AreaNewMainPage'));
const AreaEditMainPage = lazy(() => import('./modules/Area/pages/area-edit-page/AreaEditMainPage'));

//Companies
const CompaniesMainPage = lazy(() => import('./modules/Companies/pages/groups-page/GroupsMainPage'));
const CompaniesEditMainPage = lazy(() => import('./modules/Companies/pages/groups-edit/GroupEditMainPage'));
const CompaniesNewMainPage = lazy(() => import('./modules/Companies/pages/groups-new/GroupsNewMainPage'));

//AccessRules
const AccessRulesMainPage = lazy(() => import('./modules/AccessRule/pages/accessrules-page/AccessRulesMainPage'));
const AccessRulesNewMainPage = lazy(() => import('./modules/AccessRule/pages/accessrules-new/AccessRulesNewMainPage'));

const AccessRulesMainPageException = lazy(() => import('./modules/RulesException/pages/accessrules-page/AccessRulesMainPage'));
const AccessRulesNewMainPageAccessRulesMainPageException = lazy(() => import('./modules/RulesException/pages/accessrules-new/AccessRulesNewMainPage'));

//VehicleAccessRules
const VehicleAccessRulesMainPage = lazy(() => import('./modules/VehicleAccessRule/pages/vehicleaccessrules-page/VehicleAccessRulesMainPage'));
const VehicleAccessRulesNewMainPage = lazy(() => import('./modules/VehicleAccessRule/pages/vehicleaccessrules-new/VehicleAccessRulesNewMainPage'));
// const AccessRulesEditMainPage = lazy(() => import('./modules/AccessRule/pages/accessrules-edit/AccessRulesEditMainPage'));

//AccessLogs
const AccessLogsMain = lazy(() => import('./modules/Reports/index'));

//AccessLogs
const Registers = lazy(() => import('./modules/RegisterLogs/index'));
const CurrentStatus = lazy(() => import('./modules/CurrentStatus/index'));

const PermissionLogs = lazy(() => import('./modules/PermissionLogs/index'));

const UserProfileMainPage = lazy(() => import('./modules/UserProfile/user-profile-page/UserProfileMainPage'));

//Vehicles
const VehiclesHomePage = lazy(() => import('./modules/vehicles/pages/VehiclesHomePage'));
const CreateVehiclePage = lazy(() => import('./modules/vehicles/pages/CreateVehiclePage'));

//Device Notifications
const DeviceNotificationHome = lazy(() => import('./modules/devices-notifications/pages/home/index'));
const DeviceNotificationsCreatePage = lazy(() => import('./modules/devices-notifications/pages/create/index'));
const DeviceNotificationsEditPage = lazy(() => import('./modules/devices-notifications/pages/edit/index'));

export default function BasePage() {
  // useEffect(() => {

  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from='/' to='/dashboard' />
        }
        <ContentRoute path='/dashboard' component={DashboardPage} children={null} render={null} />
        <ContentRoute path='/builder' component={BuilderPage} children={null} render={null} />
        <ContentRoute path='/my-page' component={MyPage} children={null} render={null} />
        {/* <Route path='/google-material' component={GoogleMaterialPage} />
        <Route path='/react-bootstrap' component={ReactBootstrapPage} /> */}

        {/* Persons */}
        <Route exact path='/people' component={PersonsMainPage} />
        <Route path='/people/newperson' component={PersonsNewMainPage} />
        <Route exact path='/people/:id' component={EditPersonMainPage} />

        {/* Groups */}
        <Route exact path='/groups' component={GroupsMainPage} />
        <Route path='/groups/newgroups' component={GroupsNewMainPage} />
        <Route exact path='/groups/:id' component={GroupEditMainPage} />

        {/* Visitors */}
        <Route exact path='/visitors' component={VisitorMainPage} />
        <Route path='/visitors/newvisitor' component={VisitorNewMainPage} />
        <Route exact path='/visitors/:id' component={VisitorEditMainPage} />

        {/* Devices */}
        <Route exact path='/devices' component={DeviceMainPage} />
        <Route exact path='/devices/create' component={CreateDevicePage} />
        <Route exact path='/devices/edit' component={EditDevicePage} />

        {/* Times */}
        <Route exact path='/times' component={TimesMainPage} />
        <Route path='/times/newtimes' component={TimesNewMainPage} />
        <Route exact path='/times/:id' component={TimeEditMainPage} />

        {/* Area */}
        <Route exact path='/area' component={AreaMainPage} />
        <Route path='/area/newarea' component={AreaNewMainPage} />
        <Route exact path='/area/:id' component={AreaEditMainPage} />

        {/*Companies*/}
        <Route exact path='/companies' component={CompaniesMainPage} />
        <Route path='/companies/newcompany' component={CompaniesNewMainPage} />
        <Route exact path='/companies/:id' component={CompaniesEditMainPage} />

        {/*Access Rules*/}
        <Route exact path='/accessrules' component={AccessRulesMainPage} />
        <Route path='/accessrules/newaccessrule' component={AccessRulesNewMainPage} />
        {/* <Route path='/accessrules/newaccessrule' component={CreatedAndEditAccessRule} /> */}
        <Route exact path='/accessrules/edit/:id' component={AccessRulesNewMainPage} />
        <Route exact path='/releases' component={Releases} />

        {/*Vehicle Access Rules*/}
        <Route exact path='/vehicleaccessrules' component={VehicleAccessRulesMainPage} />

        <Route exact path='/exceptionlistrules' component={AccessRulesMainPageException} />
        <Route path='/exceptionlistrules/newaccessrule' component={AccessRulesNewMainPageAccessRulesMainPageException} />
        <Route exact path='/exceptionlistrules/edit/:id' component={AccessRulesNewMainPageAccessRulesMainPageException} />

        <Route exact path='/vehicleaccessrules' component={VehicleAccessRulesMainPage} />
        <Route path='/vehicleaccessrules/newvehicleaccessrule' component={VehicleAccessRulesNewMainPage} />
        <Route exact path='/vehicleaccessrules/edit/:id' component={VehicleAccessRulesNewMainPage} />
        {/* <Route path='/accessrules/newaccessrule' component={CreatedAndEditAccessRule} /> */}
        {/* <Route exact path='/releases' component={Releases} /> */}

        {/*Access Logs*/}
        <Route
          exact
          path={[
            '/logscenter',
            '/logsvehicleaccess',
            '/logs',
            '/accesslogs/vehicle',
            '/logs/visits',
            '/logs/vehicle/byvehicle',
            '/logs/datetime',
            '/logs/vehicle/datetime',
            '/logs/auth',
            '/logs/vehicle/auth',
            '/logs/people',
            '/logs/vehicle/people',
            '/logs/devices',
            '/logs/vehicle/devices',
            '/logs/areas',
            '/logs/vehicle/parking',
            '/logs/groupsandcompany',
            '/logs/vehicle/groupsandcompany',
            '/logs/blackList',
            '/logs/company-visited',
            '/logs/presence-company-position',
            '/logs/number-access-person',
            '/logs/accesses-with-details-of-credit-usage'
          ]}
          component={AccessLogsMain}
        />
        <Route
          exact
          path={[
            '/registersCenter',
            '/peopleRecords',
            '/visitorsRecords',
            '/peopleRecordsByPeopleGroup',
            '/peopleRecordsByCompanies',
            '/visitorsRecordsByPeopleGroup',
            '/visitorsRecordsByCompanies',
            '/creditbypeople',
            '/expiredDocuments',
            '/alarmHistory',
            '/badlogin',
            '/photosRegister',
            '/blackListRegister'
          ]}
          component={Registers}
        />

        <Route
          exact
          path={[
            '/currentStatus',
            '/locationPeoples',
            '/locationVisitors',
            '/visitorsExpireds',
            '/NumberPeopleVisitorsPresentCompanies',
            '/PeopleVisitorsPresentCompany',
            '/LengthStayCurrentArea',
            '/lengthStayCurrentParking',
            '/lengthStayCurrentVehicleLocal'
          ]}
          component={CurrentStatus}
        />

        <Route
          exact
          path={[
            '/centerlogpermissions',
            '/centerlogpermissions/persons',
            '/centerlogpermissions/companies',
            '/centerlogpermissions/personsgroups',
            '/centerlogpermissions/visitorsgroups',
            '/centerlogpermissions/personstoarea',
            '/centerlogpermissions/companiestoarea'
          ]}
          component={PermissionLogs}
        />

        <Route exact path='/blueprint' component={SynopticPanel} />
        <Route exact path='/blueprint/create' component={BluePrintCreate} />
        <Route exact path='/blueprint/edit/:id' component={BluePrintEdit} />
        <Route exact path='/blueprint/view/:id' component={BluePrintView} />

        {/* <Route exact path='/config' component={Config} /> */}
        <Route path='/user-profile' component={UserProfileMainPage} />

        <Route exact path='/config' component={Config} />
        <Route exact path='/access' component={PermissionAccess} />
        <Route exact path='/access/create' component={CreatePermission} />
        <Route exact path='/access/edit/:id' component={CreatePermission} />
        <Route exact path='/notifications' component={NotificationList} />
        <Route exact path='/notifications/create' component={CreateNotification} />
        <Route exact path='/notifications/edit/:id' component={CreateNotification} />

        <Route exact path='/auditlogs' component={Auditlogs} />
        <Route exact path='/pdfdownload' component={ListPdf} />
        <Route exact path='/creditmanagement' component={ListCreditTypesManagemnt} />
        <Route exact path='/creditmanagement/create' component={CreateAndEditCreditTypesManagemnt} />
        <Route exact path='/creditmanagement/management' component={ManagementCreditTypesManagemnt} />
        <Route exact path='/creditmanagement/edit' component={CreateAndEditCreditTypesManagemnt} />

        {/*Credits*/}
        <Route exact path='/credittype' component={CreditType} />
        <Route exact path='/credittype/create' component={CreditTypeCreate} />
        <Route exact path='/credittype/edit' component={CreditTypeEdit} />

        <Route exact path='/monitoring' component={Monitoring} />
        <Route exact path='/vehiclemonitoring' component={MonitoringVehicle} />
        <Route exact path='/monitoringalarms' component={MonitoringAlarms} />

        {/* Parking */}
        <Route exact path='/parkingregister' component={ParkingList} />
        <Route exact path='/parkingregister/manager' component={ParkingManager} />

        {/*Vehicles*/}
        <Route exact path='/vehicles' component={VehiclesHomePage} />
        <Route exact path='/vehicles/create' component={CreateVehiclePage} />
        <Route exact path='/vehicles/edit/:id' component={CreateVehiclePage} />

        <Route path={DEVICE_NOTIFICATION_ROUTES.BASE} component={DevicesNotificationsRoutes} />

        <Route path={TEMPORARY_CARDS.BASE} component={TemporaryCardsRoutes} />

        <Route path='/schedule-inactivation' component={ScheduleInactivationsRoutes} />

        <Redirect to='error/error-v1' />
      </Switch>
    </Suspense>
  );
}
