import {createSlice} from '@reduxjs/toolkit';
import {useSelector} from 'react-redux';

type DocumentType = {
  description: string;
  expirationBlockAccess: boolean;
  hasExpirationDate: boolean;
  hasImageAttached: boolean;
  id: number;
};

export const logsInitialState = {
  isLoading: false,
  data: {
    entities: [],
    total: 0,
    docsColumns: []
  },
  error: false
};

const logsSlice = createSlice({
  name: 'logs',
  initialState: logsInitialState,
  reducers: {
    startFetching(state) {
      state.isLoading = true;
      state.error = false;
    },
    dataFetched(state, {payload}) {
      state.isLoading = false;
      state.error = false;
      state.data = {...state.data, ...payload};
    },
    dataFetchError(state, {payload}) {
      state.isLoading = false;
      state.error = payload;
    }
  }
});

export const {startFetching, dataFetched, dataFetchError} = logsSlice.actions;

export const logsSelector = (state: {logs: {isLoading: boolean; data: any; error: any}}) => state.logs;

export default logsSlice;
