import {createSlice} from '@reduxjs/toolkit';

const initialPersonsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  personForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: 'list',
  action: 'action'
};

export const personsSlice = createSlice({
  name: 'persons',
  initialState: initialPersonsState,
  reducers: {
    catchError: (state: any, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state: any, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state: any, action) => {
      state.error = null;

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    callFinally: (state: any, action) => {
      state.listLoading = false;

      state.actionsLoading = false;
    },
    // getPersonById
    personFetched: (state: any, action) => {
      state.actionsLoading = false;
      state.personForEdit = undefined;
      state.personForEdit = action.payload.personForEdit;
      state.error = null;

      //@ts-ignore
      window.module = {person: action.payload.personForEdit}
    },
    // findPersons
    personsFetched: (state: any, action) => {
      const {totalCount, entities} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.personForEdit = undefined;
    },
    groupPersonsFetched: (state: any, action) => {
      const {totalCount, entities} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createPerson
    personCreated: (state: any, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.personForEdit = undefined;
      state.entities.push(action.payload.person);
    },
    // updatePerson
    personUpdated: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity: any) => {
        if (entity.id === action.payload.person.id) {
          return action.payload.person;
        }
        return entity;
      });
      state.personForEdit = undefined;
    },
    // deletePerson
    personDeleted: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.listLoading = false;
      state.entities = state.entities.filter((el: any) => el.id !== action.payload.id);
    },

    // deletePerson
    personEnable: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.listLoading = false;
      state.entities = state.entities.filter((el: any) => el.id !== action.payload.id);
    },
    // deletePersons
    personsDeleted: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el: any) => !action.payload.ids.includes(el.id));
    },
    // personsUpdateState
    personsStatusUpdated: (state: any, action) => {
      state.actionsLoading = false;
      state.error = null;
      const {ids, status} = action.payload;
      state.entities = state.entities.map((entity: any) => {
        if (ids.findIndex((id: number) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    // activePerson
    activePerson: (state: any, action) => {
      state.actionsLoading = false;
      state.error = null;
      const {id, status} = action.payload;
      state.entities = state.entities.map((entity: any) => {
        if (id.findIndex((id: number) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    linkedPersons: (state: any, action) => {
      const {persons} = action.payload;
      state.linkedPersonsIds = persons;
    },
    linkedPersonDeleted: (state: any, action) => {
      state.linkedPersonsIds = state.linkedPersonsIds.filter((el: any) => el.id !== action.payload.id);
    }
  }
});
