import {getLanguage} from 'src/app/modules/Config/_redux/reducer';

// 19/04/2023, 16:59:11 (24h format)

export const formatFullDateStyle = (date: string, addHours = true): string => {
  const {selectedLang} = getLanguage();
  if (addHours) {
    let result = new Date(date).toLocaleString(selectedLang, {hour12: false});
    if (result === 'Invalid Date') return date;

    return result.replace(',', '');
  }

  return new Date(date).toLocaleDateString(selectedLang);
};
