import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { getDownloadCSV } from 'src/services/downloadCSV';
import moment from 'moment';
import { execToast } from './Toast';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from 'src/_metronic/_helpers';
import { Button, ListItemIcon, makeStyles, MenuItem, Typography } from '@material-ui/core';

const fileDownload = require('js-file-download');
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));
export function DownloadCSV({
  filtro,
  api,
  headers,
  columns,
  onClick,
  nomeArquivo = 'Pessoas'
}: {
  filtro: any;
  api: string;
  headers: string[];
  columns?: string[];
  onClick?: () => void;
  nomeArquivo?: string;
}) {
  const classes = useStyles();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  function downloadCSV(e: any) {
    e.preventDefault();
    setIsLoading(true);

    getDownloadCSV(api, filtro, headers, columns)
      .then(async (response: any) => {

        const textCSV = await response.data.text();
        let rows = textCSV.split('\n');


        rows[0] = rows[0].normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        rows = rows.join('\n');


        //@ts-ignore
        fileDownload(rows, `${nomeArquivo.replace(' ', '-')}_${moment().format('YYYYMMDD_HHmm')}.csv`);
        execToast('success', intl.formatMessage({ id: 'TOAST.DOWNLOAD.SUCCESS' }), 3000);
      })
      .catch((error: any) => {
        console.error(error);
        execToast('error', intl.formatMessage({ id: 'TOAST.DOWNLOAD.ERROR' }), 3000);
      })
      .finally(() => {
        setIsLoading(false);
        if (onClick) onClick();
      });
  }
  return (
    // <MenuItem onClick={downloadCSV}>
    //   {intl.formatMessage({id: 'FILTER.BUTTON.CSV_DOWNLOAD'})}
    //   {isLoading ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : <SVG src={toAbsoluteUrl('/media/svg/icons/Files/DownloadedFile.svg')} />}
    // </MenuItem>
    <MenuItem onClick={downloadCSV}>
      <ListItemIcon>
        <span className='svg-icon svg-icon-xl svg-icon-primary'>
          <SVG src={toAbsoluteUrl('/media/svg/icons/Files/DownloadedFile.svg')} />
        </span>
      </ListItemIcon>
      <Typography variant='inherit' noWrap>
        {intl.formatMessage({ id: 'FILTER.BUTTON.CSV_DOWNLOAD' })}
      </Typography>
    </MenuItem>
  );
}
