import {createSlice} from '@reduxjs/toolkit';

const initialOperatorState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  personForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: 'list',
  action: 'action'
};

export const operatorSlice = createSlice({
  name: 'operator',
  initialState: initialOperatorState,
  reducers: {
    catchError: (state: any, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state: any, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    operatorUpdated: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity: any) => {
        if (entity.id === action.payload.operator.id) {
          return action.payload.operator;
        }
        return entity;
      });
    },
    operatorInfoSetted: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.operator = action.payload;
    }
  }
});
