import axios from 'axios';

const idsecureAPI = process.env.REACT_APP_IDSECURE_API_URL;

export function getAllNotificationPersons(params: any) {
  return axios.get(`${idsecureAPI}/persons`, { params });
}

export function getRoutesNotification(route: string, params: any) {
  return axios.get(`${idsecureAPI}/${route}`, { params });
}

export function getAllNotification(params: any) {
  return axios.get(`${idsecureAPI}/notificationrules`, { params });
}

export function activeNotification(id: any) {
  return axios.put(`${idsecureAPI}/notificationrules/${id}`);
}

export function deleteNotification(id: any, erase?: boolean) {
  return axios.delete(`${idsecureAPI}/notificationrules/${id}`, {
    params: {
      erase
    }
  });
}

export function getNotificationById(id: number) {
  return axios.get(`${idsecureAPI}/notificationrules/${id}`);
}

type CREATE_NOTIFICATION_RULE_PROPS = {
  description: string;
  notifyAlerts: boolean;
  notifyDeniedAccess: boolean;
  notifyGarantAccess: boolean;
  // notifyNotIdentified: boolean;
  emailTitle: string;
  emailSendType: string;
  emailSendTime: string;
  emailRecipients: string;
  emailSubject: string;
  emailBody: string;
  emailSignature: string;
  sendMonday: boolean;
  sendTuesday: boolean;
  sendWednesday: boolean;
  sendThursday: boolean;
  sendFriday: boolean;
  sendSaturday: boolean;
  sendSunday: boolean;
  personTypes: string[];
  personsIds: number[];
  groupsIds: number[];
  areasIds: number[];
  isAllPerson: boolean;
  isAllArea: boolean;
};

export function CREATE_NOTIFICATION_RULE(data: CREATE_NOTIFICATION_RULE_PROPS) {


  return axios.post(`${idsecureAPI}/notificationrules`, data);
}

interface EDIT_NOTIFICATION_RULE_PROPS extends CREATE_NOTIFICATION_RULE_PROPS {
  id: number;
}

export function EDIT_NOTIFICATION_RULE(data: EDIT_NOTIFICATION_RULE_PROPS) {
  return axios.put(`${idsecureAPI}/notificationrules`, data);
}
