import React, {ReactNode, useState} from 'react';

import {Button, Modal, Spinner} from 'react-bootstrap';
import {useIntl} from 'react-intl';

type TRenderProps = {
  onOpen(): void;
};

interface IConfirmationModal {
  title: string;
  description?: string | JSX.Element;
  onConfirm(): void | Promise<void>;
  onCancel?: () => void;
  openErase?: () => void;
  children?: (props: TRenderProps) => ReactNode;
  isOpen?: boolean;
  variant: 'activate' | 'inactivate' | 'confirm';
  permanentlyDelete?: boolean;
}

export function ConfirmModal({
  onConfirm,
  children,
  title,
  description,
  permanentlyDelete,
  ...props
}: IConfirmationModal) {
  const isControlled = props?.isOpen !== undefined;
  const [isOpen, setIsOpen] = useState(false);
  const localIsOpen = isControlled ? props.isOpen : isOpen;
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    !isControlled && setIsOpen(false);
    !!props.onCancel && props.onCancel();
  };

  const onOpenErase = () => {
    !!props.openErase && props.openErase();
    !isControlled && setIsOpen(false);
    !!props.onCancel && props.onCancel();
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    await onConfirm();

    !isControlled && setIsOpen(false);
    setIsLoading(false);
  };

  return (
    <>
      {!!children && children({onOpen})}

      <Modal show={localIsOpen} onHide={() => {}} dialogClassName='text-start'>
        <Modal.Header>
          <Modal.Title className='text-start'>{title}</Modal.Title>
        </Modal.Header>

        {!!description && (
          <Modal.Body className='justify-content-center'>
            {typeof description !== 'string' ? description : <span>{description}</span>}
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button className='btn btn-light' onClick={onClose} disabled={isLoading}>
            {intl.formatMessage({id: 'CONFIG.BUTTON_CANCEL'})}
          </Button>
          {permanentlyDelete ? (
            <>
              <div style={{width: '88px'}} />
              <Button disabled={isLoading} variant='outline-danger' onClick={() => onOpenErase()}>
                {intl.formatMessage({id: 'PERMANENTLY_DELETE'})}
              </Button>
            </>
          ) : null}
          <Button
            className={`btn ${confirmBtnClass[props.variant]}`}
            style={{display: 'flex', alignItems: 'center', gap: 10}}
            onClick={handleConfirm}
            disabled={isLoading}>
            {intl.formatMessage({id: confirmBtnLabel[props.variant]})}
            {isLoading && <Spinner animation='border' style={{width: '1.5rem', height: '1.5rem'}} />}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const confirmBtnClass = {
  confirm: '',
  activate: '',
  inactivate: 'btn-danger'
};

const confirmBtnLabel = {
  confirm: 'PERSONS.FACIAL.MODAL.CONFIRM',
  activate: 'TOAST.ENABLE',
  inactivate: 'TOAST.DELETE_BUTTON'
};
