import {LicenseRepo} from 'src/services/configs';

export type LicenseModel = {
  user: {
    total: number;
    used: number;
    percentage: number;
  };
  device: {
    total: number;
    used: number;
    percentage: number;
  };
};

interface IGetLicense {
  exec(): Promise<LicenseModel>;
}

export interface ILicenseRepo {
  getLicense(): Promise<LicenseModel>;
}

const licenseRepo = new LicenseRepo();

class GetLicense implements IGetLicense {
  async exec(): Promise<LicenseModel> {
    try {
      return await licenseRepo.getLicense();
    } catch (e) {
      throw new Error('WENT_WRONG');
    }
  }
}

export const getLicense = new GetLicense();
