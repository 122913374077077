import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {put, takeLatest} from 'redux-saga/effects';
import cookie from 'js-cookie';
import {decodeJwt, JWTPayload} from 'jose';

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action'
};

const initialAuthState = {
  user: undefined,
  authToken: undefined
};

export const reducer = persistReducer<any, any>({storage, key: 'v713-demo1-auth', whitelist: ['user', 'authToken']}, (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.Login: {
      const {authToken} = action.payload;

      return {authToken, user: undefined};
    }

    case actionTypes.Register: {
      const {authToken} = action.payload;

      return {authToken, user: undefined};
    }

    case actionTypes.Logout: {
      // TODO: Change this code. Actions in reducer aren't allowed.
      return initialAuthState;
    }

    case actionTypes.UserLoaded: {
      const {user} = action.payload;
      return {...state, user};
    }

    case actionTypes.SetUser: {
      const {user} = action.payload;
      return {...state, user};
    }

    default:
      return state;
  }
});

export const actions = {
  login: (authToken: string) => ({type: actionTypes.Login, payload: {authToken}}),
  register: (authToken: string) => ({
    type: actionTypes.Register,
    payload: {authToken}
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: (user: any) => ({
    type: actionTypes.UserRequested,
    payload: {user}
  }),
  fulfillUser: (user: any) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user: any) => ({type: actionTypes.SetUser, payload: {user}})
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser(null));
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser(null));
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const token = cookie.get('auth-token');
    let data: any;

    if (token) {
      data = decodeJwt(token);
    }

    yield put(actions.fulfillUser({name: data.name}));
  });

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const { data: user } = yield getUserByToken();

  //   yield put(actions.fulfillUser(user));
  // });
}
