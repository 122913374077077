const tables = [
   "AccessLog",
	 "AccessRule",
	 "AccessRuleArea",
	 "AccessRuleGroup",
	 "AccessRulePerson",
	 "AccessRulePersonType",
	 "AccessRuleSchedule",
	 "AreaPortal",
	 "Area",
	  "Blueprint",
	  "BlueprintDevices",
	  "CreditType",
	  "CreditTypePerson",
	  "CreditTypeUsage",
	  "Device",
	  "DeviceOutput",
	  "DeviceReader",
	  "DeviceRemoteRegistration",
	  "Group",
	  "NotificationRule",
	  "NotificationRuleArea",
	  "NotificationRuleGroup",
	  "NotificationRulePerson",
	  "NotificationRulePersonType",
	  "OperatorRole",
	  "OperatorRoleFunction",
	  "OperatorRoleModule",
	  "PersonAccessLog",
	  "PersonCard",
	  "PersonDocument",
	  "PersonFingerPrint",
	  "PersonFingerPrintImage",
	  "PersonGroup",
	  "PersonOperatorRole",
	  "PersonPassWord",
	  "PersonPin",
	  "Person",
	  "PersonPhoto",
	  "Schedule",
	  "SystemConfig"
];

export const TABLE_ENUMERATORS = tables.map((item, index) => {
  return {
    label: item,
    id: index + 1
  };
});
