import React, { CSSProperties, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { Pagination } from 'src/_metronic/_partials/controls';
import BootstrapTable, { SortOrder } from 'react-bootstrap-table-next';
import { Button, Modal } from 'react-bootstrap';
import './styles.css';
import SVG from 'react-inlinesvg';
import { IoIosEye } from 'react-icons/io';
import { NoRecordsFoundMessage, PleaseWaitMessage, sortCaret, headerSortingClasses, toAbsoluteUrl } from 'src/_metronic/_helpers';

import { useIntl } from 'react-intl';

import { geAllBluePrints, reativeBluePrintById, removeBluePrintById } from 'src/services/bluePrintCrud';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { usePermisssion } from 'src/redux/userPermissions/usePermission';

let debounce: NodeJS.Timeout | null;
export function SynopticPanel() {
  const history = useHistory();
  const intl = useIntl();
  const [pagination, setPagination] = useState<number>(1);
  const [sizePerPage, setSizePerPage] = useState<number>(10);
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [dataTableTotal, setDataTableTotal] = useState<number>(0);
  const [sortField, setSortField] = useState('Description');
  const [search, setSearch] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [status, setStatus] = useState(1);
  const [typeView, setTypeView] = useState<'LIST' | 'GRID'>('GRID');

  const [modalDeleteIsShow, setModalDeleteIsShow] = useState(false);
  const [modalActivedIsShow, setModalActivedIsShow] = useState(false);

  const [dataModal, setDataModel] = useState({
    id: 0,
    name: ''
  });

  const columns = [
    {
      dataField: 'description',
      text: intl.formatMessage({ id: 'PAGE.BLUEPRINT.LIST_COLUMN_DESCRIPTION' }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },

    // {
    //   dataField: 'deviceModelType',
    //   text: 'modelText',
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses
    // },

    // {
    //   dataField: 'status',
    //   text: 'status',
    //   sort: true
    // },

    {
      dataField: 'actions',
      text: intl.formatMessage({ id: 'PAGE.BLUEPRINT.LIST_COLUMN_ACTION' }),
      classes: 'text-right pr-0',
      headerClasses: 'text-right pr-3',
      style: {
        minWidth: '100px'
      }
    }
  ];

  function RenderAction(item: any) {
    if (item.isEnabled) {
      return (
        <>
          <Link title={intl.formatMessage({ id: 'PAGE.BLUEPRINT.VIEW' })} className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3' to={`/blueprint/view/${item.id}`}>
            <span className='svg-icon svg-icon-md svg-icon-primary'>
              {/* <SVG src={toAbsoluteUrl('/media/svg/icons/General/Visible.svg')} /> */}
              <IoIosEye />
            </span>
          </Link>

          <Link className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3' to={`/blueprint/edit/${item.id}`}>
            <span className='svg-icon svg-icon-md svg-icon-primary'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
            </span>
          </Link>

          <a
            className='btn btn-icon btn-light btn-hover-danger btn-sm mx-3'
            onClick={() => {
              handleDelete(item.id, item.description);
            }}>
            <span className='svg-icon svg-icon-md svg-icon-danger'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
            </span>
          </a>
        </>
      );
    }

    return (
      <>
        <a className='btn btn-icon btn-icon btn-light btn-hover-success btn-sm mx-3' onClick={() => handleReactive(item.id, item.description)}>
          <span className='svg-icon svg-icon-md svg-icon-success'>
            <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Check.svg')} />
          </span>
        </a>
      </>
    );
  }

  function handleDelete(id: number, name: string) {
    setDataModel({ id, name });
    setModalDeleteIsShow(true);
  }

  function handleReactive(id: number, name: string) {
    setDataModel({ id, name });
    setModalActivedIsShow(true);
  }

  async function getAll() {
    try {
      const { data } = await geAllBluePrints({
        pageNumber: pagination,
        pageSize: sizePerPage,
        status: status,
        sortOrder: sortOrder,
        sortField: sortField,
        value: search
      });

      const dataRequest = data.data;
      const dataToRenderTable = dataRequest.data.map((item: any) => {
        return {
          ...item,
          id: item.id,
          description: item.description,
          actions: RenderAction(item)
        };
      });

      setPagination(dataRequest.page);
      setDataTableTotal(dataRequest.total);
      setDataTable(dataToRenderTable);
    } catch (err) {
      console.error('err');
      //alert('erro');
    }
  }

  useEffect(() => {
    getAll();
  }, [sortOrder, status, search, pagination]);

  const { moduleOnly } = usePermisssion();
  const [isRead, setIsRead] = useState(false);

  useEffect(() => {
    const data = moduleOnly(2);

    if (data.isRedirect) {
      history.push('/dashboard');
    }

    setIsRead(data.isLevel);
  }, []);

  const paginationOptions = {
    custom: true,
    totalSize: dataTableTotal,
    sizePerPageList: window.sizePerPageList,
    sizePerPage: sizePerPage,
    page: pagination
  };

  return (
    <Card fluidHeight={null} className={null}>
      <CardHeader icon={null} toolbar={null} className={null} labelRef={null} sticky={false} linkAddTitle={intl.formatMessage({ id: 'PAGE.BLUEPRINT.LIST_TITLE_NEW' })} linkAddTo='/blueprint/create' title={intl.formatMessage({ id: 'PAGE.BLUEPRINT.LIST_TITLE' })}>
        <CardHeaderToolbar className={null}>
          <div className='' style={{ paddingRight: 5, textAlign: 'right' }}>
            <a data-toggle='tooltip' title='List' className={`btn btn-icon btn-sm mx-3 btn-primary  ${typeView === 'GRID' ? 'btn-outline-secondary btn-hover-primary' : 'btn-hover-primary'}`} onClick={() => setTypeView('LIST')}>
              <span className='svg-icon svg-icon-md '>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Text/Bullet-list.svg')} />
              </span>
            </a>
            <a data-toggle='tooltip' title='Grid' className={`btn btn-icon btn-sm mx-3 btn-primary ${typeView === 'LIST' ? 'btn-outline-secondary btn-hover-primary' : 'btn-hover-primary'}`} onClick={() => setTypeView('GRID')}>
              <span className='svg-icon svg-icon-md '>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Layout/Layout-grid.svg')} />
              </span>
            </a>
            {/* <a data-toggle='tooltip' title='Grid' className={`btn btn-icon btn-sm mx-3 btn-secondary ${typeView === 'LIST' ? 'btn-outline-secondary' : 'btn-hover-secondary'}`} onClick={() => setTypeView('GRID')}>
              <span className='svg-icon svg-icon-md svg-icon-secondary'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Layout/Layout-grid.svg')} />
              </span>
            </a> */}
          </div>

          <div style={{ width: '8vw' }}>
            <select className='form-control' value={status} onChange={({ target }) => setStatus(Number(target.value))}>
              <option value='2'>{intl.formatMessage({ id: 'PAGE.BLUEPRINT.LIST_STATUS.ALL' })}</option>
              <option value='1'>{intl.formatMessage({ id: 'PAGE.BLUEPRINT.LIST_STATUS.ACTIVE' })}</option>
              <option value='0'>{intl.formatMessage({ id: 'PAGE.BLUEPRINT.LIST_STATUS.DESACTIVE' })}</option>
            </select>
          </div>

          <div style={{ width: '8vw', marginLeft: '10px' }}>
            <input
              onChange={({ target }) => {
                if (debounce) clearTimeout(debounce);

                debounce = setTimeout(() => {
                  setSearch(target.value);
                  setPagination(1);
                }, 500);
              }}
              className='form-control'
              type='text'
              placeholder={intl.formatMessage({ id: 'PAGE.BLUEPRINT.LIST_INPUT.SEARCH' })}
            />
          </div>

          <div>
            <Link title={intl.formatMessage({ id: 'PAGE.BLUEPRINT.LIST_TITLE_NEW' })} type='button' className='btn btn-primary ml-5 mr-2' onClick={() => { }} to='/blueprint/create'>
              {intl.formatMessage({ id: 'PAGE.BLUEPRINT.LIST_TITLE_NEW' })}
            </Link>
          </div>
        </CardHeaderToolbar>
      </CardHeader>

      <CardBody fit={null} fluid={null} className={null}>
        <Modal show={modalDeleteIsShow} onHide={() => setModalDeleteIsShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {intl.formatMessage({ id: 'TOAST.DELETE_BUTTON' })} : {dataModal.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {intl.formatMessage({
              id: 'PAGE.BLUEPRINT.MODAL.DELETE'
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => {
                setModalDeleteIsShow(false);
              }}>
              {intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' })}
            </Button>
            <Button
              variant='danger'
              disabled={isRead}
              onClick={async () => {
                if (isRead) return;
                setModalDeleteIsShow(false);
                await removeBluePrintById(dataModal.id);
                getAll();
                execToast('success', intl.formatMessage({ id: 'PAGE.BLUEPRINT.TOAST_INATIVE' }), 3000);
              }}>
              {intl.formatMessage({ id: 'TOAST.DELETE_BUTTON' })}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={modalActivedIsShow} onHide={() => setModalActivedIsShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {intl.formatMessage({ id: 'TOAST.ENABLE' })} : {dataModal.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {intl.formatMessage({
              id: 'PAGE.BLUEPRINT.MODAL.ATIVE'
            })}{' '}
            {dataModal.name} ?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => {
                setModalActivedIsShow(false);
              }}>
              {intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' })}
            </Button>
            <Button
              variant='primary'
              disabled={isRead}
              onClick={async () => {
                if (isRead) return;
                setModalActivedIsShow(false);
                await reativeBluePrintById(dataModal.id);
                getAll();
                execToast('success', intl.formatMessage({ id: 'PAGE.BLUEPRINT.TOAST_ATIVE' }), 3000);
              }}>
              {intl.formatMessage({ id: 'TOAST.ENABLE' })}
            </Button>
          </Modal.Footer>
        </Modal>

        <PaginationProvider
          pagination={paginationFactory(
            //@ts-ignore
            paginationOptions
          )}>
          {({ paginationProps, paginationTableProps }: any) => {

            return (
              <Pagination isLoading={false} paginationProps={paginationProps}>
                {typeView === 'LIST' && (
                  <BootstrapTable
                    onTableChange={(type, props) => {
                      if (props.sortOrder !== sortOrder) {
                        setSortOrder(props.sortOrder);
                      }


                      if (!props.page) return;
                      setPagination(props.page);
                      setSizePerPage(props.sizePerPage);
                    }}
                    // defaultSorted={[defaultSortedColumn]}
                    wrapperClasses='table-responsive'
                    bordered={false}
                    classes='table table-head-custom table-vertical-center overflow-hidden'
                    remote
                    bootstrap4
                    keyField='id'
                    data={dataTable}
                    columns={columns}
                    // columns={tableColumns(lang, intl)}
                    {...paginationTableProps}>
                    <PleaseWaitMessage entities={dataTable} />
                    <NoRecordsFoundMessage entities={[dataTable]} />
                  </BootstrapTable>
                )}

                {typeView === 'GRID' && (
                  <>
                    <div style={{ display: 'none' }}>
                      <BootstrapTable
                        onTableChange={(type, props) => {
                          if (props.sortOrder !== sortOrder) {
                            setSortOrder(props.sortOrder);
                          }


                          if (!props.page) return;
                          setPagination(props.page);
                          setSizePerPage(props.sizePerPage);
                        }}
                        // defaultSorted={[defaultSortedColumn]}
                        wrapperClasses='table-responsive'
                        bordered={false}
                        classes='table table-head-custom table-vertical-center overflow-hidden'
                        remote
                        bootstrap4
                        keyField='id'
                        data={dataTable}
                        columns={columns}
                        // columns={tableColumns(lang, intl)}
                        {...paginationTableProps}>
                        <PleaseWaitMessage entities={dataTable} />
                        <NoRecordsFoundMessage entities={[dataTable]} />
                      </BootstrapTable>
                    </div>

                    <div style={styles.containerGrid}>
                      {dataTable.map((value) => {
                        return (
                          <div key={value.id} style={styles.card} className='GRID-BLUEPRINT'>
                            <Link style={styles.containerImg} className='btn btn-icon btn-light btn-hover-primary ' to={`/blueprint/view/${value.id}`}>
                              <img style={styles.img} src={value.blueprintPhoto} alt='' title={intl.formatMessage({ id: 'PAGE.BLUEPRINT.VIEW' })} />
                            </Link>

                            <footer style={styles.footer}>
                              <span style={styles.footerSpan}>{value.description}</span>

                              <div style={styles.footerContainerButtons}>
                                {value.isEnabled && (
                                  <>
                                    <Link className='btn btn-icon btn-light btn-hover-primary ' to={`/blueprint/edit/${value.id}`}>
                                      <span className='svg-icon svg-icon-md svg-icon-primary'>
                                        <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
                                      </span>
                                    </Link>

                                    <a
                                      className='btn btn-icon btn-light btn-hover-danger '
                                      onClick={() => {
                                        handleDelete(value.id, value.description);
                                      }}>
                                      <span className='svg-icon svg-icon-md svg-icon-danger'>
                                        <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
                                      </span>
                                    </a>
                                  </>
                                )}

                                {!value.isEnabled && (
                                  <a className='btn btn-icon btn-icon btn-light btn-hover-success btn-sm mx-3' onClick={() => handleReactive(value.id, value.description)}>
                                    <span className='svg-icon svg-icon-md svg-icon-success'>
                                      <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Check.svg')} />
                                    </span>
                                  </a>
                                )}
                              </div>
                            </footer>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </Pagination>
            );
          }}
        </PaginationProvider>
      </CardBody>
    </Card>
  );
}

type CSSPROPS = {
  containerGrid: CSSProperties;
  card: CSSProperties;
  footer: CSSProperties;
  footerSpan: CSSProperties;
  containerImg: CSSProperties;
  img: CSSProperties;
  footerContainerButtons: CSSProperties;
};

const styles: CSSPROPS = {
  containerGrid: {
    display: 'flex',
    width: '100%',
    gap: '10px',
    flexWrap: 'wrap'
  },
  card: {
    display: 'flex',
    width: '22vw',
    flexDirection: 'column',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px'
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px'
  },

  footerSpan: {
    fontSize: '1.43rem',
    display: 'block'
  },
  footerContainerButtons: {
    display: 'flex',
    gap: '10px'
  },
  containerImg: {
    width: '100%',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    height: '100%'
  },
  img: {
    width: '100%',
    height: '100%'
  }
};
