import axios from 'axios';

const idsecureAPI = process.env.REACT_APP_IDSECURE_API_URL;

export async function login(email: string, password: string, tenantId?: number) {
  return await axios.post(`${idsecureAPI}/operators/login`, {email, password, tenantId});
}

export async function logout() {
  const dataUser = localStorage.getItem('persist:v713-demo1-auth');

  if (!dataUser) return;

  const data = JSON.parse(dataUser);
  const dataToken = JSON.parse(data.authToken);

  const api = axios.create({
    baseURL: idsecureAPI,
    headers: {
      //@ts-ignore
      Authorization: `Bearer ${dataToken.token}`
    }
  });

  return await api.post(`${idsecureAPI}/operators/logout`);
}

export function loginTenant(email: string, password: string, tenantId: number) {
  return axios.post(`${idsecureAPI}/operators/login`, {email, password, tenantId: tenantId});
}

export function requestPassword(email: string, language: string) {
  return axios.post(`${idsecureAPI}/operators/reset?email=${email}&language=${language}`);
}

export function OperatorResetPassword(email: string, oldPassword: string, newPassword: string, avatar: any, name: string) {
  return axios.post(`${idsecureAPI}/operators/settings`, {email, oldPassword, newPassword, avatar, name});
}

export function getCurrentLoggedUser() {
  return axios.get(`${idsecureAPI}/operators/me`);
}

/** TODO: REMOVE this before */
export function salesChannelLogin(email: string, password: string) {
  return axios.post(`${idsecureAPI}/auth/salesChannel/login`, {email, password});
}
/** TODO: REMOVE this before */
export function register(email: string, fullname: string, username: string, password: string) {
  return axios.post(`${idsecureAPI}/operator/register`, {email, fullname, username, password});
}
