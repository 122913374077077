import React, { useCallback, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { uploadFilePostApi } from 'src/services/downloadCSV';
import exemplo from './exemplo.zip';
import { execToast } from './Toast';
import { ToggleSwitch } from 'src/_metronic/_partials/controls/forms/ToggleSwitch';

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};
export function UpLoadCSV(props: any) {
  const intl = useIntl();
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [switchSectionContent, setSwitchSectionContent] = useState(false);
  const [updateAndInserted, setUpdateAndInserted] = useState(false);

  const saveFile = (e: any) => {
    setFile(e);
    setFileName(e.name);
  };

  const uploadFile = async (e: any) => {
    setSwitchSectionContent(false);
    // props.onHide()
    execToast('success', intl.formatMessage({ id: 'PERSONS.FINGERPRINTS.MODAL.PROCESSING' }), 3000);

    try {
      const retorno = await uploadFilePostApi(e, file, fileName, updateAndInserted);
      // .then((retorno: any) => {

      const message = retorno?.response?.data?.message;
      const status = retorno?.response?.status;

      if (+status === 400) {
        execToast('error', intl.formatMessage({ id: 'TOAST.UPLOAD.ERROR' }), 3000);
        return;
      }

      if (message == '404') {
        execToast('error', intl.formatMessage({ id: 'TOAST.IMPORT_ERROR' }), 3000);
      } else if (message == '450') {
        execToast('error', intl.formatMessage({ id: 'TOAST.PERSON.MAX_NUMBER' }), 3000);
      } else if (message == '453') {
        execToast('error', intl.formatMessage({ id: 'TOAST.PERSON.DOCUMENT' }), 3000);
      } else if (retorno?.data?.message == '200') {
        execToast('success', intl.formatMessage({ id: 'TOAST.IMPORT_SUCCESS' }), 3000);
        props.onHide();
      }
      // })
      // .catch((error: any) => {
      //   console.error(error);
      //   execToast('error', intl.formatMessage({id: 'TOAST.UPLOAD.ERROR'}), 3000);
      // })
      // .finally(() => {
      //   props.onHide()
      //   setUpdateAndInserted(false)
      // });
    } catch (ex) {
      // props.onHide();
      execToast('error', intl.formatMessage({ id: 'TOAST.UPLOAD.ERROR' }), 3000);
      console.error(ex);
    }
  };

  const onDrop = useCallback((acceptedFiles: any[]) => {
    saveFile(acceptedFiles[0]);
    setFileName(acceptedFiles[0].name);
    setSwitchSectionContent(true);
  }, []);

  return (
    <Modal
      {...props}
      aria-labelledby='contained-modal-title-vcenter'
      size='lg'
      onHide={() => {
        setUpdateAndInserted(false);
        props.onHide();
      }}>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>{intl.formatMessage({ id: 'IMPORT.PERSON.TITLE' })}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='show-grid'>
        <Container>
          <Row>
            <Col xs={8} md={12} className='p-5'>
              <h5>{intl.formatMessage({ id: 'IMPORT.PERSON.PRICIPAL.TITLE' })}</h5>
              <div className='d-flex justify-content-center'>
                <a className='col-12' href={exemplo} download='exemplo.zip'>
                  <Button className='col-12 mt-5'>
                    {intl.formatMessage({ id: 'IMPORT.PERSON.MODAL.EXAMPLE.BUTTON.ZIP' })}
                  </Button>
                </a>
              </div>
            </Col>
          </Row>

          <div
            style={{
              display: 'flex'
            }}>
            {/* <div>
              <h5>{intl.formatMessage({id: 'Atualizar e Inserir dados do CPF'})}</h5>
              <ToggleSwitch
                checked={updateAndInserted}
                onChange={(e) => {
                  setUpdateAndInserted(e.target.checked);
                }}
              />
            </div> */}

            <div></div>
          </div>
          <hr />
          <Row>
            {switchSectionContent ? (
              <section className='container' style={{ padding: '0 11px' }}>
                <h5>{intl.formatMessage({ id: 'IMPORT.PERSON.MODAL.SUCESS-MENSAGE' })}</h5>
                <p>{fileName}</p>
              </section>
            ) : (
              <section className='container' style={{ padding: '0 11px' }}>
                <h5>{intl.formatMessage({ id: 'IMPORT.PERSON.SUBMIT.TITLE' })}</h5>
                <Dropzone
                  onDrop={onDrop}
                  maxFiles={1}
                  multiple={false}
                  maxSize={268435456} //250BM
                  accept={{
                    'application/zip': ['.zip'],
                    'application/octet-stream': ['.zip', '.csv'],
                    'text/csv': ['.csv']
                  }}>
                  {({ getRootProps, getInputProps, isFocused, isDragAccept, isDragReject }) => (
                    <div
                      {...getRootProps({
                        className: 'dropzone',
                        style: {
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '30px',
                          borderWidth: 2,
                          borderRadius: 2,
                          borderColor: '#eeeeee',
                          borderStyle: 'dashed',
                          backgroundColor: '#fafafa',
                          color: '#bdbdbd',
                          outline: 'none',
                          transition: 'border .24s ease-in-out',
                          ...(isFocused ? focusedStyle : {}),
                          ...(isDragAccept ? acceptStyle : {}),
                          ...(isDragReject ? rejectStyle : {})
                        }
                      })}>
                      <input {...getInputProps()} />
                      <p className='m-0'>{intl.formatMessage({ id: 'IMPORT.PERSON.DRAGDROP' })}</p>
                    </div>
                  )}
                </Dropzone>
              </section>
            )}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' disabled={!file} onClick={uploadFile}>
          {intl.formatMessage({ id: 'PERSON.NEWPERSON_UPLOAD' })}
        </Button>

        <Button
          variant='secondary'
          onClick={() => {
            setUpdateAndInserted(false);
            props.onHide();
          }}>
          {intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
