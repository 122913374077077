import {getVisitorToken, tokenStorageKey} from '../../domain/token-storage-key';
import {Visitor} from '../../domain/visitor';
import {visitorRepo} from '../../repo';

class EditVisitor {
  async exec(input: Visitor): Promise<void> {
    try {
      const token = getVisitorToken();

      await visitorRepo.editVisitor(input, token!);
      window.sessionStorage.removeItem(tokenStorageKey);
    } catch (e) {
      if (e.response.status === 409) throw new Error('TOAST.ADD.PERSON_ERROR.EMAIL');
      throw new Error('WENT_WRONG');
    }
  }
}

export const editVisitor = new EditVisitor();
