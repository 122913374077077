///api/v1/reports

import axios from 'axios';
import { defaultDateTime } from 'src/utils/defaultDateTime';

const idsecureAPI = process.env.REACT_APP_IDSECURE_API_URL;
interface queryParams {
  pageSize: number;
  pageNumber: number;
  status: number;
  // value : string;
  sortOrder: string;
  sortField: string;
}

export function getAllReports(params: any, idsPersons: number[], idsGrups: number[]) {
  let idsPersonsString = idsPersons.reduce((acc, el) => {
    if (acc === '') {
      acc += `?personsIds=${el}`;
    } else {
      acc += `&personsIds=${el}`;
    }

    return acc;
  }, '');

  let idsGroupsString = idsGrups.reduce((acc, el) => {
    acc += `&groupsIds=${el}`;

    return acc;
  }, '');

  let queryGroup = '';

  if (idsPersonsString === '') {
    queryGroup = idsGroupsString.replace('&', '?');
  }

  return axios.get(`${idsecureAPI}/reports${idsPersonsString}${queryGroup}`, {
    params,
    transformResponse: [function (data) {

      const response = JSON.parse(data)

      try {
        const transformValues = response.data.data.map((v: any) => {
          return {
            ...v,
            createdAt: defaultDateTime.labelDateTime(v.createdAt)
          }
        })

        response.data.data = transformValues


        return {
          data: {
            ...response.data,
            data: transformValues
          }
        }



      } catch {
        return response
      }


    }],
  });
}

export enum StatusPhoto {
  Unknown = 0,
  Success = 1,
  FaceDistant = 2,
  FaceClose = 3,
  FaceNotCentered = 4,
  FacePoseNotCentered = 5,
  LowShaprness = 6,
  FaceNotDetected = 7,
  FaceExists = 8,
  ImageFileNotRecognized = 9,
  ImageShort = 10,
  ImageLong = 11,
  UserNotExist = 12,
  TooManyFaces = 13
}

export enum IStatus {
  All = 2,
  Active = 1,
  Inactive = 0,
}

export enum PersonType {
  Person = 1,
  Visitant = 2
}

export enum PersonPhotoFilter {
  NoPhoto = 1,
  WithBadPhoto = 2,
  All = 3
}

export type GetPhotoParams = {
  Names?: string;
  StatusPhotos?: string;
  PersonPhoto: PersonPhotoFilter;
  FinishDate?: number;
  StartDate?: number;
  pageSize: number;
  pageNumber: number;
  status: any;
  value?: string;
  sortOrder: 'asc' | 'desc';
  sortField: string;
  culture?: string
};

type PhotoModel = {
  personId: number;
  name: string;
  personPhotoId: number | null;
  type: number | null;
  photo: string | null;
  status: null | StatusPhoto;
  personType: PersonType;
  registrationName: string | null;
  professionalRole: string | null;
};

export type PhotoResponse = {
  message: string;
  success: boolean;
  data: {
    data: PhotoModel[];
    total: number;
    page: number;
    limit: number;
    pages: number;
  };
};

export class PersonPhotoRepository {
  private endpoint = `${idsecureAPI}/reports/personPhoto`;

  async getPhotos(params: GetPhotoParams) {
    const { data } = await axios.get<PhotoResponse>(this.endpoint, { params });
    return data.data;
  }
}
