import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import * as S from './styles';
import { Form } from './components/form';
import { Show } from 'src/app/components/Show';
import { Success } from './components/success';
import { TView, VisitorRegistrationContext } from './context/visitor-registration-context';
import { InvalidToken } from './components/invalid-token';
import { tokenStorageKey } from './domain/token-storage-key';
import { toAbsoluteUrl } from 'src/_metronic/_helpers';

export function VisitorRegistration() {
  const [view, setView] = useState<TView>('form');

  const value = useMemo(
    () => ({
      view,
      changeView: (newView: TView) => setView(newView)
    }),
    [view]
  );

  return (
    <VisitorRegistrationContext.Provider value={value}>
      <S.Wrapper>
        <Show show={view === 'form'}>
          <Form />
        </Show>

        <Show show={view === 'success'}>
          <Success />
        </Show>

        <Show show={view === 'invalidToken'}>
          <InvalidToken />
        </Show>

        <S.Footer>
          <img alt='logo' src='/media/logos/logo_branco.svg' className='bg-width-200' />

          <div>
            <div id='logo-copy' className='opacity-70 font-weight-bold	text-white'>
              &copy; 2022 Control iD
            </div>
            <p id='app-version' className='text-white'>
              {process.env.REACT_APP_VERSION}
            </p>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={toAbsoluteUrl("media/2022.12.02 - Acordo de Licença Para Usuário Final (EULA)_v2_clean.pdf")}
              className='text-white ml-10'>
              <FormattedMessage id={'FOOTER.ITEM_USE_LICENSE'} />
            </a>
            <a href={toAbsoluteUrl("media/politicaPrivacidade.pdf")} target='_blank' rel="noopener noreferrer" className='text-white ml-10'>
              <FormattedMessage id={'FOOTER.ITEM_PRIVACY'} />
            </a>
          </div>
        </S.Footer>
      </S.Wrapper>
    </VisitorRegistrationContext.Provider>
  );
}



export function RedirectToVisitorRegistration() {
  const { search } = useLocation()

  useEffect(() => {
    const query = new URLSearchParams(search)
    window.sessionStorage.setItem(tokenStorageKey, query.get('token') || '')
  }, [search])

  return <Redirect to='/remote-registration' />
}