import React, {useState} from 'react';
import {BiCheckSquare} from 'react-icons/bi';
import {Button, Modal, Spinner} from 'react-bootstrap';
import {useIntl} from 'react-intl';
import {AiOutlineLock, AiOutlineWarning} from 'react-icons/ai';
import {ConfirmModal} from './ConfirmModal';
import {ExceptionMode, setExceptionMode} from 'src/services/devicesCrud';
import {execToast} from '../Toast';

interface IModal {
  isOpen: boolean;
  onClose(): void;
}

export function ExceptionModeModal(props: IModal) {
  const intl = useIntl();

  return (
    <Modal size='lg' show={props.isOpen} onHide={props.onClose}>
      <Modal.Header className='justify-content-center'>
        <h1 className='text-uppercase h2 mb-0' style={{color: '#3F4254'}}>
          {intl.formatMessage({id: 'DEVICE.DEVICE_EXCEPTION_MODE_BUTTON'})}
        </h1>
      </Modal.Header>

      <Modal.Body className='border d-flex flex-wrap justify-content-center align-items-center' style={{gap: '1.5rem'}}>
        <NormalMode />
        <EmergencyMode />
        <LockdownMode />
      </Modal.Body>

      <Modal.Footer>
        <Button variant='primary' onClick={props.onClose}>
          {intl.formatMessage({id: 'NEWPERSON.MODAL.CLOSE'})}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const NormalMode = () => {
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();

  const handleMutateExceptionMode = async () => {
    setIsLoading(true);
    try {
      await setExceptionMode({isAllDevices: true, mode: ExceptionMode.normal});
      execToast('success', intl.formatMessage({id: 'DEVICE.NORMAL_MODE_SUCCESS'}), 3000);
    } catch (e) {
      execToast('error', intl.formatMessage({id: 'DEVICE.NORMAL_MODE_ERROR'}), 3000);
    }
    setIsLoading(false);
  };

  return (
    <Button
      disabled={isLoading}
      onClick={handleMutateExceptionMode}
      className='d-flex flex-column align-items-center justify-content-between p-5'
      style={{gap: '1rem', width: 220, height: 150, borderRadius: 10}}>
      {isLoading ? (
        <Spinner animation='border' style={{margin: 'auto'}} />
      ) : (
        <>
          <div className='d-flex flex-column align-items-center'>
            <BiCheckSquare style={{fontSize: 32}} />
            <span style={{fontSize: 18}}>{intl.formatMessage({id: 'DEVICE.NORMAL_MODE'})}</span>
          </div>

          <div className='pt-1' style={{width: '100%', borderTop: '2px solid #ebedf366'}}>
            {intl.formatMessage({id: 'DEVICE.NORMAL_MODE_INFO'})}
          </div>
        </>
      )}
    </Button>
  );
};

const EmergencyMode = () => {
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();

  const handleMutateExceptionMode = async () => {
    setIsLoading(true);
    try {
      await setExceptionMode({isAllDevices: true, mode: ExceptionMode.emergency});
      execToast('success', intl.formatMessage({id: 'DEVICE.EMERGENCY_MODE_SUCCESS'}), 3000);
    } catch (e) {
      execToast('error', intl.formatMessage({id: 'DEVICE.EMERGENCY_MODE_ERROR'}), 3000);
    }
    setIsLoading(false);
  };

  return (
    <>
      <ConfirmModal
        title={intl.formatMessage({id: 'DEVICE.EMERGENCY_MODE_ALERT'})}
        onConfirm={handleMutateExceptionMode}
        variant='confirm'>
        {(props) => (
          <Button
            disabled={isLoading}
            onClick={props.onOpen}
            className='btn-danger d-flex flex-column align-items-center justify-content-between p-5'
            style={{gap: '1rem', width: 220, height: 150, borderRadius: 10}}>
            {isLoading ? (
              <Spinner animation='border' style={{margin: 'auto'}} />
            ) : (
              <>
                <div className='d-flex flex-column align-items-center'>
                  <AiOutlineWarning style={{fontSize: 32}} />
                  <span style={{fontSize: 18}}>{intl.formatMessage({id: 'DEVICE.EMERGENCY_MODE'})}</span>
                </div>

                <div className='pt-1' style={{width: '100%', borderTop: '2px solid #ebedf366'}}>
                  {intl.formatMessage({id: 'DEVICE.EMERGENCY_MODE_INFO'})}
                </div>
              </>
            )}
          </Button>
        )}
      </ConfirmModal>
    </>
  );
};

const LockdownMode = () => {
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();

  const handleMutateExceptionMode = async () => {
    setIsLoading(true);
    try {
      await setExceptionMode({isAllDevices: true, mode: ExceptionMode.lockdown});
      execToast('success', intl.formatMessage({id: 'DEVICE.LOCKDOWN_MODE_SUCCESS'}), 3000);
    } catch (e) {
      execToast('error', intl.formatMessage({id: 'DEVICE.LOCKDOWN_MODE_ERROR'}), 3000);
    }
    setIsLoading(false);
  };

  return (
    <>
      <ConfirmModal
        title={intl.formatMessage({id: 'DEVICE.LOCKDOWN_MODE_ALERT'})}
        onConfirm={handleMutateExceptionMode}
        variant='confirm'>
        {(props) => (
          <Button
            disabled={isLoading}
            onClick={props.onOpen}
            className='btn-warning d-flex flex-column align-items-center justify-content-between p-5'
            style={{gap: '1rem', width: 220, height: 150, borderRadius: 10}}>
            {isLoading ? (
              <Spinner animation='border' style={{margin: 'auto'}} />
            ) : (
              <>
                <div className='d-flex flex-column align-items-center'>
                  <AiOutlineLock style={{fontSize: 32}} />
                  <span style={{fontSize: 18}}>{intl.formatMessage({id: 'DEVICE.LOCKDOWN_MODE'})}</span>
                </div>

                <div className='pt-1' style={{width: '100%', borderTop: '2px solid #ebedf366'}}>
                  {intl.formatMessage({id: 'DEVICE.LOCKDOWN_MODE_INFO'})}
                </div>
              </>
            )}
          </Button>
        )}
      </ConfirmModal>
    </>
  );
};
