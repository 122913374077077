import {IntlShape} from 'react-intl';

export const dateToUNIX = (date: Date) => Math.floor(+date / 1000);

export const eventsLiteral = (intl: IntlShape) => ({
  1: intl.formatMessage({id: 'EVENTS.INVALID_DEVICE'}),
  2: intl.formatMessage({id: 'EVENTS.INVALID_ID_PARAMS'}),
  3: intl.formatMessage({id: 'EVENTS.NOT_IDENTIFIED'}),
  4: intl.formatMessage({id: 'EVENTS.PENDING_ID'}),
  5: intl.formatMessage({id: 'EVENTS.ID_TIMEOUT'}),
  6: intl.formatMessage({id: 'EVENTS.ACCESS_DENIED'}),
  7: intl.formatMessage({id: 'EVENTS.ACCESS_GRANTED'}),
  8: intl.formatMessage({id: 'EVENTS.ACCESS_PENDING'}),
  9: intl.formatMessage({id: 'EVENTS.NOT_ADMIN'}),
  10: intl.formatMessage({id: 'EVENTS.NOT_ID_ACCESS'}),
  11: intl.formatMessage({id: 'EVENTS.BUTTONHOLE_ACCESS'}),
  12: intl.formatMessage({id: 'EVENTS.WEB_INTERFACE_ACCESS'}),
  13: intl.formatMessage({id: 'EVENTS.GIVEUP_ACCESS'}),
  14: intl.formatMessage({id: 'EVENTS.NO_ANSWER'}),
  15: intl.formatMessage({id: 'EVENTS.INTERFONIA_ACCESS'})
});
