// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useCallback, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { getHandlerTableChange, NoRecordsFoundMessage, PleaseWaitMessage, sortCaret, headerSortingClasses } from '../../../../../_metronic/_helpers';
import { Pagination } from 'src/_metronic/_partials/controls';
import { useIntl } from 'react-intl';
import { findPersons } from '../../../../../services/personsCrud';
import { findPersons as findPersonOperatorRules } from '../../../../../services/permissions';
import { useLocation } from 'react-router-dom';
// import * as uiHelpers from '../../../Person/pages/PersonsUIHelpers';
import { isEqual, isFunction } from 'lodash';

const initialFilter = {
  value: '',
  status: 1,
  sortOrder: 'asc', // asc||desc
  sortField: 'Name',
  pageNumber: 1,
  pageSize: 10,
  personType: 1
};

interface PROPS {
  users: any[];
  fnUserSeelected: (value: any) => void;
}

export const PersonsTable = ({ users, fnUserSeelected }: PROPS) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [entities, setEntities] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const usersListNoselected = entities.filter((item) => {
    const userSelected = users.find((user) => user.id === item.id);

    if (!userSelected) return item;
  });

  const diffUsers = entities.length - usersListNoselected.length;

  const NameText = intl.formatMessage({ id: 'PERSON.PERSONS_TABLE_NAME' });
  const registrationText = intl.formatMessage({ id: 'PERSON.PERSONS_TABLE_REGISTRATION' });
  const phoneNumberText = intl.formatMessage({ id: 'PERSON.PERSONS_TABLE_PHONE_NUMBER' });

  const handleFetchPeople = async () => {
    setLoading(true);

    const splitted = document.location.href.split('/');
    // const groupId = splitted[splitted.length - 1];

    if (pathname.includes('access/')) {
      const {
        data: {
          data: { data: people, total: totalCount }
        }
      } = await findPersonOperatorRules({ ...queryParams });

      setEntities(people as any);
      setTotalCount(totalCount);
      setLoading(false);
    } else {
      const {
        data: {
          data: { data: people, total: totalCount }
        }
      } = await findPersons({ ...queryParams });

      setEntities(people as any);
      setTotalCount(totalCount);
      setLoading(false);
    }
  };

  const columns = [
    {
      dataField: 'name',
      text: NameText,
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'registration',
      text: registrationText,
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'phoneNumber',
      text: phoneNumberText,
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    }
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalCount - diffUsers,
    sizePerPageList: window.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber
  };



  const selectRow = {
    mode: 'checkbox',
    onSelect: (row: any, isSelect: boolean, rowIndex: number, e: any) => {
      // const filtered = users.filter((el: any) => el.id !== row.id);

      fnUserSeelected(row);
    },
    onSelectAll: (isSelect: boolean, rows: any[], e: any) => {
      if (isSelect) {
        fnUserSeelected(rows);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await handleFetchPeople();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await handleFetchPeople();
    })();
  }, [queryParams]);

  const setQueryParams = useCallback(
    (nextQueryParams: any) => {
      setQueryParamsBase((prevQueryParams) => {
        if (isFunction(nextQueryParams)) {
          nextQueryParams = nextQueryParams(prevQueryParams);
        }

        if (isEqual(prevQueryParams, nextQueryParams)) {
          return prevQueryParams;
        }

        return nextQueryParams;
      });
    },
    [queryParams, initialFilter]
  );


  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }: { paginationProps: any; paginationTableProps: any }) => {
        return (
          <Pagination isLoading={loading} paginationProps={paginationProps}>
            <form className='form form-label-right'>
              <div className='form-group row justify-left'>
                <div className='col-12'>
                  <input
                    onChange={(e) => {
                      setQueryParamsBase({ ...queryParams, value: e.target.value, pageNumber: 1 });
                    }}
                    type='text'
                    className='form-control'
                    name='searchText'
                    placeholder={intl.formatMessage({ id: 'PERSON.PERSONS_FILTER_SEARCH_PH' })}
                  />
                </div>
              </div>
            </form>
            <BootstrapTable
              wrapperClasses='table-responsive'
              bordered={false}
              classes='table table-head-custom table-vertical-center overflow-hidden'
              bootstrap4
              remote
              keyField='id'
              data={usersListNoselected}
              columns={columns}
              selectRow={selectRow}
              // defaultSorted={uiHelpers.defaultSorted}
              onTableChange={getHandlerTableChange(setQueryParams)}
              {...paginationTableProps}>
              <PleaseWaitMessage entities={usersListNoselected} />
              <NoRecordsFoundMessage entities={usersListNoselected} />
            </BootstrapTable>
          </Pagination>
        );
      }}
    </PaginationProvider>
  );
};

// export default injectIntl(ListPersonsTable);
