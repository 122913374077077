import {all} from 'redux-saga/effects';
import {combineReducers} from 'redux';

import * as auth from '../app/modules/Auth/_redux/authRedux';
import {personsSlice} from '../app/modules/Person/_redux/persons/personsSlice';
import {groupsSlice} from '../app/modules/Groups/_redux/groupsSlice';
import {timesSlice} from '../app/modules/Times/_redux/timesSlice';
import {areaSlice} from 'src/app/modules/Area/_redux/areaSlice';
import {visitorsSlice} from 'src/app/modules/Visitors/_redux/visitorsSlice';
import {deviceSlice} from 'src/app/modules/Devices/_redux/deviceSlices';
import {companiesSlice} from 'src/app/modules/Companies/_redux/groupsSlice';
import {accessRulesSlice} from 'src/app/modules/AccessRule/_redux/accessRulesSlice';
import {vehicleAccessRulesSlice} from 'src/app/modules/VehicleAccessRule/_redux/vehicleAccessRulesSlice';
import {dashboardSlices} from 'src/app/modules/Dashboard/_redux/dashboardSlices';
import logsSlice from 'src/app/modules/Reports/redux/logsSlice';
import {operatorSlice} from 'src/app/modules/UserProfile/_redux/operatorSlice';
import configSystem from 'src/app/modules/Config/_redux/reducer';
import userPermissions from './userPermissions/reducer';
import userMode from './isMode/reducer';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  persons: personsSlice.reducer,
  groups: groupsSlice.reducer,
  visitors: visitorsSlice.reducer,
  devices: deviceSlice.reducer,
  times: timesSlice.reducer,
  area: areaSlice.reducer,
  companies: companiesSlice.reducer,
  accessRules: accessRulesSlice.reducer,
  vehicleAccessRules: vehicleAccessRulesSlice.reducer,
  dashboard: dashboardSlices.reducer,
  logs: logsSlice.reducer,
  operator: operatorSlice.reducer,
  configs: configSystem,
  userPermissions,
  userMode
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
