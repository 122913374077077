import {IntlShape} from 'react-intl';

export function standardTranslation(value: string, intl: IntlShape): string {
  switch (value) {
    case 'GROUPS.DEFAULT.PERSON':
      return intl.formatMessage({id: value});
    case 'GROUPS.DEFAULT.VISITOR':
      return intl.formatMessage({id: value});
    case 'AREA.DEFAULT':
      return intl.formatMessage({id: value});
    case 'Visitant':
      return intl.formatMessage({id: 'ACCESS_LOGS.COLUMNS.VISITANT'});
    case 'Not Identified':
      return intl.formatMessage({id: 'EVENTS.NOT_IDENTIFIED'});
    case 'Person':
      return intl.formatMessage({id: 'ACCESS_LOGS.COLUMNS.PERSON'});
    case 'ACRULE.DEFAULT.PERSON':
      return intl.formatMessage({id: 'ACRULE.DEFAULT.PERSON'});
    case 'ACRULE.DEFAULT.VISITOR':
      return intl.formatMessage({id: 'ACRULE.DEFAULT.VISITOR'});
    default:
      return value;
  }
}
