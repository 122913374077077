import * as yup from 'yup';

const personDocuments = yup.object().shape({
  value: yup.string().nullable().when('documentTypeId', {
    is: (isRequired: boolean, documentTypeId?: number) => isRequired || !!documentTypeId,
    then: yup.string().required('REQUIRED_FIELD')
  }),
  documentTypeId: yup.number().nullable()
})

export const formSchema = yup.object().shape({
  name: yup.string(),
  email: yup
    .string()
    .email('NOTIFICATION.INVALID_EMAIL')
    .when('requiredFields.email', {
      is: true,
      then: yup.string().required('REQUIRED_FIELD')
    }),
  avatar: yup
    .string()
    .nullable()
    .when('requiredFields.personPhoto', {
      is: true,
      then: yup.string().required('REQUIRED_FIELD')
    }),
  phoneNumber: yup.string().when('requiredFields.phoneNumber', {
    is: true,
    then: yup.string().required('REQUIRED_FIELD')
  }),
  personDocuments: personDocuments.when('requiredFields.personDocuments', {
    is: true,
    then: yup.object().shape({
      value: yup.string().required('REQUIRED_FIELD'),
      documentTypeId: yup.number().required('REQUIRED_FIELD')
    })
  })
});
 