/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useEffect} from 'react';
import objectPath from 'object-path';
import ApexCharts from 'apexcharts';
import {useHtmlClassService} from '../../../../_metronic/layout';
import {useIntl} from 'react-intl';
import {AccessLastWeek} from '../_redux/dashboardSlices';
const fakeAccessLastWeek:AccessLastWeek[] = [
  // {
  //   day: '08/06/2022',
  //   totalVisitants: 3,
  //   totalPersons: 2
  // },
  // {
  //   day: '08/07/2022',
  //   totalVisitants: 6,
  //   totalPersons: 8
  // },
  // {
  //   day: '08/08/2022',
  //   totalVisitants: 1,
  //   totalPersons: 1
  // },
  // {
  //   day: '08/09/2022',
  //   totalVisitants: 6,
  //   totalPersons: 5
  // },
  // {
  //   day: '08/10/2022',
  //   totalVisitants: 3,
  //   totalPersons: 6
  // },
  // {
  //   day: '08/11/2022',
  //   totalVisitants: 4,
  //   totalPersons: 4
  // }
];
export function ChartBar({className, numberFormat, accessLastWeek, heigth, readOnly}: any) {
  const accessLast: AccessLastWeek[] = !readOnly ? accessLastWeek : fakeAccessLastWeek;

  const uiService = useHtmlClassService();
  const intl = useIntl();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(uiService.config, 'js.colors.gray.gray500'),
      colorsGrayGray200: objectPath.get(uiService.config, 'js.colors.gray.gray200'),
      colorsGrayGray300: objectPath.get(uiService.config, 'js.colors.gray.gray300'),
      colorsThemeBasePrimary: objectPath.get(uiService.config, 'js.colors.theme.base.primary'),
      colorsThemeLightPrimary: objectPath.get(uiService.config, 'js.colors.theme.light.primary'),
      fontFamily: objectPath.get(uiService.config, 'js.fontFamily')
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById('kt_stats_widget_12_chart');

    if (!element) {
      return;
    }

    const options = getChartOption();
    const chartnewUsers = new ApexCharts(element, options);
    chartnewUsers.render();
    return function cleanUp() {
      chartnewUsers.destroy();
    };
  }, [layoutProps, accessLast]);

  const getDay = (item: any) => {
    const day = new Date(item.day).getDay().toString();
    return intl.formatMessage({id: `DAY.${day}`});
  };

  const fomatDate = () => {
    try{
      const from = new Date(accessLast[0].day);
    const to = new Date(accessLast[accessLast.length - 1].day);

    return intl.locale === 'en'
      ? `
      ${intl.formatMessage({id: `MONTH.${(from.getMonth() + 1).toString() || 13}`})}
      ${to.getDate().toString()}  
      -
      ${intl.formatMessage({id: `MONTH.${(to.getMonth() + 1).toString() || 13}`})} 
      ${to.getDate().toString()} 
      ${to.getFullYear().toString()}
    `
      : `
      ${to.getDate().toString()} 
      ${intl.formatMessage({id: `MONTH.${(from.getMonth() + 1).toString()}`})}
      -
      ${to.getDate().toString()} 
      ${intl.formatMessage({id: `MONTH.${(to.getMonth() + 1).toString()}`})} 
      ${to.getFullYear().toString()}
     
    `;
    }catch{
      return ''
    }
  };

  return (
    <div className={`card ${className}`}>
      <div className='card-body d-flex flex-column p-0'>
        <div className='d-flex align-items-center justify-content-between card-spacer flex-grow-1'>
          <div className='d-flex flex-stack flex-wrap flex-grow-1 justify-content-between'>
            <div className='me-2'>
              <span className='text-dark-75 font-weight-bolder font-size-h5'>{intl.formatMessage({id: 'DASH.ACCESS_LAST_WEEKS'})}</span>
              <br />
              <span className='text-muted'>{fomatDate()}</span>
            </div>
          </div>
        </div>
        <div id='kt_stats_widget_12_chart' className='card-rounded-bottom'></div>
      </div>
    </div>
  );

  function getChartOption() {
    const labelColor = '#B5B5C3';
    const borderColor = '#b5b5c338';
    const secondaryColor = '#B5B5C3';
    const baseColor = '#3699FF';

    return {
      series: [
        {
          name: intl.formatMessage({id: 'DASH.PEOPLE'}),
          data: accessLast.map((item) => item.totalPersons)
        },
        {
          name: intl.formatMessage({id: 'DASH.VISITORS'}),
          data: accessLast.map((item) => item.totalVisitants)
        }
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'bar',
        height: parseInt(`${heigth - 565}`) > 300 ? parseInt(`${heigth - 565}`) : 300,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '30%',
          borderRadius: '20px'
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: accessLast.map((item) => getDay(item)),
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px'
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px'
          }
        }
      },
      fill: {
        type: 'solid'
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        hover: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: '12px'
        },
        y: {
          formatter: function(val: any) {
            return val + ` ${intl.formatMessage({id: 'DASH.ACCESS'})}`;
          }
        }
      },
      colors: [baseColor, secondaryColor],
      grid: {
        padding: {
          top: 10
        },
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true
          }
        }
      }
    };
  }
}
