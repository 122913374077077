import { Modal } from 'react-bootstrap';
import { useTableHooks } from './hooks/table';
import React, { useEffect, useRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

interface Props {
  data: any[];
  isVisible: boolean;
  moduleId: number;
  title: string;
  hiddenModal(): void;
}
export function ModalFunctions({ data, isVisible, moduleId, hiddenModal, title }: Props) {
  const formRef = useRef<HTMLFormElement>(null);
  const { INTL } = useTableHooks();

  const columns = [
    {
      dataField: 'module',
      text: INTL('OPERATOR_ROLE_TAB_TREE_TABLE_COLLUm_MODULE'),
      style: {
        minWidth: '300px'
      }
    },

    {
      dataField: 'access',
      text: INTL('OPERATOR_ROLE_TAB_TREE_TABLE_COLLUm_ACCESS'),
      style: {
        minWidth: '200px'
      }
    }
  ];

  function renderColumnsName(name: string) {
    return columns.map((value, i) => {
      const up: any = { ...value };
      if (!i) {
        up.text = name;
      }
      return up;
    });
  }

  const values = data.filter((item: any) => item.idModule === moduleId);

  useEffect(() => {


    if (values.length === 0) return;
    const idModule = values[0].idModule;

    const elements = document.querySelectorAll(`#FORM [data-module="${idModule}"]`);



    elements.forEach((element) => {
      const name = element.getAttribute('name');

      if (document.querySelector(`#meref [name="${name}"]`)) {
        //@ts-ignore
        document.querySelector(`#meref [name="${name}"]`).value = element.value;
      }
    });
  }, [values]);

  return (
    <Modal show={isVisible} centered size={'lg'} onHide={hiddenModal}>
      <Modal.Header>
        <Modal.Title>
          {title} - {INTL('OPERATOR_ROLE_TAB_FOUR')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form ref={formRef} id='meref'>
          {values.map((m: any) => {
            return (
              <div className='function-panel' key={m.idModule} style={m.data.length > 0 ? {} : { display: 'none' }}>
                <BootstrapTable wrapperClasses='table-responsive' bordered={false} classes='table table-head-custom table-vertical-center overflow-hidden' remote bootstrap4 keyField='id' data={m.data} columns={renderColumnsName(INTL(m.name))} />
              </div>
            );
          })}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex justify-content-end'>
          <button type='button' className={'btn btn-danger'} onClick={hiddenModal}>
            {INTL('PERSON.NEWPERSON_CANCEL_BUTTON')}
          </button>
        </div>
        <div className='d-flex justify-content-end'>
          <button
            type='button'
            className={'btn btn-primary'}
            onClick={() => {
              const elements = document.querySelectorAll('#meref select');

              // console.dir(elements);
              elements.forEach((select) => {
                const name = select.getAttribute('name');

                if (document.querySelector(`#FORM [name="${name}"]`)) {
                  //@ts-ignore
                  document.querySelector(`#FORM [name="${name}"]`).value = select.value;
                }
              });

              hiddenModal();
            }}>
            {INTL('SAVE')}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
