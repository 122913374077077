import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { ModalSelectUsers } from './Components/modalSelectedUser';
import { Card, CardBody, CardHeader } from '../../../../../_metronic/_partials/controls';

import { ToggleSwitch } from 'src/_metronic/_partials/controls/forms/ToggleSwitch';
import { Container, FooterContainer } from './styles';
import { useHistory, useLocation } from 'react-router-dom';
import { ADD_MANAGE_CREDIT, EDIT_CREDIT_MANAGEMENT, GET_CREDIT_MANAGEMENT_BY_ID, GET_LIST_ALL_CREDIT_TYPES_ACTIVED, GET_LIST_ALL_CREDIT_TYPES_ACTIVED_CHANGED_POLICY } from 'src/services/manageCredtis';

import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { usePermisssion } from 'src/redux/userPermissions/usePermission';


interface ErrorRequestApi {
  response: {
    data: {
      message: string;
    };
  };
}

interface ListCreditType {
  description: string,
  id: number
}

export function CreateAndEditCreditTypesManagemnt() {
  const history = useHistory();
  const { pathname } = useLocation()
  const intl = useIntl();
  const [isModalPersonsActived, setIsModalPersonsActived] = useState(false);
  const [userId, setUserId] = useState(0);
  const [userData, setDataUser] = useState('');
  const [typeCreditSelected, setTtypeCreditSelected] = useState(0);
  const refValueInserted = useRef<HTMLInputElement>(null);
  const refValueInsertedAjusted = useRef<HTMLInputElement>(null);

  const [listCreditsTypes, setListCreditsTypes] = useState<ListCreditType[]>([])
  const [addCredit, setAddCredit] = useState(true);
  const [ajustCredit, setAjustCredit] = useState(false);

  let isEditing = pathname === '/creditmanagement/management' ? false : true
  // let isEditing = pathname === '/creditmanagement/create' ? false : true

  const [isRead, setIsRead] = useState(false);
  const { redirectView, actionsIsDesatived } = usePermisssion();

  useEffect(() => {
    redirectView(17);
    const value = actionsIsDesatived(17);
    setIsRead(value);
  }, []);

  function INTL(value: string) {
    return intl.formatMessage({ id: value });
  }

  function toggleModal() {
    setIsModalPersonsActived(!isModalPersonsActived);
  }

  function handleSelectedUser(item: any) {

    setUserId(item.id);
    setDataUser(item.name);
  }

  function hanldeAddListUsers() {
    toggleModal();
  }

  async function handleSave() {
    let value = '0';

    if (refValueInserted.current?.value && +refValueInserted.current?.value) {
      value = refValueInserted.current?.value;
    }

    if (refValueInsertedAjusted.current?.value && +refValueInsertedAjusted.current?.value) {
      value = refValueInsertedAjusted.current?.value;
    }

    if (+value <= 0) {
      return execToast('error', INTL('MANAGER_CREDIT_ERROR_VALUE'), 3000);
    }

    const data = {
      'creditTypeId': typeCreditSelected.toString(),
      'personId': userId.toString(),
      'currentBalance': value
    };

    try {

      if (!userId) {
        throw new Error('Is select one user')
      }

      if (!typeCreditSelected) {
        throw new Error('Is selecte type credit')
      }



      await ADD_MANAGE_CREDIT(data);

      execToast('success', INTL('MANAGEMENT_TYPES_CREDITS_CREATE_SUCCESS'), 3000);
      history.push('/creditmanagement')

    } catch (erro: ErrorRequestApi | any) {



      if (erro.message === 'Is selecte type credit') {
        execToast('error', INTL('MANAGEMENT_TYPES_CREDITS_CREATE_NO_TYPECREDIT'), 3000);
        return
      }

      if (erro.message === 'Is select one user') {
        execToast('error', INTL('MANAGEMENT_TYPES_CREDITS_CREATE_NO_PERSON'), 3000);
        return
      }

      if (erro.response.data.message == '410') {
        execToast('error', INTL('MANAGEMENT_TYPES_CREDITS_CREATE_PERSON_IS_EXISTING'), 3000);
        return
      }

      if (erro.response.data.message == '404') {
        execToast('error', INTL('MANAGEMENT_TYPES_CREDITS_CREATE_PERSON_OR_TYPECREDIT_NOT_EXISTING'), 3000);
        return
      }
    }
  }

  async function handleEdit() {
    let value = '0';

    if (refValueInserted.current && refValueInsertedAjusted.current) {

      if (addCredit) {
        value = refValueInserted.current?.value;

      } else {
        value = refValueInsertedAjusted.current?.value;
      }
    }

    const data = {
      "id": localStorage.getItem('@CONTROLID@/creditmanagement/edit')!,
      'creditTypeId': typeCreditSelected,
      'personId': userId,
      'currentBalance': value,
      'managementType': addCredit ? "Add" : "Adjust"
    };



    try {

      if (!userId) {
        throw new Error('Is select one user')
      }

      if (!typeCreditSelected) {
        throw new Error('Is selecte type credit')
      }



      await EDIT_CREDIT_MANAGEMENT(data);

      execToast('success', INTL('MANAGEMENT_TYPES_CREDITS_EDIT_SUCCESS'), 3000);
      history.push('/creditmanagement')

    } catch (erro: ErrorRequestApi | any) {



      if (erro.message === 'Is selecte type credit') {
        execToast('error', INTL('MANAGEMENT_TYPES_CREDITS_CREATE_NO_TYPECREDIT'), 3000);
        return
      }

      if (erro.message === 'Is select one user') {
        execToast('error', INTL('MANAGEMENT_TYPES_CREDITS_CREATE_NO_PERSON'), 3000);
        return
      }

      if (erro.response.data.message == '410') {
        execToast('error', INTL('MANAGEMENT_TYPES_CREDITS_CREATE_PERSON_IS_EXISTING'), 3000);
        return
      }

      if (erro.response.data.message == '404') {
        execToast('error', INTL('MANAGEMENT_TYPES_CREDITS_CREATE_PERSON_OR_TYPECREDIT_NOT_EXISTING'), 3000);
        return
      }
    }
  }

  async function getAllCreditTypes() {
    try {
      const { data } = await GET_LIST_ALL_CREDIT_TYPES_ACTIVED_CHANGED_POLICY({
        pageNumber: 1,
        pageSize: 1000,
        sortOrder: 'asc',
        status: '1',
        sortField: 'Description',
      })


      const values = data.data.data


      setListCreditsTypes(values)
    } catch { }
  }



  async function getData() {

    const id = localStorage.getItem('@CONTROLID@/creditmanagement/edit')!

    const data = await GET_CREDIT_MANAGEMENT_BY_ID(id)

    if (refValueInserted.current) {
      refValueInserted.current.value = data.data.data.currentBalance
    }

    setUserId(data.data.data.personId)
    setDataUser(data.data.data.personName)
    setTtypeCreditSelected(data.data.data.creditTypeId)
  }

  useEffect(() => {
    getAllCreditTypes()

    if (isEditing) {
      getData()
    }
  }, [])





  return (
    <Card fluidHeight={null} className={null}>
      {/* <CardHeader icon={null} toolbar={null} className={null} labelRef={null} sticky={false} linkAddTitle={undefined} title='' /> */}

      {
        isEditing && (
          <CardHeader icon={null} toolbar={null} className={null} labelRef={null} sticky={false} linkAddTitle={undefined} title={isEditing ? INTL('ITEM_MANAGER_CREDIT_BUTTON') : INTL('ITEM_MANAGER_CREDIT_BUTTON')} />

        )
      }

      <CardBody fit={null} fluid={null} className={null}>
        <Container>
          <div>
            <label htmlFor=''>{INTL('MANAGEMENT_TYPES_CREDITS_GRID_COLUMN_ONE')}</label>
            <span style={{ width: '310px' }} onClick={toggleModal} className='form-control'>
              {userData === '' ? INTL('MANAGEMENT_TYPES_CREDITS_BATCH_CREDIT_SELECT_PERSON_OR_VISITS') : userData}
            </span>

            <div className='mt'>
              <span>
                <ToggleSwitch
                  checked={addCredit}
                  onChange={(e) => {
                    // if(!isEditing) return 

                    setAddCredit(e.target.checked);

                    if (e.target.checked) {
                      setAjustCredit(false);
                      setAddCredit(e.target.checked);
                    } else {
                      setAjustCredit(true);
                      setAddCredit(false);
                    }
                  }}
                />{' '}
                {INTL('MANAGEMENT_TYPES_CREDITS_BATCH_CREDIT_ADD_CASH')}
              </span>
              <input ref={refValueInserted} disabled={ajustCredit} min="0" type='number' className='form-control' defaultValue="0" />
            </div>
          </div>

          <div>
            <label htmlFor=''>{INTL('MANAGEMENT_TYPES_CREDITS_BATCH_CREDIT_SELECT_TYPE_CREDIT')}</label>
            <select value={typeCreditSelected} className='custom-select' onChange={e => {

              setTtypeCreditSelected(+e.target.value)
            }}>
              <option value='0'>{INTL('MANAGEMENT_TYPES_CREDITS_BATCH_CREDIT_SELECT_TYPE_CREDIT')}</option>
              {
                listCreditsTypes.map(item => <option value={item.id} key={item.id}>{item.description}</option>)
              }
            </select>

            <div className='mt'>
              <span>
                <ToggleSwitch
                  // disabled={!isEditing}
                  checked={ajustCredit}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setAjustCredit(e.target.checked);
                      setAddCredit(false);
                    } else {
                      setAjustCredit(e.target.checked);
                      setAddCredit(true);
                    }
                  }}
                />{' '}
                {INTL('MANAGEMENT_TYPES_CREDITS_BATCH_CREDIT_AJUST_CASH')}
              </span>

              <input ref={refValueInsertedAjusted} type='number' min="0" className='form-control' defaultValue="0" />
              {/* <input ref={refValueInsertedAjusted} disabled={addCredit} type='number' className='form-control' /> */}
            </div>
          </div>
        </Container>

        <FooterContainer>
          <button
            className='btn btn-danger'
            onClick={() => {
              history.push('/creditmanagement');
            }}>
            {INTL('PAGE.BLUEPRINT.BTN.Cancel')}
          </button>
          <button
            disabled={isRead}
            className='btn btn-primary'
            onClick={() => {

              if (isEditing) {

                handleEdit()

              } else {
                handleSave();
              }
            }}>
            {INTL('PAGE.BLUEPRINT.BTN.SAVE')}
          </button>
        </FooterContainer>

        <ModalSelectUsers isVisible={isModalPersonsActived} close={toggleModal} users={[userId]} fnUserSeelected={handleSelectedUser} fnSave={hanldeAddListUsers} title={INTL('Selecione uma Pessoa/Visitante')} />
      </CardBody>
    </Card>
  );
}
