import {execToast} from 'src/_metronic/_partials/controls/Toast';
import * as requestFromServer from '../../../../services/authCrud';
import {operatorSlice, callTypes} from './operatorSlice';

const {actions} = operatorSlice;

export const updateOperator = (operator: any) => (dispatch: Function) => {
  dispatch(actions.startCall({callType: callTypes.action}));
  return requestFromServer
    .OperatorResetPassword(operator.email, operator.oldPassword, operator.newPassword, operator.avatar, operator.name)
    .then((response) => {
      dispatch(actions.operatorUpdated({operator}));
      execToast('success', 'Success edit', 3000);
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't update operator";
      dispatch(actions.catchError({error, callType: callTypes.action}));
      execToast('error', `Error - ${error.clientMessage}`, false);
      return error;
    });
};

export const getOperatorData = () => (dispatch: Function) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .getCurrentLoggedUser()
    .then((response) => {
      const {
        data: {data: operator}
      } = response;

      dispatch(actions.operatorInfoSetted(operator));

      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't update operator";
      dispatch(actions.catchError({error, callType: callTypes.action}));
      execToast('error', `Error - ${error.clientMessage}`, false);

      return error;
    });
};
