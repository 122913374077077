import axios from 'axios';
import { getUnixTime } from 'date-fns';
import { TFilterExpiredDocument } from 'src/app/modules/RegisterLogs/components/ExpiredDocuments/hooks/useQueryExpiredDocuments';
import { defaultDateTime } from 'src/utils/defaultDateTime';

export type TExpiredDocuments = {
  hasValue: true;
  value: {
    data: {
      personName: string;
      documentType: string;
      expiration: string;
      expiredDays: number;
      registrationName: string | null;
    }[];
    total: number;
    page: number;
    limit: number;
    pages: number;
  };
};

const idsecureAPI = process.env.REACT_APP_IDSECURE_API_URL;
const idsecureAPI_REPORTS = process.env.REACT_APP_IDSECURE_API_URL_REPORTS

// CREATE =>  POST: add a new person to the server
export function createPerson(person: any) {
  // const config = JSON.parse(localStorage.getItem('persist:v713-demo1-auth') || '{}');
  // const token = JSON.parse(config.authToken);
  // const configHeader = {
  //   headers: {Authorization: `Bearer ${token.token}`}
  // };
  return axios.post(`${idsecureAPI}/persons`, { ...person, updatePersonOperatorRole: true });
}

// READ
export function getAllPersons(params: any) {
  return axios.get(`${idsecureAPI}/persons`, { params });
}

export function checkAndRenderNewQRCODE() {
  return axios.get(`${idsecureAPI}/persons/qrcode`);
}

export function getPersonById(personId: number) {
  return axios.get(`${idsecureAPI}/persons/${personId}`); // GET: get a person by id
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findPersonsAdvanced(params: any) {
  return axios.get(`${idsecureAPI}/persons/advanced`, { params });
}

export function findPersons(params: any) {
  return axios.get(`${idsecureAPI}/persons`, { params });
}

export function findPersonsInGroups(queryParams: any, groupId: any) {
  return axios.get(`${idsecureAPI}/groups/${groupId}/persons`, {
    params: queryParams
  });
}

export function findGroupsInPersons(groupId: any) {
  return axios.get(`${idsecureAPI}/persons/${groupId}/groups`);
}

export function findGroups(queryParams: any) {
  return axios.get(`${idsecureAPI}/persons/groups`, {
    params: {
      ...queryParams,
      pageSize: 10000000
    }
  });
}

// UPDATE => PUT: update the person on the server
export function updatePerson(person: any) {
  return axios.put(`${idsecureAPI}/persons`, { ...person, updatePersonOperatorRole: true });
}

// UPDATE AVATAR => POST: update the person avatar on the server
export async function uploadPersonAvatar(avatar: any, personId: number) {
  const data = new FormData();
  data.append('file', avatar);
  return await axios.post(`${idsecureAPI}/persons/personPhoto/${personId}`, data);
}

//
export function activePerson(personId: any, personStatus: any) {
  return axios.put(`${idsecureAPI}/persons/active/${personId}`, { ...personStatus });
}

// UPDATE Status
export function updateStatusForPersons(ids: number[], status: any) {
  return axios.post(`${idsecureAPI}/updateStatusForPersons`, {
    ids,
    status
  });
}

// DELETE => delete the person from the server
export function deletePerson(personId: number, erase?: boolean) {
  return axios.delete(`${idsecureAPI}/persons/${personId}`, {
    params: {
      erase
    }
  });
}

export function enablePerson(personId: number) {
  return axios.put(`${idsecureAPI}/persons/${personId}`);
}

// DELETE Persons by ids
export function deletePersons(ids: number[]) {
  return axios.post(`${idsecureAPI}/persons/deletePersons`, { ids });
}

export function linkPersonGroup(personId: any, groupIds: any) {
  return axios.post(`${idsecureAPI}/persons/linkPersonGroup?personId=${personId}&groupIds=${groupIds}`);
}

export function unlinkPersonGroup(personId?: any, groupId?: any, isAll?: boolean, personType?: any) {
  return axios.delete(`${idsecureAPI}/groups/${groupId}/persons`, {
    data: [personId],
    params: {
      isAll,
      personType
    }
  });
}

export const linkPeopleToGroup = (groupId: number, people: any[], isAll = false, personType: string) => {
  return axios.post(`${idsecureAPI}/groups/${groupId}/persons`, people, {
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      isAll,
      personType
    }
  });
};

export const getPin = () => {
  return axios.get(`${idsecureAPI}/persons/idNumber`);
};

// DELETE Person fingerprints by id
export function deletePersonFingerprint(id: number, isDuress: boolean) {
  return axios.delete(`${idsecureAPI}/persons/${id}/deleteTemplates?isDuress=${isDuress}`);
}

// GET Devices
export function getAllDevices(params: any) {
  return axios.get(`${idsecureAPI}/persons/devices`, { params });
}

// GET Devices
export function getAllDevicesNoPag(params: any) {
  return axios.get(`${idsecureAPI}/persons/devices/verifiedDevices`, { params });
}

export function getAllDevicesNoPagChangedPolicy(params: any) {
  return axios.get(`${idsecureAPI}/temporarycards/devices/verifiedDevices`, { params });
}

// POST cadastro remoto
export function initRemoteRegister(data: any) {
  return axios.post(`${idsecureAPI}/persons/devices/enrollCredential`, data);
}

// GET status do cadastro remoto
export function getRemoteRegisterStatus(params: any) {
  return axios.get(`${idsecureAPI}/persons/devices/enrollingStatus`, { params });
}

export function getRealTimeStatus(data: any) {
  return axios.post(`${idsecureAPI}/persons/devices/realtime-status`, data);
}

export function cancelRemoteRegister(data: any) {
  return axios.post(`${idsecureAPI}/persons/devices/cancelEnrolling`, data);
}

// export function saveCredentials(data: any) {
//   return axios.post(`${idsecureAPI}/persons/devices/saveCredentials`, data);
// }

type data = {
  email: string;
  base64: string;
};

export function sendMail({ email, base64 }: data) {
  return axios.post(`${idsecureAPI}/persons/send-qrcode`, {
    'to': [email],
    'subject': 'QRCODE',
    'body': '',
    'base64QRCode': base64,
    'isHtml': true
  });
}

export function sendMailVisitors({ email, base64 }: data) {
  return axios.post(`${idsecureAPI}/visitors/send-qrcode`, {
    'to': [email],
    'subject': 'QRCODE',
    'body': '',
    'base64QRCode': base64,
    'isHtml': true
  });
}

export function GET_ALL_TYPE_DOCUMENT() {
  return axios.get(`${idsecureAPI}/persons/settings/documentTypes`);
}

export function GET_ALL_TYPE_DOCUMENT_CHANGED_POLICY() {
  return axios.get(`${idsecureAPI}/persons/config/documentTypes`);
}

export type TGetExpiredDocuments = TFilterExpiredDocument;

export async function getExpiredDocuments(raw: TGetExpiredDocuments) {
  const params = {
    PersonsIds: raw.personId.join(','),
    DocumentsTypesId: raw.documentType.join(','),
    DtStart: raw.startDate && getUnixTime(raw.startDate),
    DtEnd: raw.endDate && getUnixTime(raw.endDate),
    sortField: 'Expiration',
    sortOrder: 'desc',
    pageNumber: raw.page || 1,
    pageSize: raw.sizePerPage,
    culture: raw.culture
  };

  const { data } = await axios.get<TExpiredDocuments>(`${idsecureAPI}/persons/persondocument`, {
    params,
    transformResponse: [function (data) {

      const response = JSON.parse(data)


      try {
        const transformValues = response.value.data.map((v: any) => {
          return {
            ...v,
            expiration: defaultDateTime.labelDateTime(v.expiration)
          }
        })

        // response.data.data = transformValues


        return {


          hasValue: response.hasValue,
          value: {
            ...response.value,
            data: transformValues
          }
        }



      } catch {

        return response
      }


    }]
  });

  return data.value;
}

export function cardIsValidPerson(params: any) {
  return axios.get(`${idsecureAPI}/persons/validateCard`, { params });
}
