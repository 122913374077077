const BASE = '/schedule-inactivation';

export const SCHEDULE_INACTIVATION_ROUTES = {
  BASE,
  CREATE: `${BASE}/create`,
  EDIT: (id: number | string) => `${BASE}/edit/${id}`
};

export const SCHEDULE_INACTIVATION_ROUTES_LIST = [
  SCHEDULE_INACTIVATION_ROUTES.BASE,
  SCHEDULE_INACTIVATION_ROUTES.CREATE,
  SCHEDULE_INACTIVATION_ROUTES.EDIT(':id')
];
