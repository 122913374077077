import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {LayoutSplashScreen} from '../../../../_metronic/layout';
import cookie from 'js-cookie';
import * as auth from '../_redux/authRedux';

interface IProps {
  logout: () => void;
  hasAuthToken: Boolean;
}

class Logout extends Component<IProps> {
  componentDidMount() {
    cookie.remove('auth-token');
    this.props.logout();
  }

  render() {
    const {hasAuthToken} = this.props;
    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to='/auth/login' />;
  }
}

export default connect<any, any, any, any>(({auth}) => ({hasAuthToken: Boolean(auth.authToken)}), auth.actions)(Logout);
