import {createSlice} from '@reduxjs/toolkit';

const initialVisitorsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  visitorForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: 'list',
  action: 'action'
};

export const visitorsSlice = createSlice({
  name: 'visitors',
  initialState: initialVisitorsState,
  reducers: {
    catchError: (state: any, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state: any, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getPersonById
    visitorFetched: (state: any, action) => {
      state.actionsLoading = false;
      state.visitorForEdit = action.payload.visitorForEdit;
      state.error = null;
    },
    // findvisitors
    visitorsFetched: (state: any, action) => {
      const {totalCount, entities} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.visitorForEdit = undefined;
    },
    // createvisitor
    visitorCreated: (state: any, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.visitor);
      state.visitorForEdit = action.payload.visitor;
    },
    // updatevisitor
    visitorUpdated: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity: any) => {
        if (entity.id === action.payload.visitor.id) {
          return action.payload.visitor;
        }
        return entity;
      });
      state.visitorForEdit = action.payload.visitor;
    },
    // deletevisitor
    visitorDeleted: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el: any) => el.id !== action.payload.id);
    },
    // deletevisitors
    visitorsDeleted: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el: any) => !action.payload.ids.includes(el.id));
    },
    // visitorsUpdateState
    visitorsStatusUpdated: (state: any, action) => {
      state.actionsLoading = false;
      state.error = null;
      const {ids, status} = action.payload;
      state.entities = state.entities.map((entity: any) => {
        if (ids.findIndex((id: number) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    linkedVisitors: (state: any, action) => {
      const {visitors} = action.payload;
      state.linkedVisitorsIds = visitors;
    },
    linkedVisitorDeleted: (state: any, action) => {
      state.linkedVisitorsIds = state.linkedVisitorsIds.filter((el: any) => el.id !== action.payload.id);
    }
  }
});
