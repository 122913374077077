import React from 'react';
import {InputHTMLAttributes} from 'react';
// import './styles.scss'

type ToggleSwitchProps = InputHTMLAttributes<HTMLInputElement>;

export function ToggleSwitch({...props}: ToggleSwitchProps) {
  return (
    <>
      <label className='switch-check mr-3'>
        <input
          style={{
            opacity: 0,
            width: 0,
            border: 'none',
            height: 0,
            visibility: 'hidden'
          }}
          className='input-checkbox'
          type='checkbox'
          {...props}
        />
        <span className='slider-check round'></span>
      </label>
    </>
  );
}
