import React from 'react';
export const pageListRendererModal = ({pages, onPageChange}) => {
  // just exclude <, <<, >>, >
  //   const pageWithoutIndication = pages.filter((p) => typeof p.page !== 'string');
  return (
    <div className='col-sm-12'>
      {pages.map((p) => (
        <a key={p.page} onClick={() => {

          // if(!p.active){
          //   //@ts-ignore
          //  window.setPageNumber(p.page)
          // }
         
          
          onPageChange(p.page)
        }} className={`btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1 ${p.active ? 'active' : ''}`}>
          {p.page == '<<' && <i className='ki ki-bold-double-arrow-back icon-xs'></i>}
          {p.page == '<' && <i className='ki ki-bold-arrow-back icon-xs'></i>}
          {/* {p.page > 5 && <a className='btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1'>...</a>} */}
          {typeof p.page !== 'string' && <span>{p.page}</span>}
          {/* {p.page < 5 && p.page < pagesCount && <a className='btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1'>...</a>} */}
          {p.page == '>' && <i className='ki ki-bold-arrow-next icon-xs'></i>}
          {p.page == '>>' && <i className='ki ki-bold-double-arrow-next icon-xs'></i>}
        </a>
      ))}
    </div>
  );
};
