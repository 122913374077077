import {startOfDay} from 'date-fns';
import {VisitorResponse} from '../domain/visitor';
import {VisitorResponseDTO} from '../repo/response';

export class VisitorMap {
  static toDomain(visitor: VisitorResponseDTO['data']): VisitorResponse {
    return {
      visitor: {
        name: visitor.name || '',
        email: visitor?.email || '',
        entryDate: startOfDay(new Date(visitor.visitorStartDate)),
        exitDate: startOfDay(new Date(visitor.visitorEndDate)),
        phoneNumber: visitor?.phoneNumber || '',
        phoneDDI: visitor.phoneDDI || '',
        avatar: visitor?.personPhoto?.photo || '',
        rawAvatar: visitor?.personPhoto,
        personDocuments: visitor?.personDocuments?.pop()
      },
      formConfig: {
        requiredFields: visitor.mandatoryInputRemoteOnBoardings?.reduce(
          (fields, field) => ({...fields, [field]: true}),
          {}
        ) as any,
        documentTypes: [...visitor.documentTypes.map((type) => ({value: type.id, label: type.description})), {value: undefined as any , label: 'NONE'}]
      }
    };
  }
}
