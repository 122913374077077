import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { v4 } from 'uuid';
import { Container, TableContainerB } from './styles';

interface LOGSPROPS {
  datetime: string;
  name: string;
  area: string;
  device: string;
  authorizated: string;
  id: number;
  eventClass: boolean;
}

interface PROPS {
  data: LOGSPROPS[];
  nextPage(): void;
  selected(id: number): void;
  idActived: number;
}
export function Table({ data, nextPage, selected, idActived }: PROPS) {
  const trigger = data.length - 2;

  const intl = useIntl()

  useEffect(() => {
    if (data.length < 8) return;

    const intersectionObserver = new IntersectionObserver((entries) => {
      const elementVisible = entries.some((entrie) => entrie.isIntersecting);

      if (elementVisible) {

        //setTimeout(() => {
        nextPage();
        // setData(current => {
        //   return [...current,20,30,40,50,60,70,80,90]
        // })
        //},1000)
      }
    });

    intersectionObserver.observe(document.querySelector('#next')!);

    return () => {
      intersectionObserver.disconnect();
    };
  }, [data]);

  return (
    <Container>
      <TableContainerB responsive>
        <thead>
          <tr>
            <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
              {intl.formatMessage({ id: 'DATE_TIME_LABEL' })}
            </th>
            <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
              {intl.formatMessage({ id: 'NAME_LABEL' })}
            </th>
            <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
              {intl.formatMessage({ id: 'AREA_LABEL' })}
            </th>
            <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
              {intl.formatMessage({ id: 'DEVICE_LABEL' })}
            </th>
            <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
              {intl.formatMessage({ id: 'AUTHORIZATION_LABEL' })}
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              // <tr style={idActived === item.id ? {backgroundColor: '#e6e6e6'} : {}} key={v4()} data-item={item.id} className='item-table' id={index === trigger ? 'next' : ''} onClick={() => selected(item.id)}>
              <tr
                style={
                  idActived === item.id
                    ? item.eventClass
                      ? { backgroundColor: '#D2F6F4' }
                      : { backgroundColor: '#F6D6D2' }
                    : {}
                }
                key={v4()}
                data-item={item.id}
                className='item-table'
                id={index === trigger ? 'next' : ''}
                onClick={() => selected(item.id)}>
                <td>{item.datetime}</td>
                <td>{item.name}</td>
                <td>{item.area}</td>
                <td>{item.device}</td>
                <td className={item.eventClass ? 'green' : 'red'}>{item.authorizated}</td>
              </tr>
            );
          })}
        </tbody>
      </TableContainerB>
    </Container>
  );
}
