import React, { useEffect, useMemo, useState } from 'react'
import { IoIosArrowDown } from 'react-icons/io'
import { ToggleSwitch } from 'src/_metronic/_partials/controls/forms/ToggleSwitch';


import { Item, Main } from './styles'
import { HandlerAlterInputConfigProps } from '../..';


interface Props {
  values: {
    visitor: {
      isRequiredCPF: boolean,
      isValidationCPF: boolean,
    },

    people: {
      isRequiredCPF: boolean,
      isValidationCPF: boolean,
    },
  }

  alterValues: (data: HandlerAlterInputConfigProps) => void
}

export function InputsConfigs({ alterValues, values }: Props) {
  const [people, setPeople] = useState({
    nameSession: 'Pessoas',
    inputs: [
      {
        nameInput: 'isRequiredCPF',
        nameInputView: 'CPF Obrigatorio',
        isRequired: values.people.isRequiredCPF,
      },
      {
        nameInput: 'isValidationCPF',
        nameInputView: 'Validar CPF',
        isRequired: values.people.isValidationCPF,
      },


    ]
  })


  const [visitor, setVisitor] = useState({
    nameSession: 'Visitantes',
    inputs: [
      {
        nameInput: 'isRequiredCPF',
        nameInputView: 'CPF Obrigatorio',
        isRequired: values.visitor.isRequiredCPF,
      },
      {
        nameInput: 'isValidationCPF',
        nameInputView: 'Validar CPF',
        isRequired: values.visitor.isValidationCPF,
      },


    ]
  })

  useEffect(() => {


    setVisitor({
      nameSession: 'Visitantes',
      inputs: [
        {
          nameInput: 'isRequiredCPF',
          nameInputView: 'CPF Obrigatorio',
          isRequired: values.visitor.isRequiredCPF,
        },
        {
          nameInput: 'isValidationCPF',
          nameInputView: 'Validar CPF',
          isRequired: values.visitor.isValidationCPF,
        },


      ]
    })

    setPeople({
      nameSession: 'Pessoas',
      inputs: [
        {
          nameInput: 'isRequiredCPF',
          nameInputView: 'CPF Obrigatorio',
          isRequired: values.people.isRequiredCPF,
        },
        {
          nameInput: 'isValidationCPF',
          nameInputView: 'Validar CPF',
          isRequired: values.people.isValidationCPF,
        },


      ]
    })
  }, [values])

  return (
    <Main>

      <Item>
        <div className='header-item'>
          <span>
            {people.nameSession}
          </span>

          <IoIosArrowDown />

          {/* <IoIosArrowUp /> */}
        </div>

        <div className='list-inputs'>
          {
            people.inputs.map((item, index: number) => {
              return (
                <div key={item.nameInput}>
                  <span>{item.nameInputView}</span>
                  <ToggleSwitch
                    checked={item.isRequired}
                    onChange={(e) => {

                      alterValues({
                        value: e.target.checked,
                        view: 'people',
                        input: item.nameInput
                      })
                    }}
                  />
                </div>
              )
            })
          }
        </div>
      </Item>

      <Item>
        <div className='header-item'>
          <span>
            {visitor.nameSession}
          </span>

          <IoIosArrowDown />

          {/* <IoIosArrowUp /> */}
        </div>

        <div className='list-inputs'>
          {
            visitor.inputs.map(item => {
              return (
                <div key={item.nameInput}>
                  <span>{item.nameInputView}</span>
                  <ToggleSwitch
                    checked={item.isRequired}
                    onChange={(e) => {
                      alterValues({
                        value: e.target.checked,
                        view: 'visitor',
                        input: item.nameInput as any
                      })
                    }}
                  />
                </div>
              )
            })
          }
        </div>
      </Item>

    </Main>
  )
}


