type MODULE_PROPS = {
  id: number;
  permission: number;
};

interface FUNCTIONALITY_PROPS {
  id_module: number;
  id: number;
  permission: number;
}

export type USER_PERMISSIONS_PROPS = {
  modules: MODULE_PROPS[];
  functionalities: FUNCTIONALITY_PROPS[];
};

export enum ActionTypes {
  SET_PERMISSIONS = 'SET_PERMISSIONS'
}
