import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  background-color: white;

  & > * {
    padding: 20px;
  }

  @media (max-width: 576px) {
    .bg-width-200 {
      width: 160px;
    }

    #app-version,
    #logo-copy {
      display: none;
    }

    .title {
      font-size: 28px;
    }
    .sub-title {
      font-size: 16px;
    }
  }

  .show-in {
    opacity: 0;
    transform: translateY(-10px);
    animation: showIn 0.3s ease-out forwards;
  }

  @keyframes showIn {
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .title {
    font-size: 32px;
    margin: 0;
  }

  .sub-title {
    font-size: 18px;
    color: #b5b5c3;

    margin: 0;
    line-height: 1;
  }
`;

export const Footer = styled.div`
  margin-top: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-image: url('/media/bg/bg-4.jpg');

  img {
    margin-bottom: -11px;
  }

  div {
    display: flex;

    align-items: center;
    gap: 20px;

    & * {
      margin: 0 !important;
    }
  }
`;
