import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { CardHeaderToolbar } from 'src/_metronic/_partials/controls';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { Pagination } from 'src/_metronic/_partials/controls';
import BootstrapTable, { SortOrder } from 'react-bootstrap-table-next';
import { NoRecordsFoundMessage, PleaseWaitMessage } from 'src/_metronic/_helpers';
import { useIntl } from 'react-intl';
import { AsyncPaginate } from 'react-select-async-paginate';

import { useLang } from 'src/_metronic/i18n';
import moment from 'moment';


// @ts-ignore
import DatetimeRangePicker from 'react-datetime-range-picker';

//@ts-ignore
import { CSSObjectWithLabel } from 'react-select';
import LongMenu from 'src/_metronic/_partials/controls/MenuToolbarCard';
import { findGroups, getAllPersons } from 'src/services/accessLogsCrud';
import { standardTranslation } from 'src/utils/standardTranslation';
import { getAllReports } from 'src/services/reports';


import { loadConfig } from 'src/utils/configColumnsStorage';
import { TableNoData } from 'src/_metronic/layout/components/TableNoData';
import { checkThereDataTheArray } from 'src/utils/checkThereDataTheArray';
import { endOfDay, startOfDay, subMonths } from 'date-fns';
import { Culture } from 'src/utils/culture';
import { dateToUNIX } from './utils/utils';
import { getFileRequest } from 'src/services/getFiles';
import { FaRegFilePdf } from 'react-icons/fa';
import { statusPDF, typeReportPDF } from './utils/typeReportPDF';
import { typesReports } from './utils/typesReports';
import { DateTimeRangeInput } from 'src/app/components/inputs/DateTimeRangeInput';
import { nameAndTypePDFs } from 'src/utils/getPDF';
// import { SelectorColumn } from './components/SelecorColumn';

const headersCSVLogs = {
  'pt': [
    'NOME',
    'MATRICULA',
    'CPF',
    'RG',
    'PIS',
    'CÓDIGO DE BARRAS',
    'E-MAIL',
    'OBSERVAÇÕES',
    'INATIVADO',
    'TELEFONE',
    'DATA/HORA',
    'PROFISSÃO'
  ],
  'en': ['NAME', 'ENROLLMENT', 'CPF', 'RG', 'PIS', 'BARCODE', 'MAIL', 'NOTES', 'INACTIVATED', 'PHONE', 'DATETIME', 'PROFESSION'],
  'es': [
    'Nombre',
    'MATRICULA',
    'CPF',
    'RG',
    'PIS',
    'CÓDIGO DE BARRAS',
    'CORREO ELECTRÓNICO',
    'COMENTARIOS',
    'INACTIVADO',
    'TELÉFONO',
    'DATOS',
    'PROFESIÓN'
  ]
};

let isApiRequest = false;
let debounce: NodeJS.Timeout | null = null;

export function ListPdf() {
  const query = {
    pageSize: 10,
    pageNumber: 1,
    sortOrder: 'desc',
    sortField: 'Time'
  };

  const today = startOfDay(new Date());
  const todayEnd = endOfDay(new Date());


  const [endDate, setEndDate] = useState(todayEnd);

  const [startDate, setStartDate] = useState(subMonths(today, 2));


  const [isLoadingData, setLoadingData] = useState(true);
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [idsVisitsActived, setIdsVisitsActived] = useState<any[]>([]);
  const [idsGrups, setIdsGrups] = useState<any[]>([]);
  const [dataTableTotal, setDataTableTotal] = useState<number>(0);
  const [pagination, setPagination] = useState<number>(1);
  const [sizePerPage, setSizePerPage] = useState<number>(10);


  const [type, setType] = useState('')


  const [data, setData]: any = useState({ start: subMonths(today, 2), end: todayEnd });





  const intl = useIntl();
  const languages = useLang();

  const defaultSortedColumn: { dataField: any; order: SortOrder } = { dataField: 'time', order: 'desc' };

  const columns = [
    {
      dataField: 'requestDate',
      text: intl.formatMessage({ id: 'PDF.TABLE.REQUEST.DATE' }),
      headerAlign: 'center',
      align: 'center',
      sort: true,
      style: {
        minWidth: '150px',
        textAlign: 'center'
      }
    },
    // {
    //   dataField: 'personId',
    //   text: intl.formatMessage({ id: 'personId' }),
    //   formatter: (value?: string) => (!!value ? value : '-'),
    //   headerAlign: 'center',
    //   align: 'center',
    //   sort: true,
    //   style: {
    //     minWidth: '150px',
    //     textAlign: 'center'
    //   }
    // },
    {
      dataField: 'type',
      text: intl.formatMessage({ id: 'ACCESS_LOGS.BLACK_LIST.TYPE' }),
      headerAlign: 'center',
      align: 'center',
      sort: true,
      style: {
        minWidth: '150px',
        textAlign: 'center'
      }
    },
    {
      dataField: 'status',
      text: intl.formatMessage({ id: 'REPORTS.CURRENT_STATUS' }),
      headerAlign: 'center',
      align: 'center',
      sort: true,
      style: {
        minWidth: '150px',
        textAlign: 'center'
      }
    },
    {
      dataField: 'link',
      text: intl.formatMessage({ id: 'PDF' }),
      headerAlign: 'center',
      align: 'center',
      sort: true,
      style: {
        minWidth: '150px',
        textAlign: 'center'
      }
    },


  ];





  const getData = async () => {
    const query: any = {
      dtStart: dateToUNIX(data.start),
      dtEnd: dateToUNIX(data.end)
    };

    if (data.start == 0) {
      delete query.dtStart;
      delete query.dtEnd;
    }

    const params = {
      pageSize: sizePerPage,
      pageNumber: pagination,
      sortOrder: 'desc',
      sortField: 'Id',
      // culture: Culture(languages),
      status: 1,
      reportType: type,
      ...query
    };

    const resultApi = await getFileRequest.listPDFs(params);



    // setDataTableTotal(resultApi.data.data.total);

    const dataToTable = resultApi.data.data.data.map((item: any) => {
      return {

        status: statusPDF(item.status),
        type: intl.formatMessage({ id: nameAndTypePDFs.getOnlyNames(item.type).nameToIntl }),
        personId: item.personId,
        requestDate: item.requestDate,
        // ? moment(item.requestDate).format(`${languages == 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'} HH:mm:ss`)
        // : item.requestDate,
        link: item.link ? <a href={item.link} download="relatodio.pdf" target='_blank' rel="noopener noreferrer">
          <FaRegFilePdf size={16} />
        </a> : <FaRegFilePdf color='#ddd' size={16} />,

      };
    });


    // // setDataTable(dataToTable);

    setTimeout(() => {
      setLoadingData(false);
      setDataTable(checkThereDataTheArray(dataToTable, intl, 'name'));
      setDataTableTotal(resultApi.data.data.total);
      isApiRequest = false;
    }, 100);
  };



  useEffect(() => {

    if (debounce) clearTimeout(debounce);
    setLoadingData(true);

    debounce = setTimeout(() => {
      if (isApiRequest) return;

      isApiRequest = true;
      getData();
    }, 900);

    return () => {
      isApiRequest = false;
    };
  }, [pagination, idsVisitsActived, sizePerPage, data, idsGrups, type]);



  const paginationOptions = {
    custom: true,
    totalSize: dataTableTotal,
    sizePerPageList: window.sizePerPageList,
    sizePerPage: sizePerPage,
    page: pagination
  };



  const columnsToTable = columns
    .map((item: any, index) => {
      if (index === 0) {
        delete item.align;
        delete item.headerAlign;

        item.style = {
          minWidth: '150px'
        };
        return item;
      }

      return item;
    });


  return (
    <Card style={{ marginTop: 80 }} className={'card card-custom gutter-b mt-md-0'}>
      <Card.Header>
        <Card.Title className={'m-0'}>{intl.formatMessage({ id: 'PDF.LIST.PAGE.TILE' })}</Card.Title>


      </Card.Header>
      <Card.Body>
        <PaginationProvider
          pagination={paginationFactory(
            //@ts-ignore
            paginationOptions
          )}>
          {({ paginationProps, paginationTableProps }: any) => {

            return (
              <Pagination isLoading={false} paginationProps={paginationProps}>
                <form
                  className={'form mb-3'}
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}>
                  <div className={`row col-12 mb-5`} style={{ paddingRight: '0px' }}>
                    <div className={`col-6 mb-5 mb-md-0`}>

                      {/* <DatetimeRangePicker
                        timeFormat={"HH:mm"}
                        className={'row d-flex'}
                        inputProps={{
                          className: 'form-control'
                        }}
                        dateFormat={languages == 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'}
                        pickerClassName={'col-12 mb-3 mb-md-0 col-md'}
                        onChange={({ start, end }: { start: Date; end: Date }) => {

                          setPagination(1);

                          setData({
                            end: end,
                            start: start
                          });
                          // clearTimeout(timeout);

                          // timeout = setTimeout(() => {
                          //   setQueryParams((prev: any) => ({...prev, dtStart: dateToUNIX(start), dtEnd: dateToUNIX(end)}));
                          // }, 500);
                        }}
                        endDate={data.end}
                        startDate={data.start}
                      /> */}


                      <DateTimeRangeInput
                        label={intl.formatMessage({ id: 'ACCESS_LOGS.INPUTS.DATETIME_RANGE' })}
                        valueEnd={endDate}
                        valueStart={startDate}
                        updateValueEnd={(e) => {
                          setPagination(1);
                          setEndDate(e);

                          setData({
                            end: dateToUNIX(e),
                            start: dateToUNIX(data.start)
                          });
                        }}
                        updateValueStart={(e) => {
                          setPagination(1);
                          setStartDate(e);

                          setData({
                            end: dateToUNIX(data.end),
                            start: dateToUNIX(e)
                          });

                        }}
                      />
                    </div>


                    <div className={`col-3 mb-5 mb-md-0`}>
                      <label className={'form-label'}>{intl.formatMessage({ id: 'REPORTS.AUDITLOG.FILTER.TYPE.OPERATOR' })}</label>

                      <select value={type} onChange={(e) => { setType(e.target.value) }} className='form-control'>
                        <option value="">{intl.formatMessage({ id: 'PERSON.PERSONS_FILTER_STATUS_OP1' })}</option>
                        {
                          typesReports(intl).map(o => (
                            <option value={o?.value} key={o?.value}>{o?.label}</option>
                          ))
                        }
                      </select>
                    </div>



                  </div>
                </form>

                {isLoadingData ? (
                  <TableNoData columnsToTable={columnsToTable} paginationTableProps={paginationTableProps} />
                ) : (
                  <BootstrapTable
                    onTableChange={(type, props) => {
                      if (props.page !== 0) {
                        setPagination(props.page);
                      }

                      setSizePerPage(props.sizePerPage);
                    }}
                    // defaultSorted={[defaultSortedColumn]}
                    wrapperClasses='table-responsive'
                    bordered={false}
                    classes='table table-head-custom table-vertical-center overflow-hidden'
                    remote
                    bootstrap4
                    keyField='id'
                    data={dataTable}
                    columns={columnsToTable}
                    // columns={tableColumns(lang, intl)}
                    {...paginationTableProps}>
                    <PleaseWaitMessage entities={dataTable} />
                    <NoRecordsFoundMessage entities={[dataTable]} />
                  </BootstrapTable>
                )}
              </Pagination>
            );
          }}
        </PaginationProvider>
      </Card.Body>
    </Card>
  );
}
