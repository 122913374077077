import Container from '@mui/material/Container';
import React, {ReactElement, useState, useCallback} from 'react';
import {Button, Row} from 'react-bootstrap';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {convertOffsetInMinutesToString, findTimeZone, TimeZoneSelectDialog} from 'react-timezone-map-select';
import {ConfigSystemProps} from './_redux/types';

function TzMap({setTimeZone, setTimeZoneCode, data, ...props}: any): ReactElement {
  const GLOBALCONFIGS = useSelector<any, ConfigSystemProps>((stage) => stage.configs);
  const DEFAULT_TIME_ZONE_NAME = GLOBALCONFIGS.timeZoneCode;

  /** Set true when you open TimeZoneSelectDialog. */
  const [open, setOpen] = useState(false);

  /** Called when you press "Open Dialog" button. */
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  /** Called when TimeZoneSelectDialog is closed. */
  const handleClose = useCallback((newTimeZoneName: string) => {
    const newtimezone = findTimeZone(newTimeZoneName);
    const timeOffset = newtimezone ? convertOffsetInMinutesToString(newtimezone?.rawOffsetInMinutes) : 0;
    const srtInt = timeOffset.toString().split(':');
    const timezoneInt = parseInt(srtInt[0]);

    setTimeZone(timezoneInt);
    setTimeZoneCode(newTimeZoneName);
    setOpen(false);
  }, []);

  /** Detailed timezone info */
  const timezone = findTimeZone(data);
  const timeOffset = timezone ? convertOffsetInMinutesToString(timezone?.rawOffsetInMinutes) : 0;
  const intl = useIntl();
  const titulo = intl.formatMessage({id: 'CONFIG.STANDART_TIMEZONE.TITLE'});
  const description = intl.formatMessage({id: 'CONFIG.STANDART_TIMEZONE.DESCRIPTION'});
  const cancel = intl.formatMessage({id: 'CONFIG.BUTTON_CANCEL'});

  return (
    <Row className='ml-1 col-12'>
      <input className='custom-select col-12' type='text' value={`${timeOffset}: ${timezone?.countryName}/${timezone?.mainCities[0]}`} onClick={handleOpen} name='deafultTimeZone'></input>
      <TimeZoneSelectDialog title={titulo} description={description} open={open} timeZoneName={data} onClose={handleClose} buttonLabelCancel={cancel} />
    </Row>
  );
}

export default TzMap;
