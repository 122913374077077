import { IntlShape } from "react-intl"

type Props = {
  values:string[]
  intl:IntlShape
}

export function toColumnsTable({values,intl}:Props):any{
  return values.map((value, index) => {

    if(index + 1 === values.length){
      return{
        dataField: index.toString(),
        text: intl.formatMessage({id: 'OPERATOR_ROLE_COLLUM_ACTIONS'}),
        classes: 'text-right pr-0',
        headerClasses: 'text-right pr-3',
        style: {
          minWidth: '100px'
        }
      }
    }

    return {
      dataField: index.toString(),
      text: intl.formatMessage({id: value}),
      sort: true,
      headerAlign: 'center',
      align: 'center',
      style: {
        minWidth: '150px',
        textAlign: 'center'
      }
    }
  })
}