import styled from 'styled-components';

export const SelectImage = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;

  div {
    width: 80%;
    border: 1px solid #9e9e9e;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20vh;

    p {
      font-size: 1.2rem;
    }

    button {
      width: 8%;
    }
  }
`;

export const Hidden = styled.div`
  display: none;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  input {
    max-width: 700px;
  }
`;

export const ContainerImg = styled.div`
  @keyframes COLOR_RED {
    from {
      border: 1px solid rgb(0, 0, 0);
    }
    to {
      border: 1px solid red;
    }
  }

  @keyframes COLOR_GREEN {
    from {
      border: 1px solid rgb(0, 0, 0);
    }
    to {
      border: 2px solid green;
    }
  }

  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  section {
    border: 1px solid #e4e6ef;
    border-radius: 6px;
    padding: 5px;
    position: relative;
    max-height: 600px;
    /* overflow-y: hidden; */

    > img {
      width: 800px;
      height: 570px;
      object-fit: contain;
    }

    > span {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
    }

    /* .point-block {
      transition: border 0.5s;
      animation: COLOR_RED 1.2s infinite;
    }

    .point-open {
      transition: border 0.5s;
      animation: COLOR_GREEN 1.2s infinite;
    } */

    .point-block {
      border: 1px solid red !important;
    }

    .point-open {
      border: 1px solid green !important;
    }

    & + section {
      width: 33%;

      p {
        text-align: center;
        font-size: 1.1rem;
      }

      ul {
        margin: 0;
        padding: 0;
        border-radius: 10px;
        border: 1px solid #e4e6ef;
        position: relative;

        .divisor {
          max-height: 500px;
          min-height: 500px;
          overflow-y: auto;
        }

        li {
          list-style: none;
          display: flex;
          padding: 5px;
          align-items: center;
          gap: 5px;
          border-bottom: 1px solid #e4e6ef;
          margin-bottom: 5px;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 1px solid #ccc;
            object-fit: cover;
          }

          .name {
            width: 250px;
            margin-left: 5px;
          }

          .model {
            width: 150px;
          }

          .output {
            width: 150px;
          }

          .area {
            width: 250px;
          }

          .action {
          }

          &.fixedd {
            border-bottom: 1px solid #e4e6ef;
            img {
              opacity: 0;
            }

            .output {
              width: 150px;
            }

            .action {
              opacity: 0;
            }
          }
        }
      }
    }
  }
`;

export const Title = styled.p`
  font-size: 22px;
  margin-top: 10px;
  width: 800px;
  /* display: flex;
  justify-content: center;
  margin-left: -1.9%; */
`;

export const Person = styled.div`
  @keyframes SHOW {
    from {
      left: -3px;
      width: 34px;
      border-radius: 50%;
      padding: 2px 0px;

      p {
        display: none;
      }
    }
    to {
      left: 45px;
      width: 150px;
    }
  }

  @keyframes HIDE {
    to {
      left: -3px;
      width: 34px;
      border-radius: 50%;
      padding: 2px 0px;

      p {
        display: none;
      }
    }
    from {
      left: 45px;
      width: 150px;
    }
  }

  display: flex;
  position: absolute;
  background: #fff;
  /* width: 200px;
  padding: 10px; */
  border: 1px solid #efefef;
  top: -16px;
  border-radius: 50px 10px 10px 50px;
  left: 46px;
  gap: 5px;
  overflow: hidden;
  width: 150px;
  height: 34px;
  padding: 4px 2px;

  align-items: center;
  animation: SHOW 0.5s;
  transition: all 0.5s;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 12px;
  }
`;
