import axios from 'axios';
import qs from 'qs';
import { defaultDateTime } from 'src/utils/defaultDateTime';

class GetFileRequest {
  idsecureAPI = process.env.REACT_APP_IDSECURE_API_URL_REPORTS



  async PDF(api: string, filtros: any, headers: string[], columns?: string[]) {
    return axios.get(`${this.idsecureAPI}${api}`, {
      responseType: filtros.pageSize >= 10000 ? 'json' : 'blob',
      //  responseType: 'blob',
      params: { ...filtros, pageNumber: 1, headers: headers, columns: columns, exportType: "PDF" },
      paramsSerializer: (prms) => {
        return qs.stringify(prms, { arrayFormat: 'repeat' });
      }
    });
  }

  listPDFs(params: any) {
    return axios.get(`${this.idsecureAPI}/reportStatus`, {
      params,
      transformResponse: [function (data) {

        const response = JSON.parse(data)

        try {

          const transformValues = response.data.data.map((v: any) => {
            return {
              ...v,
              requestDate: defaultDateTime.labelDateTime(v.requestDate)
            }
          })

          response.data.data = transformValues


          return {
            data: {
              ...response.data,
              data: transformValues
            }
          }



        } catch {

          return response
        }


      }]
    });
  }
}


export const getFileRequest = new GetFileRequest()