import styled from 'styled-components'

export const FormElement = styled.form`
  article{
    display: flex;
    gap:20px;

    div {
      width: 40%;
    }
  }

  label{
    font-size: 1rem;
    font-weight: 400;
    color: #3F4254;
  }

  section{
    margin-top: 20px;
    display: flex;
    gap:35px;
    align-items: center;

    div{
      display: flex;
      align-items: top;
    }

    .container{
      display: flex;
      justify-content: end;
    }
  }

  .spacing{
    /* border: 1px solid #ccc */
  }
`