import styled from 'styled-components';

export const SelectImage = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;

  div {
    width: 80%;
    border: 1px solid #9e9e9e;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20vh;

    p {
      font-size: 1.2rem;
    }

    button {
      width: 8%;
    }
  }
`;

export const Hidden = styled.div`
  display: none;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  input {
    max-width: 700px;
  }
`;

export const ContainerImg = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  section {
    border: 1px solid #e4e6ef;
    border-radius: 6px;
    padding: 5px;
    position: relative;

    > img {
      width: 800px;
      height: 570px;
      object-fit: contain;
    }

    > span {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
    }

    & + section {
      width: 33%;

      p {
        text-align: center;
        font-size: 1.1rem;
      }

      ul {
        margin: 0;
        padding: 0;
        border-radius: 10px;
        border: 1px solid #e4e6ef;
        position: relative;

        .divisor {
          max-height: 500px;
          min-height: 500px;
          overflow-y: auto;
        }

        li {
          list-style: none;
          display: flex;
          padding: 5px;
          align-items: center;
          gap: 5px;
          border-bottom: 1px solid #e4e6ef;
          margin-bottom: 5px;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 1px solid #ccc;
            object-fit: cover;
          }

          .name {
            width: 250px;
            margin-left: 5px;
          }

          .model {
            width: 150px;
          }

          .output {
            width: 150px;
          }

          .area {
            width: 250px;
          }

          .action {
          }

          &.fixedd {
            border-bottom: 1px solid #e4e6ef;
            img {
              opacity: 0;
            }

            .output {
              width: 150px;
            }

            .action {
              opacity: 0;
            }
          }
        }
      }
    }
  }
`;
