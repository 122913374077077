import React, { useEffect, useRef, useState, useCallback } from 'react';
import { createBluePrints, geAllBluePrintsDevicesActiveds, getBluePrintById, GetProps } from 'src/services/bluePrintCrud';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { standardTranslation } from 'src/utils/standardTranslation';
import { useIntl } from 'react-intl';
import { MdAddCircle } from 'react-icons/md';
const IMAGES: any = {
  1: 'iDAccess-Perspectiva.jpg',
  2: 'iDAccess_nano.jpg',
  3: 'iDAccess_pro.jpg',
  4: 'iDAccess-Prox-Perspectiva.jpg',
  5: 'iDBlock-Perspectiva.jpg',
  6: 'idblock_balcao.jpg',
  7: 'idblock_bqc.jpg',
  8: 'idblock_facial.jpg',
  15: 'idblock_next.jpg',
  9: 'idblock_pne.jpg',
  10: 'iDBox-Perspectiva.jpg',
  11: 'iDFace.jpg',
  12: 'iDAccess-4x2-Perspectiva.jpg',
  13: 'iDFlex-Frontal.jpg',
  14: 'iDUHF.jpg'
};

type PointPorps = {
  id: number;
  x: number;
  y: number;
  name: string;
  img: string;
  actived: boolean;
  idDevice: number;
};

type DeviceRequest = {
  deviceModelType: number;
  name: string;
  deviceModel: string;
  areaTo: string;
  deviceOutput: {
    id: number;
    number: number;
  };
};

export function PainelHooks() {
  const P = useParams();
  const history = useHistory();
  const intl = useIntl();
  const [devices, setDevices] = useState<any[]>([]);

  const [pagination, setPagination] = useState<number>(1);
  const [sizePerPage, setSizePerPage] = useState<number>(10);

  const [points, setPoints] = useState<PointPorps[]>([]);
  const [plantImage, setPlantImage] = useState<string>('');
  const [ext, setExt] = useState<string>('');

  const inputRef = useRef<HTMLInputElement>(null);

  function removePoint(id: number) {
    setPoints((stage) => {
      const newValue = stage.map((item) => {
        if (item.id === id) {
          item.actived = false;
        }
        return item;
      });

      return newValue;
    });
  }

  const renderTable = useCallback(
    (data: any[]) => {
      const update = data.filter((item: any) => {
        const findId = points.find((poit) => poit.id === item.deviceOutput.id);

        if (findId && findId.actived) return false;
        return true;
      });

      return update.map((item: any) => (
        <li key={item.id}>
          <div>
            <img src={`/media/devices/${IMAGES[item.deviceModelType]}`} alt='' />
          </div>
          <div className='name'>{item.name}</div>
          <div className='model'>{item.deviceModel}</div>
          <div className='output'>{item.deviceOutput.number}</div>
          <div className='area'>{standardTranslation(item.areaTo, intl)}</div>
          <div className='action'>
            <button title={intl.formatMessage({ id: 'PAGE.BLUEPRINT.ADD_DEVICE_TO_PLANT' })} disabled={!plantImage} style={{ cursor: plantImage ? 'pointer' : 'initial' }} className='btn btn-primary'>
              <MdAddCircle size={16} />
            </button>
          </div>
        </li>
      ));
    },
    [plantImage, points, pagination, sizePerPage, devices]
  );

  function handleUpdatePosition(id: number, x: number, y: number) {
    setPoints((oldStage: any) => {
      const newStage = oldStage.map((stage: any) => {
        if (id === stage.id) {
          return {
            ...stage,
            x,
            y
          };
        } else {
          return stage;
        }
      });

      localStorage.setItem('teste', JSON.stringify(newStage));

      return newStage;
    });
  }

  function helperYPositionTOremove() {
    // alert(points.length);
    let newValues: PointPorps[] = [];
    const indexPosition = [...points]
      .map((point, i) => {
        if (!point.actived) {
          const helper = { ...point, index: i };

          return helper;
        }

        return point;
      })
      .filter((point) => {
        //@ts-ignore
        if (point.index || point.index === 0) {

          return true;
        }

        return false;
      })
      .map((point) => {
        //@ts-ignore
        return point.index;
      });

    for (const index of indexPosition) {
      points.forEach((point, i) => {
        const currentPoint = { ...point };

        if (i > index) {
          currentPoint.y = point.y + 40;
        }

        //verificar se ja existe
        const isValueIsExited = newValues.find((item) => item.id === point.id);
        if (isValueIsExited) {
          if (i > index) {
            newValues = [...newValues].map((item) => {
              if (item.id === point.id) {
                return {
                  ...item,
                  y: item.y + 40
                };
              }

              return item;
            });
          }
        } else {
          newValues.push(currentPoint);
        }
      });
    }



    const values = newValues.filter((item) => item.actived);

    return values.length > 0 ? values : points;
  }

  async function handleSave() {
    const props = {
      description: inputRef.current?.value,
      blueprintPhoto: plantImage,
      blueprintExt: ext,
      height: 800,
      width: 570,
      blueprintDevices: helperYPositionTOremove().map((item) => {
        return {
          deviceOutputId: item.id,
          positionX: item.x,
          positionY: item.y
        };
      })
    };

    try {
      await createBluePrints(props);
      // alert('Salvo');
    } catch (err) {
      // alert('Deu erro');
    }

    //localStorage.setItem('teste', JSON.stringify(helperYPositionTOremove()));
  }

  async function getDevices(isReturn = false) {
    try {
      const props: GetProps = {
        pageNumber: pagination,
        pageSize: sizePerPage,
        sortField: 'Name',
        sortOrder: 'asc',
        status: 1
      };

      const { data } = await geAllBluePrintsDevicesActiveds(props);

      const values = data.value.data.filter((item: DeviceRequest) => {
        const findId = points.find((poit) => poit.id === item.deviceOutput.id);

        if (findId && findId.actived) return false;
        return true;
      });



      if (isReturn) {
        return values;
      } else {
        setDevices([...devices, ...values]);
      }
    } catch (e) { }
  }

  async function Load() {
    //@ts-ignore
    const { data } = await getBluePrintById(P.id);



    setPlantImage(data.data.blueprintPhoto);
    //@ts-ignore
    inputRef.current.value = data.data.description;

    const devicesPreSeted: any[] = [];
    const ts = data.data.blueprintDevices.map((item: any) => {
      const devicePreSeted = {
        areaFrom: item.deviceBlueprint.areaFrom,
        areaTo: item.deviceBlueprint.areaTo,
        deviceId: item.deviceBlueprint.deviceId,
        deviceModel: item.deviceBlueprint.deviceModel,
        deviceModelType: item.deviceBlueprint.deviceModelType,
        name: item.deviceBlueprint.name,
        deviceOutput: {
          number: 1,
          id: item.deviceOutputId
        },
        actived: false
      };

      devicesPreSeted.push(devicePreSeted);

      return {
        idDevice: item.deviceBlueprint.deviceId,
        id: item.deviceOutputId,
        x: item.positionX,
        y: item.positionY,
        name: item.deviceBlueprint.name,
        img: `/media/devices/${IMAGES[item.deviceBlueprint.deviceModelType]}`,
        actived: true
      };
    });

    //const devicesGet = await getDevices(true);
    setPoints(ts);

    //setDevices([...devicesPreSeted, ...devicesGet]);
  }

  useEffect(() => {
    Load();
    // if (plantImage) return;
    // const dataString = localStorage.getItem('teste');
    // const restoredPoint = dataString ? JSON.parse(dataString) : [];
    // setPoints(restoredPoint);
  }, []);

  useEffect(() => {
    //getDevices();
    // const mockData = 'anderson'
    //   .split('')
    //   .map((_, i) => {
    //     return {
    //       image: (
    //         <div
    //           style={{position: 'relative', cursor: plantImage ? 'pointer' : 'initial'}}
    //           onClick={() => {
    //             if (!plantImage) return;
    //             const data = {
    //               id: i,
    //               x: 310,
    //               y: -300,
    //               name: `device ${i + 1}`,
    //               img: `/media/devices/${IMAGES[i + 1]}`,
    //               actived: true
    //             };
    //             insertedPoint(data);
    //           }}>
    //           <img
    //             alt=''
    //             className='tag'
    //             src={`/media/devices/${IMAGES[i + 1]}`}
    //             onClick={
    //               plantImage
    //                 ? () => {
    //                     const data = {
    //                       id: i,
    //                       x: 310,
    //                       y: -300,
    //                       name: `device ${i + 1}`,
    //                       img: `/media/devices/${IMAGES[i + 1]}`,
    //                       actived: true
    //                     };
    //                     insertedPoint(data);
    //                   }
    //                 : () => {
    //                     
    //                   }
    //             }
    //             // onMouseMove={(e) => {
    //             //   if (!e.buttons) return;
    //             
    //             // }}
    //           />
    //           <div style={{top: 0, position: 'absolute', width: '40px', height: '40px', borderRadius: '50%'}}></div>
    //         </div>
    //       ),
    //       name: `device ${i + 1}`,
    //       model: `00000${i + 1}`,
    //       reader: `${(i + 1) * 2}`,
    //       work: `Casa do anderson`,
    //       id: i
    //     };
    //   })
    //   .filter((item) => {
    //     const findId = points.find((poit) => poit.id === item.id);
    //     if (findId && findId.actived) return false;
    //     return true;
    //   });
    // setDevices(mockData);
  }, [plantImage, sizePerPage, pagination]);

  return {
    TABLEDEVICES: renderTable,
    plantImage,
    setPlantImage,
    points,
    handleUpdatePosition,
    removePoint,
    handleSave,
    inputRef,
    devices,
    setExt,
    history
  };
}
