import React, { useMemo } from 'react';

import * as S from './styles';
import { useIntl } from 'react-intl';
import { CustomLabels } from 'src/utils/customLabels';

export function Success() {

  const intl = useIntl()

  const msg = useMemo(() => {
    const customLabels = new CustomLabels()

    return customLabels.getCustomLabel({
      isTagNew: false,
      inputName: 'personalizedMessageForRemoteRegistration',
      defaultName: intl.formatMessage({ id: 'VISITOR.REMOTE_REGISTRATION.VISIT_CONFIRMED' }),
      intl
    })
  }, [intl])

  return (
    <S.Wrapper>
      <S.Icon className='show-in'>
        <img src='/media/svg/avatars/user-checked.svg' alt='User checked' width={100} />

        <div className='background' />
      </S.Icon>

      <div className='content-container'>
        <h1 className='title show-in' style={{ animationDelay: '.1s' }}>
          {msg}
        </h1>
      </div>
    </S.Wrapper>
  );
}
