import {useReducer} from 'react';

type TEntry = {
  label: string;
  value: string;
};

type TEmail = {
  value: string;
  entries: TEntry[];
  error: string;
};

export const useEmailsInput = () => {
  const [emails, dispatch] = useReducer(emailsReducer, {
    error: '',
    value: '',
    entries: []
  });

  const addNewEmailEntry = (e: any) => {
    const hasValue = emails.value.length > 0
     
    if (['Enter', 'Tab'].includes(e.key) && hasValue) {
      e.target.focus()
      e.preventDefault()

      dispatch({type: 'ADD_NEW_ENTRY'});
    }
  };

  const changeEmailEntry = (payload: TEntry[]) => dispatch({type: 'CHANGE_ENTRIES', payload: payload || []});

  const setEmailValue = (payload: string) => dispatch({type: 'SET_VALUE', payload});

  const setEmailInitialValue = (emails: string) => {
    const rawEmails = emails.split(';');

    const parsed: TEntry[] = rawEmails.map((email) => ({label: email, value: email}));

    changeEmailEntry(parsed);
  };

  const getEmailsPayload = () => emails.entries.map((entry) => entry.value).join(';');

  return {emails, addNewEmailEntry, changeEmailEntry, setEmailValue, setEmailInitialValue, getEmailsPayload};
};

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const validators = [
  {
    validate: (input: Record<string, any>) =>
      input.value.length === 0 || !validateEmail(input.value) ? 'INVALID_EMAIL' : ''
  },
  {
    validate: (input: Record<string, any>) => {
      const isEqual = input.entries.some((entry: TEntry) => entry.value === input.value);

      return isEqual ? 'EQUAL_EMAIL' : '';
    }
  }
];

const emailsReducer = (state: TEmail, action: any) => {
  switch (action.type) {
    case 'SET_VALUE': {
      return {
        ...state,
        value: action.payload,
        error: ''
      };
    }

    case 'ADD_NEW_ENTRY': {
      const value = state.value;
      let error = '';

      validators.forEach((validator) => {
        if (!!error) return;

        error = validator.validate(state);
      });

      if (!!error)
        return {
          ...state,
          error
        };

      return {
        ...state,
        entries: [...state.entries, {value, label: value}],
        value: ''
      };
    }

    case 'CHANGE_ENTRIES': {
      return {
        ...state,
        entries: action.payload as TEntry[]
      };
    }

    default:
      return state;
  }
};
