import React, { useEffect, useRef, useState, useCallback } from 'react';
import { editBluePrints, geAllBluePrintsDevicesActiveds, getBluePrintById, GetProps } from 'src/services/bluePrintCrud';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { standardTranslation } from 'src/utils/standardTranslation';
import { useIntl } from 'react-intl';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { MdAddCircle } from 'react-icons/md';

const IMAGES: any = {
  1: 'iDAccess-Perspectiva.jpg',
  2: 'iDAccess_nano.jpg',
  3: 'iDAccess_pro.jpg',
  4: 'iDAccess-Prox-Perspectiva.jpg',
  5: 'iDBlock-Perspectiva.jpg',
  6: 'idblock_balcao.jpg',
  7: 'idblock_bqc.jpg',
  8: 'idblock_facial.jpg',
  15: 'idblock_next.jpg',
  9: 'idblock_pne.jpg',
  10: 'iDBox-Perspectiva.jpg',
  11: 'iDFace.jpg',
  12: 'iDAccess-4x2-Perspectiva.jpg',
  13: 'iDFlex-Frontal.jpg',
  14: 'iDUHF.jpg'
};

type PointPorps = {
  id: number;
  x: number;
  y: number;
  name: string;
  img: string;
  actived: boolean;
};

type DeviceRequest = {
  deviceModelType: number;
  name: string;
  deviceModel: string;
  areaTo: string;
  deviceOutput: {
    id: number;
    number: number;
  };
};

export function PainelHooks() {
  const [controllerPosition, setControllerPosition] = useState({
    x: 10,
    y: -560
  });

  const [plantId, setPlaintId] = useState(0);
  const P = useParams();
  const intl = useIntl();
  const history = useHistory();
  const [devices, setDevices] = useState<any[]>([]);

  const [pagination, setPagination] = useState<number>(1);
  const [sizePerPage, setSizePerPage] = useState<number>(10);

  const [points, setPoints] = useState<PointPorps[]>([]);
  const [pointsIsRemoved, setPointsIsRemoved] = useState<PointPorps[]>([]);
  const [plantImage, setPlantImage] = useState<string>('');
  const [ext, setExt] = useState<string>('');

  const inputRef = useRef<HTMLInputElement>(null);

  function insertedPoint({ id, img, name, x }: PointPorps) {
    // alert(id);
    const point = {
      id,
      x,
      y: -300,
      name,
      img,
      actived: true
    };

    // x: 310,
    //   y: -300,

    setPoints((state) => {
      const findPoint = state.findIndex((item) => item.id === id);

      if (findPoint !== -1) {
        // alert('caiu');
        state[findPoint].actived = true;
        // state[findPoint].x = 300;
        // state[findPoint].y = -300;

        return [...state];
      }
      return [...state, point];
    });
  }

  function removePoint(id: number) {
    // let itemIsRemoved = {} as PointPorps;

    setPoints((stage) => {
      const newValue = stage.map((item) => {
        if (item.id === id) {
          item.actived = false;
          // itemIsRemoved = {...item};
        }
        return item;
      });

      return newValue;
    });

    // setPointsIsRemoved((stage) => [...stage, itemIsRemoved]);
  }

  const renderTable = useCallback(
    (data: any[]) => {
      const update = data.filter((item: any) => {
        const findId = points.find((poit) => poit.id === item.deviceOutput.id);

        if (findId && findId.actived) return false;
        return true;
      });

      const blockDev: number[] = [];

      const newUp = update.filter((item: any) => {
        if (!blockDev.includes(item.deviceId)) {
          blockDev.push(item.deviceId);
          return true;
        }

        return false;
      });

      return newUp.map((item: any) => (
        <li key={item.id}>
          <div>
            <img src={`/media/devices/${IMAGES[item.deviceModelType]}`} alt='' />
          </div>
          <div className='name'>{item.name}</div>
          <div className='model'>{item.deviceModel}</div>
          <div className='output'>{item.deviceOutput.number}</div>
          <div className='area'>{standardTranslation(item.areaTo, intl)}</div>
          <div className='action'>
            <button
              title={intl.formatMessage({ id: 'PAGE.BLUEPRINT.ADD_DEVICE_TO_PLANT' })}
              disabled={!plantImage}
              style={{ cursor: plantImage ? 'pointer' : 'initial' }}
              className='btn btn-primary'
              onClick={() => {
                const data = {
                  id: item.deviceOutput.id,
                  x: controllerPosition.x,
                  y: controllerPosition.y,
                  name: item.name,
                  img: `/media/devices/${IMAGES[item.deviceModelType]}`,
                  actived: true
                };

                insertedPoint(data);

                setControllerPosition((state) => {
                  const calc = state.y - 30;
                  return {
                    ...state,
                    y: calc
                  };
                });
              }}>
              <MdAddCircle size={16} />
            </button>
          </div>
        </li>
      ));
    },
    [plantImage, points, pagination, sizePerPage, devices]
  );

  function handleUpdatePosition(id: number, x: number, y: number) {
    setPoints((oldStage: any) => {
      const newStage = oldStage.map((stage: any) => {
        if (id === stage.id) {
          return {
            ...stage,
            x,
            y
          };
        } else {
          return stage;
        }
      });

      return newStage;
    });
  }

  function helperYPositionTOremove() {
    // alert(points.length);
    let newValues: PointPorps[] = [];
    const indexPosition = [...points]
      .map((point, i) => {
        if (!point.actived) {
          const helper = { ...point, index: i };

          return helper;
        }

        return point;
      })
      .filter((point) => {
        //@ts-ignore
        if (point.index || point.index === 0) {

          return true;
        }

        return false;
      })
      .map((point) => {
        //@ts-ignore
        return point.index;
      });

    for (const index of indexPosition) {
      points.forEach((point, i) => {
        const currentPoint = { ...point };

        if (i > index) {
          currentPoint.y = point.y + 40;
        }

        //verificar se ja existe
        const isValueIsExited = newValues.find((item) => item.id === point.id);
        if (isValueIsExited) {
          if (i > index) {
            newValues = [...newValues].map((item) => {
              if (item.id === point.id) {
                return {
                  ...item,
                  y: item.y + 40
                };
              }

              return item;
            });
          }
        } else {
          newValues.push(currentPoint);
        }
      });
    }

    const values = newValues.filter((item) => item.actived);

    return values.length > 0 ? values : points.filter((item) => item.actived);
  }

  async function handleSave() {
    const props = {
      id: plantId,
      description: inputRef.current?.value,
      blueprintPhoto: plantImage,
      blueprintExt: ext.replace('image/', ''),
      height: 800,
      width: 570,
      isEnabled: true,
      blueprintDevices: helperYPositionTOremove().map((item) => {
        return {
          deviceOutputId: item.id,
          positionX: item.x,
          positionY: item.y
        };
      })
    };

    if (!props.description) {
      execToast('error', intl.formatMessage({ id: 'PAGE.BLUEPRINT.TOAST_INPTU_DESC' }), 3000);
      inputRef.current?.focus();
      return;
    }

    if (!plantImage) {
      execToast('error', intl.formatMessage({ id: 'PAGE.BLUEPRINT.TOAST_INPTU_IMAGE' }), 3000);

      return;
    }

    try {
      await editBluePrints(props);
      execToast('success', intl.formatMessage({ id: 'PAGE.BLUEPRINT.TOAST_EDIT_SAVE' }), 3000);
      history.push('/blueprint');
    } catch (err) {
      //@ts-ignore
      if (err.response.data.message === '410') {
        execToast('error', intl.formatMessage({ id: 'PAGE.BLUEPRINT.TOAST_SAVE_NEW_ERROR_DES' }), 3000);
        return;
      }
    }


    //localStorage.setItem('teste', JSON.stringify(helperYPositionTOremove()));
  }

  async function getDevices(isReturn = false) {
    try {
      const props: GetProps = {
        pageNumber: pagination,
        pageSize: sizePerPage,
        sortField: 'Name',
        sortOrder: 'asc',
        status: 1
      };

      const { data } = await geAllBluePrintsDevicesActiveds(props);

      const values = data.value.data.filter((item: DeviceRequest) => {
        const findId = points.find((poit) => poit.id === item.deviceOutput.id);

        if (findId && findId.actived) return false;
        return true;
      });



      if (isReturn) {
        return values;
      } else {
        setDevices([...devices, ...values]);
      }
    } catch (e) { }
  }

  async function Load() {
    //@ts-ignore
    const { data } = await getBluePrintById(P.id);

    setPlantImage(data.data.blueprintPhoto);
    //@ts-ignore
    inputRef.current.value = data.data.description;
    setPlaintId(data.data.id);
    const devicesPreSeted: any[] = [];
    const ts = data.data.blueprintDevices.map((item: any) => {
      const devicePreSeted = {
        areaFrom: item.deviceBlueprint.areaFrom,
        areaTo: item.deviceBlueprint.areaTo,
        deviceId: item.deviceBlueprint.deviceId,
        deviceModel: item.deviceBlueprint.deviceModel,
        deviceModelType: item.deviceBlueprint.deviceModelType,
        name: item.deviceBlueprint.name,
        deviceOutput: {
          number: 1,
          id: item.deviceOutputId
        },
        actived: false
      };

      devicesPreSeted.push(devicePreSeted);

      return {
        id: item.deviceOutputId,
        x: item.positionX,
        y: item.positionY,
        name: item.deviceBlueprint.name,
        img: `/media/devices/${IMAGES[item.deviceBlueprint.deviceModelType]}`,
        actived: true
      };
    });

    const devicesGet = await getDevices(true);
    setPoints(ts);

    setDevices([...devicesPreSeted, ...devicesGet]);
  }

  useEffect(() => {
    Load();
    // if (plantImage) return;
    // const dataString = localStorage.getItem('teste');
    // const restoredPoint = dataString ? JSON.parse(dataString) : [];
    // setPoints(restoredPoint);
  }, []);

  useEffect(() => {
    //getDevices();
    // const mockData = 'anderson'
    //   .split('')
    //   .map((_, i) => {
    //     return {
    //       image: (
    //         <div
    //           style={{position: 'relative', cursor: plantImage ? 'pointer' : 'initial'}}
    //           onClick={() => {
    //             if (!plantImage) return;
    //             const data = {
    //               id: i,
    //               x: 310,
    //               y: -300,
    //               name: `device ${i + 1}`,
    //               img: `/media/devices/${IMAGES[i + 1]}`,
    //               actived: true
    //             };
    //             insertedPoint(data);
    //           }}>
    //           <img
    //             alt=''
    //             className='tag'
    //             src={`/media/devices/${IMAGES[i + 1]}`}
    //             onClick={
    //               plantImage
    //                 ? () => {
    //                     const data = {
    //                       id: i,
    //                       x: 310,
    //                       y: -300,
    //                       name: `device ${i + 1}`,
    //                       img: `/media/devices/${IMAGES[i + 1]}`,
    //                       actived: true
    //                     };
    //                     insertedPoint(data);
    //                   }
    //                 : () => {
    //                    
    //                   }
    //             }
    //             // onMouseMove={(e) => {
    //             //   if (!e.buttons) return;
    //            
    //             // }}
    //           />
    //           <div style={{top: 0, position: 'absolute', width: '40px', height: '40px', borderRadius: '50%'}}></div>
    //         </div>
    //       ),
    //       name: `device ${i + 1}`,
    //       model: `00000${i + 1}`,
    //       reader: `${(i + 1) * 2}`,
    //       work: `Casa do anderson`,
    //       id: i
    //     };
    //   })
    //   .filter((item) => {
    //     const findId = points.find((poit) => poit.id === item.id);
    //     if (findId && findId.actived) return false;
    //     return true;
    //   });
    // setDevices(mockData);
  }, [plantImage, sizePerPage, pagination]);

  return {
    TABLEDEVICES: renderTable,
    plantImage,
    setPlantImage,
    points,
    handleUpdatePosition,
    removePoint,
    handleSave,
    inputRef,
    devices,
    setExt,
    history
  };
}
