import { formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns';
import cookie from 'js-cookie';

interface I_setCookieRefreshToken {
  refreshToken:string
  expiration:string
}

function setCookieRefreshToken(value:I_setCookieRefreshToken){

  const timeFormatterExpiration = `${value.expiration.slice(0,10)} ${value.expiration.slice(10,20)}`

  value.expiration = timeFormatterExpiration

  cookie.set('refreshtoken', JSON.stringify(value),{
    expires: 3
  })
}

function getCookieRefreshToken(){
  const cookieRestore = cookie.get('refreshtoken')

  if(!cookieRestore) return null

  const values:I_setCookieRefreshToken = JSON.parse(cookieRestore)

  return values
}


function isRefreshToken(){
  const data = getCookieRefreshToken()

  if(!data) return 

  const dateTokenValid = new Date(data.expiration) //data.expiration

  
  const value = formatDistanceToNow(dateTokenValid)
  const isOtherToken =  isTimerMenor(value)

  return isOtherToken
  
}

function isTimerMenor(value:string){

  if(value.includes('minutes')){
    const minutesRest = value.replaceAll(/([a-z])\w+/g, '').trim()

    return +minutesRest <= 30
  }
}


function isRequestNewTokenProgress(){
  const value = cookie.get('isRequestNewTokenProgress')

  return value === 'true'
}

function setRequestNewTokenProgress(value:boolean){
  cookie.set('isRequestNewTokenProgress', `${value}`)
}

export {
  getCookieRefreshToken,
  setCookieRefreshToken,
  isRefreshToken,
  isRequestNewTokenProgress,
  setRequestNewTokenProgress
}