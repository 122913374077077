import { useReducer } from 'react';
import { Visitor } from '../../../domain/visitor';
import { FormConfig } from '../../../domain/form-config';
import { DeepPartial } from 'redux';
import { formSchema } from '../validation';
import { handleParseYupError } from 'src/app/modules/Devices/utils/handle-parse-yup-error';

type FormState = {
  data: Visitor;
  isDirty: boolean;
  errors: Record<string, any>;
  formConfig: FormConfig;
};

type Reducer = (state: FormState, action: { type: ActionType; payload: DeepPartial<FormState> }) => FormState;
type ActionType =
  | 'SET_DATA'
  | 'SET_ERRORS'
  | 'SET_DIRTY'
  | 'SET_FORM_CONFIG'
  | 'SET_FORM_STATE'
  | 'SET_PERSON_DOCUMENTS';

const INITIAL_STATE: FormState = {
  isDirty: false,
  errors: {},

  data: {
    name: '',
    email: '',
    phoneNumber: '',
    phoneDDI: '',
    avatar: '',
    entryDate: new Date(),
    exitDate: new Date(),
    personDocuments: undefined
  },

  formConfig: {
    documentTypes: [],
    requiredFields: {
      email: false,
      personDocuments: false,
      phoneNumber: false,
      personPhoto: false
    }
  }
};

const reducer: Reducer = (state, action) => {
  let newState = { ...state };

  switch (action.type) {
    case 'SET_DATA': {
      newState.data = {
        ...newState.data,
        ...(action.payload.data as any)
      };
      break;
    }

    case 'SET_ERRORS': {
      newState.errors = action.payload.errors as any;
      break;
    }

    case 'SET_DIRTY': {
      newState.isDirty = action.payload.isDirty as any;
      break;
    }

    case 'SET_FORM_CONFIG': {
      newState.formConfig.requiredFields = action.payload.formConfig as any;
      break;
    }

    case 'SET_FORM_STATE': {
      newState.data = {
        ...newState.data,
        ...(action.payload.data as any)
      };

      newState.formConfig = {
        ...newState.formConfig,
        ...(action.payload.formConfig as any)
      };
      break;
    }

    case 'SET_PERSON_DOCUMENTS': {
      const shouldResetPersonDocuments = action.payload.data?.personDocuments?.documentTypeId !== undefined || action.payload.data?.personDocuments?.value === undefined;

      if (shouldResetPersonDocuments) {
        newState.data.personDocuments = {
          value: '',
          documentTypeId: action.payload.data!.personDocuments!.documentTypeId
        };
      } else {
        newState.data.personDocuments = {
          ...newState.data.personDocuments,
          ...(action.payload.data?.personDocuments as any)
        };
      }

      break;
    }
  }

  if (newState.isDirty) {
    try {
      formSchema.validateSync(
        { ...newState.data, requiredFields: newState.formConfig.requiredFields },
        { abortEarly: false }
      );
      newState.errors = {};
    } catch (e) {
      newState.errors = handleParseYupError(e);
    }
  }

  return newState;
};

export const useFormState = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const actions = {
    setData: (data: Partial<Visitor>) => dispatch({ type: 'SET_DATA', payload: { data } }),
    setDirty: (isDirty: boolean) => dispatch({ type: 'SET_DIRTY', payload: { isDirty } }),
    setErrors: (errors: Record<string, string>) => dispatch({ type: 'SET_ERRORS', payload: { errors } }),
    setFormConfig: (formConfig: FormConfig) => dispatch({ type: 'SET_FORM_CONFIG', payload: { formConfig } }),
    setFormState: (payload: { data: Visitor; formConfig: FormConfig }) => dispatch({ type: 'SET_FORM_STATE', payload }),
    setPersonDocuments: (personDocuments: Partial<Visitor['personDocuments']>) =>
      dispatch({ type: 'SET_PERSON_DOCUMENTS', payload: { data: { personDocuments: personDocuments as any } } })
  };



  return {
    state,
    actions
  };
};
