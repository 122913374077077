import React from 'react';
import {useIntl} from 'react-intl';
import {Modal} from 'react-bootstrap';

import {Companies} from 'src/_metronic/layout/components/TableLists/Companies';

interface Props {
  isVisible: boolean;
  users: any[];
  title: string;
  close: (value: boolean) => void;
  fnUserSeelected: (value: any) => void;
  fnSave: () => void;
}

export function ModalSelectedCompanies({title, close, isVisible, users, fnUserSeelected, fnSave}: Props) {
  const intl = useIntl();

  function INTL(label: string) {
    return intl.formatMessage({id: label});
  }

  return (
    <Modal
      show={isVisible}
      centered
      size={'lg'}
      onHide={() => {
        close(false);
      }}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Companies users={users} fnUserSeelected={fnUserSeelected} />
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex justify-content-end'>
          <button
            onClick={() => {
              // setShow(false);
              close(false);
            }}
            className={'btn btn-danger'}>
            {INTL('PERSON.NEWPERSON_CANCEL_BUTTON')}
          </button>
        </div>
        <div className='d-flex justify-content-end'>
          <button
            onClick={() => {
              fnSave();
            }}
            className={'btn btn-primary'}>
            {INTL('SAVE')}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
