import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik, getIn } from 'formik';
import objectPath from 'object-path';
import * as Yup from 'yup';
import { connect, useDispatch } from 'react-redux';
import cookie from 'js-cookie';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { login, loginTenant } from '../../../../services/authCrud';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { useHtmlClassService } from 'src/_metronic/layout';
import { LanguageSelectorDropdown } from 'src/_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown';
import { indexedDB } from 'src/utils/DataBaseIndexed';
import { ALTER_LANGUAGUE_CONFIG, ALTER_PACK_CONFIG } from '../../Config/_redux/actions';
import { setLanguage, useLang } from 'src/_metronic/i18n';
import { getConfigService, getConfigServiceDashboard } from 'src/services/configs';
import { convertTypePermissions } from '../utils/convertPermissionsType';
import { ALTER_PERMISSIONS_SYSTEM } from 'src/redux/userPermissions/action';
import { ALTER_MODE_OPERATION } from 'src/redux/isMode/actions';
import { setCookieRefreshToken, setRequestNewTokenProgress } from 'src/utils/refreshToken';
import { CustomLabels } from 'src/utils/customLabels';
import { defaultDateTime } from 'src/utils/defaultDateTime';
import { globalConfigs } from 'src/utils/globalsConfigs';
import { modulesConfigCulture } from 'src/utils/modulesControll';
import { useParams, useLocation } from 'react-router-dom';
// import {isArray} from 'lodash';

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

interface LoginFormFields {
  email: string;
  password: string;
  tenantId: number;
}

export interface UserAuthFiels {
  userId: number;
  name: string;
  tenantId: string;
  email: string;
  created: Date;
  expiration: Date;
  token: string;
}

const initialValues: LoginFormFields = {
  email: '',
  password: '',
  tenantId: 0
};

function Login(props: any) {
  const link = useLocation()
  const intl = useIntl();
  const dispatch = useDispatch();
  const lan = useLang();
  const [loading, setLoading] = useState(false);
  const [tenants, setTenets] = useState<any[]>([]);




  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'EMAIL.NOT.VALID' }))
      .min(3, intl.formatMessage({ id: "INPUT.MIN.C3" }))
      .max(100, intl.formatMessage({ id: "INPUT.MAX.C100" }))
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
    password: Yup.string()
      .min(3, intl.formatMessage({ id: "INPUT.MIN.C3" }))
      .max(50, intl.formatMessage({ id: "INPUT.MAX.C50" }))
      .required(
        intl.formatMessage({
          id: 'AUTH.INPUT.PASSWORD'
        })
      )
  });

  const labelEmail = intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' });
  const labelPassword = intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' });
  const labelTenant = intl.formatMessage({ id: 'AUTH.INPUT.TENANT' });
  // const formTitle = intl.formatMessage({id: 'AUTH.FORM.TITLE'});

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(uiService.config, 'extras.search.display'),
      viewNotificationsDisplay: objectPath.get(uiService.config, 'extras.notifications.display'),
      viewQuickActionsDisplay: objectPath.get(uiService.config, 'extras.quick-actions.display'),
      viewCartDisplay: objectPath.get(uiService.config, 'extras.cart.display'),
      viewQuickPanelDisplay: objectPath.get(uiService.config, 'extras.quick-panel.display'),
      viewLanguagesDisplay: objectPath.get(uiService.config, 'extras.languages.display'),
      viewUserDisplay: objectPath.get(uiService.config, 'extras.user.display'),

      headerBlackLogo: uiService.getLogoDark()
    };
  }, [uiService]);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname: string) => {
    if (getIn(formik.touched, fieldname) && getIn(formik.errors, fieldname)) {
      return 'is-invalid';
    }

    if (getIn(formik.touched, fieldname) && !getIn(formik.errors, fieldname)) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        let tenantIdR = values?.tenantId;
        if (!tenantIdR && tenants.length) tenantIdR = tenants[0].id;
        login(values.email, values.password, tenantIdR || undefined)
          .then(async (response) => {

            if (response?.data?.data?.accessType == 1) {
              execToast('error', intl.formatMessage({ id: 'TOAST.LOGIN_ERROR_ACCESSTYPE' }), false);

              disableLoading();
              setSubmitting(false);
            } else if (response.data?.data?.token) {

              // LOGIN REALIZADO

              localStorage.setItem('licenseExpiration', response.data?.data.licenseExpiration)
              localStorage.setItem('resellerId', response.data?.data.resellerId)

              globalConfigs.saveConfigsInMachine(response.data?.data?.countryDetail)

              cookie.set('auth-token', response.data?.data?.token);
              disableLoading();

              //@ts-ignore
              window.h = response.data?.data?.token;

              localStorage.setItem('@P', JSON.stringify(response.data?.data.claims));
              localStorage.setItem('QWER', JSON.stringify({
                id: response.data?.data?.userId,
                qwer: response.data?.data?.isAdmin,
              }));



              setCookieRefreshToken({
                // expiration: '2024-03-0416:40:00',
                expiration: response.data?.data?.expiration,
                refreshToken: response.data?.data?.refreshToken
              })

              props.login(response.data?.data);

              execToast('success', intl.formatMessage({ id: 'TOAST.LOGIN_SUCCESS' }), 3000);

              try {
                const responseConfig = await getConfigServiceDashboard(response.data?.data?.userId, response.data?.data?.token);


                const customLabels = new CustomLabels()

                customLabels.setCustomLabel({
                  inputName: 'Pessoas',
                  value: responseConfig.data.data.personalizedPersonLabel
                })

                customLabels.setCustomLabel({
                  inputName: 'personalizedMessageForRemoteRegistration',
                  value: responseConfig.data.data.personalizedMessageForRemoteRegistration
                })

                if (responseConfig.data.data.dateFormat &&
                  responseConfig.data.data.timeFormat
                ) {
                  defaultDateTime.setDefault({
                    date: responseConfig.data.data.dateFormat,
                    time: responseConfig.data.data.timeFormat
                  })
                }

                const dataToStore = responseConfig.data.data.value;

                let language_to_number = '';

                if (dataToStore.defaultLanguage === 1) language_to_number = 'pt';
                if (dataToStore.defaultLanguage === 2) language_to_number = 'en';
                if (dataToStore.defaultLanguage === 3) language_to_number = 'es';
                if (dataToStore.defaultLanguage === 4) language_to_number = 'fr';

                dispatch(
                  ALTER_PACK_CONFIG({
                    language: language_to_number,
                    personCardType: dataToStore.defaultCardType,
                    personDocumentType: dataToStore.defaultDocumentType === 1 ? 'rg' : 'cpf',
                    timeZone: dataToStore.defaultTimeZone,
                    timeZoneCode: dataToStore.dafaultTimeZoneCode
                  })
                );

                const LANGUAGUE_PRESET = localStorage.getItem('i18nConfig');

                if (!LANGUAGUE_PRESET) {
                  setLanguage(language_to_number);
                  return;
                }

                if (language_to_number !== JSON.parse(LANGUAGUE_PRESET).selectedLang) {
                  setLanguage(language_to_number);
                }





              } catch {

                getConfigService(0).then(response => {


                  if (response.data.data.dateFormat &&
                    response.data.data.timeFormat
                  ) {
                    defaultDateTime.setDefault({
                      date: response.data.data.dateFormat,
                      time: response.data.data.timeFormat
                    })
                  }
                })
              }

              try {
                const permissions = response.data?.data.claims;

                const permissionsData = convertTypePermissions(permissions);

                dispatch(ALTER_PERMISSIONS_SYSTEM(permissionsData));

                const isModeDemo = response.data?.data.tenant === 'Control iD' || response.data?.data.tenant === 'controlid' || response.data?.data.tenant === 'fabcidnovo' || response.data?.data.tenant === 'Fábrica Control iD Novo';
                localStorage.setItem('controlid-demo', response.data?.data.tenant);

                dispatch(ALTER_MODE_OPERATION(isModeDemo));
              } catch { }



            } else {
              var tenant = document.getElementById('tenant') as HTMLSelectElement;
              var tenantGroup = document.getElementById('tenantGroup') as HTMLSelectElement;

              if (!values.tenantId && response.data?.data.length > 1 && tenant?.value === '') {
                response.data?.data.forEach((item: any) => {
                  let option = document.createElement('option');

                  option.value = item.id;
                  option.innerHTML = `${item.registration} - ${item.name}`;
                  tenant?.appendChild(option);

                  disableLoading();
                  setSubmitting(false);
                });
                setTenets([...response.data?.data]);
                tenantGroup?.classList.remove('hide-file');
              } else {
                let tenantIdRequest: number;
                tenantIdRequest = response.data?.data.length == 1 ? response.data?.data[0].id : tenant?.value;
                if (!tenantIdRequest && tenants.length) tenantIdRequest = tenants[0].id;
                loginTenant(values.email, values.password, tenantIdRequest)
                  .then((response: any) => {
                    disableLoading();
                    props.login(response.data?.data);
                    execToast('success', intl.formatMessage({ id: 'TOAST.LOGIN_SUCCESS' }), 3000);
                  })
                  .catch(() => {
                    execToast(
                      'error',
                      intl.formatMessage({
                        id: 'AUTH.VALIDATION.INVALID_LOGIN'
                      }),
                      false
                    );
                    disableLoading();
                    setSubmitting(false);
                    setStatus(
                      intl.formatMessage({
                        id: 'AUTH.VALIDATION.INVALID_LOGIN'
                      })
                    );
                  });
              }
            }
          })
          .catch((error) => {
            if (error?.response?.data?.message == '452') {
              execToast('error', intl.formatMessage({ id: 'TOAST.LOGIN_ERROR_LICENCEEXPIRATION' }), false);
            } else if (error?.response?.data?.message == '406') {
              execToast('error', intl.formatMessage({ id: 'AUTH.NOTVALIDATION.INVALID_LOGIN' }), false);
              disableLoading();
              setSubmitting(false);
              // setStatus(intl.formatMessage({id: 'AUTH.VALIDATION.INVALID_LOGIN'}));
            } else {
              execToast('error', intl.formatMessage({ id: 'AUTH.VALIDATION.INVALID_LOGIN' }), false);
              disableLoading();
              setSubmitting(false);
              setStatus(intl.formatMessage({ id: 'AUTH.VALIDATION.INVALID_LOGIN' }));
            }
          });
      }, 1000);
    }
  });

  // useEffect(() => {
  //   indexedDB.indexedStarted().then(async () => {
  //     const data: any = await indexedDB.loadConfig();


  //     if (data.length === 0) return;

  //     dispatch(ALTER_LANGUAGUE_CONFIG({language: data[0].language}));
  //     // if (lan === data[0].language) {
  //     //   return;
  //     // }

  //     // setLanguage(data[0].language);
  //   });
  // }, []);

  useEffect(() => {
    setRequestNewTokenProgress(false)
  }, [])

  useEffect(() => {

    try {

      if (window.location.href.match(/lang/g)) {
        const language = window.location.href.match(/en|es|pt|fr/g) || ['pt']
        setLanguage(language)
      } else {
        const browserLang = window.navigator.language.match(/en|es|pt/g) || ['pt']

        // setLanguage(browserLang)
      }
    } catch (e) {
      console.error({ e })
      console.log({ e })
    }
  }, [])

  return (
    <div className='login-form login-signin' id='kt_login_signin_form'>
      {/* begin::Head */}
      <div className='text-center mb-10 mb-lg-20'>
        <div className='dropdown-flag'>{layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />}</div>
      </div>
      {/* end::Head */}
      <div className='bg-dark-position-center mb-20'>
        <Link to='' className='brand-logo'>
          <img alt='logo' src={layoutProps.headerBlackLogo} className='bg-width-300 ' />
        </Link>
        {/* <h5 className=''>iDSecure - {formTitle}</h5> */}
        <h5 className=''>iDSecure</h5>
      </div>

      {/*begin::Form*/}
      <form onSubmit={formik.handleSubmit} className='form fv-plugins-bootstrap fv-plugins-framework'>
        <div className='form-group fv-plugins-icon-container'>
          <label> {labelEmail} </label>
          <input placeholder='Email' type='email' className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('email')}`} {...formik.getFieldProps('email')} />
          {formik.touched.email && formik.errors.email ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className='form-group fv-plugins-icon-container'>
          <label> {labelPassword} </label>
          <input placeholder='Password' type='password' className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('password')}`} {...formik.getFieldProps('password')} />

          {formik.touched.password && formik.errors.password ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className='form-group fv-plugins-icon-container hide-file' id='tenantGroup'>
          <label> {labelTenant} </label>
          <select placeholder='TenantId' id='tenant' className={`form-control form-control-solid h-auto py-5 px-6`} {...formik.getFieldProps('tenantId')}></select>
        </div>
        <div className='form-group d-flex flex-wrap justify-content-between align-items-center'>
          <Link to='/auth/forgot-password' className='text-dark-50 text-hover-primary my-3 mr-2' id='kt_login_forgot'>
            <FormattedMessage id='AUTH.GENERAL.FORGOT_BUTTON' />
          </Link>
          <button id='kt_login_signin_submit' type='submit' disabled={formik.isSubmitting} className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}>
            <FormattedMessage id='AUTH.GENERAL.SUBMIT_BUTTON' />
            {loading && <span className='ml-3 spinner spinner-white'></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
