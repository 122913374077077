import React, { useEffect, useState } from 'react';

import BootstrapTable, { PaginationOptions } from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { Pagination } from 'src/_metronic/_partials/controls';

import { FormattedMessage, useIntl } from 'react-intl';

import { NoRecordsFoundMessage, PleaseWaitMessage, sortCaret, headerSortingClasses } from '../../../../../_metronic/_helpers';

import { Icon } from '@material-ui/core';
import './styles.scss';

import { pageListRendererModal } from '../../../../../_metronic/_partials/controls/CustonButtonNextPagination';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { InputModalSearch } from '../../../../../_metronic/_partials/controls/InputSeachModal';

import { getAllPersons } from '../../../../../services/permissionLogs';
import { useTableHooks } from '../../hooks/table';
import { findGroups } from 'src/services/accessLogsCrud';
import { standardTranslation } from 'src/utils/standardTranslation';

type Person = {
  id: number;
  name: string;
};

interface Props {
  data: {
    removeSelect: (id: number) => void;
    addSelect: (id: Person) => void;
    itensSelected: Person[];
  };
}

let debounce: NodeJS.Timeout | null;

export function TableSelectionGroupsAndCompanies({ data: { itensSelected, removeSelect, addSelect } }: Props) {
  const intl = useIntl();
  const { INTL } = useTableHooks();
  const [personList, setPersonList] = useState<Person[]>([]);
  const [pageCurrentAvaliable, setPageCurrentAvaliable] = useState(1)
  const [totalToPageAvaliable, setTotalToPageAvaliable] = useState(5)
  const [totalItensAvailiable, setTotalItensAvailiable] = useState(0)
  const [search, setSearch] = useState('');


  const [pageCurrentSelected, setPageCurrentSelected] = useState(1)
  const [totalToPageSelected, setTotalToPageSelected] = useState(5)
  const [itensSelectedLocal, setItensSelectedLocal] = useState<Person[]>([]);
  const [searchSelecteds, setSearchSelecteds] = useState('');
  const [totalItensSelected, setTotalItensSelected] = useState(0)



  const actionIconLeft = (cell: any, row: any) => {
    return <span className='span-arrow'>&lt;</span>;
  };

  const actionIconRight = (cell: any, row: any) => {
    return <span className='span-arrow'>&gt;</span>;
  };

  const actionSetSelected = (cell: any, row: any) => {
    return (
      <button
        className='btn btn-primary modal-button'
        onClick={() => {
          // setSelected(row.id);
          const person = personList.find((person) => person.id === row.id)!;

          addSelect(person);
        }}>
        <i className='fa fa-arrow-circle-right'></i>
      </button>
    );
  };

  const actionSetAvailable = (cell: any, row: any) => {
    return (
      <button className='btn btn-danger modal-button' onClick={() => removeSelect(row.id)}>
        <i className='fa fa-arrow-circle-left'></i>
      </button>
    );
  };

  const personDataAvailable = personList.filter((person) => {
    const personIsSelected = itensSelected.find((personSelected) => personSelected.id === person.id);

    if (!personIsSelected) {
      return person;
    }
  });

  const selectedColumns = [
    {
      dataField: 'action',
      text: '',
      formatter: actionSetAvailable,
      headerFormatter: actionIconLeft,
      classes: 'modal-table modal-table__action',
      headerClasses: 'modal-table modal-table__action modal-table__icon'
    },
    {
      dataField: 'name',
      text: INTL('PERSON.PERSONS_TABLE_NAME'),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    }
  ];

  const availableColumns = [
    {
      dataField: 'name',
      text: INTL('PERSON.PERSONS_TABLE_NAME'),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'action',
      text: '',
      formatter: actionSetSelected,
      headerFormatter: actionIconRight,
      classes: 'modal-table modal-table__action',
      headerClasses: 'modal-table modal-table__action modal-table__icon'
    }
  ];

  const optionsAvaliable = {
    custom: true,
    totalSize: totalItensAvailiable,
    sizePerPageList: window.sizePerPageList,
    sizePerPage: totalToPageAvaliable,
    page: pageCurrentAvaliable
  }

  const optionsSelecteds = {
    custom: true,
    totalSize: totalItensSelected,
    sizePerPageList: window.sizePerPageList,
    sizePerPage: totalToPageSelected,
    page: pageCurrentSelected
  }

  const getPeople = async (value: string) => {
    const {
      data: {
        data: { data: people, total }
      }
    } = await getAllPersons({
      pageSize: totalToPageAvaliable,
      pageNumber: pageCurrentAvaliable,
      status: 1,
      ...(value && { value }),
      sortOrder: 'asc',
      sortField: 'Name',
      personType: 'Visitant'
    });


    setTotalItensAvailiable(total - itensSelected.length)
    setPersonList(people);
  };

  const getGroupsAndBuniss = async (value: string) => {
    const {
      data: {
        data: { data: people, total: totalOne }
      }
    } = await findGroups({
      groupType: 1,
      status: 1,
      sortOrder: 'asc',
      ...(value && { value }),
      sortField: 'Description',
      pageSize: totalToPageAvaliable,
      pageNumber: pageCurrentAvaliable,
    });

    const {
      data: {
        data: { data: buniss, total: totalTwo }
      }
    } = await findGroups({
      groupType: 4,
      status: 1,
      sortOrder: 'asc',
      ...(value && { value }),
      sortField: 'Description',
      pageSize: totalToPageAvaliable,
      pageNumber: pageCurrentAvaliable,
    });

    const total = totalTwo + totalOne




    const dataFull = [...people.map((el: any) => ({ ...el, name: standardTranslation(el.description, intl) })), ...buniss.map((el: any) => ({ ...el, name: standardTranslation(el.description, intl) }))]
    setTotalItensAvailiable(total - itensSelected.length)
    setPersonList(dataFull);
  };


  useEffect(() => {
    getGroupsAndBuniss(search);
  }, [pageCurrentAvaliable, itensSelected, totalToPageAvaliable, search]);

  useEffect(() => {

    if (!searchSelecteds) {
      setItensSelectedLocal(itensSelected)
      setTotalItensSelected(itensSelected.length)
      return
    } else {
      const update = itensSelected.filter(person => person.name.toLowerCase().includes(searchSelecteds.toLowerCase()))
      setItensSelectedLocal(update)
      setTotalItensSelected(update.length)
    }


  }, [searchSelecteds, itensSelected])




  return (
    <>
      <div className='row'>
        <div className='col-lg-6 pagination-enable'>
          <span className='SI'>
            <div className='row'>
              <div className='col-lg-12'>
                <span className='label-controls'>
                  <Icon className='icon-layers'>layers</Icon>
                  <FormattedMessage id='GENERIC.SELECTS' />
                </span>
              </div>
              <div className='col-lg-12'>
                <input
                  onChange={({ target }) => {
                    if (debounce) clearTimeout(debounce);
                    debounce = setTimeout(() => {
                      setSearch(target.value);
                    }, 500);
                  }}
                  className='form-control'
                  type='text'
                  placeholder={INTL('GENERIC.SEARCH')}
                  style={{ marginTop: '7px' }}
                />
              </div>
            </div>

            <PaginationProvider
              pagination={paginationFactory(
                //@ts-ignore
                optionsAvaliable
              )}>
              {({ paginationProps, paginationTableProps }: any) => {

                return (
                  <Pagination isLoading={false} paginationProps={paginationProps}>
                    <form
                      className={'form mb-3'}
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}></form>
                    <BootstrapTable
                      onTableChange={(type, props) => {
                        // if (type == 'sort') {
                        //   setOrder(props.sortOrder);
                        // }
                        // setPagination(props.page);
                        // setSizePerPage(props.sizePerPage);
                        setPageCurrentAvaliable(props.page)
                        setTotalToPageAvaliable(props.sizePerPage)
                      }}
                      // defaultSorted={[defaultSortedColumn]}
                      wrapperClasses='table-container'
                      bordered={false}
                      classes='table table-head-custom table-vertical-center overflow-hidden'
                      remote
                      bootstrap4
                      keyField='id'
                      data={personDataAvailable}
                      columns={availableColumns}
                      // columns={tableColumns(lang, intl)}
                      {...paginationTableProps}>
                      <PleaseWaitMessage entities={personDataAvailable} />
                      <NoRecordsFoundMessage entities={[personDataAvailable]} />
                    </BootstrapTable>
                  </Pagination>
                );
              }}
            </PaginationProvider>
          </span>
        </div>

        <div className='col-lg-6'>
          <span className='SI'>
            <div className='row'>
              <div className='col-lg-12'>
                <span className='label-controls'>
                  <Icon className='icon-layers'>layers</Icon>
                  <FormattedMessage id='GENERIC.SELECTS' />
                </span>
              </div>
              <div className='col-lg-12'>
                <input
                  onChange={({ target }) => {
                    if (debounce) clearTimeout(debounce);
                    debounce = setTimeout(() => {
                      setSearchSelecteds(target.value);
                    }, 500);
                  }}
                  className='form-control'
                  type='text'
                  placeholder={INTL('GENERIC.SEARCH')}
                  style={{ marginTop: '7px' }}
                />
              </div>
            </div>

            <PaginationProvider
              pagination={paginationFactory(
                //@ts-ignore
                optionsSelecteds
              )}>
              {({ paginationProps, paginationTableProps }: any) => {

                return (
                  <Pagination isLoading={false} paginationProps={paginationProps}>
                    <form
                      className={'form mb-3'}
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}></form>
                    <BootstrapTable
                      onTableChange={(type, props) => {
                        setPageCurrentSelected(props.page)
                        setTotalToPageSelected(props.sizePerPage)
                      }}
                      // defaultSorted={[defaultSortedColumn]}
                      wrapperClasses='table-container'
                      bordered={false}
                      classes='table table-head-custom table-vertical-center overflow-hidden'
                      remote
                      bootstrap4
                      keyField='id'
                      data={itensSelectedLocal}
                      columns={selectedColumns}
                      // columns={tableColumns(lang, intl)}
                      {...paginationTableProps}>
                      <PleaseWaitMessage entities={itensSelectedLocal} />
                      <NoRecordsFoundMessage entities={[itensSelectedLocal]} />
                    </BootstrapTable>
                  </Pagination>
                );
              }}
            </PaginationProvider>
          </span>
        </div>
      </div>
    </>
  );
}
