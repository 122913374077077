import React from 'react';
import InputMask from 'react-input-mask';
import {useIntl} from 'react-intl';

const getFieldCSSClasses = (touched, errors, value) => {
  const classes = ['form-control'];
  if (touched && errors) {
    classes.push('is-invalid');
  }
  // so add que o formulario esta bala agora quando tem um valor associado ✌️
  if (touched && !errors && !!value) {
    // nao comentar isso agora eu vaalido se o valor esta preenchido
    classes.push('is-valid');
  }

  return classes.join(' ');
};

export function Input({
  field, // { name, value, onChange, onBlur }
  form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  mask,
  defaultValue,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = 'text',
  ...props
}) {
  // const {setFieldValue, values} = useFormikContext();
  const intl = useIntl();
  field.value = field.value == 'AREA.DEFAULT' ? intl.formatMessage({id:'AREA.DEFAULT'}) : field.value
  field.value = field.value == 'SCHEDULE.DEFAULT' ? intl.formatMessage({id:'SCHEDULE.DEFAULT'}) : field.value
  field.value = field.value == 'ACRULE.DEFAULT.VISITOR' ? intl.formatMessage({id:'ACRULE.DEFAULT.VISITOR'}) : field.value
  field.value = field.value == 'ACRULE.DEFAULT.PERSON' ? intl.formatMessage({id:'ACRULE.DEFAULT.PERSON'}) : field.value
  field.value = field.value == 'GROUPS.DEFAULT.VISITOR' ? intl.formatMessage({id:'GROUPS.DEFAULT.VISITOR'}) : field.value
  field.value = field.value == 'GROUPS.DEFAULT.PERSON' ? intl.formatMessage({id:'GROUPS.DEFAULT.PERSON'}) : field.value
 

  return (
    <>
      {label && <label> {label}</label>}
      <InputMask type={type} mask={mask} defaultValue={defaultValue} className={getFieldCSSClasses(touched[field.name], errors[field.name], field.value)} {...field} {...props} />
      {/* {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )} */}
    </>
  );
}
