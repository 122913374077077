import styled, {css} from 'styled-components';

export const Wrapper = styled.div`
  margin: auto;
  max-width: 750px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;

  > header {
    text-align: center;
  }

  .form-container {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .footer {
    align-self: end;

    display: flex;
    align-items: center;
    gap: 25px;
  }

  #select {
    & * {
      border-color: #e4e6ef;
    }
  }

  @media (max-width: 576px) {
    .form-container {
      gap: 15px;
    }

    .title {
      font-size: 24px;
    }

    gap: 25px;
  }
`;

export const Date = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 15px;

  border-radius: 5px;

  background-color: #f3f6f9;
  border: 1px solid #e5e7eb;

  svg {
    font-size: 24px;
  }

  span {
    line-height: 1;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  gap: 20px;

  color: #6b7280;

  h1,
  span {
    margin: 0;
    font-weight: 400;
  }

  h1 {
    font-size: 13px;
    margin-bottom: 7px;
  }
  span {
    font-size: 14px;
  }

  > div {
    flex: 1;
  }
`;

export const LoadingScreen = styled.div`
  position: fixed;
  inset: 0;
  z-index: 999;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(17, 21, 24, 0.45);
  transition: all 0.15s ease-out;

  backdrop-filter: blur(10px);

  color: white;
`;
