export namespace GetVisitorErrors {
  export class InvalidToken extends Error {
    constructor() {
      super('REMOTE_ONBOARDING_ERRORS_INVALID_TOKEN');
      this.name = 'InvalidToken';
    }
  }
  export class TokenNotFound extends Error {
    constructor() {
      super('REMOTE_ONBOARDING_ERRORS_INVALID_TOKEN');
      this.name = 'TokenNotFound';
    }
  }
}
