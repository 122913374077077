import React, {useEffect, useState} from 'react';

import {useParkingProvider} from '../Context/ParkingContext';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import {useIntl} from 'react-intl';
import {usePermisssion} from 'src/redux/userPermissions/usePermission';

export function Modals() {
  const {modals, fn} = useParkingProvider();
  const {showModalEdit, showModalDelete, showModalErase, modalToggle, itemSelected} = modals;

  const [isRead, setIsRead] = useState(false);

  const {redirectView, actionsIsDesatived} = usePermisssion();

  useEffect(() => {
    redirectView(20);
    const value = actionsIsDesatived(20);
    setIsRead(value);
  }, []);

  const intl = useIntl();

  function INTL(value: string) {
    return intl.formatMessage({id: value});
  }

  return (
    <>
      <Modal show={showModalDelete} onHide={() => modalToggle('delete')}>
        <Modal.Header closeButton>
          <Modal.Title>
            {INTL('TOAST.DELETE_BUTTON')} : {itemSelected.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{intl.formatMessage({id: 'PARKING.DO_YOU_WANT_TO_INACTIVATE_THIS_CAR_PARK'})} </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              modalToggle('delete');
            }}>
            {INTL('PERSON.NEWPERSON_CANCEL_BUTTON')}
          </Button>
          <div style={{width: '88px'}} />
          <Button
            variant='outline-danger'
            onClick={() => {
              modalToggle('erase');
              modalToggle('delete');
            }}>
            {INTL('PERMANENTLY_DELETE')}
          </Button>

          <Button
            variant='danger'
            disabled={isRead}
            onClick={async () => {
              fn.deletePark();
            }}>
            {INTL('TOAST.DELETE_BUTTON')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModalErase} onHide={() => modalToggle('erase')}>
        <Modal.Header closeButton>
          <Modal.Title style={{color: '#F64E60'}}>{`${itemSelected.name}: ${INTL(
            'ARE_YOU_SURE_YOU_WANT_TO_PERMANENTLY_DELETE_IT'
          )}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{intl.formatMessage({id: 'THIS_ACTION_CANNOT_BE_UNDONE'})} </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              modalToggle('erase');
            }}>
            {INTL('PERSON.NEWPERSON_CANCEL_BUTTON')}
          </Button>

          <div style={{width: '34px'}} />
          <Button
            disabled={isRead}
            variant='outline-danger'
            onClick={() => {
              fn.deletePark('inactivate');
            }}>
            {intl.formatMessage({id: 'INACTIVATE_ONLY'})}
          </Button>

          <Button
            variant='danger'
            disabled={isRead}
            onClick={async () => {
              fn.deletePark('erase');
            }}>
            {INTL('PERMANENTLY_DELETE')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalEdit} onHide={() => modalToggle('reactive')}>
        <Modal.Header closeButton>
          <Modal.Title>
            {INTL('TOAST.ENABLE')} : {itemSelected.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{intl.formatMessage({id: 'PARKING.DO_YOU_WANT_TO_ENABLE_PARKING'})}</Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              modalToggle('reactive');
            }}>
            {INTL('PERSON.NEWPERSON_CANCEL_BUTTON')}
          </Button>
          <Button
            variant='primary'
            // disabled={isRead}
            onClick={async () => {
              fn.reativePark();
            }}>
            {INTL('TOAST.ENABLE')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
