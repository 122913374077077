import React, {useMemo, useState} from 'react';
import {useFormik, getIn} from 'formik';
import {connect} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import * as Yup from 'yup';
import {FormattedMessage, injectIntl} from 'react-intl';
import * as auth from '../_redux/authRedux';
import {requestPassword} from '../../../../services/authCrud';
import {useHtmlClassService} from 'src/_metronic/layout';
import objectPath from 'object-path';
import {LanguageSelectorDropdown} from 'src/_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown';
import {execToast} from 'src/_metronic/_partials/controls/Toast';

const initialValues = {
  email: ''
};

function ForgotPassword(props: any) {
  let lang = localStorage.getItem('i18nConfig');
  const {intl} = props;
  const [isRequested, setIsRequested] = useState(false);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(uiService.config, 'extras.search.display'),
      viewNotificationsDisplay: objectPath.get(uiService.config, 'extras.notifications.display'),
      viewQuickActionsDisplay: objectPath.get(uiService.config, 'extras.quick-actions.display'),
      viewCartDisplay: objectPath.get(uiService.config, 'extras.cart.display'),
      viewQuickPanelDisplay: objectPath.get(uiService.config, 'extras.quick-panel.display'),
      viewLanguagesDisplay: objectPath.get(uiService.config, 'extras.languages.display'),
      viewUserDisplay: objectPath.get(uiService.config, 'extras.user.display'),

      headerBlackLogo: uiService.getLogoDark()
    };
  }, [uiService]);

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
  });

  const getInputClasses = (fieldname: string) => {
    if (getIn(formik.touched, fieldname) && getIn(formik.errors, fieldname)) {
      return 'is-invalid';
    }

    if (getIn(formik.touched, fieldname) && !getIn(formik.errors, fieldname)) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      const newLang = JSON.parse(lang || '{"selectedLang":"en"}');
      requestPassword(values.email, newLang.selectedLang)
        .then(() => {
          setIsRequested(true);
          execToast('success', intl.formatMessage({id: 'AUTH.FORGOT.SUCCESS'}), 3000);
        })
        .catch(() => {
          // Show success even though the e-mail was not found in our system
          setIsRequested(true);
          execToast('success', intl.formatMessage({id: 'AUTH.FORGOT.SUCCESS'}), 3000);
        });
    }
  });

  return (
    <>
      {isRequested && <Redirect to='/auth' />}
      {!isRequested && (
        <div className='login-form login-forgot' style={{display: 'block'}}>
          <div className='text-center mb-10 mb-lg-20'>
            <div className='dropdown-flag'>{layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />}</div>
          </div>
          <div className='text-center mb-10 mb-lg-20'>
            <div className='bg-dark-position-center mb-20'>
              <Link to='' className='brand-logo'>
                <img alt='logo' src={layoutProps.headerBlackLogo} className='bg-width-300 ' />
              </Link>
            </div>
            <h3 className='font-size-h1'>
              <FormattedMessage id='AUTH.FORGOT.TITLE' />
            </h3>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className='form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp'>
            {formik.status && (
              <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}
            <div className='form-group fv-plugins-icon-container'>
              <label>
                <div className='text-muted font-weight-bold'>
                  <FormattedMessage id='AUTH.FORGOT.DESC' />
                </div>
              </label>
              <input
                type='email'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('email')}`}
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className='form-group d-flex flex-wrap flex-center'>
              <button
                id='kt_login_forgot_submit'
                type='submit'
                className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4'
                disabled={formik.isSubmitting}>
                <FormattedMessage id='AUTH.FORGOT.SUBMIT' />
              </button>
              <Link to='/auth'>
                <button
                  type='button'
                  id='kt_login_forgot_cancel'
                  className='btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4'>
                  <FormattedMessage id='PERSON.NEWPERSON_CANCEL_BUTTON' />
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
